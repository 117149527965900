import gql from "graphql-tag";

export default gql`
  mutation($filename: String!, $filemine: String!) {
    createSignedUrl(filename: $filename, filemine: $filemine) {
      key
      url
    }
  }
`;
