import React, { Component } from "react";
import styled from "styled-components";
import UploadSingle from "../UploadSingle";
import { Row, Col, Button, Card, Skeleton, notification, Modal } from "antd";
import productById from "../../graphql/queries/productById";
import { Query } from "react-apollo";
import Meta from "antd/lib/card/Meta";
import { AWS_CLOUDFRONT_URL } from "../../config";
import { UPLOAD_PRINT_FILE } from "../../graphql/mutates/uploadPrintFiles";
import approveProductDesign from "../../graphql/mutates/approveProductDesign";
import { apolloClient } from "../../apollo-client";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import _ from "lodash";
import ImageUrl from "../ImageUrl";

const Container = styled.div`
  .ant-card .ant-card-body {
    padding: 0;
  }
  .ant-card .ant-card-head {
    padding: 0 15px;
  }
  .card-footer {
    padding: 0 15px 15px;
    height: 70px;
  }
  h3 {
    margin-top: 0px;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
  }
  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-size: 14px;
    }
  }
`;

class UploadDesign extends Component {
  state = {
    designs: [],
    product: null,
    visible: false,
  };

  onChangePrintFiles = (designId, file) => {
    const newDesigns = [...this.state.designs];
    const designIndex = newDesigns.findIndex((el) => el.id === designId);
    newDesigns[designIndex].file = { ...file.file, key: file.file.key };
    newDesigns[designIndex].fileId = file.fileId;
    this.setState({ designs: newDesigns });
  };

  bytesToSize = (fileSize) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (fileSize === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(fileSize) / Math.log(1024)));
    return Math.round(fileSize / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  onSavePrintFiles = (value) => {
    const formatDesigns = [...this.state.designs].map((design) => {
      return {
        id: design.id,
        baseDesignId: design.baseDesignId,
        fileId: design.fileId,
      };
    });
    apolloClient
      .mutate({
        mutation: UPLOAD_PRINT_FILE,
        variables: {
          productId: this.props.productOrder.product.id,
          designs: formatDesigns,
        },
      })
      .then(() => {
        if (value) {
          this.onApprovePrintFiles();
        } else {
          notification.success({ message: "Upload print files successful!" });
          this.props.onClose();
          this.props.refetch();
        }
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  onApprovePrintFiles = () => {
    apolloClient
      .mutate({
        mutation: approveProductDesign,
        variables: {
          productId: this.props.productOrder.product.id,
        },
      })
      .then(() => {
        notification.success({ message: "Print files had been approved!" });
        this.props.refetch();
        this.props.onClose();
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  render() {
    const { productOrder } = this.props;
    const { product } = this.state;
    if (!productOrder) return <Skeleton />;
    return (
      <Container>
        <Row>
          <h2>Print Files</h2>
          <Query
            query={productById}
            fetchPolicy="no-cache"
            variables={{ id: productOrder.product.id }}
            onCompleted={(res) => {
              this.setState({
                designs: res.product.designs,
                product: res.product,
              });
            }}
          >
            {({ error, loading, data, refetch }) => {
              if (loading) return <Skeleton />;
              if (error) return <div>{error.toString()}</div>;
              this.refetch = refetch;
              // designs format data
              const mergeDesignByBaseId = () => {
                return data.product.productBases?.reduce((init, base) => {
                  var key = base.id;
                  if (!init[key]) {
                    init[key] = {
                      title: base.title,
                      designs: [],
                    };
                  }
                  data.product.designs.forEach((ds) => {
                    if (ds.baseDesign.baseId === base.id) {
                      init[key].designs.push({
                        ...ds,
                      });
                    }
                  });
                  return init;
                }, {});
              };
              return (
                <Col lg={24} span={24}>
                  {_.map(mergeDesignByBaseId(), (designHasBase, key) => (
                    <div key={key}>
                      <h3>{designHasBase.title}</h3>
                      <Row>
                        {_.orderBy(
                          designHasBase.designs,
                          [(design) => design.baseDesign.ordering],
                          ["asc"]
                        ).map((design, index) => (
                          <Col
                            style={{ paddingRight: 15, marginBottom: 20 }}
                            key={index}
                            lg={8}
                            span={8}
                          >
                            <Card title={design.baseDesign.name}>
                              <div>
                                <div
                                  style={{
                                    pointerEvents:
                                      // productOrder.status === "fulfilled" &&
                                      ["fulfilled", "in_production"].includes(
                                        productOrder.status
                                      ) && product?.designStatus === "approved"
                                        ? "none"
                                        : "auto",
                                  }}
                                >
                                  <UploadSingle
                                    disabled={["fulfilled", "in_production", "cancelled"].includes(productOrder.status)}
                                    printFiles={true}
                                    onChangePrintFiles={(file) =>
                                      this.onChangePrintFiles(design.id, file)
                                    }
                                    setWidth="100%"
                                    setHeight="150px"
                                    imageKey={
                                      //design.file?.thumbnail ?? design.file?.key
                                      design.file?.key
                                    }
                                  />
                                </div>
                              </div>
                              {design.file ? (
                                <Meta
                                  className="card-footer"
                                  title={design.file.fileName}
                                  description={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: 14,
                                      }}
                                    >
                                      <span>
                                        {this.bytesToSize(design.file.fileSize)}
                                      </span>
                                      <div>
                                        <Button
                                          type="link"
                                          size={"small"}
                                          onClick={() => {
                                            this.setState({
                                              visible: design.file,
                                            });
                                          }}
                                          icon={<EyeOutlined />}
                                        />

                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href={`${AWS_CLOUDFRONT_URL}/${design.file.key}`}
                                        >
                                          <DownloadOutlined
                                            style={{ color: "dodgerblue" }}
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  }
                                />
                              ) : (
                                <Meta
                                  className="card-footer"
                                  title={`${design.baseDesign.width} x ${design.baseDesign.height}`}
                                  description="Please upload correct print file"
                                />
                              )}
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                  {product && product.mockups.length > 0 && (
                    <div
                      style={{
                        marginBottom: 50,
                        borderTop: "1px solid #f0f0f0",
                        paddingTop: 10,
                      }}
                    >
                      <h3>Product mockups </h3>
                      <div style={{ textAlign: "left" }}>
                        <img
                          style={{ maxWidth: 400, height: "400" }}
                          src={
                            product.mockups[0].file.source === "store"
                              ? product.mockups[0].file.url
                              : `${AWS_CLOUDFRONT_URL}/${product.mockups[0].file.key}`
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </Col>
              );
            }}
          </Query>
        </Row>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            borderTop: "1px solid #ccc",
            height: 65,
            right: 0,
            textAlign: "right",
            background: "white",
          }}
        >
          <div>
            <Button
              style={{ margin: "15px 15px 15px 0" }}
              onClick={() => {
                this.props.onClose();
                this.refetch();
              }}
            >
              Cancel
            </Button>
            {product?.designStatus === "done" && (
              <Button
                onClick={() => this.onSavePrintFiles("designed")}
                type="primary"
                style={{ margin: "15px 15px 15px 0" }}
              >
                Approve
              </Button>
            )}
            {!["fulfilled", "in_production", "cancelled"].includes(productOrder.status) && (
              <Button
                onClick={() => this.onSavePrintFiles()}
                type="primary"
                style={{ margin: "15px 15px 15px 0" }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <Modal
          title="View lange file"
          visible={typeof this.state.visible === "object" ? true : false}
          onCancel={() => this.setState({ visible: false })}
          footer={false}
        >
          <ImageUrl
            widthImage="100%"
            imageKey={this.state.visible && this.state.visible.key}
          // id={this.state.visible && this.state.visible.id}
          />
        </Modal>
      </Container>
    );
  }
}

export default UploadDesign;
