import { Form, Input, notification, Select, Skeleton } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Query } from "react-apollo";
import { SET_FF_VARINAT_ID } from "../../graphql/mutates/SetFFVariantID";
import fulfillmentProducts from "../../graphql/queries/orders/fulfillmentProducts";
import productBaseByIds from "../../graphql/queries/productBaseByIds";
import _ from "lodash";

const FulfillVariant = ({ order, refetch, closeModal }) => {
  const [setFFVariant] = useMutation(SET_FF_VARINAT_ID);
  const [variantCC, setVariantsCC] = useState();
  const [variantDS, setVariantDS] = useState();
  const { loading, data } = useQuery(productBaseByIds, {
    variables: { ids: [order?.variant?.productBase?.id] },
    skip: !order,
  });
  const isDreamship = data?.productBaseByIds[0].fulfillServices
    ? data?.productBaseByIds[0].fulfillServices.some(
        (item) => item.type === "dreamship"
      )
    : false;

  const isCustomcat = data?.productBaseByIds[0].fulfillServices
    ? data?.productBaseByIds[0].fulfillServices.some(
        (item) => item.type === "customcat"
      )
    : false;
  const onFinish = (value) => {
    console.log("value", value);
    setFFVariant({
      variables: {
        ccId: value.ccId ? value.ccId : null,
        dsId: value.dsId ? value.dsId : null,
        orderId: order.id,
      },
    })
      .then((res) => {
        notification.success({
          message: "Set fullfilment variant successful!",
        });
        refetch();
        closeModal();
      })
      .catch((err) => {
        notification.error({
          message: err.message,
        });
      });
  };
  return (
    <div>
      <div style={{ marginBottom: 20, fontWeight: 800 }}>
        <span>Variant App: {order.variant_title}</span>
      </div>
      <Form
        id="myForm"
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Query
          query={fulfillmentProducts}
          fetchPolicy="no-cache"
          variables={{ filter: { fulfillmentName: "customcat", limit: -1 } }}
        >
          {({ error, loading, data, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            return (
              <div>
                {isCustomcat && !order.variant?.ccId ? (
                  <div>
                    <Form.Item
                      label="Product Customcat"
                      name="productCC"
                      rules={[
                        {
                          required: isCustomcat,
                          message: "Please input your product customcat!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(value, data) => {
                          setVariantsCC(data.variants);
                        }}
                        filterOption={(input, option) =>
                          option.search
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {data.fulfillmentProducts.hits.map((item) => (
                          <Select.Option
                            value={item.id}
                            variants={item.variants}
                            key={item.id}
                            search={item.title}
                          >
                            {item.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {variantCC ? (
                      <Form.Item
                        label="Variant Customcat"
                        name="ccId"
                        rules={[
                          {
                            required: isCustomcat,
                            message: "Please input your variant customcat!",
                          },
                        ]}
                      >
                        <Select
                          filterOption={(input, option) =>
                            option.search
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                        >
                          {variantCC.map((item) => (
                            <Select.Option
                              key={item.fulfillment_product_id}
                              value={item.fulfillment_product_id}
                              search={`${item.attributes.map(
                                (item) => `${item.name}: ${item.option} `
                              )} ${item.fulfillment_product_id}`}
                            >
                              {item.attributes.map(
                                (item) => `${item.name}: ${item.option},`
                              )}
                              ({item.fulfillment_product_id})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          }}
        </Query>

        <Query
          query={fulfillmentProducts}
          fetchPolicy="no-cache"
          variables={{ filter: { fulfillmentName: "dreamship", limit: -1 } }}
        >
          {({ error, loading, data, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;

            return (
              <div>
                {isDreamship && !order.variant?.dsId ? (
                  <div>
                    <Form.Item
                      label="Product Dreamship"
                      name="productDS"
                      rules={[
                        {
                          required: isDreamship,
                          message: "Please input your product dreamship!",
                        },
                      ]}
                    >
                      <Select
                        onChange={(value, data) => {
                          setVariantDS(data.variants);
                        }}
                        filterOption={(input, option) =>
                          option.search
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {data.fulfillmentProducts.hits.map((item) => (
                          <Select.Option
                            value={item.id}
                            variants={item.variants}
                            key={item.id}
                            search={item.title}
                          >
                            {item.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {variantDS ? (
                      <Form.Item
                        label="Variant Dreamship"
                        name="dsId"
                        rules={[
                          {
                            required: isDreamship,
                            message: "Please input your variant dreamship!",
                          },
                        ]}
                      >
                        <Select
                          filterOption={(input, option) =>
                            option.search
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                        >
                          {variantDS.map((item) => (
                            <Select.Option
                              key={item.fulfillment_product_id}
                              value={item.fulfillment_product_id}
                              search={`${item.attributes.map(
                                (item) => `${item.name}: ${item.option} `
                              )} ${item.fulfillment_product_id}`}
                            >
                              {item.attributes.map(
                                (item) => `${item.name}: ${item.option},`
                              )}
                              ({item.fulfillment_product_id})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          }}
        </Query>
      </Form>
    </div>
  );
};

export default FulfillVariant;
