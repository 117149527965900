import { gql } from "apollo-boost";

export default gql`
  query productByMockup($productId: ID!) {
    productByMockup(productId: $productId) {
      title
      id
      sku
      variants {
        id
        attributes
      }
      mockups {
        id
        fileId
        fileName
        file {
          url
        }
      }
    }
  }
`;
