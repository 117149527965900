import React, { Component } from "react";
import createFile from "../graphql/mutates/createFile";
import { message } from "antd";
import Upload from "./Upload";
import { apolloClient } from "../apollo-client";
import styled from "styled-components";
// import { AWS_CLOUDFRONT_URL } from "../config";

const UploadContainer = styled.div`
  width: 100%;
`;
class UploadSingle extends Component {
  state = {
    file: this.props.file,
    uploading: false,
    percent: 0,
  };
  onUpload = (file) => {
    apolloClient
      .mutate({
        mutation: createFile,
        variables: {
          input: {
            source: "aws",
            key: file.key,
            fileName: file.file.name,
            fileMime: file.file.type,
            fileSize: file.file.size,
            width: file.width,
            height: file.height,
            genThumb: null//this.props.printFiles ?? null, TOTO: Need to check later
          },
        },
      })
      .then((res) => {
        console.log("CreateFile", res);
        if (this.props.onChange) {
          this.props.onChange(res.data.createFile.id);
        }
        if (this.props.onChangePrintFiles) {
          this.props.onChangePrintFiles({
            fileId: res.data.createFile.id,
            file: {
              source: "aws",
              key: file.key,
              fileName: file.file.name,
              fileMime: file.file.type,
              fileSize: file.file.size,
              width: file.width,
              height: file.height,
            },
          });
        }
      })
      .catch((err) => {
        console.log("create file err", err);
        message.error(err);
      });
  };
  render() {
    const { imageKey, setWidth, setHeight, printFiles } = this.props;
    return (
      <UploadContainer>
        <Upload
          imageKey={imageKey}
          key={this.props.file ? this.props.file : null}
          onChange={(file) => this.onUpload(file)}
          fileList={false}
          setWidth={setWidth}
          setHeight={setHeight}
          accept=".jpg, .png, .tiff, .psd"
          printFiles={printFiles}
          disabled={this.props.disabled}
        />
        <div>{this.props.children}</div>
      </UploadContainer>
    );
  }
}

export default UploadSingle;
