import React, { Component } from "react";
import { Table } from "antd";
import _ from "lodash";

class ViewTemplates extends Component {
  render() {
    const columns = [
      { title: "Column name", key: "name", dataIndex: "name", width: 150 },
      { title: "Type", key: "type", dataIndex: "type", width: 150 },
      { title: "Value", key: "value", dataIndex: "value", width: 150 },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <div>
        <Table
          scroll={{ x: tableWidth }}
          pagination={false}
          columns={columns}
          dataSource={this.props.data}
          rowKey={(index, key) => key}
        />
      </div>
    );
  }
}

export default ViewTemplates;
