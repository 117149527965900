import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, notification } from "antd";
import { useMutation } from "@apollo/react-hooks";
import styled from "styled-components";
import { CHANGE_PASSWORD } from "../graphql/mutates/changePassword";

const Container = styled.div``;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const ChangePassword = (props) => {
  const { onCancel, id } = props;
  const [form] = Form.useForm();
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);
  return (
    <Container>
      <Form
        {...layout}
        onFinish={(values) => {
          delete values.confirm;
          changePassword({ variables: { ...values, id: id } })
            .then(() => {
              notification.success({
                message: "Password has been changed!",
              });
              onCancel();
              form.resetFields();
            })
            .catch((err) => {
              notification.error({
                message: err.message,
              });
            });
        }}
      >
        <Form.Item
          name="current_password"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="new_password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["new_password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ textAlign: "right" }}>
          <Button
            style={{ marginTop: 20, marginRight: 15 }}
            disabled={loading}
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            style={{ marginTop: 20 }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

ChangePassword.propTypes = {
  onDone: PropTypes.func,
  onError: PropTypes.func,
};

export default ChangePassword;
