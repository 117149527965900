import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button, Popconfirm, Divider, Tooltip } from "antd";
import styled from "styled-components";
import { DeleteTwoTone, CopyOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

const Actions = styled.div``;
class MappingTable extends Component {
  render() {
    const { data, group, onEdit, onDelete, onClone } = this.props;
    let columns = [
      {
        title: "Store",
        dataIndex: "origin",
        width: 150,
        key: "store",
      },
      {
        title: "Fuel Category",
        key: "category",
        width: 200,
        dataIndex: "category",
      },
      {
        title: "Fuel Identifier ID",
        key: "target",
        width: 200,
        dataIndex: "target",
      },
      {
        title: "Fuel Print Type",
        key: "printType",
        width: 100,
        dataIndex: "printType",
      },
      {
        title: "Actions",
        width: 200,
        align: "right",
        render: (text, record, index) => (
          <Actions className={"actions"}>
            <Button onClick={() => onEdit(record)} size={"small"}>
              <EditOutlined />
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure delete this mapping?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
              okButtonProps={{type:"danger"}}
              onConfirm={() => onDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button size={"small"}>
                <DeleteTwoTone twoToneColor="#FF0000" />
              </Button>
            </Popconfirm>
            <Divider type="vertical" />
            <Tooltip title="Clone product type">
              <Button onClick={() => onClone(record)} size={"small"}>
                <CopyOutlined />
              </Button>
            </Tooltip>
          </Actions>
        ),
      },
    ];
    if (group === "Size" || group === "Color") {
      columns = [
        {
          title: "Product Type",
          dataIndex: ["parent", "origin"],
          key: "product_type",
        },
        ...columns,
      ];
    }
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <div>
        <Table
          rowKey={(_, index) => index}
          scroll={{ x: tableWidth }}
          columns={columns}
          dataSource={data.mappings.hits}
          pagination={false}
        />
      </div>
    );
  }
}

MappingTable.propTypes = {
  group: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default MappingTable;
