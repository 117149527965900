import React, { Component } from "react";
import { PageHeader } from "antd";
import { AppContext } from "../context";
import PersonalizedList from "../components/personalized/Personalized";

class Personalized extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <div>
            <PageHeader
              title="Personalized"
              onBack={() => this.props.history.goBack()}
            />
            <PersonalizedList currentUser={currentUser} />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default Personalized;
