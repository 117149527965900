import { message, Modal, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { UPDATE_ORDER_VARIANT } from '../../graphql/mutates/order';
import productVariants from '../../graphql/queries/productVariants';

const ChangeOrderVariant = ({ order, visible, setVisible, onUpdated = () => { } }) => {
  const [variants, setVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(order?.variant?.id);
  const [UpdateOrderVariant, { loading: updating }] = useMutation(UPDATE_ORDER_VARIANT)
  const { data, loading, error } = useQuery(productVariants, {
    variables: {
      id: order?.product?.id
    },
    onCompleted: data => {
      console.log(data);
      setVariants(data.product.variants?.filter(v => v.status)?.map(v => {
        return {
          id: v.id,
          title: v.attributes?.map(a => a.option).join('/')
        }
      }))
    }
  });

  const handleUpdateOrderVariant = () => {
    UpdateOrderVariant({
      variables: {
        id: order?.id,
        variantId: selectedVariant
      }
    }).then(({ data }) => {
      console.log(data);
      setVisible(false);
      onUpdated();
      message.success('Update order variant successfully');
    }).catch(err => {
      console.log(err);
      message.error('Update order variant failed');
    })
  }
  if (loading) return <Skeleton active />;
  return (
    <Modal
      title="Change order variant"
      visible={visible} onCancel={() => setVisible(false)}
      onOk={handleUpdateOrderVariant}
      okButtonProps={{ loading: updating }}
    >
      <Select
        showSearch
        style={{ width: "100%" }}
        defaultValue={selectedVariant}
        optionFilterProp="children"
        onChange={value => setSelectedVariant(value)}
      >
        {variants?.map(v => (
          <Select.Option value={v.id}>{v.title}</Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default ChangeOrderVariant;