import { gql } from "apollo-boost";

export default gql`
  mutation createProduct($input: NewProductInput!) {
    createProduct(input: $input) {
      id
      sku
      title
      description
      categories {
        id
        title
      }
      tags {
        id
        title
      }
      designs {
        id
        baseDesignId
        productId
        fileId
        baseDesign {
          description
          id
          file {
            id
            thumbnail
            fileName
            fileMime
            key
            sku
            source
          }
          name
          fileId
          width
          height
        }
        file {
          id
          thumbnail
          fileName
          fileMime
          key
          sku
          source
        }
      }
      variants {
        id
        sku
        attributes
        basePrice
        regularPrice
        salePrice
        fileId
        status
        ordering
        productBaseVariantId
        file {
          id
          thumbnail
          sku
          fileName
          fileMime
          fileSize
        }
      }
      mockups {
        id
        fileName
        fileId
        file {
          id
          thumbnail
          sku
          fileName
          fileMime
          fileSize
        }
      }
      hidden
      sites {
        id
        site {
          id
          title
        }
        siteId
      }
      productBases {
        id
        title
        sku
        mockup
        description
        variants {
          baseId
          id
          sku
          attributes
          basePrice
          regularPrice
          salePrice
          status
          ordering
        }
      }
    }
  }
`;
