import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Checkbox, message } from "antd";
import styled from "styled-components";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";

const Container = styled.div``;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default class UserForm extends Component {
  formRef = React.createRef();

  onFinish = (values) => {
    delete values.confirm;
    const id = this.props.value && this.props.value.id;
    if (!id) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation($input: NewUser!) {
              createUser(input: $input) {
                id
                firstName
                lastName
                email
                roles
              }
            }
          `,
          variables: {
            input: values,
          },
        })
        .then(() => {
          message.success("Create user successfully");
          this.setState({
            loading: false,
          });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
    if (id) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation($input: EditUser!) {
              updateUser(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: { ...values, id },
          },
        })
        .then(() => {
          message.success("Update user successfully");
          this.setState({
            loading: false,
          });
          this.props.refetch();
          this.formRef.current.resetFields();
          this.props.onClose();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };
  render() {
    const { value } = this.props;
    return (
      <Container>
        {/* <Card title={!value ? "Add User" : null} style={{ maxWidth: 700 }}> */}
        <Form
          ref={this.formRef}
          {...layout}
          name="register"
          onFinish={this.onFinish}
        >
          <Row gutter={24}>
            <Col md={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                initialValue={value ? value.firstName : ""}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                initialValue={value ? value.lastName : ""}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                name="email"
                label="E-mail"
                initialValue={value ? value.email : ""}
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {value && value.id ? null : (
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Form.Item
            name="roles"
            label="Roles"
            initialValue={value ? value.roles : []}
            rules={[
              {
                required: true,
                message: "Please select at least one role!",
              },
            ]}
          >
            <Checkbox.Group>
              <Checkbox value="Administrator">Administrator</Checkbox>
              {/* <Checkbox value="Seller">Seller</Checkbox>
                <Checkbox value="Supplier">Supplier</Checkbox>
                <Checkbox value="Supporter">Supporter</Checkbox> */}
              <Checkbox value="Designer">Designer</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {value ? "Save" : "Add User"}
            </Button>
          </Form.Item>
        </Form>
        {/* </Card> */}
      </Container>
    );
  }
}
