import { gql } from "apollo-boost";

export default gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      total
      nodes {
        id
        title
        sku
        productBases {
          id
          title
          sku
          images {
            id
            fileName
            thumbnail
          }
        }
        productType
        sites {
          id
          permalink
          siteId
          site {
            id
            title
            domain
          }
        }
        description
        categories {
          id
          title
        }
        tags {
          id
          title
        }
        status
        designStatus
        mockups {
          file {
            id
            thumbnail
            fileName
            fileMime
            key
            sku
            source
            url
          }
          fileId
          fileName
          type
        }
        variants {
          id
          sku
          attributes
          regularPrice
          salePrice
          status
          ordering
          basePrice
          fileId
        }
        designs {
          id
          productId
          baseDesignId
        }
        isCustomize
      }
    }
  }
`;
