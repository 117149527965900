import { gql } from "apollo-boost";

export default gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      sku
      title
      designStatus
      file {
        id
        fileName
        fileMime
        fileSize
        source
        url
        key
      }
      mockups {
        id
        fileId
        file {
          id
          fileName
          fileMime
          fileSize
          source
          url
          key
        }
      }
      productBaseOrders {
        id
        productBaseId
        ordering
      }
      productBases {
        id
        title
        sku
        autoRender
        renderAttrName
        attributes
        images {
          id
          fileName
          thumbnail
        }
        designs {
          id
          name
          description
          fileId
          width
          height
          baseId
        }
        variants {
          id
          attributes
          sku
          baseId
          basePrice
          salePrice
          regularPrice
          baseId
          ordering
          status
        }
      }
      sites {
        id
        permalink
        siteId
        site {
          id
          title
        }
      }
      description
      categories {
        id
        title
      }
      tags {
        id
        title
      }
      status
      # designStatus
      mockups {
        id
        file {
          id
          thumbnail
          fileName
          fileMime
          key
          sku
          source
        }
        fileId
        fileName
        type
      }
      designs {
        id
        baseDesignId
        productId
        baseDesign {
          description
          id
          ordering
          baseId
          file {
            id
            fileSize
            thumbnail
            fileName
            fileMime
            key
            sku
            source
          }
          name
          fileId
          width
          height
        }
        fileId
        file {
          id
          thumbnail
          fileName
          fileSize
          fileMime
          key
          sku
          source
          url
        }
      }
      variants {
        id
        sku
        baseId
        attributes
        regularPrice
        salePrice
        basePrice
        fileId
        ordering
        status
        baseVariantId: productBaseVariantId

        file {
          id
          sku
          fileName
          fileMime
          fileSize
          thumbnail
          key
        }
      }
      isCustomize
    }
  }
`;
