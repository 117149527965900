import React, { Component } from "react";
import { Col, Row } from "antd";
import UploadSingle from "../UploadSingle";
import _ from "lodash";
import { AWS_CLOUDFRONT_URL } from "../../config";
import { DownloadOutlined } from "@ant-design/icons";

class ProductPrintFiles extends Component {
  state = { data: [] };

  componentDidMount() {
    this.setState({ data: this.props.value ? this.props.value : [] });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ data: this.props.value ? this.props.value : [] });
    }
  }

  mergeDesignByBaseId(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  render() {
    const { data } = this.state;
    return (
      <div>
        <Row gutter={[16, 16]}>
          {_.map(
            this.mergeDesignByBaseId(data, "baseId"),
            (designs, dsIndex) => {
              return (
                <Col
                  key={dsIndex}
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                  md={24}
                  span={24}
                >
                  <h3
                    style={{
                      paddingLeft: 8,
                      borderBottom: "1px solid #d9d9d9",
                    }}
                  >
                    {designs[0].title}
                  </h3>
                  <Row gutter={[16, 16]}>
                    {designs.map((design) => (
                      <Col
                        style={{ display: "inline-block" }}
                        key={design.id}
                        md={8}
                        span={8}
                      >
                        <div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3>
                              {design.baseDesign
                                ? design.baseDesign.name
                                : design.name}
                            </h3>
                            {design.file && <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${AWS_CLOUDFRONT_URL}/${design.file?.key}`}
                            >
                              <DownloadOutlined
                                style={{ color: "dodgerblue" }}
                              />
                            </a>}
                          </div>
                          <UploadSingle
                            multiple={false}
                            fileList={false}
                            value={design.fileId}
                            imageKey={design.file ? design.file.key : null}
                            onChangePrintFiles={(file) => {
                              const index = data.findIndex(
                                (el) => el.id === design.id
                              );
                              const newData = [...data];
                              newData[index].fileId = file.fileId;
                              newData[index].file = {
                                ...newData[index].file,
                                key: file.file.key,
                              };
                              this.setState({ data: newData }, () =>
                                this.props.onChange(newData)
                              );
                            }}
                            setHeight="160px"
                          >
                            <div style={{ textAlign: "center" }}>{`${design.baseDesign
                              ? design.baseDesign.height
                              : design.height
                              } x ${design.baseDesign
                                ? design.baseDesign.width
                                : design.width
                              }`}</div>
                          </UploadSingle>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              );
            }
          )}
        </Row>
      </div>
    );
  }
}

export default ProductPrintFiles;
