import { gql } from "apollo-boost";

export default gql`
  mutation updateFulfillService($id: ID!, $input: EditFulfillService!) {
    updateFulfillService(id: $id, input: $input) {
      id
      name
      status
      type
      createdAt
      templates {
        id
        name
        data
        createdAt
      }
    }
  }
`;
