import { gql } from "apollo-boost";

export default gql`
  query pullShopifyProduct($orderId: ID!) {
    pullShopifyProduct(orderId: $orderId) {
      id
      variants {
        originId
        title
        isCurrent
        sku
      }
    }
  }
`;
