import { gql } from "apollo-boost";

export const LOGIN_ICTEES_APP = gql`
  mutation loginIcteesApp {
    loginIcteesApp
  }
`;
export const LOGOUT_ICTEES_APP = gql`
  mutation logoutIcteesApp {
    logoutIcteesApp
  }
`;