import React, { Component } from "react";
import { Avatar, Button } from "antd";
import styled from "styled-components";
import { CheckCircleOutlined } from "@ant-design/icons";
import { AWS_CLOUDFRONT_URL } from "../../config";
import history from "../../history";
import defaultImage from "../../assets/images/default.png";

const Container = styled.div`
  .ant-card-cover img {
    border-radius: 2px 2px 0 0;
    height: 200px;
    width: 200px;
    object-fit: contain;
  }
  .product-base-card {
    display: flex;
    flex-wrap: wrap;
    .product-base {
      width: 192px;
      min-height: 250px;
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid rgb(195, 207, 217);
      filter: ${(props) =>
    props.disable === false ? "grayscale(50%)" : "inherit"};
      opacity: ${(props) => (props.disable === false ? "0.5" : "1")};
      pointer-events: ${(props) =>
    props.disable === false ? "none" : "inherit"};
      &:hover {
        cursor: pointer;
        &:after {
          content: " ";
          background-color: rgba(200, 200, 200, 0.3);
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
        }
      }
    }
    .description {
      text-align: center;
      border-top: 1px solid rgb(195, 207, 217);
      padding-top: 5px;
      .base-title {
        font-weight: 700;
        marfin: auto;
      }
      .base-sku {
        color: #999;
        font-size: 13px;
      }
    }
  }
  .p-icon-select {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon svg {
      display: inline-block;
      width: 30px;
      height: 30px;
      color: #519c57;
    }
  }
`;
class ShowProductBase extends Component {
  state = {
    selected: [],
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      selected: value ? value : [],
    });
  }
  render() {
    const {
      selectedProductBase,
      multiple,
      productBaseGroup,
      handleChange,
      disable,
    } = this.props;
    return (
      <Container disable={disable}>
        <div style={{ display: "flex", margin: "10px 0" }}>
          <h3>{productBaseGroup?.category?.name}</h3>
          <div style={{ marginLeft: 10 }}>
            {selectedProductBase.length > 0 ? (
              <div>
                {productBaseGroup.category.name ===
                  selectedProductBase[0].category.title ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      history.push(
                        `/products/add/${selectedProductBase
                          .map((item) => item.id)
                          .join(",")}`
                      )
                    }
                  >
                    Continue
                  </Button>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="product-base-card">
          {productBaseGroup?.group.map((base, index) => (
            <div
              key={base.id}
              className="product-base"
              onClick={() => {
                //select multiple
                if (multiple) {
                  // Get index from exist or not productBase in SelectedProductBase array
                  const index = selectedProductBase.findIndex(
                    (p) => p.id === base.id
                  );
                  //exit
                  if (index > -1) {
                    let newSelectedProductBase = selectedProductBase.filter(
                      (p) => p.id !== base.id
                    );
                    if (handleChange) {
                      handleChange(newSelectedProductBase);
                    }
                    // Mon-existing
                  } else {
                    let newSelectedProductBase = [...selectedProductBase, base];
                    if (handleChange) {
                      handleChange(newSelectedProductBase);
                    }
                  }
                  // Select one by one
                } else {
                  if (selectedProductBase[0]?.id === base.id) {
                    if (handleChange) {
                      handleChange([]);
                    }
                  } else {
                    if (handleChange) {
                      handleChange([base]);
                    }
                  }
                }
              }}
            >
              <Avatar
                style={{ borderRadius: 0, background: "lightgray" }}
                size={190}
                alt="example"
                src={
                  base?.images.length
                    ? `${AWS_CLOUDFRONT_URL}/${base.images[0].key}`
                    : defaultImage
                }
                shape="square"
              />
              <div className="description">
                <p className="base-title">{base.title}</p>
                <div className="base-sku">{base?.sku}</div>
                <p>{base.fulfillService.name}</p>
                {/* <p>
                  {base?.variants[0]?.regularPrice
                    ? `${base?.variants[0]?.regularPrice}$`
                    : "Base cost $"}
                </p> */}
              </div>
              {selectedProductBase?.find((p) => p.id === base.id) &&
                productBaseGroup?.category.id && (
                  <div className="p-icon-select">
                    <CheckCircleOutlined className="p-icon" />
                  </div>
                )}
            </div>
          ))}
        </div>
      </Container>
    );
  }
}

export default ShowProductBase;
