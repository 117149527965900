import { gql } from "apollo-boost";

export default gql`
  query FulfillServices($filter: FulfillServiceFilter) {
    FulfillServices(filter: $filter) {
      total
      nodes {
        id
        name
        status
        type
        createdAt
        apiKey
        templates {
          id
          name
          data
          createdAt
        }
      }
    }
  }
`;
