import React, { Component } from "react";
import { PageHeader } from "antd";
import ListProduct from "../../src/components/products/Products";

class Products extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Products"
          onBack={() => this.props.history.goBack()}
        />
        <ListProduct />
      </div>
    );
  }
}

export default Products;
