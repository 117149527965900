import { gql } from "apollo-boost";

export default gql`
  mutation updateCategory($id: ID!, $input: EditCategory!) {
    updateCategory(id: $id, input: $input) {
      id
      title
      description
      status
    }
  }
`;
