import React, { Component } from "react";
import { Tabs, PageHeader, Button, Drawer, message } from "antd";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import history from "../../history";
import MappingTable from "./MappingTable";
import _ from "lodash";
import MappingForm from "./MappingForm";
import { apolloClient } from "../../apollo-client";

const keys = ["Product Type", "Color", "Size"];
const QUERY = gql`
  query load($filter: MappingFilter) {
    mappings: mappings(filter: $filter) {
      count
      hits {
        id
        group
        parent_id
        parent {
          id
          origin
          target
        }
        origin
        target
        category
        printType
      }
    }
    # site: siteById(id: $site_id) {
    #   id
    #   title
    # }
  }
`;

class ListMapping extends Component {
  state = {
    group: keys[0],
    productTypes: [],
    visible: false,
    edit: null,
    type: "",
  };

  deleteStore = (record) => {
    this.setState({ loading: true });
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteMapping($id: ID!) {
            deleteMapping(id: $id)
          }
        `,
        variables: { id: record.id },
      })
      .then(() => {
        message.success("Delete store success!");
        this.refetch();
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { group, visible, productTypes, type } = this.state;
    const { siteId } = this.props;
    const variable = {
      // site_id: siteId,
      filter: { group },
    };
    return (
      <Query
        onCompleted={(res) => {
          if (productTypes.length === 0) {
            this.setState({
              productTypes: res.mappings.hits.filter(
                (p) => p.group === keys[0]
              ),
            });
          }
        }}
        query={QUERY}
        variables={variable}
      >
        {({ data, loading, err, client, refetch }) => {
          this.refetch = refetch;
          return (
            <div>
              <PageHeader
                onBack={() => {
                  history.push(`/sites`);
                }}
                title={_.get(data, "site.title", "Mappings")}
                // subTitle={"Mappings"}
                extra={[
                  <Button
                    key="button-id"
                    onClick={() =>
                      this.setState({ visible: true, type: "Add" })
                    }
                    type="primary"
                  >
                    Add new
                  </Button>,
                ]}
              />
              <Tabs
                onChange={(group) => {
                  this.setState({ group });
                }}
                defaultActiveKey={group}
              >
                {keys.map((k) => (
                  <Tabs.TabPane tab={k} key={k}>
                    <MappingTable
                      onEdit={(record) => {
                        this.setState({ visible: record, type: "Edit" });
                      }}
                      onDelete={(record) => {
                        this.deleteStore(record);
                      }}
                      group={group}
                      data={data ? data : { mappings: { hits: [] } }}
                      onClone={(record) => {
                        this.setState({
                          visible: record,
                          type: "Clone",
                        });
                      }}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>
              <Drawer
                width={520}
                title={type + " " + group + " mapping"}
                placement="right"
                closable={true}
                onClose={() => this.setState({ visible: false })}
                visible={visible}
              >
                <MappingForm
                  type={type === "Clone" ? type : null}
                  key={typeof visible === "object" ? visible.id : "0"}
                  store={typeof visible === "object" ? visible : null}
                  productTypes={productTypes}
                  siteId={siteId}
                  group={group}
                  onDone={() => {
                    this.setState({ visible: false }, () => refetch());
                  }}
                />
              </Drawer>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default ListMapping;
