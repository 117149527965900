import React, { Component } from "react";
import {
  Table,
  Select,
  Button,
  Drawer,
  Divider,
  Skeleton,
  Popconfirm,
  message,
} from "antd";
import styled from "styled-components";
import CategoryForm from "./CategoryForm";
import { PlusOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Query } from "react-apollo";
import categories from "../../graphql/queries/categories";
import _ from "lodash";
import { gql } from "apollo-boost";
import { apolloClient } from "../../apollo-client";
const Container = styled.div`
  .p-filter {
    display: flex;
    margin: 20px 0;
    .p-select {
      flex-grow: 1;
    }
  }
`;
class Categories extends Component {
  state = {
    showDrawer: false,
    filter: {
      status: true,
      limit: 20,
      offset: 0,
    },
    page: 1,
  };
  onClose = () => {
    this.setState({ showDrawer: false });
  };
  deleteCategory = (id) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteCategory($id: ID!) {
            deleteCategory(id: $id)
          }
        `,
        variables: {
          id,
        },
      })
      .then(() => {
        message.success("Delete category success!");
      });
  };
  render() {
    const { showDrawer, filter, page } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <div className="p-select">
            <Select
              style={{ width: 200 }}
              defaultValue="true"
              onChange={(status) => {
                this.setState({
                  filter: { ...filter, status: JSON.parse(status) },
                });
              }}
            >
              <Select.Option value="true">Active</Select.Option>
              <Select.Option value="false">Inactive</Select.Option>
            </Select>
          </div>
          <Button
            onClick={() => this.setState({ showDrawer: true })}
            type="primary"
          >
            <PlusOutlined /> Add category
          </Button>
        </div>
        <Query query={categories} variables={{ filter: { ...filter } }}>
          {({ data, loading, error, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const columns = [
              {
                title: "Categories",
                key: "title",
                dataIndex: "title",
                width: 400,
              },
              {
                title: "Product type",
                key: "type",
                dataIndex: "type",
                width: 250,
              },
              {
                title: "Action",
                key: "action",
                width: 150,
                align: "right",
                render: (record) =>
                  record.status === true && (
                    <div>
                      <Button
                        size="small"
                        onClick={() => this.setState({ showDrawer: record })}
                      >
                        <EditOutlined />
                      </Button>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Are you sure delete category?"
                        okText="Yes"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                        okButtonProps={{type:"danger"}}
                        cancelText="No"
                        placement="top"
                        onConfirm={() => {
                          this.deleteCategory(record.id);
                          refetch();
                        }}
                      >
                        <Button style={{ color: "red" }} size="small">
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </div>
                  ),
              },
            ];
            const pagination = {
              current: page,
              total: data.categories.total,
              pageSize: filter.limit,
              onChange: (page, pageSize) => {
                this.setState({
                  page: page,
                  filter: {
                    ...filter,
                    offset: (page - 1) * pageSize,
                  },
                });
              },
            };
            const tableWidth = _.sum(columns.map((c) => c.width));
            const dataSource = data.categories.nodes;
            // if (dataSource)
            return (
              <div>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  rowKey={(index) => index.id}
                  scroll={{ x: tableWidth }}
                  pagination={pagination}
                />
                <Drawer
                  width={600}
                  visible={this.state.showDrawer}
                  title={`${showDrawer.id ? "Edit category" : "Add category"}`}
                  onClose={this.onClose}
                >
                  <CategoryForm
                    onClose={this.onClose}
                    showDrawer={showDrawer}
                    refetch={() => refetch()}
                    id={showDrawer && showDrawer.id}
                    key={showDrawer && showDrawer.id}
                  />
                </Drawer>
              </div>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default Categories;
