import React, { Component } from "react";
import {
  Table,
  // Select,
  Button,
  Skeleton,
  Divider,
  Popconfirm,
  notification,
  Popover,
  Input,
  Form,
  Select,
} from "antd";
import styled from "styled-components";
import {
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import productBases from "../../graphql/queries/productBases";
import { Query } from "react-apollo";
import _ from "lodash";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import categories from "../../graphql/queries/categories";

const Container = styled.div`
  .p-filter {
    display: flex;
    margin: 20px 0;
    .p-select {
      flex-grow: 1;
      margin-right: 20px;
      .ant-form-item {
        margin-bottom: 15px;
      }
    }
  }
`;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
class ProductBase extends Component {
  formRef = React.createRef();

  state = {
    filter: {
      offset: 0,
      limit: 20,
      categoryId: null,
      autoRender: null,
      search: null,
    },
    page: 1,
  };

  onDelete = (id, refetch) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteProductBase($id: ID!) {
            deleteProductBase(id: $id)
          }
        `,
        variables: {
          id,
        },
      })
      .then(() => {
        notification.success({ message: "Delete base success!" });
        this.refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };

  render() {
    const { filter, page } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <div className="p-select">
            <Input.Search
              placeholder="Search"
              onSearch={(s) =>
                this.setState({ filter: { ...filter, search: s } })
              }
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...filter,
                      search: null,
                    },
                  });
                }
              }}
              addonBefore={
                <Popover
                  trigger="click"
                  placement="bottomLeft"
                  content={
                    <Form
                      style={{ width: 250 }}
                      onFinish={(values) =>
                        this.setState({
                          filter: {
                            ...filter,
                            ...values,
                          },
                        })
                      }
                      {...layout}
                      ref={this.formRef}
                    >
                      <Query query={categories}>
                        {({ data, loading, error }) => {
                          if (loading) return <Skeleton />;
                          if (error) return <div>{error.toString()}</div>;
                          return (
                            <Form.Item name="categoryId">
                              <Select placeholder="Category">
                                {data?.categories?.nodes.map((cate) => (
                                  <Select.Option value={cate.id} key={cate.id}>
                                    {cate.title}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          );
                        }}
                      </Query>
                      <Form.Item name="autoRender">
                        <Select placeholder="Auto render attribute">
                          <Select.Option value={true}>
                            Auto render
                          </Select.Option>
                          <Select.Option value={false}>
                            Default attribute
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        Fillter
                      </Button>
                      <Button
                        style={{ marginLeft: 10 }}
                        htmlType="button"
                        onClick={() => {
                          this.formRef.current.resetFields();
                          this.setState({
                            filter: {
                              search: null,
                              autoRender: null,
                              categoryId: null,
                            },
                          });
                        }}
                      >
                        Reset
                      </Button>
                    </Form>
                  }
                >
                  <div style={{ cursor: "pointer" }}>
                    Filter <FilterOutlined />
                  </div>
                </Popover>
              }
            />
          </div>
          <Link to="/product-bases/add">
            <Button type="primary">
              <PlusOutlined /> Add base
            </Button>
          </Link>
        </div>
        <Query
          query={productBases}
          variables={{ filter: { ...filter } }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, data, error, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const dataSource = data.productBases.nodes;
            this.refetch = refetch;
            const columns = [
              { title: "Name", key: "name", dataIndex: "title", width: 180 },
              { title: "Display Name", key: "storeType", dataIndex: "storeType", width: 120 },
              { title: "SKu", key: "sku", dataIndex: "sku", width: 180 },
              {
                title: "Category",
                key: "category",
                dataIndex: "category",
                render: (category) => `${category.title}`,
                width: 150,
              },
              {
                title: "Fulfillment",
                key: "fulfillment",
                dataIndex: "fulfillService",
                render: (fulfillService) => `${fulfillService.name}`,
                width: 150,
              },
              {
                title: "Type",
                key: "type",
                dataIndex: "type",
                render: (type) => `${type ? type.origin : ""}`,
                width: 120,
              },
              {
                title: "Action",
                key: "action",
                align: "right",
                width: 180,
                render: (record) => (
                  <div>
                    <Link to={`/product-bases/variants/${record.id}`}>
                      Variants
                    </Link>
                    <Divider type="vertical" />
                    <Link to={`/product-bases/edit/${record.id}`}>
                      <Button size="small">
                        <EditOutlined />
                      </Button>
                    </Link>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Are you sure delete base ?"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                      okButtonProps={{type:"danger"}}
                      okText="Yes"                 
                      cancelText="No"
                      onConfirm={() => {
                        this.onDelete(record.id);
                      }}
                    >
                      <Button style={{ color: "red" }} size="small">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </div>
                ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <Table
                rowKey={(index) => index.id}
                columns={columns}
                dataSource={[...dataSource]}
                scroll={{ x: tableWidth }}
                loading={loading}
                pagination={{
                  current: page,
                  pageSize: filter.limit,
                  total: data?.productBases?.total,
                  onChange: (page, pageSize) => {
                    this.setState({
                      page: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * pageSize,
                      },
                    });
                  },
                }}
              />
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default ProductBase;
