import React, { Component } from "react";
import styled from "styled-components";
import { Button, Popover, message, Tabs } from "antd";
import ImageUrl from "../ImageUrl";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import _ from "lodash";

const Container = styled.div``;

const { TabPane } = Tabs;

export default class DesignConfirmation extends Component {
  state = {
    visible: false,
    fileUrl: [],
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  onFinish = (values) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!) {
            updateOrderStatus(orderIds: $orderIds, status: $status)
          }
        `,
        variables: {
          orderIds: [this.props.orderId],
          status: values,
        },
      })
      .then(() => {
        message.success("Status order has been move to " + values);
        this.setState({ visible: false });
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { visible } = this.state;
    const { fileId, hidePopover, size } = this.props;
    const content = (
      <div
        style={{
          width: hidePopover ? "100%" : 200,
          padding: hidePopover ? 10 : "auto",
          background: "white",
        }}
      >
        <Tabs defaultActiveKey="front" tabBarExtraContent={<div>{size}</div>}>
          {_.orderBy(fileId, ["side"], ["desc"]).map((file) => {
            if (file.side === "front") {
              return (
                <TabPane
                  tab={
                    file?.file?.width
                      ? `Front (${file.file.width} X ${file.file.height} )`
                      : "Front"
                  }
                  key="front"
                >
                  <div
                    onClick={() => this.props.onClickShowModal()}
                    style={{ cursor: "pointer" }}
                  >
                    <ImageUrl
                      widthImage={hidePopover ? "100%" : "200px"}
                      heightImage={hidePopover ? "auto" : "200px"}
                      //imageKey={file.file.thumbnail ?? file.file.key}
                      imageKey={file.file.key}
                    />
                  </div>
                </TabPane>
              );
            }
            return (
              <TabPane
                tab={
                  file?.file?.width
                    ? `Back (${file.file.width} X ${file.file.height} )`
                    : "Back"
                }
                key="back"
              >
                <div
                  onClick={() => this.props.onClickShowModal()}
                  style={{ cursor: "pointer" }}
                >
                  <ImageUrl
                    widthImage={hidePopover ? "100%" : "200px"}
                    heightImage={hidePopover ? "auto" : "200px"}
                    //imageKey={file.file?.thumbnail ?? file.file?.key}
                    imageKey={file.file?.key}
                  />
                </div>
              </TabPane>
            );
          })}
        </Tabs>
        {hidePopover ? null : (
          <div style={{ marginTop: 15 }}>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => this.onFinish("designed")}
            >
              Approve
            </Button>
            <Button onClick={() => this.onFinish("rejected")}>Reject</Button>
          </div>
        )}
      </div>
    );
    return (
      <Container>
        {hidePopover ? (
          <div>{content}</div>
        ) : (
          <div>
            <Popover
              content={content}
              trigger="click"
              visible={visible}
              placement="bottom"
              onVisibleChange={this.handleVisibleChange}
            >
              <a href="/#">Design Confirm</a>
            </Popover>
          </div>
        )}
      </Container>
    );
  }
}
