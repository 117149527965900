import React, { Component } from "react";
import {
  Table,
  Button,
  Input,
  Select,
  Popconfirm,
  Tag,
  Modal,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  DragOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { DropTarget, DragSource, DndProvider } from "react-dnd";
import update from "immutability-helper";
import styled from "styled-components";
import { HTML5Backend } from "react-dnd-html5-backend";
import _ from "lodash";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";

const SortTable = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`;
let dragingIndex = -1;
class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }
    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}
const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};
const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);
class ProductBaseAttributes extends Component {
  state = {
    data: [],
    isModalVisible: false,
    newOptions: [],
  };
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ data: this.props.value ? this.props.value : [] });
    }
  }
  handleDelete = (id) => {
    const { data } = this.state;
    const newData = data.filter((item, i) => i !== id);
    this.setState(
      {
        data: newData,
      },
      () => {
        this.props.onChange(newData);
        this.props.onValidate(newData);
      }
    );
  };
  components = {
    body: {
      row: DragableBodyRow,
    },
  };
  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
      () => {
        this.props.onChange(this.state.data);
        this.props.onValidate(this.state.data);
      }
    );
  };
  render() {
    const { data } = this.state;
    const columns = [
      {
        title: "",
        key: "drag",
        width: 30,
        render: () => <DragOutlined />,
      },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        width: 200,
        render: (text, record, index) => (
          <Input
            value={text}
            onChange={(e) => {
              data[index].name = e.target.value;
              this.setState({ data }, () => {
                this.props.onChange(data);
                this.props.onValidate(data);
              });
            }}
          />
        ),
      },
      {
        title: "Options",
        key: "opntions",
        dataIndex: "options",
        width: 200,
        render: (options, row, index) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              showSearch
              mode="tags"
              style={{ width: "100%" }}
              value={options}
              onChange={(value) => {
                data[index].options = value;
                this.setState({ data }, () => {
                  this.props.onChange(data);
                  this.props.onValidate(data);
                });
              }}
              dropdownStyle={{ display: "none" }}
              tokenSeparators={[","]}
            ></Select>
            {options.length > 1 ? (
              <Tooltip title="Sort option">
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      isModalVisible: { ...row, index },
                      newOptions: options,
                    });
                  }}
                  icon={<RetweetOutlined />}
                ></Button>
              </Tooltip>
            ) : null}
          </div>
        ),
      },
      {
        title: "",
        key: "remove",
        width: 50,
        render: (_, row, id) => (
          <Popconfirm
            title="Are you sure delete?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{ type: "danger" }}
            onConfirm={() => this.handleDelete(id)}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <div className="attribute">
        <SortTable>
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={columns}
              dataSource={data}
              rowKey={(row, index) => index}
              components={this.components}
              scroll={{ x: tableWidth }}
              onRow={(row, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              pagination={false}
              footer={() => (
                <Button
                  type="link"
                  onClick={(e) => {
                    e.preventDefault();
                    const { data } = this.state;
                    // data.push({ name: "", slug: "", options: [] });
                    this.setState({
                      data: [...data, { name: "", options: [] }],
                    });
                  }}
                >
                  <PlusOutlined /> Add new
                </Button>
              )}
            />
          </DndProvider>
        </SortTable>
        <Modal
          title="Basic Modal"
          visible={this.state.isModalVisible}
          footer={[
            <Button
              onClick={() => {
                this.setState({ isModalVisible: false });
              }}
            >
              Cancel
            </Button>,
            <Button
              type="primary"
              onClick={() => {
                const { isModalVisible, newOptions } = this.state;
                data[isModalVisible.index].options = newOptions;
                this.setState({ data, isModalVisible: false }, () => {
                  this.props.onChange(data);
                  this.props.onValidate(data);
                });
              }}
            >
              Save
            </Button>,
          ]}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <div>
            <span>{this.state.isModalVisible.name}:</span>
            <SortableList
              value={this.state.newOptions}
              axis="xy"
              distance={1}
              onSortEnd={({ oldIndex, newIndex }) => {
                console.log(oldIndex, newIndex);
                this.setState({
                  newOptions: arrayMove(
                    this.state.newOptions,
                    oldIndex,
                    newIndex
                  ),
                });
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProductBaseAttributes;
const SortableList = SortableContainer(({ value }) => {
  return (
    <div>
      {value?.map((option, index) => (
        <SortableItem key={index} option={option} index={index} />
      ))}
    </div>
  );
});
const SortableItem = SortableElement(({ option }) => (
  <Tag style={{ zIndex: 1000 }}>{option}</Tag>
));
