import { gql } from "apollo-boost";

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $id: ID!
    $current_password: String
    $new_password: String!
  ) {
    changePassword(
      id: $id
      current_password: $current_password
      new_password: $new_password
    )
  }
`;
