import React, { Component } from "react";
import tags from "../../graphql/queries/tags";
import {
  Button,
  Table,
  Drawer,
  Skeleton,
  Popconfirm,
  notification,
  Divider,
  // Select,
  Input,
} from "antd";
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import TagForm from "./TagForm";
import { Query } from "react-apollo";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import _ from "lodash";
import styled from "styled-components";
const Container = styled.div`
  .p-filter {
    display: flex;
    margin: 20px 0;
    .p-select {
      flex-grow: 1;
      margin-right: 10px;
    }
  }
`;

class ProductTags extends Component {
  state = {
    showDrawer: false,
    filter: {
      limit: 20,
      offset: 0,
      status: true,
      search: null,
    },
    page: 1,
  };
  onClose = () => {
    this.setState({
      showDrawer: false,
    });
  };
  onDelete = (record) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteProductTag($id: ID!) {
            deleteProductTag(id: $id)
          }
        `,
        variables: {
          id: record.id,
        },
      })
      .then(() => {
        notification.success({ message: "Delete category success!" });
        this.refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  render() {
    const { filter, page, showDrawer } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <div className="p-select">
            <Input.Search
              placeholder="Search..."
              onSearch={(s) =>
                this.setState({
                  filter: {
                    ...filter,
                    search: s,
                  },
                })
              }
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...filter,
                      search: null,
                    },
                  });
                }
              }}
            />
          </div>
          <Button
            style={{ marginBottom: 15 }}
            type="primary"
            onClick={() => this.setState({ showDrawer: true })}
          >
            Add Tag
          </Button>
        </div>
        <Query query={tags} variables={{ filter: { ...filter } }}>
          {({ data, loading, error, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const dataSource = data.productTags.nodes;
            this.refetch = refetch;
            const columns = [
              { title: "Name", key: "name", dataIndex: "title", width: 400 },
              {
                title: "Action",
                key: "action",
                align: "right",
                width: 100,
                render: (record) => (
                  <div>
                    <Button
                      size="small"
                      onClick={() => this.setState({ showDrawer: record })}
                    >
                      <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Are you sure delete category?"
                      okText="Yes"
                      cancelText="No"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                      okButtonProps={{type:"danger"}}
                      onConfirm={() => {
                        this.onDelete(record);
                      }}
                    >
                      <Button style={{ color: "red" }} size="small">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </div>
                ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <div>
                <Table
                  rowKey={(index) => index.id}
                  dataSource={dataSource}
                  columns={columns}
                  scroll={{ x: tableWidth }}
                  pagination={{
                    current: page,
                    total: data.productTags.total,
                    pageSize: filter.limit,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...filter,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                  }}
                />
                <Drawer
                  width={600}
                  placement="right"
                  title={`${showDrawer.id ? "Edit tag" : "Add tag"}`}
                  visible={this.state.showDrawer}
                  onClose={this.onClose}
                >
                  <TagForm
                    showDrawer={this.state.showDrawer}
                    onClose={this.onClose}
                    refetch={() => refetch()}
                    id={showDrawer?.id}
                    key={showDrawer?.id}
                  />
                </Drawer>
              </div>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default ProductTags;
