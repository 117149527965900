import { gql } from "apollo-boost";

export default gql`
  query orderActivitys($orderId: ID!) {
    orderActivitys(orderId: $orderId) {
      id
      orderId
      userId
      user {
        id
        firstName
        lastName
      }
      message
      type
      data
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
