import React, { Component } from "react";
import ProductForm from "./ProductForm";
import { PageHeader } from "antd";
import { apolloClient } from "../../apollo-client";
import productBaseByIds from "../../graphql/queries/productBaseByIds";

class AddProductPage extends Component {
  state = {
    productBase: [],
    variants: [],
    loading: false,
    designs: [],
    mockups: [],
    productBases: [],
  };
  componentDidMount() {
    const id = this.props.match.params.id.split(",");
    this.setState({ loading: true });
    apolloClient
      .query({
        query: productBaseByIds,
        variables: {
          ids: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.setState({
          productBases: res.data.productBaseByIds,
          productBase: res?.data?.productBaseByIds,
          variants: [].concat.apply(
            [],
            res?.data?.productBaseByIds.map((type) => type.variants)
          ),
          designs: [].concat.apply(
            [],
            res?.data?.productBaseByIds.map((design) => {
              let designTitle = design.designs.map((item) => {
                return { ...item, title: design.title };
              });
              return designTitle;
            })
          ),

          loading: false,
        });
      });
  }

  render() {
    const { productBase, designs, productBases, mockups, loading } = this.state;
    if (loading) return null;

    return (
      <div>
        <PageHeader
          title="Create New Product: Step 2"
          onBack={() => this.props.history.goBack()}
        />
        <ProductForm
          productBases={productBases}
          productBase={productBase}
          designs={designs}
          mockups={mockups}
        />
      </div>
    );
  }
}

export default AddProductPage;
