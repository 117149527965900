import React, { Component } from "react";
import { InputNumber } from "antd";

class ProductVariantCostField extends Component {
  render() {
    return (
      // <Form.Item label={this.props.label}>
      <InputNumber
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        onChange={this.props.onChange}
        value={this.props.value}
      />
      // </Form.Item>
    );
  }
}

export default ProductVariantCostField;
