import React from "react";
import OrdersPage from "./pages/Orders";
import DefaultLayout from "./layouts/default";
import Login from "./pages/Login";
import {
  CreditCardOutlined,
  UserOutlined,
  GlobalOutlined,
  DatabaseOutlined,
  ProfileOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Users from "./pages/Users.";
import Sites from "./pages/Sites";
import ProductOrder from "./components/orders/ProductOrder";
import Mappings from "./pages/Mappings";
import Carriers from "./pages/Carriers";
import Categories from "./pages/Categories";
import ProductBases from "./pages/ProductBases";
import Fulfillments from "./pages/Fulfillments";
import ProductBaseForm from "./components/product_base/ProductBaseForm";
import ExportTemplates from "./pages/ExportTemplates";
import ExportTemplateForm from "./components/export-template/ExportTemplateForm";
import ProductBaseVariants from "./components/product_base/ProductBaseVariants";
import Products from "./pages/Products";
import ProductCategories from "./pages/ProductCategories";
import ProductTags from "./pages/ProductTags";
import ProductStep1 from "./components/products/ProductStep1";
import AddProduct from "./components/products/AddProduct";
import EditProduct from "./components/products/EditProduct";
import ExternalOrders from "./pages/ExternalOrders";
import DownloadFileCSV from "./pages/DownloadFileCSV";
import Setting from "./pages/Setting";
import Personalized from "./pages/Personalized";
import DetailPersonalizedOrder from "./components/personalized/DetailPersonalizedOrder";

export default [
  {
    path: "/users",
    component: Users,
    layout: DefaultLayout,
    icon: <UserOutlined />,
    menu: {
      title: "Users",
    },
    roles: ["Administrator"],
  },
  {
    path: "/sites",
    component: Sites,
    layout: DefaultLayout,
    icon: <GlobalOutlined />,
    menu: {
      title: "Sites",
    },
    roles: ["Administrator"],
  },
  {
    key: "products",
    path: "/product",
    icon: <DatabaseOutlined />,
    layout: DefaultLayout,
    menu: {
      title: "Product",
    },
    roles: ["Administrator"],
    child: [
      {
        path: "/products",
        component: Products,
        layout: DefaultLayout,
        menu: {
          title: "Products",
        },
        exact: true,
        roles: ["Administrator"],
      },
      {
        path: "/products-categories",
        component: ProductCategories,
        layout: DefaultLayout,
        menu: {
          title: "Product Categories",
        },
        exact: true,
        roles: ["Administrator"],
      },
      {
        path: "/products-tags",
        component: ProductTags,
        layout: DefaultLayout,
        menu: {
          title: "Product Tags",
        },
        exact: true,
        roles: ["Administrator"],
      },
    ],
  },
  {
    path: "/products/add",
    component: ProductStep1,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/products/add/:id",
    component: AddProduct,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/products/edit/:id",
    component: EditProduct,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    key: "order",
    path: "/order",
    menu: {
      title: "Orders",
    },
    icon: <CreditCardOutlined />,
    roles: ["Administrator", "Designer"],
    child: [
      {
        path: "/orders",
        component: OrdersPage,
        layout: DefaultLayout,
        menu: {
          title: "Internal Orders",
        },
        exact: true,
        roles: ["Administrator", "Designer"],
      },
      {
        path: "/orders/personalized",
        component: Personalized,
        exact: true,
        layout: DefaultLayout,
        menu: {
          title: "Personalized Orders",
        },
        roles: ["Administrator", "Designer"],
      },
      {
        path: "/orders/external",
        component: ExternalOrders,
        exact: true,
        layout: DefaultLayout,
        menu: {
          title: "External Orders",
        },
        roles: ["Administrator", "Designer"],
      },
      {
        path: "/orders/download-csv",
        component: DownloadFileCSV,
        exact: true,
        layout: DefaultLayout,
        menu: {
          title: "Download CSV",
        },
        roles: ["Administrator", "Designer"],
      },
    ],
  },
  {
    path: "/personalized-order/:siteId/:id",
    component: DetailPersonalizedOrder,
    layout: DefaultLayout,
  },
  {
    path: "/product-order/:siteId/:id",
    component: ProductOrder,
    layout: DefaultLayout,
  },
  {
    key: "base",
    path: "/prdbs",
    icon: <ProfileOutlined />,
    layout: DefaultLayout,
    menu: {
      title: "Base Settings",
    },
    roles: ["Administrator"],
    child: [
      {
        path: "/product-bases",
        component: ProductBases,
        layout: DefaultLayout,
        menu: {
          title: "Product Bases",
        },
        exact: true,
        roles: ["Administrator"],
      },
      {
        path: "/mappings/:siteId",
        component: Mappings,
        layout: DefaultLayout,
        menu: {
          title: "Mappings",
        },
        roles: ["Administrator"],
      },
      {
        path: "/carriers",
        component: Carriers,
        layout: DefaultLayout,
        menu: {
          title: "Carriers",
        },
        roles: ["Administrator"],
      },
      {
        path: "/categories",
        component: Categories,
        layout: DefaultLayout,
        menu: {
          title: "Categories",
        },
        roles: ["Administrator"],
      },
      {
        path: "/fulfillments",
        component: Fulfillments,
        layout: DefaultLayout,
        menu: {
          title: "Fulfillment Services",
        },
        exact: true,

        roles: ["Administrator"],
      },

      {
        path: "/export-templates",
        component: ExportTemplates,
        layout: DefaultLayout,
        menu: {
          title: "Export Templates",
        },
        exact: true,

        roles: ["Administrator"],
      },
    ],
  },

  {
    path: "/product-bases/add",
    component: ProductBaseForm,
    layout: DefaultLayout,
    // icon: <AppstoreOutlined />,
    // menu: {
    //   title: "Product Bases",
    // },
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/product-bases/edit/:id",
    component: ProductBaseForm,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/product-bases/variants/:id",
    component: ProductBaseVariants,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/export-templates/add",
    component: ExportTemplateForm,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/export-templates/edit/:id",
    component: ExportTemplateForm,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/export-templates/clone/:id",
    component: ExportTemplateForm,
    layout: DefaultLayout,
    exact: true,
    roles: ["Administrator"],
  },
  {
    path: "/login",
    title: "Login",
    component: Login,
    layout: null,
    menu: null,
  },
  {
    path: "/settings",
    component: Setting,
    layout: DefaultLayout,
    icon: <SettingOutlined />,
    exact: true,
    menu: {
      title: "Settings",
    },
    roles: ["Administrator"],
  },
];
