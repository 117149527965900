import gql from "graphql-tag";

export default gql`
  mutation($input: NewFile!) {
    createFile(input: $input) {
      id
      source
      key
      fileName
      fileMime
      fileSize
      thumbnail
      sku
      width
      height
      url
    }
  }
`;
