import React, { Component } from "react";
import {
  PageHeader,
  Table,
  Skeleton,
  Input,
  notification,
  Button,
  Switch,
  Form,
  Dropdown,
  Menu,
} from "antd";
import bases from "../../graphql/queries/bases";
import { Query } from "react-apollo";
import _ from "lodash";
import update from "immutability-helper";
import Modal from "antd/lib/modal/Modal";
import ProductBaseVariantSelect from "./ProductBaseVariantSelect";
import ProductVariantCostField from "./ProductVariantCostField";
import styled from "styled-components";
import { gql } from "apollo-boost";
import history from "../../history";
import { DropTarget, DragSource, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DownOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import SelectModalFulfil from "./SelectModalFulfil";
import SelectMapingFulfill from "./SelectMapingFulfill";

const Container = styled.div`
  .action {
    display: flex;
    .left-action {
      flex-grow: 1;
    }
  }
`;

let permutationArr = [];
let draggingIndex = -1;

const JOIN = "-";
class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > draggingIndex) {
        className += " drop-over-download";
      }
      if (restProps.index < draggingIndex) {
        className += " drop-over-upward";
      }
    }

    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}

const rowSource = {
  beginDrag(props) {
    draggingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const DraggableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);
class ProductBaseVariants extends Component {
  state = {
    attributes: [],
    dataSource: [],
    loading: false,
    checked: true,
    status: true,
    isCustomcat: false,
    isDreamship: false,
    productBaseSKU: "",
    modal: {
      visible: false,
      selected: [],
      missingVariants: [],
    },
    selectedVariant: null,
    fulfillmentProductID: null,
    selectedRowKeys: [],
  };
  components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { dataSource } = this.state;
    const dragRow = dataSource[dragIndex];

    this.setState(
      update(this.state, {
        dataSource: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      })
    );
  };
  heapPermutation = (options = [], size = 0) => {
    if (size === 1) {
      let items = [];
      for (let i = 0; i < options.length; i++) {
        items.push(options[i]);
      }
      permutationArr.push(items.join(JOIN));
      return permutationArr;
    }
    for (let i = 0; i < size; i++) {
      this.heapPermutation(options, size - 1, options.length);
      if (size % 2 === 1) {
        const tmp = options[0];
        options[0] = options[size - 1];
        options[size - 1] = tmp;
      }
    }
  };

  getCombine = () => {
    const { attributes, productBaseSKU } = this.state;
    let res = [];
    const n = attributes.length;
    let indices = [];
    for (let i = 0; i < n; i++) {
      indices[i] = 0;
    }
    while (true) {
      let options = [productBaseSKU];
      let attrs = [];
      for (let i = 0; i < n; i++) {
        const option = attributes[i].options[indices[i]];
        options.push(option);
        attrs.push({
          name: attributes[i].name,
          option: option,
        });
      }
      const sku = options.join(JOIN);
      res.push({
        sku: sku,
        attributes: attrs,
        regularPrice: null,
        salePrice: null,
        basePrice: null,
        baseId: null,
        status: true,
      });
      let next = n - 1;
      while (
        next >= 0 &&
        indices[next] + 1 >= attributes[next].options.length
      ) {
        next--;
      }
      if (next < 0) {
        break;
      }
      indices[next]++;
      for (let i = next + 1; i < n; i++) {
        indices[i] = 0;
      }
    }
    return res;
  };

  getMissingVariants = () => {
    const { dataSource, attributes, productBaseSKU } = this.state;
    if (attributes.length === 0) {
      notification.warning({ message: "There is no attribute." });
      return [];
    }
    let dataSourceByOptionsMap = {};
    for (let i = 0; i < dataSource.length; i++) {
      let options = [productBaseSKU];
      for (let j = 0; j < dataSource[i].attributes.length; j++) {
        options.push(dataSource[i].attributes[j].option);
      }
      permutationArr = [];
      this.heapPermutation(options, options.length);
      for (let j = 0; j < permutationArr.length; j++) {
        const key = permutationArr[j];
        dataSourceByOptionsMap[key] = dataSource[i];
      }
    }
    const combine = this.getCombine();
    let missingVariants = [];
    for (let i = 0; i < combine.length; i++) {
      let exists = false;
      Object.keys(dataSourceByOptionsMap).forEach((key) => {
        let oldAttributes = _.orderBy(
          dataSourceByOptionsMap[key].attributes.map((a) => ({
            name: a.name,
            option: a.option,
          })),
          ["asc"]
        );
        let newAttributes = _.orderBy(
          combine[i].attributes.map((a) => ({
            name: a.name,
            option: a.option,
          })),
          ["asc"]
        );
        if (_.isEqual(oldAttributes, newAttributes)) {
          exists = true;
        }
      });
      if (!exists) {
        missingVariants.push(combine[i]);
      }
    }
    return missingVariants;
  };

  addMissingVariants = () => {
    const { dataSource } = this.state;
    const missingVariants = this.getMissingVariants();
    if (missingVariants.length > 0) {
      this.setState({
        dataSource: [...dataSource, ...missingVariants],
      });
    }
  };
  onSubmit = (client) => {
    const { dataSource } = this.state;
    const id = this.props.match.params.id;
    const args = [];
    const mutationArr = [];
    let variables = {};
    for (let i = 0; i < dataSource.length; i++) {
      const item = dataSource[i];
      item.ordering = i;
      item.attributes = item.attributes.map((it) => {
        // eslint-disable-next-line valid-typeof
        if (typeof it.__typename !== "underfined") {
          delete it.__typename;
        }
        return it;
      });
      // eslint-disable-next-line valid-typeof
      if (typeof item.__typename !== "underfined") {
        delete item.__typename;
      }
      // eslint-disable-next-line valid-typeof
      if (typeof item.createdAt !== "underfined") {
        delete item.createdAt;
      }
      // eslint-disable-next-line valid-typeof
      // if (typeof item.baseId !== "underfined") {
      //   delete item.baseId;
      // }
      item.baseId = id;
      variables[`variants_${i}`] = [item];
      args.push(`$variants_${i}:[UpdateBaseVariant!]`);
      mutationArr.push(
        `m_${i}:updateProductBaseVariants(variants:$variants_${i})`
      );
    }
    const q = `mutation execute(${args.join(",")}){
      ${mutationArr.join("\n")}
    }`;
    client
      .mutate({
        mutation: gql`
          ${q}
        `,
        variables,
      })
      .then((res) => {
        notification.success({ message: "Variants has been saved!" });
        if (res.data) {
          for (let i = 0; i < dataSource.length; i++) {
            dataSource[i] = res.data[`m_${i}`];
          }
          this.setState({
            loading: false,
            dataSource,
          });
        } else {
          this.setState({ loading: false });
        }
        history.push("/product-bases");
      })
      .catch((e) => {
        this.setState({ loading: false });
        notification.error({ message: e.toString() });
      });
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const {
      dataSource,
      attributes,
      isCustomcat,
      isDreamship,
      autoRender,
      selectedRowKeys,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div>
        <PageHeader
          title="Products Bases Variants"
          onBack={() => this.props.history.goBack()}
          extra={
            <div>
              {(isCustomcat || isDreamship) && !autoRender && (
                <Dropdown
                  overlay={
                    <Menu>
                      {isCustomcat && !autoRender && (
                        <Menu.Item>
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ visibleAllFulfill: "customcat" });
                            }}
                          >
                            Mapp CCID
                          </a>
                        </Menu.Item>
                      )}
                      {isDreamship && !autoRender && (
                        <Menu.Item>
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ visibleAllFulfill: "dreamship" });
                            }}
                          >
                            Mapp DSID
                          </a>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  placement="bottomLeft"
                  disabled={selectedRowKeys.length === 0}
                >
                  <Button>
                    Bulk action <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            </div>
          }
        />
        <Query
          fetchPolicy="no-cache"
          query={bases}
          variables={{
            ids: [this.props.match.params.id],
            baseId: this.props.match.params.id,
          }}
          onCompleted={(data) => {
            this.setState({
              attributes: data.productBaseByIds[0]?.attributes
                ? data.productBaseByIds[0]?.attributes
                : [],
              autoRender: data.productBaseByIds[0]?.autoRender,
              dataSource: data.productBaseVariants
                ? data.productBaseVariants
                : [],
              productBaseSKU: data?.productBaseByIds[0]?.sku,
              isCustomcat: data.productBaseByIds[0].fulfillServices.find(
                (item) => item.name === "CustomCat"
              )
                ? true
                : false,
              isDreamship: data.productBaseByIds[0].fulfillServices.find(
                (item) => item.name === "Dreamship"
              )
                ? true
                : false,
            });
          }}
        >
          {({ loading, error, client }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            let columns = [
              {
                title: "Variant",
                key: "variant",
                width: 120,
                render: (record) => (
                  <div>
                    {record?.attributes?.map((attr) => attr.option).join("/")}
                  </div>
                ),
              },
              {
                title: "SKU",
                key: "sku",
                width: 250,
                render: (_, record, index) => (
                  <div>
                    <Input
                      onChange={(e) => {
                        dataSource[index].sku = e.target.value;
                        this.setState({
                          dataSource,
                        });
                      }}
                      defaultValue={record.sku}
                    />
                  </div>
                ),
              },
            ];
            columns.push({
              title: "Base price",
              key: "seller",
              width: 150,
              render: (_, record, index) => (
                <div>
                  <ProductVariantCostField
                    value={record.basePrice}
                    onChange={(value) => {
                      dataSource[index].basePrice = value;
                      this.setState({ dataSource });
                    }}
                  />
                </div>
              ),
            });
            columns.push({
              title: "Regular price",
              key: "regular",
              width: 150,
              render: (_, record, index) => (
                <div>
                  <ProductVariantCostField
                    value={record.regularPrice}
                    onChange={(value) => {
                      dataSource[index].regularPrice = value;
                      this.setState({ dataSource });
                    }}
                  />
                </div>
              ),
            });
            columns.push({
              title: "Sale price",
              key: "sale",
              width: 150,
              render: (_, record, index) => (
                <div>
                  <ProductVariantCostField
                    value={record.salePrice}
                    onChange={(value) => {
                      dataSource[index].salePrice = value;
                      this.setState({ dataSource });
                    }}
                  />
                </div>
              ),
            });
            if (isCustomcat && !autoRender) {
              columns.push({
                dataIndex: "ccId",
                title: "ccId",
                key: "ccId",
                width: 120,
                render: (ccId, record, index) => (
                  <div style={{ display: "flex", gap: 5 }}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      validateStatus={!ccId || ccId === "" ? "error" : ""}
                    >
                      <Input
                        value={record.ccId}
                        size="small"
                        onChange={(value) => {
                          dataSource[index].ccId = value.target.value;
                          this.setState({
                            dataSource,
                          });
                        }}
                      />

                      <div>
                        {!ccId || ccId === "" ? (
                          <small
                            style={{
                              position: "absolute",
                              bottom: -14,
                              color: "red",
                            }}
                          >
                            Please input ccId
                          </small>
                        ) : null}
                      </div>
                    </Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        this.setState({
                          selectFulfil: {
                            index: index,
                            value: record.ccId,
                            type: "customcat",
                            column: "ccId",
                            variantApp: record?.attributes
                              ?.map((attr) => attr.option)
                              .join("/"),
                            attributes: record.attributes,
                          },
                          fulfillmentProductID: record.ccId,
                        });
                      }}
                      icon={<FormOutlined />}
                    />
                  </div>
                ),
              });
            }
            if (isDreamship && !autoRender) {
              columns.push({
                dataIndex: "dsId",
                title: "dsId",
                key: "dsId",
                width: 120,
                render: (dsId, record, index) => (
                  <div style={{ display: "flex", gap: 10 }}>
                    <Form.Item
                      style={{ marginBottom: 0 }}
                      validateStatus={!dsId || dsId === "" ? "error" : ""}
                    >
                      <Input
                        value={record.dsId}
                        size="small"
                        onChange={(value) => {
                          dataSource[index].dsId = value.target.value;
                          this.setState({
                            dataSource,
                          });
                        }}
                      />

                      <div>
                        {!dsId || dsId === "" ? (
                          <small
                            style={{
                              position: "absolute",
                              bottom: -14,
                              color: "red",
                            }}
                          >
                            Please input dsId
                          </small>
                        ) : null}
                      </div>
                    </Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        this.setState({
                          selectFulfil: {
                            index: index,
                            value: record.dsId,
                            type: "dreamship",
                            column: "dsId",
                            variantApp: record?.attributes
                              ?.map((attr) => attr.option)
                              .join("/"),
                            attributes: record.attributes,
                          },
                          fulfillmentProductID: record.dsId,
                        });
                      }}
                      icon={<FormOutlined />}
                    />
                  </div>
                ),
              });
            }
            columns.push({
              dataIndex: "size",
              title: "Size",
              key: "size",
              width: 100,
              render: (_, record, index) => (
                <Input
                  value={record.size}
                  size="small"
                  onChange={(value) => {
                    dataSource[index].size = value.target.value;
                    this.setState({
                      dataSource,
                    });
                  }}
                />
              ),
            });
            columns.push({
              dataIndex: "status",
              title: "Status",
              key: "status",
              width: 100,
              render: (_, record, index) => (
                <div>
                  <Switch
                    size="small"
                    onChange={(value) => {
                      dataSource[index].status = value;
                      this.setState({
                        dataSource,
                      });
                    }}
                    checked={record.status}
                  />
                </div>
              ),
            });
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <Container>
                <DndProvider backend={HTML5Backend}>
                  <Table
                    rowSelection={rowSelection}
                    // rowKey={"sku"}
                    rowKey={(record) => record}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    components={this.components}
                    scroll={{ x: tableWidth }}
                    onRow={(row, index) => ({
                      index,
                      moveRow: this.moveRow,
                    })}
                    locale={{
                      emptyText: (
                        <div>
                          <p>No variants added</p>
                        </div>
                      ),
                    }}
                    footer={() => (
                      <div className="action">
                        <div className="left-action">
                          <Button
                            style={{ marginBottom: 5 }}
                            onClick={() => this.addMissingVariants()}
                          >
                            <PlusOutlined />
                            Add all missing variants
                          </Button>
                          <span style={{ margin: "0px 5px" }}>or</span>
                          <Button
                            onClick={() => {
                              const missingVariants = this.getMissingVariants();
                              if (missingVariants.length > 0) {
                                this.setState({
                                  modal: {
                                    ...this.state.modal,
                                    visible: true,
                                    missingVariants,
                                  },
                                });
                              } else {
                                notification.info({
                                  message: "No missing variants",
                                });
                              }
                            }}
                          >
                            Select variants
                          </Button>
                        </div>
                        <div className="right-action">
                          <Button
                            onClick={() => history.push("/product-bases")}
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading}
                            onClick={() => {
                              if (!dataSource.length) {
                                return;
                              }
                              this.setState({ loading: true });
                              this.onSubmit(client);
                            }}
                            disabled={
                              (isCustomcat &&
                                !autoRender &&
                                dataSource.find(
                                  (item) => !item.ccId || item.ccId === ""
                                )) ||
                              (isDreamship &&
                                !autoRender &&
                                dataSource.find(
                                  (item) => !item.dsId || item.dsId === ""
                                ))
                                ? true
                                : false
                            }
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                </DndProvider>

                {this.state.modal.visible && (
                  <Modal
                    className="product-type-modal"
                    width={700}
                    title={"Select Variants"}
                    onOk={() => {
                      if (this.state.modal?.selected?.length) {
                        this.setState({
                          modal: {
                            ...this.state.modal,
                            visible: false,
                            selected: [],
                          },
                          dataSource: [
                            ...dataSource,
                            ...this.state.modal?.selected,
                          ],
                        });
                      }
                    }}
                    onCancel={() => {
                      this.setState({
                        modal: {
                          ...this.state.modal,
                          visible: false,
                        },
                      });
                    }}
                    visible={this.state.modal.visible}
                  >
                    <ProductBaseVariantSelect
                      onChange={(variants) => {
                        this.setState({
                          modal: {
                            ...this.state.modal,
                            selected: variants,
                          },
                        });
                      }}
                      variants={this.state.modal.missingVariants}
                      attributes={attributes}
                    />
                  </Modal>
                )}
                {this.state.selectFulfil && (
                  <Modal
                    title="Select fulfillment"
                    onCancel={() => {
                      this.setState({
                        selectFulfil: false,
                        fulfillmentProductID: null,
                      });
                    }}
                    visible={this.state.selectFulfil}
                    footer={
                      <div>
                        <Button
                          onClick={() => {
                            this.setState({
                              fulfillmentProductID: null,
                              selectFulfil: false,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          disabled={this.state.fulfillmentProductID === null}
                          onClick={() => {
                            const {
                              selectFulfil,
                              fulfillmentProductID,
                            } = this.state;
                            const { index, column } = selectFulfil;
                            dataSource[index][column] = fulfillmentProductID;
                            this.setState({
                              dataSource,
                              fulfillmentProductID: null,
                              selectFulfil: false,
                            });
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    }
                  >
                    <SelectModalFulfil
                      dataVariant={this.state.selectFulfil}
                      onChange={(value) => {
                        this.setState({ fulfillmentProductID: value });
                      }}
                      fulfillmentProductID={this.state.fulfillmentProductID}
                    />
                  </Modal>
                )}
                {this.state.visibleAllFulfill && (
                  <SelectMapingFulfill
                    type={this.state.visibleAllFulfill}
                    dataSource={dataSource}
                    selectedRowKeys={selectedRowKeys}
                    setVisibleAllFulfull={(value) => {
                      this.setState({ visibleAllFulfill: value });
                    }}
                    setSelectRowKey={(value) => {
                      this.onSelectChange(value);
                    }}
                    onChange={(data) => {
                      this.setState({ dataSource: data });
                    }}
                  />
                )}
              </Container>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ProductBaseVariants;
