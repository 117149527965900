import { Skeleton, Timeline } from "antd";
import React from "react";
import { Query } from "react-apollo";
import orderActivity from "../../graphql/queries/orders/orderActivity";
import moment from "moment";

const OrderActivity = ({ order }) => {
  return (
    <div>
      <Query
        query={orderActivity}
        skip={!order}
        fetchPolicy="no-cache"
        variables={{ orderId: order.id }}
      >
        {({ error, loading, data, refetch }) => {
          if (loading) return <Skeleton />;
          if (error) return <div>{error.toString()}</div>;
          return (
            <div>
              <Timeline>
                {data?.orderActivitys.map((item) => (
                  <Timeline.Item key={item.id}>
                    <div>{item.message}</div>
                    <i style={{ color: "#908c8c" }}>
                      {moment(item.createdAt).format("lll")}
                    </i>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default OrderActivity;
