import React, { Component } from "react";
import styled from "styled-components";
import {
  Button,
  Popover,
  Form,
  Divider,
  Input,
  message,
  notification,
} from "antd";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import moment from "moment";

const Container = styled.div`
  .ant-btn:hover,
  .ant-btn:focus {
    border: none;
  }
`;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

export default class PushToStore extends Component {
  state = {
    productTypes: [],
    visible: false,
    loading: false,
  };

  hide = () => {
    this.setState({
      visible: false,
      loading: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    const { order } = this.props;
    // const cookieExpired =
    //   moment(order.site.cookieUpdatedAt)
    //     .add(1, "days")
    //     .diff(moment(), "minutes") < 10;
    // if (cookieExpired) {
    //   if (
    //     moment(order.site.cookieUpdatedAt)
    //       .add(1, "days")
    //       .diff(moment(), "minutes") < 0
    //   ) {
    //     notification.error({
    //       message: order.site.title + "'s cookie has expired!",
    //     });
    //     this.setState({ loading: false });
    //   } else if (
    //     0 <
    //     moment(order.site.cookieUpdatedAt)
    //       .add(1, "days")
    //       .diff(moment(), "minutes") <
    //     10
    //   ) {
    //     this.setState({ loading: false });
    //     notification.warning({
    //       message:
    //         order.site.title +
    //         "'s cookie will expire in" +
    //         moment(order.site.cookieUpdatedAt)
    //           .add(1, "days")
    //           .diff(moment(), "minutes") +
    //         "minutes",
    //     });
    //   }
    // } else {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($order_ids: [ID!], $options: PushProductOption) {
            pushProduct(order_ids: $order_ids, options: $options)
          }
        `,
        variables: {
          order_ids: [order.id],
          options: {
            color: values.color,
            size: values.size,
            category: values.product_type_id,
          },
        },
      })
      .then(() => {
        message.success(
          "Pushing product in the background, the order status will be updated in a moment.",
          5
        );
        this.setState({
          visible: false,
          loading: false,
        });
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
    //}
  };

  render() {
    const { visible, loading } = this.state;
    const { order, disabled, productOrder } = this.props;
    let size, color;
    //  size color order extenal
    order &&
      order.variant === null &&
      order.variant_title.split(" / ").map((e) => {
        if (
          ["S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"].includes(e) === true
        ) {
          size = e;
        } else {
          color = e;
        }
        return { size, color };
      });

    //  size color order internal
    order &&
      order.variant !== null &&
      order.variant.attributes.map((e) => {
        if (e.name.toUpperCase() === "SIZE") {
          size = e.option;
        }
        if (e.name.toUpperCase() === "COLOR") {
          color = e.option;
        }
        return { size, color };
      });
    const content = (
      <Form
        {...layout}
        className="form-create-task"
        style={{ width: 300 }}
        onFinish={this.onFinish}
      >
        <Form.Item
          label="Product type"
          name="product_type_id"
          initialValue={
            order && order.variant && order.variant.productBase.type?.origin
              ? order.variant.productBase.type.origin
              : order.product.productType
          }
          rules={[
            {
              required: true,
              message: "Please input product type!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
          initialValue={color ? color : ""}
          rules={[
            {
              required: true,
              message: "Please input variant Color!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Size"
          name="size"
          initialValue={size ? size : ""}
          rules={[
            {
              required: true,
              message: "Please input variant size!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div>
          <Button loading={loading} type="primary" htmlType="submit">
            Push to store
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.hide}>Cancel</Button>
        </div>
      </Form>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={disabled ? false : visible}
        placement="bottom"
        onVisibleChange={this.handleVisibleChange}
      >
        {productOrder ? (
          <Container>
            <Button
              disabled={disabled}
              style={{
                pointerEvents: disabled ? "none" : "auto",
                color: disabled ? "#ccc" : null,
                cursor: disabled ? "default" : null,
              }}
              type="primary"
            >
              Push to store
            </Button>
          </Container>
        ) : (
          <a
            href="/#"
            style={{
              pointerEvents: disabled ? "none" : "auto",
              color: disabled ? "#ccc" : null,
              cursor: disabled ? "default" : null,
            }}
            onClick={(e) => e.preventDefault()}
          >
            Push to store
          </a>
        )}
      </Popover>
    );
  }
}
