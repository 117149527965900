import { gql } from "apollo-boost";

export default gql`
  query carriers($filter: CarrierFilter) {
    carriers(filter: $filter) {
      id
      name
      code
      description
      trackingUrl
      status
    }
  }
`;
