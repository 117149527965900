import React, { Component } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Checkbox,
  PageHeader,
  Button,
  notification,
  Select,
} from "antd";
import Wysiwyg from "../Wysiwyg";
import ProductBasePrintFile from "./ProductBasePrintFile";
import ProductBaseAttributes from "./ProductBaseAttributes";
import { Link } from "react-router-dom";
import CarrierSelect from "../carriers/CarrierSelect";
import CategorySelect from "../categories/CategorySelect";
import MappingSelect from "../mappings/MappingSelect";
import createProductBase from "../../graphql/mutates/createProductBase";
import { apolloClient } from "../../apollo-client";
import history from "../../history";
import FFServices from "../../graphql/queries/ffServices";
import productBaseByIds from "../../graphql/queries/productBaseByIds";
import updateProductBase from "../../graphql/mutates/updateProductBase";
import Upload from "../files/Upload";
import SelectFullfill from "./SelectFullfill";

const Container = styled.div`
  .btn {
    margin-right: 5px;
  }
`;

const shippingmethod = [
  "Economy",
  "Ground",
  "2-Day",
  "Overnight",
  "International Economy",
];
class ProductBaseForm extends Component {
  state = {
    fulfillment: [],
    value: [],
    productBase: [],
    loading: false,
    images: [],
    productBaseImage: [],
    attributesValidator: [],
    choseFulfill: null,
    isCustomcat: false,
    isDreamship: false,
  };
  formRef = React.createRef();
  componentDidMount() {
    apolloClient
      .query({
        query: FFServices
      })
      .then((res) => {
        console.log("here", res)

        this.setState({
          fulfillment: res?.data?.FulfillServices?.nodes,
        });
      });
    const { id } = this.props.match.params;
    id && this.productBases(id);
  }
  productBases = (id) => {
    this.setState({ loading: true });
    apolloClient
      .query({
        query: productBaseByIds,
        fetchPolicy: "network-only",
        variables: {
          ids: [id],
          baseId: id,
        },
      })
      .then((res) => {
        this.setState({
          productBaseVariants: res?.data?.productBaseVariants || [],
          productBase: res?.data?.productBaseByIds || [],
          productBaseImage: res?.data?.productBaseByIds[0].images || [],
          loading: false,
          attributesValidator: res?.data?.productBaseByIds[0]?.attributes || [],
          choseFulfill: res?.data.productBaseByIds[0].fulfillServiceId,
          isCustomcat: res?.data.productBaseByIds[0].fulfillServices.find(
            (item) => item.name === "CustomCat"
          )
            ? true
            : false,
          isDreamship: res?.data.productBaseByIds[0].fulfillServices.find(
            (item) => item.name === "Dreamship"
          )
            ? true
            : false,
        });
      });
  };
  onFinish = (values) => {
    values.images = [...this.state.productBaseImage, ...this.state.images].map(
      (e) => e.id
    );
    const newDesign = values.designs.map((d, index) => {
      return {
        id: d.id,
        name: d.name,
        width: d.width,
        height: d.height,
        description: d.description,
        side: d.side,
        ccName: d.ccName,
        dsName: d.dsName,
        ordering: index,
        target: d.target,
      };
    });
    //trim empty space around attributes name and attribute options
    const newAttributes = values.attributes.map((a) => {
      a.name = a.name.trim();
      a.options = a.options.map((o) => o.trim());
      return a;
    });

    const { id } = this.props.match.params;
    if (!id) {
      apolloClient
        .mutate({
          mutation: createProductBase,
          variables: {
            // input: values,
            // designs: newDesign,
            input: {
              ...values,
              attributes: newAttributes,
              designs: newDesign,
              fulfillServiceId: this.state.choseFulfill,
            },
          },
        })
        .then(() => {
          notification.success({ message: "Add base success!" });
          history.push("/product-bases");
        })
        .catch((err) => {
          notification.error({
            message: err.message,
          });
        });
    }
    if (id) {
      apolloClient
        .mutate({
          mutation: updateProductBase,
          variables: {
            input: {
              ...values,
              id,
              attributes: newAttributes,
              designs: newDesign,
              fulfillServiceId: this.state.choseFulfill,
            },
          },
        })
        .then(() => {
          notification.success({ message: "Edit base success!" });
          history.push("/product-bases");
        })
        .catch((err) => {
          notification.error({
            message: err.message,
          });
        });
    }
  };
  deleteImage = (key, value) => {
    if (value === "productBaseImage") {
      this.setState({
        productBaseImage: this.state.productBaseImage.filter(
          (el) => el.key !== key
        ),
      });
    } else {
      this.setState({
        images: this.state.images.filter((el) => el.key !== key),
      });
    }
  };

  validateCharater = (value) => {
    var expression = /^[a-zA-Z0-9]+$/;
    var regex = new RegExp(expression);
    if (value.match(regex)) {
      return true;
    }
    return false;
  };

  validateAttribute = (value) => {
    const { attributesValidator } = this.state;
    const attributesName = attributesValidator.map((attri) =>
      attri.name.toLowerCase()
    );
    if (attributesName.includes(value.toLowerCase())) {
      return false;
    }
    return true;
  };
  // toTitle = (text) => {
  //   if (!text) {
  //     return;
  //   }
  //   return text
  //     .toLowerCase()
  //     .replace(/[^\w ]+/g, "")
  //     .replace(/ +/g, "-");
  // };

  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const { id } = this.props.match.params;
    const {
      fulfillment,
      productBase,
      loading,
      productBaseImage,
      choseFulfill,
      isCustomcat,
      isDreamship,
    } = this.state;
    const carrier = productBase[0]?.carriers?.map((c) => c.id);
    let idCustomCat = fulfillment.find((item) => item.type === "customcat");
    let idDreamship = fulfillment.find((item) => (item.type = "dreamship"));
    if (loading) return null;

    return (
      <Container>
        <PageHeader
          title={`${id ? "Edit Product Base" : "Add Product Base"}`}
          onBack={() => this.props.history.goBack()}
        />
        <Form {...layout} ref={this.formRef} onFinish={this.onFinish}>
          <Row gutter={16} style={{ marginTop: 20 }}>
            <Col span={24} md={16}>
              <Card title="Detail" style={{ marginBottom: 10 }}>
                <Form.Item
                  label="Tille"
                  name="title"
                  rules={[{ required: true, message: "Please input title!" }]}
                  initialValue={productBase ? productBase[0]?.title : ""}
                >
                  <Input
                    onChange={(e) => {
                      if (productBase.length < 1) {
                        this.formRef.current.setFieldsValue({
                          storeType: e.target.value,
                        });
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Display title"
                  name="storeType"
                  // rules={[{ required: true, message: "Please input title!" }]}
                  initialValue={productBase ? productBase[0]?.storeType : ""}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="SKU"
                  name="sku"
                  rules={[
                    {
                      validator: (_, value) =>
                        value && value.length
                          ? this.validateCharater(value)
                            ? Promise.resolve()
                            : Promise.reject(
                              "Invalid SKU. Not allowing symbols or special chars!"
                            )
                          : Promise.reject("Please input SKU!"),
                    },
                  ]}
                  initialValue={productBase ? productBase[0]?.sku : ""}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Mockup Link"
                  name="mockup"
                  initialValue={productBase ? productBase[0]?.mockup : ""}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  initialValue={productBase ? productBase[0]?.description : ""}
                >
                  <Wysiwyg />
                </Form.Item>
              </Card>
              <Card title="Attributes" style={{ marginBottom: 10 }}>
                <Row>
                  <Col span={24} md={12}>
                    <Form.Item
                      valuePropName="checked"
                      name="autoRender"
                      style={{ marginBottom: 20 }}
                      initialValue={
                        productBase ? productBase[0]?.autoRender : false
                      }
                    >
                      <Checkbox
                        disabled={productBase && productBase[0]?.used}
                        onChange={(e) => this.setState({ e: e.target.value })}
                      >
                        Auto render attribute by mockups
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={12}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.autoRender !== currentValues.autoRender
                      }
                    >
                      {({ getFieldValue }) => {
                        return (
                          (getFieldValue("autoRender") === true ||
                            (productBase[0] && productBase[0].autoRender)) && (
                            <Form.Item
                              initialValue={
                                productBase
                                  ? productBase[0]?.renderAttrName
                                  : ""
                              }
                              rules={[
                                {
                                  validator: (_, value) =>
                                    value && value.length
                                      ? this.validateAttribute(value)
                                        ? Promise.resolve()
                                        : Promise.reject(
                                          `Invalid attribute name. Attribute name of "Auto render" can not be the same of attributes!`
                                        )
                                      : Promise.reject(
                                        "Please input attribute name!"
                                      ),
                                },
                              ]}
                              name="renderAttrName"
                            >
                              <Input placeholder="Enter attribute name" />
                            </Form.Item>
                          )
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="attributes"
                  initialValue={productBase ? productBase[0]?.attributes : ""}
                >
                  <ProductBaseAttributes
                    onValidate={(value) =>
                      this.setState({ attributesValidator: value })
                    }
                  />
                </Form.Item>
              </Card>
              <Card title="Print Files" style={{ marginBottom: 10 }}>
                <Form.Item
                  name="designs"
                  initialValue={productBase ? productBase[0]?.designs : ""}
                >
                  <ProductBasePrintFile
                    checkValidate={(value) => {
                      this.setState({ checkValidate: value });
                    }}
                    productBaseVariants={this.state.productBaseVariants}
                    onChange={(value) => {
                      if (
                        (isCustomcat &&
                          value.find(
                            (item) => !item.ccName || item.ccName === ""
                          )) ||
                        (isDreamship &&
                          value.find(
                            (item) => !item.dsName || item.dsName === ""
                          ))
                      ) {
                        this.setState({ checkValidate: true });
                      } else {
                        this.setState({ checkValidate: false });
                      }

                      console.log("onchange", value);
                    }}
                    isCustomcat={isCustomcat}
                    isDreamship={isDreamship}
                  />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24} md={8}>
              <Card style={{ marginBottom: 10 }}>
                <Form.Item
                  name="categoryId"
                  label="Category"
                  initialValue={productBase ? productBase[0]?.category?.id : ""}
                  rules={[
                    { required: true, message: "Please input category!" },
                  ]}
                >
                  <CategorySelect />
                </Form.Item>
                {/* <Form.Item
                  name="fulfillServiceId"
                  label="Fulfillment"
                  rules={[
                    { required: true, message: "Please input fulfillment!" },
                  ]}
                  initialValue={
                    productBase ? productBase[0]?.fulfillServiceId : ""
                  }
                >
                  <Select placeholder="Select.." optionFilterProp="children">
                    {fulfillment.map((ful) => (
                      <Select.Option key={ful.id} value={ful.id}>
                        {ful.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  name="fulfillServiceIds"
                  label="Fulfillments"
                  initialValue={
                    productBase
                      ? productBase[0]?.fulfillServices.map((item) => item.id)
                      : ""
                  }
                  rules={[
                    { required: true, message: "Please input Fulfillments!" },
                  ]}
                >
                  <SelectFullfill
                    fulfillment={fulfillment}
                    choseFulfill={choseFulfill}
                    onChangeDefault={(value) => {
                      this.setState({ choseFulfill: value });
                    }}
                    onChange={(value) => {
                      if (
                        (value.includes(idCustomCat?.id) &&
                          this.formRef.current
                            ?.getFieldValue("designs")
                            ?.find(
                              (item) => !item.ccName || item.ccName === ""
                            )) ||
                        (idDreamship.id &&
                          this.formRef.current
                            ?.getFieldValue("designs")
                            ?.find(
                              (item) => !item.dsName || item.dsName === ""
                            ))
                      ) {
                        this.setState({ checkValidate: true });
                      } else {
                        this.setState({ checkValidate: false });
                      }

                      this.setState({
                        isCustomcat: value.includes(idCustomCat.id),
                        isDreamship: value.includes(idDreamship.id),
                      });
                    }}
                  />
                </Form.Item>
                {isCustomcat ? (
                  <Form.Item
                    name="shippingMethod"
                    label="shipping Method"
                    initialValue={
                      productBase ? productBase[0]?.shippingMethod : null
                    }
                  >
                    <Select>
                      {shippingmethod.map((item, key) => (
                        <Select.Option value={item} key={key}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : null}
                {fulfillment.find((el) => el.id === choseFulfill)?.type ===
                  "fuel" && (
                    <Form.Item
                      name="typeId"
                      label="Type"
                      initialValue={productBase ? productBase[0]?.type?.id : ""}
                    >
                      <MappingSelect />
                    </Form.Item>
                  )}

                {/* <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.fulfillServiceId !==
                    currentValues.fulfillServiceId
                  }
                >
                  {({ getFieldValue }) => {
                    let fulfillServiceId =
                      typeof getFieldValue("fulfillServiceId") !== "undefined"
                        ? getFieldValue("fulfillServiceId")
                        : productBase[0]?.fulfillService?.id;

                    return (
                      fulfillment.find((el) => el.id === fulfillServiceId)
                        ?.type === "api" && (
                        <Form.Item
                          name="typeId"
                          label="Type"
                          initialValue={
                            productBase ? productBase[0]?.type?.id : ""
                          }
                        >
                          <MappingSelect />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item> */}
                <Form.Item
                  name="carriers"
                  label="Carriers"
                  initialValue={carrier ? carrier : []}
                >
                  <CarrierSelect multiple />
                </Form.Item>
              </Card>
              <Card>
                <Form.Item style={{ width: "100%" }} label="Image">
                  <Upload
                    productBaseImage={productBaseImage}
                    multiple={true}
                    onCompleted={(files) => this.setState({ images: files })}
                    deleteImage={(key, value) => this.deleteImage(key, value)}
                  />
                </Form.Item>
              </Card>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="btn"
              disabled={
                this.state.checkValidate ||
                this.formRef.current
                  ?.getFieldValue("designs")
                  ?.find((item) => !item.name || item.name === "")
              }
            >
              Save
            </Button>
            <Link to="/product-bases">
              <Button>Cancel</Button>
            </Link>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default ProductBaseForm;
