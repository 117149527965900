import React, { Component } from "react";
import categories from "../../graphql/queries/categories";
import { apolloClient } from "../../apollo-client";
import { Select } from "antd";

class CategorySelect extends Component {
  state = {
    category: [],
    value: this.props.value ?? [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    apolloClient
      .query({
        query: categories,
      })
      .then((res) => {
        this.setState({
          category: res?.data?.categories?.nodes,
        });
      });
  }

  render() {
    const { category, value } = this.state;
    return (
      <div>
        <Select
          placeholder="Select..."
          optionFilterProp="children"
          value={value}
          onChange={(value) => {
            this.setState({ value }, () => this.props.onChange(value));
          }}
        >
          {category.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {el.title}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default CategorySelect;
