import { gql } from "apollo-boost";

export default gql`
  query productTags($filter: ProductTagFilter) {
    productTags(filter: $filter) {
      total
      nodes {
        id
        title
        status
        description
        createdAt
      }
    }
  }
`;
