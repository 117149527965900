import React from "react";
import history from "../history";
export default function (route, currentUser) {
  class RenderRouter extends React.Component {
    state = {
      loading: null,
    };
    componentDidMount() {
      if (
        currentUser &&
        currentUser.roles &&
        currentUser.roles.length &&
        currentUser.roles.includes("Administrator")
      ) {
        return;
      }
      const roles = route.roles ? route.roles : [];
      if (currentUser && roles.includes("Authenticated")) {
        return;
      }
      let roleMapping = {};
      for (let i = 0; i < roles.length; i++) {
        roleMapping[roles[i]] = true;
      }
      if (roles.length) {
        if (!currentUser || !currentUser.roles || !currentUser.roles.length) {
          this.redirect();
          return;
        }

        let canAccess = false;
        for (let i = 0; i < currentUser.roles.length; i++) {
          if (roleMapping[currentUser.roles[i]]) {
            canAccess = true;
            break;
          }
        }
        if (!canAccess) {
          this.redirect();
        }
      }
    }

    redirect = () => {
      history.push(`/login`);
    };

    render() {
      const ComposedComponent = route.component;
      const Layout = route.layout;
      return (
        <React.Fragment>
          {Layout ? (
            <Layout title={route.title} {...this.props}>
              <ComposedComponent {...this.props} />
            </Layout>
          ) : (
            <ComposedComponent {...this.props} />
          )}
        </React.Fragment>
      );
    }
  }

  return RenderRouter;
}
