import { gql } from "apollo-boost";

export default gql`
  mutation createProductTag($input: NewProductTag!) {
    createProductTag(input: $input) {
      id
      title
    }
  }
`;
