import React, { Component } from "react";
import {
  Table,
  Select,
  Button,
  Drawer,
  Divider,
  Skeleton,
  Popconfirm,
  notification,
  Input,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined,QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import CarrierForm from "./CarrierForm";
import carriers from "../../graphql/queries/carriers";
import { Query } from "react-apollo";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import _ from "lodash";

const Container = styled.div`
  .p-filter {
    display: flex;
    margin: 20px 0;
    .p-select {
      flex-grow: 1;
      margin-right: 5px;
    }
  }
`;
class Carriers extends Component {
  state = {
    filter: {
      status: true,
      search: null,
    },
    showDrawer: false,
  };
  onClose = () => {
    this.setState({
      showDrawer: false,
    });
  };
  deleteCarrier = (id) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteCarrier($id: ID!) {
            deleteCarrier(id: $id)
          }
        `,
        variables: { id },
      })
      .then(() => {
        notification.success({ message: "Delete carrier success!" });
      });
  };
  render() {
    const { filter, showDrawer } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <div className="p-select">
            <Input.Search
              onSearch={(s) =>
                this.setState({ filter: { ...filter, search: s } })
              }
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: { ...filter, search: null },
                  });
                }
              }}
              addonBefore={
                <Select
                  style={{ width: 140 }}
                  defaultValue={filter.status ? "true" : "false"}
                  onChange={(status) => {
                    this.setState({
                      filter: {
                        ...filter,
                        status: JSON.parse(status),
                      },
                    });
                  }}
                >
                  <Select.Option value="true">Active</Select.Option>
                  <Select.Option value="false">Inactive</Select.Option>
                </Select>
              }
            />
          </div>
          <Button
            onClick={() => this.setState({ showDrawer: true })}
            type="primary"
          >
            <PlusOutlined /> Add carrier
          </Button>
        </div>
        <Query query={carriers} variables={{ filter: { ...filter } }}>
          {({ loading, data, error, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const columns = [
              { title: "Name", key: "name", dataIndex: "name", width: 160 },
              { title: "Code", key: "code", dataIndex: "code", width: 150 },
              {
                title: "Tracking url",
                key: "trackingUrl",
                dataIndex: "trackingUrl",
                width: 250,
              },
              {
                title: "Description",
                key: "description",
                dataIndex: "description",
                width: 200,
              },
              {
                title: "Action",
                key: "action",
                width: 120,
                align: "right",
                render: (record) =>
                  record.status === true && (
                    <div>
                      <Button
                        size="small"
                        onClick={() =>
                          this.setState({
                            showDrawer: record,
                          })
                        }
                      >
                        <EditOutlined />
                      </Button>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Are you sure delete carrier?"
                        placement="top"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                        okButtonProps={{type:"danger"}}
                        onConfirm={() => {
                          this.deleteCarrier(record.id);
                          refetch();
                        }}
                      >
                        <Button style={{ color: "red" }} size="small">
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </div>
                  ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            const dataSource = data.carriers;
            if (dataSource)
              return (
                <div>
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey={(index) => index.id}
                    scroll={{ x: tableWidth }}
                  />
                  <Drawer
                    title={`${!showDrawer.id ? "Add carrier" : "Edit carrier"}`}
                    width={600}
                    visible={showDrawer}
                    onClose={this.onClose}
                  >
                    <CarrierForm
                      refetch={() => refetch()}
                      onClose={this.onClose}
                      showDrawer={showDrawer}
                      id={showDrawer && showDrawer.id}
                      key={showDrawer && showDrawer.id}
                    />
                  </Drawer>
                </div>
              );
          }}
        </Query>
      </Container>
    );
  }
}

export default Carriers;
