import React, { Component } from "react";
import styled from "styled-components";
import { Query } from "react-apollo";
import {
  Skeleton,
  Col,
  Row,
  Card,
  Select,
  Button,
  Spin,
  message,
  Dropdown,
  Menu,
  Tooltip,
  Pagination,
  Popconfirm,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import CarouselImage from "./CarouselImage";
import { LOAD_SITE } from "../sites/Sites";
import { apolloClient } from "../../apollo-client";
import UploadPNG from "./UploadPNG";
import { gql } from "apollo-boost";
import history from "../../history";
import CreateTask from "./CreateTask";
import Upload from "../Upload";
import createFile from "../../graphql/mutates/createFile";
import { AppContext } from "../../context";
import UpdateTask from "./UpdateTask";
import {
  MoreOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import ChangePrintFiles from "./ChangePrintFiles";
import RemovePrintFiles from "./RemovePrintFiles";
import PushToStore from "./PushToStore";
import { AWS_CLOUDFRONT_URL } from "../../config";
import updateOrderStatus from "../../graphql/mutates/updateOrderStatus";
import orders_query from "../../graphql/queries/orders/orders";
import uploadPrintFile from "../../graphql/mutates/uploadPrintFile";
import productById from "../../graphql/queries/productById";
import productByStore from "../../graphql/queries/productByStore";
import defaultImage from "../../assets/images/default.png";
import DesignConfirmation from "./DesignConfirmation";
import BulkUpload from "../files/Upload";
import MappingOrder from "../externalOrders/MappingOrder";
import Mapshopify from "../externalOrders/Mapshopify";
import { orderStatus } from "./FilterOrder";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";

const Container = styled.div`
  .detail-order {
    display: grid;
    grid-template-columns: 350px auto;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(240, 240, 240);
    border-image: initial;
    margin: 0px 0px 20px 20px;
    background: rgb(239, 239, 239);
  }
  .order-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .select-action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .select-status {
      margin-left: 10px;
      min-width: 150px;
    }
    .select-product {
      width: 400px;
    }
  }
  .form-create-task .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

export default class ProductOrder extends Component {
  state = {
    loading: false,
    filter: {
      limit: 20,
      offset: 0,
      siteId: null,
      status: null,
      search: null,
      productId: null,
      external: true,
    },
    page: 1,
    visible: false,
    sites: [],
    products: [],
    fetching: false,
    orders: [],
    hideText: true,
    total: null,
    showMaping: false,
    modalType: null,
    modalReject: false,
    contentReject: "",
  };

  componentDidMount() {
    const { siteId, id } = this.props.match.params;
    this.setState({
      filter: { ...this.state.filter, siteId, productId: id },
    });
    this.loadSites();
    this.loadProducts(id);
  }

  loadSites() {
    apolloClient
      .query({
        query: LOAD_SITE,
        variables: {
          filter: {
            verified: true,
          },
        },
      })
      .then((res) => {
        this.setState({
          sites: res.data.sites.hits || [],
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  loadProducts(search) {
    apolloClient
      .query({
        query: productByStore,
        variables: {
          filter: {
            limit: 20,
            offset: 0,
            search: search,
          },
        },
      })
      .then((res) => {
        this.setState({
          products: res.data.productByStore.nodes,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  showImageModal = (file) => {
    this.setState({
      visible: file,
    });
  };

  modalPrintFiles = (file) => {
    this.setState({
      modalType: "Order print files",
    });
    this.showImageModal(_.sortBy(file, (printFile) => printFile.ordering));
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  renderOrderFileName = (orders) => {
    let newOrders = [...orders];
    newOrders = newOrders.map((el) => {
      return {
        ...el,
        fileBackName: el.fileName.replace(".png", "-Back.png"),
        fileFrontName: el.fileName.replace(".png", "-Front.png"),
      };
    });
    return newOrders;
  };

  getFile = async (values) => {
    const { orders } = this.state;
    let orderAdd = await Promise.all(
      this.renderOrderFileName(orders).map(async (order) => {
        order.printFiles = [];
        const responseFile = await new Promise(async (resolve) => {
          await Promise.all(
            values.map((file) => {
              if (
                [
                  order.fileBackName
                    .toUpperCase()
                    .substring(0, order.fileBackName.length - 4),
                  order.fileFrontName
                    .toUpperCase()
                    .substring(0, order.fileFrontName.length - 4),
                ].includes(
                  file.name.toUpperCase().substring(0, file.name.length - 4)
                )
              ) {
                this.setState({
                  loading: true,
                });
                return new Promise((resol) => {
                  apolloClient
                    .mutate({
                      mutation: createFile,
                      variables: {
                        input: {
                          source: "aws",
                          key: file.key,
                          fileName: file.name,
                          fileMime: file.type,
                          fileSize: file.size,
                          genThumb: true,
                        },
                      },
                    })
                    .then((res) => {
                      if (
                        file.name
                          .toUpperCase()
                          .substring(0, file.name.length - 4) ===
                        order.fileFrontName
                          .toUpperCase()
                          .substring(0, order.fileFrontName.length - 4)
                      ) {
                        resol({
                          fileId: res.data.createFile.id,
                          side: "front",
                        });
                      } else if (
                        file.name
                          .toUpperCase()
                          .substring(0, file.name.length - 4) ===
                        order.fileBackName
                          .toUpperCase()
                          .substring(0, order.fileBackName.length - 4)
                      ) {
                        resol({
                          fileId: res.data.createFile.id,
                          side: "back",
                        });
                      }
                    })
                    .catch((err) => {
                      message.error(err.message);
                    });
                });
              } else {
                message.error(file.name);
              }
              return false;
            })
          ).then((fileResponses) => {
            fileResponses.forEach((fileResponse) => {
              if (fileResponse) {
                order.printFiles.push(fileResponse);
              }
            });
            resolve(order);
          });
        });
        return responseFile;
      })
    );
    return orderAdd;
  };

  onFinish = (values) => {
    this.getFile(values).then((orderAdd) => {
      const checkOrderHasPrintFiles = orderAdd.filter(
        (el) => el.printFiles.length > 0
      );
      if (checkOrderHasPrintFiles.length) {
        orderAdd.forEach((order) => {
          if (order.printFiles.length) {
            apolloClient
              .mutate({
                mutation: uploadPrintFile,
                variables: {
                  printFiles: order.printFiles,
                  orderIds: [order.id],
                },
              })
              .then(() => {
                this.setState({
                  loading: false,
                });
                message.success(
                  "Upload " + order.product.title + " print file successfully"
                );
                this.refetch();
                // es ở đây
                setTimeout(() => {
                  this.refetch();
                }, 1000);
              })
              .catch((err) => {
                this.setState({
                  loading: false,
                });
                message.error(
                  "Upload " + order.product.title + " print file failed"
                );
              });
          }
        });
      } else {
        message.error("Invalid file name for print files!");
      }
    });
  };

  onConfirm = (values, orderIds) => {
    apolloClient
      .mutate({
        mutation: updateOrderStatus,
        variables: {
          orderIds: [orderIds],
          status: values,
        },
      })
      .then(() => {
        message.success("Status order has been move to " + values);
        this.setState({ visible: false });
        this.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  uploadDesign = (values) => {
    apolloClient
      .mutate({
        mutation: createFile,
        variables: {
          input: {
            source: "aws",
            key: values.key,
            fileName: values.file.name,
            fileMime: values.file.type,
            fileSize: values.file.size,
          },
        },
      })
      .then((res) => {
        this.setState({ visible: false });
        apolloClient
          .mutate({
            mutation: gql`
              mutation($productID: ID!, $fileID: ID!) {
                uploadDesign(productID: $productID, fileID: $fileID)
              }
            `,
            variables: {
              fileID: res.data.createFile.id,
              productID: this.props.match.params.id,
            },
          })
          .then(() => {
            message.success("Upload design successfully");
            if (this.reloadProductById) {
              this.reloadProductById();
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { id, siteId } = this.props.match.params;
    const {
      filter,
      visible,
      modalType,
      sites,
      fetching,
      products,
      orders,
      hideText,
      page,
      total,
      loading,
      showMaping,
    } = this.state;
    const renderFileName = (fileName) => {
      return (
        <div>
          <em style={{ fontSize: 13 }}>
            <b>File Name: </b>
            {fileName}
          </em>
          <Tooltip title="Copy file name">
            <Button
              style={{ padding: "2px 10px" }}
              onClick={() => {
                var textField = document.createElement("textarea");
                textField.innerText = fileName.toString();
                document.body.appendChild(textField);
                textField.select();
                document.execCommand("copy");
                textField.remove();
                message.success(fileName + " copied");
              }}
              type="link"
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
        </div>
      );
    };
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <Container>
            <div className="order-action">
              <div className="select-action">
                <Select
                  value={filter.siteId ? filter.siteId : siteId}
                  style={{ marginRight: 10, minWidth: 150 }}
                  placeholder="Filter by store"
                  onChange={(siteId) => {
                    this.setState(
                      {
                        filter: {
                          ...filter,
                          siteId,
                          productId: null,
                          status: null,
                          offset: 0,
                          limit: 20,
                        },
                        page: 1,
                      },
                      () => this.loadProducts("")
                    );
                  }}
                >
                  {sites.map((site) => (
                    <Select.Option key={site.id} value={site.id}>
                      {site.title}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  className="select-product"
                  placeholder="Filter by product"
                  onClick={() => this.loadProducts("")}
                  onChange={(productId) =>
                    this.setState(
                      {
                        filter: { ...filter, productId, offset: 0, limit: 20 },
                        page: 1,
                        siteId: filter.siteId,
                      },
                      history.push(
                        `/product-order/${filter.siteId}/${productId}`
                      )
                    )
                  }
                  showSearch
                  value={filter.productById ? filter.productById : id}
                  onSearch={(value) => this.loadProducts(value)}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  filterOption={false}
                >
                  {products.map((product) => (
                    <Select.Option key={product.id} value={product.id}>
                      {product.title}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  className="select-status"
                  value={filter.status}
                  placeholder="Filter by status"
                  showSearch
                  onChange={(status) => {
                    this.setState({
                      filter: {
                        ...filter,
                        status,
                        siteId: filter.siteId,
                        offset: 0,
                        limit: 20,
                      },
                      page: 1,
                    });
                  }}
                >
                  <Select.Option value={null}>All Status</Select.Option>
                  {orderStatus.map((status) => (
                    <Select.Option key={status.key} value={status.key}>
                      {status.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <BulkUpload
                  accept=".png, .jpg"
                  multiple={true}
                  onChange={this.onFinish}
                  printFiles="upload-design"
                >
                  <Button
                    loading={loading}
                    type="primary"
                    disabled={
                      (filter.status === "processing" ||
                        filter.status === "rejected") &&
                        orders?.length > 0
                        ? false
                        : true
                    }
                  >
                    Bulk Upload
                  </Button>
                </BulkUpload>
              </div>
            </div>
            <div style={{ textAlign: "right", marginBottom: 20 }}>
              {total ? (
                <Pagination
                  total={total}
                  current={page}
                  pageSize={filter.limit}
                  showSizeChanger={false}
                  onChange={(page, pageSize) => {
                    this.setState({
                      page: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * pageSize,
                      },
                    });
                  }}
                />
              ) : null}
            </div>
            {loading ? (
              <h3 style={{ color: "#851227" }}>
                The print files is uploading... Please don't close page!
              </h3>
            ) : null}
            <Card>
              <Row gutter={2}>
                <Query query={productById} variables={{ id }}>
                  {({ error, loading, data, refetch }) => {
                    if (loading) return <Skeleton />;
                    if (error) return <div>{error.toString()}</div>;
                    const product = data.product;
                    this.reloadProductById = refetch;
                    return (
                      <Col span={6}>
                        <img
                          style={{ width: "100%", cursor: "pointer" }}
                          alt="productImage"
                          src={
                            product.mockups[0]
                              ? product.mockups[0].file.url
                              : defaultImage
                          }
                          onClick={() => {
                            if (product.mockups[0]) {
                              this.showImageModal(product);
                              this.setState({ modalType: "Product Images" });
                            }
                          }}
                        />
                        <span style={{ display: "flex", marginTop: 10 }}>
                          Product title:
                          <h4 style={{ marginLeft: 10 }}>
                            {product && product.title}
                          </h4>
                        </span>
                        {product?.file?.id ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignOtems: "center",
                            }}
                          >
                            <Upload
                              custom="upload-design"
                              multiple={false}
                              onChange={this.uploadDesign}
                            >
                              <Button>Edit Design</Button>
                            </Upload>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${AWS_CLOUDFRONT_URL}/${product.file.key}`}
                            >
                              Download Design
                            </a>
                          </div>
                        ) : (
                          <div>
                            <Upload
                              custom="upload-design"
                              multiple={false}
                              onChange={this.uploadDesign}
                            >
                              <Button type="primary">Upload Design</Button>
                            </Upload>
                          </div>
                        )}
                      </Col>
                    );
                  }}
                </Query>
                <Query
                  query={orders_query}
                  fetchPolicy="network-only"
                  variables={{ filter: { ...filter, productId: id } }}
                  onCompleted={(res) =>
                    this.setState({
                      orders: res.orders.hits,
                      total: res.orders.count,
                    })
                  }
                >
                  {({ error, loading, data, refetch }) => {
                    this.refetch = refetch;
                    if (loading) return <Skeleton />;
                    if (error) return <div>{error.toString()}</div>;
                    return (
                      <Col span={18}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateRows: "repeat(3,auto)",
                          }}
                        >
                          {data.orders.hits?.map((item) => {
                            const fileFrontName = item.fileName.replace(
                              ".png",
                              "-Front.png"
                            );
                            const fileBackName = item.fileName.replace(
                              ".png",
                              "-Back.png"
                            );
                            return (
                              <div
                                key={item.id}
                                className="detail-order"
                                style={{
                                  gridTemplateColumns: `${item.originPrintFileThumbUrl ? "400px" : ""
                                    } ${["error", "cancelled"].includes(item.status)
                                      ? ""
                                      : "400px"
                                    } auto`,
                                }}
                              >
                                {item.originPrintFileThumbUrl && (
                                  <div
                                    style={{
                                      padding: "72px 10px 0 10px",
                                      backgroundColor: "#eee",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                      alt="productImage"
                                      src={
                                        item.originPrintFileThumbUrl
                                          ? `${item.originPrintFileThumbUrl}`
                                          : defaultImage
                                      }
                                    />
                                  </div>
                                )}
                                <div>
                                  {
                                    {
                                      unassigned: (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "darkgray",
                                          }}
                                        >
                                          {currentUser &&
                                            currentUser.roles.find(
                                              (role) => role === "Administrator"
                                            ) && (
                                              <CreateTask
                                                color="white"
                                                orderId={item.id}
                                                refetch={() => refetch()}
                                              />
                                            )}
                                        </div>
                                      ),
                                      processing: (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "darkgray",
                                          }}
                                        >
                                          <UploadPNG
                                            productOrder={true}
                                            setWidth="100%"
                                            fileBackName={fileBackName}
                                            fileFrontName={fileFrontName}
                                            color="white"
                                            refetch={() => this.refetch()}
                                            fileName={item.fileName}
                                            orderId={item.id}
                                            size={
                                              item.variant?.productBaseVariant
                                                ?.size
                                            }
                                            tabs="tabs"
                                          />
                                        </div>
                                      ),
                                      rejected: (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            background: "darkgray",
                                          }}
                                        >
                                          <UploadPNG
                                            setWidth="100%"
                                            productOrder={true}
                                            fileBackName={fileBackName}
                                            fileFrontName={fileFrontName}
                                            color="white"
                                            refetch={() => this.refetch()}
                                            fileName={item.fileName}
                                            orderId={item.id}
                                            size={
                                              item.variant?.productBaseVariant
                                                ?.size
                                            }
                                            tabs="tabs"
                                          />
                                        </div>
                                      ),
                                      pending_approve: (
                                        <DesignConfirmation
                                          hidePopover={true}
                                          fileId={item.printFiles}
                                          widthImage="100%"
                                          heightImage="auto"
                                          onClickShowModal={() =>
                                            this.modalPrintFiles(
                                              item.printFiles
                                            )
                                          }
                                          size={
                                            item.variant?.productBaseVariant
                                              ?.size
                                          }
                                        />
                                      ),
                                      designed: (
                                        <DesignConfirmation
                                          hidePopover={true}
                                          fileId={item.printFiles}
                                          widthImage="100%"
                                          heightImage="auto"
                                          size={
                                            item.variant?.productBaseVariant
                                              ?.size
                                          }
                                          onClickShowModal={() =>
                                            this.modalPrintFiles(
                                              item.printFiles
                                            )
                                          }
                                        />
                                      ),
                                      fulfilled: (
                                        <DesignConfirmation
                                          hidePopover={true}
                                          fileId={item.printFiles}
                                          size={
                                            item.variant?.productBaseVariant
                                              ?.size
                                          }
                                          widthImage="100%"
                                          heightImage="auto"
                                          onClickShowModal={() =>
                                            this.modalPrintFiles(
                                              item.printFiles
                                            )
                                          }
                                        />
                                      ),
                                      in_production: (
                                        <DesignConfirmation
                                          hidePopover={true}
                                          fileId={item.printFiles}
                                          size={
                                            item.variant?.productBaseVariant
                                              ?.size
                                          }
                                          widthImage="100%"
                                          heightImage="auto"
                                          onClickShowModal={() =>
                                            this.modalPrintFiles(
                                              item.printFiles
                                            )
                                          }
                                        />
                                      ),
                                    }[item.status]
                                  }
                                  <div
                                    style={{
                                      padding: 10,
                                      background: "white",
                                    }}
                                  >
                                    {item.status === "pending_approve" ? (
                                      <div>
                                        <Button
                                          type="primary"
                                          style={{ marginRight: 10 }}
                                          onClick={() =>
                                            this.onConfirm("designed", item.id)
                                          }
                                        >
                                          Approve
                                        </Button>
                                        <Button
                                          onClick={() =>
                                          // this.onConfirm("rejected", item.id)
                                          {
                                            this.setState({
                                              modalReject: item.id,
                                            });
                                          }
                                          }
                                        >
                                          Reject
                                        </Button>
                                      </div>
                                    ) : null}
                                    {item.status === "designed" && (
                                      <PushToStore
                                        productOrder={true}
                                        disabled={[
                                          "Pending",
                                          "Pushing",
                                          "Pushed",
                                        ].includes(item.push_status)}
                                        custom="button"
                                        order={item}
                                        refetch={() => refetch()}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    margin: "20px 20px 10px",
                                    position: "relative",
                                  }}
                                >
                                  <Dropdown.Button
                                    disabled={[
                                      "Pending",
                                      "Pushing",
                                      "Pushed",
                                      "Unassigned",
                                    ].includes(item.push_status)}
                                    trigger={["click"]}
                                    icon={<MoreOutlined />}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      display: [
                                        "error",
                                        "fulfilled",
                                        "cancelled",
                                      ].includes(item.status)
                                        ? "none"
                                        : "inherit",
                                    }}
                                    overlay={
                                      <Menu
                                        style={{
                                          background: "white",
                                          padding: 10,
                                          lineHeight: "25px",
                                        }}
                                      >
                                        {
                                          {
                                            unassigned: (
                                              <a
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    showMaping: item,
                                                  });
                                                }}
                                                href="/#"
                                              >
                                                Mapping
                                              </a>
                                            ),
                                            processing: (
                                              <div>
                                                <a
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                      showMaping: item,
                                                    });
                                                  }}
                                                  href="/#"
                                                >
                                                  Mapping
                                                </a>
                                                {currentUser &&
                                                  currentUser.roles.find(
                                                    (role) =>
                                                      role === "Administrator"
                                                  ) && (
                                                    <UpdateTask
                                                      designerId={
                                                        item.task &&
                                                        item.task.user?.id
                                                      }
                                                      orderId={item.id}
                                                      refetch={() => refetch()}
                                                    />
                                                  )}
                                                <Popconfirm
                                                  title="Are you sure cancel this order？"
                                                  icon={
                                                    <QuestionCircleOutlined
                                                      style={{ color: "red" }}
                                                    />
                                                  }
                                                  okButtonProps={{
                                                    type: "danger",
                                                  }}
                                                  onConfirm={() =>
                                                    this.onConfirm(
                                                      "cancelled",
                                                      item.id
                                                    )
                                                  }
                                                  okText="Yes"
                                                  cancelText="No"
                                                  placement="bottom"
                                                >
                                                  <a href="/#">Cancel</a>
                                                </Popconfirm>
                                              </div>
                                            ),
                                            rejected: (
                                              <div>
                                                {currentUser &&
                                                  currentUser.roles.find(
                                                    (role) =>
                                                      role === "Administrator"
                                                  ) && (
                                                    <UpdateTask
                                                      designerId={
                                                        item.task &&
                                                        item.task.user?.id
                                                      }
                                                      orderId={item.id}
                                                      refetch={() => refetch()}
                                                    />
                                                  )}
                                                <Popconfirm
                                                  title="Are you sure cancel this order？"
                                                  onConfirm={() =>
                                                    this.onConfirm(
                                                      "cancelled",
                                                      item.id
                                                    )
                                                  }
                                                  icon={
                                                    <QuestionCircleOutlined
                                                      style={{ color: "red" }}
                                                    />
                                                  }
                                                  okButtonProps={{
                                                    type: "danger",
                                                  }}
                                                  okText="Yes"
                                                  cancelText="No"
                                                  placement="bottom"
                                                >
                                                  <a href="/#">Cancel</a>
                                                </Popconfirm>
                                              </div>
                                            ),
                                            pending_approve: (
                                              <div>
                                                {currentUser &&
                                                  currentUser.roles.find(
                                                    (role) =>
                                                      role === "Administrator"
                                                  ) && (
                                                    <UpdateTask
                                                      designerId={
                                                        item.task &&
                                                        item.task.user?.id
                                                      }
                                                      orderId={item.id}
                                                      refetch={() => refetch()}
                                                    />
                                                  )}
                                                <ChangePrintFiles
                                                  fileBackName={fileBackName}
                                                  fileFrontName={fileFrontName}
                                                  setWidth="100%"
                                                  printFiles={item.printFiles}
                                                  fileName={item.fileName}
                                                  orderId={item.id}
                                                  refetch={() => refetch()}
                                                />
                                                <RemovePrintFiles
                                                  orderId={item.id}
                                                  refetch={() => refetch()}
                                                />
                                                <Popconfirm
                                                  title="Are you sure cancel this order？"
                                                  onConfirm={() =>
                                                    this.onConfirm(
                                                      "cancelled",
                                                      item.id
                                                    )
                                                  }
                                                  icon={
                                                    <QuestionCircleOutlined
                                                      style={{ color: "red" }}
                                                    />
                                                  }
                                                  okButtonProps={{
                                                    type: "danger",
                                                  }}
                                                  okText="Yes"
                                                  cancelText="No"
                                                  placement="bottom"
                                                >
                                                  <a href="/#">Cancel</a>
                                                </Popconfirm>
                                              </div>
                                            ),
                                            designed: (
                                              <div>
                                                {currentUser &&
                                                  currentUser.roles.find(
                                                    (role) =>
                                                      role === "Administrator"
                                                  ) && (
                                                    <UpdateTask
                                                      designerId={
                                                        item.task &&
                                                        item.task.user?.id
                                                      }
                                                      orderId={item.id}
                                                      refetch={() => refetch()}
                                                    />
                                                  )}
                                                <ChangePrintFiles
                                                  fileBackName={fileBackName}
                                                  fileFrontName={fileFrontName}
                                                  setWidth="100%"
                                                  printFiles={item.printFiles}
                                                  fileName={item.fileName}
                                                  orderId={item.id}
                                                  refetch={() => refetch()}
                                                />
                                                <RemovePrintFiles
                                                  orderId={item.id}
                                                  refetch={() => refetch()}
                                                />
                                                <Popconfirm
                                                  title="Are you sure cancel this order？"
                                                  onConfirm={() =>
                                                    this.onConfirm(
                                                      "cancelled",
                                                      item.id
                                                    )
                                                  }
                                                  icon={
                                                    <QuestionCircleOutlined
                                                      style={{ color: "red" }}
                                                    />
                                                  }
                                                  okButtonProps={{
                                                    type: "danger",
                                                  }}
                                                  okText="Yes"
                                                  cancelText="No"
                                                  placement="bottom"
                                                >
                                                  <a href="/#">Cancel</a>
                                                </Popconfirm>
                                              </div>
                                            ),
                                          }[item.status]
                                        }
                                      </Menu>
                                    }
                                  />
                                  <p>
                                    OrderID: <b>{item.name}</b>
                                  </p>
                                  <p>
                                    Status:{" "}
                                    <b>
                                      {
                                        orderStatus.find(
                                          (e) => e.key === item.status
                                        ).title
                                      }
                                    </b>
                                  </p>
                                  {item.status === "rejected" ||
                                    item.status === "cancelled" ? (
                                    <p>
                                      Reason:{" "}
                                      <span style={{ color: "red" }}>
                                        {item.comment}{" "}
                                      </span>
                                    </p>
                                  ) : null}
                                  <p>
                                    Variant: <em>{item.variant_title ?? ""}</em>
                                  </p>
                                  <div>
                                    Customize:
                                    <br />
                                    {item.customize?.map((icustom, index) => (
                                      <div key={index}>
                                        {icustom.name}:
                                        {
                                          <div
                                            style={{ wordBreak: "break-word" }}
                                          >
                                            {icustom.value.length > 100 ? (
                                              <div>
                                                {icustom.value.substring(
                                                  0,
                                                  100
                                                )}
                                                <p
                                                  style={{
                                                    display:
                                                      hideText === item.id
                                                        ? "none"
                                                        : "initial",
                                                  }}
                                                >
                                                  ...
                                                </p>
                                                <p
                                                  style={{
                                                    display:
                                                      hideText === item.id
                                                        ? "initial"
                                                        : "none",
                                                  }}
                                                >
                                                  {icustom.value.substring(100)}
                                                </p>
                                                {index ===
                                                  item.customize.length - 1 && (
                                                    <div>
                                                      <Button
                                                        type="link"
                                                        size="small"
                                                        onClick={() => {
                                                          this.setState({
                                                            hideText: item.id,
                                                          });
                                                        }}
                                                        style={{
                                                          display:
                                                            hideText === item.id
                                                              ? "none"
                                                              : "initial",
                                                          padding: 0,
                                                        }}
                                                      >
                                                        Show more
                                                      </Button>
                                                      <Button
                                                        type="link"
                                                        onClick={() => {
                                                          this.setState({
                                                            hideText: false,
                                                          });
                                                        }}
                                                        size="small"
                                                        style={{
                                                          display:
                                                            hideText === item.id
                                                              ? "initial"
                                                              : "none",
                                                          padding: 0,
                                                        }}
                                                      >
                                                        Show less
                                                      </Button>
                                                    </div>
                                                  )}
                                              </div>
                                            ) : (
                                              icustom.value
                                            )}
                                          </div>
                                        }
                                      </div>
                                    ))}
                                  </div>
                                  <span>
                                    {renderFileName(
                                      item.fileName.replace(
                                        ".png",
                                        "-Front.png"
                                      )
                                    )}
                                  </span>
                                  <span>
                                    {renderFileName(
                                      item.fileName.replace(".png", "-Back.png")
                                    )}
                                  </span>
                                  {/* <div>
                                    {item.status === "pending_approve" ? (
                                      <div>
                                        <Button
                                          type="primary"
                                          style={{ marginRight: 10 }}
                                          onClick={() =>
                                            this.onConfirm("designed", item.id)
                                          }
                                        >
                                          Approve
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            // this.onConfirm("rejected", item.id)
                                            {
                                              this.setState({
                                                modalReject: item.id,
                                              });
                                            }
                                          }
                                        >
                                          Reject
                                        </Button>
                                      </div>
                                    ) : null}
                                    {item.status === "designed" && (
                                      <PushToStore
                                        productOrder={true}
                                        disabled={[
                                          "Pending",
                                          "Pushing",
                                          "Pushed",
                                        ].includes(item.push_status)}
                                        custom="button"
                                        order={item}
                                        refetch={() => refetch()}
                                      />
                                    )}
                                  </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div style={{ textAlign: "right" }}>
                          {total ? (
                            <Pagination
                              total={total}
                              current={page}
                              pageSize={filter.limit}
                              showSizeChanger={false}
                              onChange={(page, pageSize) => {
                                this.setState({
                                  page: page,
                                  filter: {
                                    ...filter,
                                    offset: (page - 1) * pageSize,
                                  },
                                });
                              }}
                            />
                          ) : null}
                        </div>
                      </Col>
                    );
                  }}
                </Query>
              </Row>
            </Card>
            <Modal
              title={modalType}
              visible={!!visible}
              onCancel={this.handleCancel}
              footer={null}
              width={500}
              key={visible.id}
            >
              <CarouselImage
                images={
                  visible && modalType === "Product Images"
                    ? visible.mockups
                    : visible
                }
              />
            </Modal>
            <Modal
              title={`Mapping Order ${showMaping.name}`}
              visible={typeof showMaping === "object" ? true : false}
              onCancel={() => {
                this.setState({ showMaping: false });
              }}
              key={showMaping.id}
              footer={null}
              width={550}
            >
              <div>
                {typeof showMaping === "object" &&
                  showMaping.site.platform === "woocommerce" ? (
                  <MappingOrder
                    order={showMaping}
                    refetch={() => {
                      this.refetch().then((newData) => {
                        this.setState({ aggs: newData.data.orders.aggs });
                      });
                      this.setState({ showMaping: false });
                    }}
                  />
                ) : typeof showMaping === "object" &&
                  showMaping.site.platform === "shopify" ? (
                  <Mapshopify
                    order={showMaping}
                    refetch={() => {
                      this.refetch().then((newData) => {
                        this.setState({
                          aggs: newData.data.orders.aggs,
                        });
                      });
                      this.setState({ showMaping: false });
                    }}
                  />
                ) : null}
              </div>
            </Modal>
            <Modal
              title="Reject design "
              visible={this.state.modalReject === false ? false : true}
              onCancel={() => {
                this.setState({ modalReject: false, contentReject: "" });
              }}
              onOk={() => {
                apolloClient
                  .mutate({
                    mutation: updateOrderStatus,
                    variables: {
                      orderIds: [this.state.modalReject],
                      status: "rejected",
                      comment: this.state.contentReject,
                    },
                  })
                  .then(() => {
                    message.success("Status order has been move to rejected");
                    this.setState({
                      modalReject: false,
                      contentReject: "",
                    });
                    this.refetch();
                  })
                  .catch((err) => {
                    message.error(err.message);
                  });
              }}
            >
              Reason
              <TextArea
                value={this.state.contentReject}
                onChange={(value) => {
                  this.setState({ contentReject: value.target.value });
                }}
              ></TextArea>
            </Modal>
          </Container>
        )}
      </AppContext.Consumer>
    );
  }
}
