import React, { Component } from "react";
import _ from "lodash";
import {
  Avatar,
  Skeleton,
  Select,
  Form,
  Alert,
  Row,
  Col,
  Tooltip,
  Checkbox,
  Button,
  notification,
  Popconfirm,
} from "antd";
import { Query, Mutation } from "react-apollo";
import productBases from "../../graphql/queries/productBases";
import mapOrderMutate from "../../graphql/mutates/mapOrder";
import { RestApi } from "../../api/RestApi";
import Icon from "@ant-design/icons";
import { omit } from "lodash";
import defaultImage from "../../assets/images/default.png";
import productByMockup from "../../graphql/queries/orders/productByMockup";
import mapOrderToProduct from "../../graphql/mutates/mapOrderToProduct";

class VariantSelect extends Component {
  render() {
    const { productType } = this.props;
    if (!productType) return null;
    const { variants } = productType || [];
    return (
      <Select
        allowClear={true}
        {...omit(this.props, ["productType"])}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {variants.map((variant) => {
          let optionName = variant.attributes
            .map((attr) => `${attr.name}: ${attr.option}`)
            .join(", ");
          return <Select.Option key={variant.id}>{optionName}</Select.Option>;
        })}
      </Select>
    );
  }
}

class MappingOrder extends Component {
  state = {
    selectedProductTypeId: null,
    showAllVariants: false,
    loading: false,
    isPersonalized: false,
    statusUrl: false,
    variantTitle: null,
  };
  formRef = React.createRef();

  onFinish = (mapOrder, values) => {
    this.setState({ loading: true });
    const { order } = this.props;
    var variants_map = Object.keys(values)
      .filter((key) => key.indexOf("variant_id_") === 0 && values[key])
      .map((key) => {
        let arraykey = key.split("_");
        return {
          variant_id: values[key],
          origin_id: arraykey[2],
          absolute: key.indexOf("_true") !== -1,
          sku: arraykey[5],
        };
      });

    mapOrder({
      variables: {
        order_id: order.id,
        variants_map: variants_map,
        is_personalized: this.state.isPersonalized,
      },
    })
      .then((res) => {
        notification.success({
          message: "Order has been mapped",
        });
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        notification.error({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
    console.log("Received values of form: ", values);
  };
  componentDidMount() {
    const { order } = this.props;
    this.setState({ variantTitle: order.variant_title })
    this.setState({ isPersonalized: order.product.isCustomize });
  }
  onFinishToExitProduct = (mapOrderToproduct, values) => {
    this.setState({ loading: true });
    const { order } = this.props;
    var variants_map = Object.keys(values)
      .filter((key) => key.indexOf("variant_id_") === 0 && values[key])
      .map((key) => {
        let arraykey = key.split("_");
        return {
          variant_id: values[key],
          origin_id: arraykey[2],
          absolute: key.indexOf("_true") !== -1,
          sku: arraykey[5],
        };
      });

    mapOrderToproduct({
      variables: {
        order_id: order.id,
        variants_map: variants_map,
        product_id: this.state.statusUrl.id,
      },
    })
      .then((res) => {
        notification.success({
          message: "Order has been mapped  to product",
        });
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        notification.error({
          message: err.message,
        });
        this.setState({ loading: false });
      });
    console.log("Received values of form: ", values);
  };

  render() {
    const {
      selectedProductTypeId,
      showAllVariants,
      isPersonalized,
      statusUrl,
    } = this.state;
    const { order } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Checkbox
            onChange={() => this.setState({ isPersonalized: !isPersonalized })}
            checked={isPersonalized}
            disabled={statusUrl.id ? true : false}
          >
            <span style={{ color: statusUrl.id ? null : "red" }}>
              Personalized?
            </span>
          </Checkbox>
        </div>
        <div style={{ marginBottom: 15, display: "flex" }}>
          <Avatar
            src={
              order.product.mockups[0]
                ? order.product.mockups[0].file.url
                : defaultImage
            }
            shape="square"
            size={64}
            style={{ marginRight: 5 }}
          />
          <div style={{ margin: "auto 0" }}>
            <p style={{ marginBottom: 0 }}>{_.get(order, "product.title")}</p>
            {this.state.variantTitle && <p style={{ marginBottom: 0, color: "red", fontSize: "14px" }}>{this.state.variantTitle}</p>}
          </div>
        </div>

        <Query
          query={productByMockup}
          variables={{
            productId: order.product.id,
          }}
        >
          {({ error, loading, data }) => {
            if (error) return <div>{error.toString()}</div>;
            if (loading) return <Skeleton loading={true} />;
            if (data.productByMockup) {
              return (
                <div style={{ marginBottom: 15 }}>
                  <span>
                    Then is a product has the same mockup url select the
                    checkbox below to map with the existing product
                  </span>
                  <br></br>

                  {data.productByMockup.map((product) => (
                    <div key={product.id}>
                      <Checkbox
                        onChange={(value) => {
                          if (value.target.checked) {
                            this.setState({ statusUrl: product });
                          } else {
                            this.setState({ statusUrl: false });
                          }
                        }}
                        checked={statusUrl?.id === product.id}
                      >
                        <Avatar
                          src={
                            product.mockups && product.mockups[0]
                              ? product.mockups[0].file.url
                              : defaultImage
                          }
                          shape="square"
                          size={64}
                          style={{ marginRight: 5 }}
                        />
                        {product.title}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              );
            }
            return null;
          }}
        </Query>
        {statusUrl ? (
          <Mutation
            mutation={mapOrderToProduct}
            onCompleted={() => {
              this.props.refetch();
            }}
          >
            {(mapOrderToProduct, res) => (
              <Form
                ref={this.formRef}
                layout="vertical"
                onFinish={(values) => {
                  this.onFinishToExitProduct(mapOrderToProduct, values);
                }}
              >
                <Form.Item label="Variants">
                  <div>
                    <RestApi
                      site={order.siteId}
                      endpoint={`/wp-json/wc/v3/orders/${order.originId}`}
                    >
                      {({ data, loading, error }) => {
                        if (loading)
                          return (
                            <Skeleton paragraph={false} loading={true} active />
                          );
                        if (error) return <div>{JSON.stringify(error)}</div>;
                        if (data) {
                          let lineItem = data.line_items?.find(
                            (lineItem) =>
                              lineItem.id.toString() === order.originLineItemID
                          );
                          if (lineItem) {
                            return (
                              <RestApi
                                site={order.siteId}
                                endpoint={`/wp-json/wc/v3/products/${lineItem.product_id}`}
                              >
                                {({ loading, error, data }) => {
                                  if (loading)
                                    return (
                                      <Skeleton
                                        paragraph={false}
                                        loading={true}
                                        active
                                      />
                                    );
                                  if (data) {
                                    const product = data;
                                    return (
                                      <RestApi
                                        site={order.siteId}
                                        endpoint={`/wp-json/wc/v3/products/${lineItem.product_id}/variations?per_page=100`}
                                      >
                                        {({ loading, error, data }) => {
                                          if (loading) {
                                            return (
                                              <Skeleton
                                                paragraph={false}
                                                loading={true}
                                                active
                                              />
                                            );
                                          }
                                          if (error) {
                                            return (
                                              <Alert
                                                type="error"
                                                message={error.message}
                                              />
                                            );
                                          }
                                          if (data) {
                                            return (
                                              <div>
                                                {data.map((variant) => {
                                                  let title = variant.attributes
                                                    .map(
                                                      (attr) =>
                                                        `${attr.name}:${attr.option}`
                                                    )
                                                    .join(", ");
                                                  let variantIdOption = statusUrl.variants.map(
                                                    (item) => {
                                                      let title = "";
                                                      for (
                                                        var i = 0;
                                                        i <
                                                        item.attributes.length;
                                                        i++
                                                      ) {
                                                        let a =
                                                          i > 0 ? ", " : "";
                                                        title =
                                                          title +
                                                          a +
                                                          item.attributes[i]
                                                            .name +
                                                          ":" +
                                                          item.attributes[i]
                                                            .option;
                                                      }

                                                      return {
                                                        id: item.id,
                                                        title: title,
                                                      };
                                                    }
                                                  );

                                                  let idVariant = variantIdOption.find(
                                                    (item) =>
                                                      item.title?.toLowerCase() === title?.toLowerCase()
                                                  );

                                                  var absolute = "false";
                                                  if (
                                                    product.attributes.length >
                                                    variant.attributes.length
                                                  ) {
                                                    absolute = "true";
                                                  }
                                                  if (
                                                    !showAllVariants &&
                                                    lineItem.variation_id !==
                                                    variant.id
                                                  )
                                                    return null;
                                                  if (
                                                    product.attributes.length >
                                                    variant.attributes
                                                      .length &&
                                                    lineItem.variation_id !==
                                                    variant.id
                                                  ) {
                                                    return null;
                                                  }
                                                  let key = `variant_id_${variant.id}_${absolute}_sku_${variant.sku}`;
                                                  let obj = {};
                                                  obj[key] = idVariant?.id;

                                                  this.formRef.current.setFieldsValue(
                                                    obj
                                                  );
                                                  return (
                                                    <Row
                                                      type="flex"
                                                      gutter={20}
                                                      key={variant.id}
                                                      style={{
                                                        // alignItems:
                                                        //   "center",
                                                        borderBottom:
                                                          "1px solid #f5f5f5",
                                                        paddingTop: 10,
                                                        color:
                                                          lineItem.variation_id ===
                                                            variant.id
                                                            ? "red"
                                                            : variant.status ===
                                                              "private"
                                                              ? "#aaa"
                                                              : "unset",
                                                      }}
                                                    >
                                                      <Col span={12}>
                                                        <div
                                                          style={{
                                                            lineHeight: 1.6,
                                                          }}
                                                        >
                                                          {variant.attributes
                                                            .map(
                                                              (attr) =>
                                                                `${attr.name}: ${attr.option}`
                                                            )
                                                            .join(", ")}
                                                          {variant.status ===
                                                            "private" && (
                                                              <Tooltip title="Variant unpublished">
                                                                <Icon
                                                                  type="warning"
                                                                  style={{
                                                                    color:
                                                                      "#ffc069",
                                                                    marginLeft: 5,
                                                                  }}
                                                                />
                                                              </Tooltip>
                                                            )}
                                                          {lineItem.variation_id ===
                                                            variant.id &&
                                                            order.meta_data && (
                                                              <p
                                                                style={{
                                                                  color:
                                                                    "#8D8D8D",
                                                                }}
                                                              >
                                                                {order.meta_data
                                                                  .map(
                                                                    (mt) =>
                                                                      mt.value
                                                                  )
                                                                  .join(", ")}
                                                              </p>
                                                            )}
                                                        </div>
                                                      </Col>
                                                      <Col span={12}>
                                                        <Form.Item
                                                          name={`variant_id_${variant.id}_${absolute}_sku_${variant.sku}`}
                                                          rules={[
                                                            {
                                                              required:
                                                                lineItem.variation_id ===
                                                                variant.id,
                                                              message:
                                                                "This field is required",
                                                            },
                                                          ]}
                                                          className="form-select"
                                                        >
                                                          <VariantSelect
                                                            productType={
                                                              statusUrl
                                                            }
                                                          />
                                                        </Form.Item>
                                                      </Col>
                                                    </Row>
                                                  );
                                                })}
                                                <div
                                                  style={{
                                                    marginTop: 10,
                                                  }}
                                                >
                                                  <Checkbox
                                                    onChange={() =>
                                                      this.setState({
                                                        showAllVariants: !showAllVariants,
                                                      })
                                                    }
                                                  >
                                                    Show all variants
                                                  </Checkbox>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }}
                                      </RestApi>
                                    );
                                  }
                                }}
                              </RestApi>
                            );
                          }
                        }
                      }}
                    </RestApi>
                  </div>
                </Form.Item>
                <Button
                  loading={this.state.loading}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Mutation>
        ) : (
          <div>
            <Query
              query={productBases}
              fetchPolicy="network-only"
              variables={{
                filter: {
                  autoRender: false,
                  limit: 500,
                },
              }}
            >
              {({ error, loading, data }) => {
                if (loading) return <Skeleton loading={true} />;
                if (error) return <div>{error.toString()}</div>;
                if (data) {
                  var productTypes = data.productBases.nodes;
                  return (
                    <Mutation
                      mutation={mapOrderMutate}
                      onCompleted={() => {
                        this.props.refetch();
                      }}
                    >
                      {(mapOrder, res) => (
                        <Form
                          ref={this.formRef}
                          layout="vertical"
                          onFinish={(values) => {
                            this.onFinish(mapOrder, values);
                          }}
                        >
                          <Form.Item
                            label="Product Type"
                            name="product_type_id"
                          >
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              onChange={(selectedProductTypeId) => {
                                this.setState({ selectedProductTypeId });
                              }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {productTypes.map((type) => (
                                <Select.Option key={type.id}>
                                  {type.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>

                          {selectedProductTypeId && (
                            <Form.Item label="Variants">
                              <div>
                                <RestApi
                                  site={order.siteId}
                                  endpoint={`/wp-json/wc/v3/orders/${order.originId}`}
                                >
                                  {({ data, loading, error }) => {
                                    if (loading)
                                      return (
                                        <Skeleton
                                          paragraph={false}
                                          loading={true}
                                          active
                                        />
                                      );
                                    if (error)
                                      return <div>{JSON.stringify(error)}</div>;
                                    if (data) {
                                      let lineItem = data.line_items?.find(
                                        (lineItem) =>
                                          lineItem.id.toString() ===
                                          order.originLineItemID
                                      );
                                      if (lineItem) {
                                        return (
                                          <RestApi
                                            site={order.siteId}
                                            endpoint={`/wp-json/wc/v3/products/${lineItem.product_id}`}
                                          >
                                            {({ loading, error, data }) => {
                                              if (loading)
                                                return (
                                                  <Skeleton
                                                    paragraph={false}
                                                    loading={true}
                                                    active
                                                  />
                                                );
                                              if (data) {
                                                const product = data;
                                                return (
                                                  <RestApi
                                                    site={order.siteId}
                                                    endpoint={`/wp-json/wc/v3/products/${lineItem.product_id}/variations?per_page=100`}
                                                  >
                                                    {({
                                                      loading,
                                                      error,
                                                      data,
                                                    }) => {
                                                      if (loading) {
                                                        return (
                                                          <Skeleton
                                                            paragraph={false}
                                                            loading={true}
                                                            active
                                                          />
                                                        );
                                                      }
                                                      if (error) {
                                                        return (
                                                          <Alert
                                                            type="error"
                                                            message={
                                                              error.message
                                                            }
                                                          />
                                                        );
                                                      }
                                                      if (data) {
                                                        return (
                                                          <div>
                                                            {data.map(
                                                              (variant) => {
                                                                let a = productTypes.find(
                                                                  (type) =>
                                                                    type.id ===
                                                                    selectedProductTypeId
                                                                );
                                                                let title = variant.attributes
                                                                  .map(
                                                                    (attr) =>
                                                                      `${attr.name}:${attr.option}`
                                                                  )
                                                                  .join(", ");
                                                                let variantIdOption = a.variants.map(
                                                                  (item) => {
                                                                    let title =
                                                                      "";
                                                                    for (
                                                                      var i = 0;
                                                                      i <
                                                                      item
                                                                        .attributes
                                                                        .length;
                                                                      i++
                                                                    ) {
                                                                      let a =
                                                                        i > 0
                                                                          ? ", "
                                                                          : "";
                                                                      title =
                                                                        title +
                                                                        a +
                                                                        item
                                                                          .attributes[
                                                                          i
                                                                        ].name +
                                                                        ":" +
                                                                        item
                                                                          .attributes[
                                                                          i
                                                                        ]
                                                                          .option;
                                                                    }

                                                                    return {
                                                                      id:
                                                                        item.id,
                                                                      title: title,
                                                                    };
                                                                  }
                                                                );
                                                                let idVariant = variantIdOption.find(
                                                                  (item) =>
                                                                    item.title ===
                                                                    title
                                                                );

                                                                var absolute =
                                                                  "false";
                                                                if (
                                                                  product
                                                                    .attributes
                                                                    .length >
                                                                  variant
                                                                    .attributes
                                                                    .length
                                                                ) {
                                                                  absolute =
                                                                    "true";
                                                                }
                                                                if (
                                                                  !showAllVariants &&
                                                                  lineItem.variation_id !==
                                                                  variant.id
                                                                )
                                                                  return null;
                                                                if (
                                                                  product
                                                                    .attributes
                                                                    .length >
                                                                  variant
                                                                    .attributes
                                                                    .length &&
                                                                  lineItem.variation_id !==
                                                                  variant.id
                                                                ) {
                                                                  return null;
                                                                }
                                                                let key = `variant_id_${variant.id}_${absolute}_sku_${variant.sku}`;
                                                                let obj = {};
                                                                obj[key] =
                                                                  idVariant?.id;

                                                                this.formRef.current.setFieldsValue(
                                                                  obj
                                                                );
                                                                return (
                                                                  <Row
                                                                    type="flex"
                                                                    gutter={20}
                                                                    key={
                                                                      variant.id
                                                                    }
                                                                    style={{
                                                                      // alignItems:
                                                                      //   "center",
                                                                      borderBottom:
                                                                        "1px solid #f5f5f5",
                                                                      paddingTop: 10,
                                                                      color:
                                                                        lineItem.variation_id ===
                                                                          variant.id
                                                                          ? "red"
                                                                          : variant.status ===
                                                                            "private"
                                                                            ? "#aaa"
                                                                            : "unset",
                                                                    }}
                                                                  >
                                                                    <Col
                                                                      span={12}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight: 1.6,
                                                                        }}
                                                                      >
                                                                        {variant.attributes
                                                                          .map(
                                                                            (
                                                                              attr
                                                                            ) =>
                                                                              `${attr.name}: ${attr.option}`
                                                                          )
                                                                          .join(
                                                                            ", "
                                                                          )}
                                                                        {variant.status ===
                                                                          "private" && (
                                                                            <Tooltip title="Variant unpublished">
                                                                              <Icon
                                                                                type="warning"
                                                                                style={{
                                                                                  color:
                                                                                    "#ffc069",
                                                                                  marginLeft: 5,
                                                                                }}
                                                                              />
                                                                            </Tooltip>
                                                                          )}
                                                                        {lineItem.variation_id ===
                                                                          variant.id &&
                                                                          order.meta_data && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#8D8D8D",
                                                                              }}
                                                                            >
                                                                              {order.meta_data
                                                                                .map(
                                                                                  (
                                                                                    mt
                                                                                  ) =>
                                                                                    mt.value
                                                                                )
                                                                                .join(
                                                                                  ", "
                                                                                )}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    </Col>
                                                                    <Col
                                                                      span={12}
                                                                    >
                                                                      <Form.Item
                                                                        name={`variant_id_${variant.id}_${absolute}_sku_${variant.sku}`}
                                                                        rules={[
                                                                          {
                                                                            required:
                                                                              lineItem.variation_id ===
                                                                              variant.id,
                                                                            message:
                                                                              "This field is required",
                                                                          },
                                                                        ]}
                                                                        className="form-select"
                                                                      >
                                                                        <VariantSelect
                                                                          productType={productTypes.find(
                                                                            (
                                                                              type
                                                                            ) =>
                                                                              type.id ===
                                                                              selectedProductTypeId
                                                                          )}
                                                                        />
                                                                      </Form.Item>
                                                                    </Col>
                                                                  </Row>
                                                                );
                                                              }
                                                            )}
                                                            <div
                                                              style={{
                                                                marginTop: 10,
                                                              }}
                                                            >
                                                              <Checkbox
                                                                onChange={() =>
                                                                  this.setState(
                                                                    {
                                                                      showAllVariants: !showAllVariants,
                                                                    }
                                                                  )
                                                                }
                                                              >
                                                                Show all
                                                                variants
                                                              </Checkbox>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    }}
                                                  </RestApi>
                                                );
                                              }
                                            }}
                                          </RestApi>
                                        );
                                      }
                                    }
                                  }}
                                </RestApi>
                              </div>
                            </Form.Item>
                          )}
                          {isPersonalized ? (
                            <Button
                              loading={this.state.loading}
                              type="primary"
                              htmlType="submit"
                            >
                              Submit
                            </Button>
                          ) : (
                            <Popconfirm
                              title="Are you sure, it isn't a personalized product?"
                              onConfirm={() => this.formRef.current.submit()}
                              onCancel={() => console.log("cancel")}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                loading={this.state.loading}
                                type="primary"
                                htmlType="submit"
                              >
                                Submit
                              </Button>
                            </Popconfirm>
                          )}
                        </Form>
                      )}
                    </Mutation>
                  );
                }
              }}
            </Query>
          </div>
        )}
      </div>
    );
  }
}
export default MappingOrder;
