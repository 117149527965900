import React, { Component } from "react";
import ListMapping from "../components/mappings/ListMapping";

class Mappings extends Component {
  render() {
    const { match } = this.props;
    const storeId = match.params.siteId;
    return <ListMapping siteId={storeId} />;
  }
}

export default Mappings;
