import gql from "graphql-tag";
export default gql`
  mutation($order_id: ID!, $variants_map: [VariantMap!]!, $product_id: ID!) {
    mapOrderToExistingProduct(
      order_id: $order_id
      variants_map: $variants_map
      product_id: $product_id
    )
  }
`;
