import { Select, Skeleton } from "antd";
import { gql } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { Query } from "react-apollo";
import { apolloClient } from "../../apollo-client";
import fulfillmentProducts from "../../graphql/queries/orders/fulfillmentProducts";

const SelectModalFulfil = ({ dataVariant, onChange, fulfillmentProductID }) => {
  const { type } = dataVariant;
  const [product, setProduct] = useState();
  const [variants, setVariants] = useState([]);
  useEffect(() => {
    loadFirstData();
  }, []);
  const loadFirstData = () => {
    apolloClient
      .query({
        query: gql`
          query($variantId: ID!, $fulfillmentName: FulfillmentServiceName!) {
            fulfillmentProductV2(
              variantId: $variantId
              fulfillmentName: $fulfillmentName
            ) {
              id
              variants
              title
            }
          }
        `,
        variables: {
          variantId: fulfillmentProductID,
          fulfillmentName: dataVariant.type,
        },
      })
      .then((res) => {
        setProduct(res.data.fulfillmentProductV2.id);
        setVariants(res.data.fulfillmentProductV2.variants);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Query
        query={fulfillmentProducts}
        fetchPolicy="no-cache"
        variables={{ filter: { fulfillmentName: type, limit: -1 } }}
      >
        {({ error, loading, data, refetch }) => {
          if (loading) return <Skeleton />;
          if (error) return <div>{error.toString()}</div>;
          return (
            <div>
              <div style={{ marginBottom: 20, fontWeight: 800 }}>
                <span>Variant App: {dataVariant.variantApp}</span>
              </div>
              <p>Product:</p>
              <Select
                style={{ width: "100%", marginBottom: 20 }}
                onChange={(value, data) => {
                  setProduct(value);
                  setVariants(data.variants);
                  let id = data.variants.find((item) => {
                    let abc = dataVariant.attributes?.map((att) => {
                      if (att.name.toLowerCase() === "color") {
                        let objectColor = item.attributes.find(
                          (item) => item.name.toLowerCase() === "color"
                        );
                        if (objectColor) {
                          return att.option
                            .toLowerCase()
                            .indexOf(objectColor.option.toLowerCase()) === 0
                            ? true
                            : false;
                        } else {
                          return false;
                        }
                      } else {
                        let objectAtr = item.attributes.find(
                          (item) =>
                            item.name.toLowerCase() === att.name.toLowerCase()
                        );
                        if (objectAtr) {
                          return (
                            objectAtr.option.toLowerCase() ===
                            att.option.toLowerCase()
                          );
                        } else {
                          return false;
                        }
                      }
                    });
                    if (!abc.includes(false)) {
                      return item;
                    }
                  });

                  onChange(
                    id?.fulfillment_product_id
                      ? id?.fulfillment_product_id
                      : null
                  );
                }}
                value={product}
                showSearch
                filterOption={(input, option) =>
                  option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {data.fulfillmentProducts.hits.map((item) => (
                  <Select.Option
                    value={item.id}
                    variants={item.variants}
                    key={item.id}
                    search={item.title}
                  >
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
              {product ? (
                <div>
                  <p>Variant:</p>
                  <Select
                    value={fulfillmentProductID}
                    style={{ width: "100%", display: "block" }}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    filterOption={(input, option) =>
                      option.search
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {variants.map((item) => (
                      <Select.Option
                        key={item.fulfillment_product_id}
                        value={item.fulfillment_product_id}
                        search={`${item.attributes.map(
                          (item) => `${item.name}: ${item.option} `
                        )} ${item.fulfillment_product_id}`}
                      >
                        {item.attributes.map(
                          (item) => `${item.name}: ${item.option},`
                        )}
                        ({item.fulfillment_product_id})
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : null}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default SelectModalFulfil;
