import React, { Component } from "react";
import styled from "styled-components";
import { Popover, Select, Divider, message, Popconfirm } from "antd";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import CreateTaskForm from "./CreateTaskForm";
import { QuestionCircleOutlined } from "@ant-design/icons";

const Container = styled.div``;

export default class CreateTask extends Component {
  state = {
    visible: false,
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  onCancel = () => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!) {
            updateOrderStatus(orderIds: $orderIds, status: $status)
          }
        `,
        variables: {
          orderIds: [this.props.orderId],
          status: "cancelled",
        },
      })
      .then(() => {
        message.success("Order has been cancel");
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  confirm() {
    this.onCancel();
  }

  render() {
    const { visible } = this.state;
    const { type, orderId, color } = this.props;
    return (
      <Container>
        <div>
          {type ? (
            <Select
              onSelect={(value) => {
                if (value === "Create task") {
                  this.handleVisibleChange();
                } else return null;
              }}
              style={{ minWidth: 150 }}
            >
              <Select.Option value="Create task">Create task</Select.Option>
              <Select.Option value="Cancel">Cancel</Select.Option>
            </Select>
          ) : (
            <div>
              <Popover
                content={
                  <CreateTaskForm
                    orderId={orderId}
                    refetch={this.props.refetch}
                    hide={() => this.hide()}
                  />
                }
                trigger="click"
                visible={visible}
                placement="bottom"
                onVisibleChange={this.handleVisibleChange}
              >
                <a style={{ color: color ? color : "#40a9ff" }} href="/#">
                  Create task
                </a>
              </Popover>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure cancel this order？"
                icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                okButtonProps={{type:"danger"}}
                onConfirm={() => this.confirm()}
                okText="Yes"
                cancelText="No"
              >
                <a style={{ color: color ? color : "#40a9ff" }} href="/#">
                  Cancel
                </a>
              </Popconfirm>
            </div>
          )}
        </div>
      </Container>
    );
  }
}
