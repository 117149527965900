import { gql } from "apollo-boost";

export default gql`
  query {
    me {
      id
      email
      firstName
      lastName
      roles
    }
  }
`;
