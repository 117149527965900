import React, { Component } from "react";
import CarrierList from "../components/carriers/Carriers";
import { PageHeader } from "antd";
class Carriers extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Carriers"
          onBack={() => this.props.history.goBack()}
        />
        <CarrierList />
      </div>
    );
  }
}

export default Carriers;
