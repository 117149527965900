import { gql } from "apollo-boost";

export const PRODUCT_ID_DESIGN = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      sku
      title
      productBases {
        id
        title
      }
      designStatus
      designs {
        id
        baseDesignId
        productId
        baseDesign {
          ordering
          description
          id
          baseId
          file {
            id
            thumbnail
            fileName
            fileMime
            key
            sku
            source
          }
          name
          fileId
          width
          height
        }
        fileId
        file {
          id
          thumbnail
          fileName
          fileMime
          key
          sku
          source
        }
      }
      mockups {
        file {
          id
          key
        }
      }
    }
  }
`;
