import { gql } from "apollo-boost";

export default gql`
  mutation updateProductCategory($id: ID!, $input: EditProductCategory!) {
    updateProductCategory(id: $id, input: $input) {
      id
      title
      status
      description
      createdAt
    }
  }
`;
