import { gql } from "apollo-boost";

export default gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      sku
      title
      productBases {
        id
        title
        sku
        images {
          id
          fileName
          thumbnail
        }
        mockup
        variants {
          id
          attributes
          sku
          baseId
          salePrice
          regularPrice
          baseId
          ordering
          status
        }
      }
      designs {
        id
        baseDesignId
        productId
        baseDesign {
          description
          id
          file {
            id
            thumbnail
            fileName
            fileMime
            key
            sku
            source
          }
          name
          fileId
          width
          height
        }
        file {
          id
          thumbnail
          fileName
          fileMime
          key
          sku
          source
        }
      }
      sites {
        id
        site {
          id
          title
        }
      }
      description
      categories {
        id
        title
      }
      tags {
        id
        title
      }
      status
      designStatus
      mockups {
        id
        file {
          id
          thumbnail
        }
        fileId
        fileName
        type
      }
      variants {
        id
        sku
        baseId
        attributes
        regularPrice
        salePrice
        basePrice
        fileId
        ordering
      }
    }
  }
`;
