import { gql } from "apollo-boost";

export default gql`
  query productBaseByIds($ids: [ID!]!, $baseId: String) {
    productBaseByIds(ids: $ids) {
      id
      title
      storeType
      mockup
      sku
      used
      designs {
        id
        name
        description
        fileId
        width
        height
        baseId
        ordering
        side
        dsName
        ccName
        target
      }
      description
      attributes
      autoRender
      renderAttrName
      images {
        id
        key
        fileName
        fileMime
        fileSize
        thumbnail
      }
      carriers {
        id
        name
      }
      category {
        id
        title
      }
      type {
        id
        category
      }
      fulfillServiceId
      fulfillService {
        id
        name
      }
      fulfillServices {
        id
        name
        type
      }
      shippingMethod
      variants {
        id
        sku
        baseId
        attributes
        regularPrice
        basePrice
        salePrice
        ordering
        status
      }
    }
    productBaseVariants(baseId: $baseId) {
      id
      sku
      baseId
      attributes
      regularPrice
      basePrice
      salePrice
      ordering
      status
      createdAt
      ccId
      dsId
      size
    }
  }
`;
