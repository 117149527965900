import { gql } from "apollo-boost";

export default gql`
  mutation updateCarrier($id: ID!, $input: EditCarrier!) {
    updateCarrier(id: $id, input: $input) {
      id
      name
      code
      description
      trackingUrl
    }
  }
`;
