import { gql } from "apollo-boost";

export default gql`
  query($filter: SiteFilter) {
    sites(filter: $filter) {
      count
      hits {
        id
        title
        domain
        verified
        platform
        email
        cookie
        user {
          id
          firstName
          lastName
        }
        status
        token
        cookieUpdatedAt
      }
    }
  }
`;
