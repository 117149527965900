import { gql } from "apollo-boost";

export default gql`
  mutation updateProductTag($input: EditProductTag!, $id: ID!) {
    updateProductTag(input: $input, id: $id) {
      id
      title
    }
  }
`;
