import React, { Component } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Tag,
  Avatar,
  Divider,
  message,
  Input,
  Drawer,
  Skeleton,
} from "antd";
import _ from "lodash";
import { apolloClient } from "../../apollo-client";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { DeleteTwoTone, PlusOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import UserForm from "./UserForm";
import { Query } from "react-apollo";

const Container = styled.div`
  .ant-input-group-addon:hover {
    color: #ff9100;
  }
  @media only screen and (max-width: 768px) {
    .p-filter {
      display: flex;
      flex-direction: column-reverse;
      .btn-add {
        text-align: right;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .btn-add {
      text-align: right;
    }
  }
`;

export const LOAD_QUERY = gql`
  query($input: FindUser) {
    users(input: $input) {
      total
      nodes {
        id
        firstName
        lastName
        email
        roles
      }
    }
  }
`;

export default class Users extends Component {
  state = {
    input: {
      search: null,
      limit: 20,
      offset: 0,
    },
    visible: false,
    page: 1,
    count: null,
  };

  showDrawer = (record) => {
    this.setState({ visible: record });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  deleteUser = (id) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteUser($id: ID!) {
            deleteUser(id: $id)
          }
        `,
        variables: { id },
      })
      .then(() => {
        message.success("Delete user success!");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onChangeTable = async (e) => {
    await this.setState({ page: e.current });
  };

  render() {
    const { visible, input, page } = this.state;
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "-webkit-fill-available", paddingRight: 20 }}>
            <Input.Search
              style={{ marginBottom: "10px", color: "#ff9100" }}
              placeholder="Search text"
              enterButton
              onSearch={(value) =>
                this.setState({
                  input: { ...input, search: value, offset: 0 },
                  page: 1,
                })
              }
              onChange={(e) => {
                if (e.target.value === "") {
                  this.setState({
                    input: { ...input, search: e.target.value, offset: 0 },
                    page: 1,
                  });
                }
              }}
            />
          </div>
          <div>
            <Button onClick={() => this.showDrawer({})} type="primary">
              <PlusOutlined />
              &nbsp; Add User
            </Button>
          </div>
        </div>
        <Query
          query={LOAD_QUERY}
          fetchPolicy="network-only"
          variables={{ input: this.state.input }}
        >
          {({ error, loading, data, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const users = data.users.nodes;
            const count = data.users.total;
            const columns = [
              {
                title: "Name",
                dataIndex: "id",
                key: "fullname",
                width: 180,
                render: (id, { firstName, lastName, avatar }) => (
                  <div>
                    <Avatar src={avatar ? avatar.url : null}>
                      {_.first(firstName)}
                      {_.first(lastName)}
                    </Avatar>
                    &nbsp;
                    {firstName} {lastName}
                  </div>
                ),
              },
              { title: "Email", dataIndex: "email", key: "email", width: 180 },
              {
                title: "Roles",
                dataIndex: "roles",
                key: "roles",
                width: 120,
                render: (roles) => (
                  <span>
                    {roles.map((role, index) => (
                      <Tag key={index} style={{ marginBottom: 3 }}>
                        {role}
                      </Tag>
                    ))}
                  </span>
                ),
              },
              {
                title: "Action",
                key: "action",
                dataIndex: "id",
                width: 120,
                align: "right",
                render: (id, record) => (
                  <span>
                    <Button
                      size="small"
                      onClick={() => this.showDrawer(record)}
                      icon={<EditOutlined />}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Are you sure delete this user?"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                      okButtonProps={{type:"danger"}}
                      onConfirm={() => {
                        this.deleteUser(id);
                        refetch();
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button size="small">
                        <DeleteTwoTone twoToneColor="#FF0000" />
                      </Button>
                    </Popconfirm>
                  </span>
                ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <Container>
                <Table
                  scroll={{ x: tableWidth }}
                  loading={loading}
                  columns={columns}
                  dataSource={users}
                  rowKey="id"
                  onChange={this.onChangeTable}
                  pagination={{
                    current: page,
                    total: count,
                    pageSize: input.limit,
                    showSizeChanger: false,
                    showTotal: (total, range) => `${range} of ${total}`,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        input: {
                          ...input,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                  }}
                />
                <Drawer
                  width="600"
                  title={visible.id ? "Edit User" : "Add User"}
                  placement="right"
                  closable={true}
                  onClose={this.onClose}
                  visible={typeof visible === "object" ? true : false}
                >
                  <UserForm
                    refetch={() => refetch()}
                    input={input}
                    onClose={this.onClose}
                    value={visible}
                    id={visible && visible.id}
                    key={visible && visible.id}
                  />
                </Drawer>
              </Container>
            );
          }}
        </Query>
      </div>
    );
  }
}
