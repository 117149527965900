import React, { Component } from "react";
import { PageHeader } from "antd";
import ExternalOrderList from "../components/externalOrders/ExternalOrders";
import { AppContext } from "../context";

class ExternalOrders extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <div>
            <PageHeader
              title="External Orders"
              onBack={() => this.props.history.goBack()}
            />
            <ExternalOrderList currentUser={currentUser} />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default ExternalOrders;
