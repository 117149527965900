import React, { Component } from "react";
import { Table, Tag } from "antd";

class ProductBaseVariantSelect extends Component {
  state = {
    selected: [],
  };
  render() {
    const { variants, attributes } = this.props;
    const columns = [
      {
        title: "SKU",
        key: "sku",
        dataIndex: "sku",
        width: 350,
        render: (text) => {
          return <strong>{text}</strong>;
        },
      },
    ];
    for (let i = 0; i < attributes?.length; i++)
      columns.push({
        title: attributes[i].name,
        dataIndex: attributes[i].slug,
        width: 500,
        render: (_, record) => {
          const dataItem =
            // eslint-disable-next-line valid-typeof
            typeof record.attributes[i] !== "underfined"
              ? record.attributes[i]
              : null;
          if (!dataItem) {
            return "N/A";
          }
          return <Tag>{dataItem.option}</Tag>;
        },
      });
    return (
      <div>
        <Table
          rowKey={(index, key) => key}
          columns={columns}
          dataSource={variants}
          pagination={false}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState(
                {
                  selected: selectedRowKeys,
                },
                () => {
                  const values = selectedRowKeys.map((v) => {
                    return variants[v];
                  });
                  if (this.props.onChange) {
                    this.props.onChange(values);
                  }
                }
              );
            },
          }}
        />
      </div>
    );
  }
}

export default ProductBaseVariantSelect;
