import { gql } from "apollo-boost";

export default gql`
  query orderExports(
    $supplier_id: Int
    $from: String
    $to: String
    $search: String
    $limit: Int!
    $offset: Int!
  ) {
    orderExports(
      supplier_id: $supplier_id
      from: $from
      to: $to
      search: $search
      limit: $limit
      offset: $offset
    ) {
      count
      hits {
        id
        fileName
        fileID
        createdAt
      }
    }
  }
`;
