import { gql } from "apollo-boost";
export default gql`
  mutation createFulfillService($input: NewFulfillService!) {
    createFulfillService(input: $input) {
      id
      name
      templates {
        id
        name
        data
        createdAt
      }
      status
      type
    }
  }
`;
