import React, { Component, createContext } from "react";
import rest from "./rest";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const initialQueryContextValue = {
  state: initialState,
};

const QueryContext = createContext(initialQueryContextValue);

export class RestApi extends Component {
  static Consumer = QueryContext.Consumer;
  static defaultProps = {
    method: "GET",
    disableInitialFetch: false,
    stateReducer: (update, state, props) => update,
  };

  state = initialState;

  setReducedState = (update) => {
    const { stateReducer } = this.props;
    this.setState((state) => stateReducer(update, state, this.props));
  };

  request = async () => {
    const { method, site, endpoint } = this.props;
    this.setReducedState({ loading: true });
    rest
      .request(method, site, endpoint)
      .then((res) => {
        this.setReducedState({ loading: false, data: res });
      })
      .catch((err) => {
        console.log(err);
        this.setReducedState({ loading: false, error: err });
      });
  };

  componentDidMount() {
    if (!this.props.disableInitialFetch) {
      this.request();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.endpoint !== this.props.endpoint) {
      this.request();
    }
  }

  render() {
    const { children } = this.props;

    const value = {
      state: this.state,
    };

    return (
      <QueryContext.Provider value={value}>
        {typeof children === "function" ? children(this.state) : children}
      </QueryContext.Provider>
    );
  }
}
