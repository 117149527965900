import React, { Component } from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Card,
  Button,
  notification,
  message,
  Popconfirm,
} from "antd";
import { AWS_CLOUDFRONT_URL } from "../../config";
import UploadSingle from "../UploadSingle";
import Meta from "antd/lib/card/Meta";
import {
  DownloadOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import ImageUrl from "../ImageUrl";
import { UPLOAD_CUSTOMIZE_DESIGN } from "../../graphql/mutates/uploadPrintFiles";
import { apolloClient } from "../../apollo-client";
import updateOrderStatus from "../../graphql/mutates/updateOrderStatus";
import _ from "lodash";
import removePersonalizedPrintFiles from "../../graphql/mutates/removePersonalizedPrintFiles";
import TextArea from "antd/lib/input/TextArea";

const Container = styled.div`
  .ant-card .ant-card-body {
    padding: 0;
  }
  .ant-card .ant-card-head {
    padding: 0 15px;
  }
  .card-footer {
    padding: 0 15px 15px;
    height: 70px;
  }
  h3 {
    margin-top: 0px;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
  }
  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-size: 14px;
    }
  }
`;

class UploadDesignIsCustom extends Component {
  state = {
    designs: this.props.productOrder.printFiles,
    visible: false,
    modalReject: false,
    contentReject: "",
  };

  onChangePrintFiles = (designId, file) => {
    const newDesigns = [...this.state.designs];
    const designIndex = newDesigns.findIndex((el) => el.id === designId);
    newDesigns[designIndex].file = { ...file.file, key: file.file.key };
    newDesigns[designIndex].fileID = file.fileId;
    this.setState({ designs: newDesigns });
  };
  bytesToSize = (fileSize) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (fileSize === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(fileSize) / Math.log(1024)));
    return Math.round(fileSize / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  handleDeletePrintFiles = (printFileID, fileID) => {
    const { designs } = this.state;
    const newDesigns = [...designs].map((el) => {
      if (el.fileID === fileID) {
        return {
          ...el,
          file: null,
          fileID: null,
        };
      }
      return el;
    });
    this.setState({
      designs: newDesigns,
    });
    // const designExisted = newDesigns.filter((ds) => ds.fileID);
    apolloClient
      .mutate({
        mutation: removePersonalizedPrintFiles,
        variables: {
          orderID: this.props.productOrder.id,
          printFileIDs: [printFileID],
        },
      })
      .then((res) => {
        notification.success({ message: "Delete print files successful!" });
        this.props.refetch();
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  onSavePrintFiles = (value) => {
    const formatDesigns = [...this.state.designs].map((design) => {
      return {
        id: design.id,
        fileId: design.fileID,
      };
    });

    apolloClient
      .mutate({
        mutation: UPLOAD_CUSTOMIZE_DESIGN,
        variables: {
          orderId: this.props.productOrder.id,
          printFiles: formatDesigns,
        },
      })
      .then(() => {
        if (value) {
          this.onApprovePrintFiles(value);
        } else {
          notification.success({ message: "Upload print files successful!" });
          this.props.onClose();
          this.props.refetch();
        }
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  onApprovePrintFiles = (value) => {
    apolloClient
      .mutate({
        mutation: updateOrderStatus,
        variables: {
          orderIds: [this.props.productOrder.id],
          status: value,
        },
      })
      .then(() => {
        message.success("Print files had been approved!");
        this.props.onClose();
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { productOrder } = this.props;
    const { designs } = this.state;
    return (
      <Container>
        <h3>Print Files</h3>
        <Row>
          {_.sortBy(
            designs,
            (design) => design.baseDesign?.name !== "Front"
          ).map((design, index) => (
            <Col
              key={index}
              lg={8}
              span={8}
              style={{ paddingRight: 15, marginBottom: 20 }}
            >
              <Card
                title={`${design.baseDesign.name} ${design?.file?.width
                  ? `(${design.file.width} x ${design.file.height})`
                  : ""
                  }`}
                extra={
                  <div>
                    {!["in_production", "fulfilled", "cancelled"].includes(
                      productOrder.status
                    ) &&
                      design.file && (
                        <Popconfirm
                          title="Are you sure delete this print file?"
                          onConfirm={() =>
                            this.handleDeletePrintFiles(
                              design.id,
                              design.fileID
                            )
                          }
                          icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                          }
                          okButtonProps={{ type: "danger" }}
                          onCancel={() => console.log("cancel")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <a
                            style={{ color: "darkred" }}
                            href="/#"
                            onClick={(e) => e.preventDefault()}
                          >
                            Delete
                          </a>
                        </Popconfirm>
                      )}
                  </div>
                }
              >
                <div>
                  <div
                    style={{
                      pointerEvents:
                        // productOrder.status === "fulfilled" &&  productOrder.product.designStatus === "approved"
                        ["fulfilled", "in_production"].includes(
                          productOrder.status
                        )
                          ? "none"
                          : "auto",
                    }}
                  >
                    <UploadSingle
                      disabled={["in_production", "fulfilled", "cancelled"].includes(productOrder.status)}
                      printFiles={true}
                      onChangePrintFiles={(file) =>
                        this.onChangePrintFiles(design.id, file)
                      }
                      setWidth="100%"
                      setHeight="150px"
                      //imageKey={design.file?.thumbnail ?? design.file?.key}
                      imageKey={design.file?.key}
                    />
                  </div>
                </div>
                {design.file ? (
                  <Meta
                    className="card-footer"
                    title={design.file.fileName}
                    description={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{this.bytesToSize(design.file.fileSize)}</span>
                        <div>
                          <Button
                            type="link"
                            size={"small"}
                            onClick={() => {
                              this.setState({ visible: design.file });
                            }}
                            icon={<EyeOutlined />}
                          />

                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${AWS_CLOUDFRONT_URL}/${design.file.key}`}
                          >
                            <DownloadOutlined style={{ color: "dodgerblue" }} />
                          </a>
                        </div>
                      </div>
                    }
                  />
                ) : (
                  <Meta
                    className="card-footer"
                    title={`${design.baseDesign.width} x ${design.baseDesign.height}`}
                    description="Please upload correct print file"
                  />
                )}
              </Card>
            </Col>
          ))}
          <Col lg={24} span={24}>
            {productOrder.product && productOrder.product.mockups.length > 0 && (
              <div
                style={{
                  marginBottom: 50,
                  borderTop: "1px solid #f0f0f0",
                  paddingTop: 10,
                }}
              >
                <h3>Product mockups</h3>
                <div style={{ textAlign: "left" }}>
                  <img
                    style={{ maxWidth: 400, height: "400" }}
                    src={
                      productOrder.product.mockups[0].file.source === "store"
                        ? productOrder.product.mockups[0].file.url
                        : `${AWS_CLOUDFRONT_URL}/${productOrder.product.mockups[0].file.key}`
                    }
                    alt=""
                  ></img>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            borderTop: "1px solid #ccc",
            height: 65,
            right: 0,
            textAlign: "right",
            background: "white",
          }}
        >
          <div>
            <Button
              style={{ margin: "15px 15px 15px 0" }}
              onClick={() => this.props.onClose()}
            >
              Cancel
            </Button>
            {_.includes(["pending_approve", "designed"], productOrder.status) &&
              designs.find((design) => design.fileID) && (
                <span>
                  {productOrder.status === "pending_approve" && (
                    <Button
                      onClick={() => this.onSavePrintFiles("designed")}
                      type="primary"
                      style={{ margin: "15px 15px 15px 0" }}
                    >
                      Approve
                    </Button>
                  )}

                  {/* <Popconfirm
                    title="Are you sure rejected this order？"
                    onConfirm={() => this.onApprovePrintFiles("rejected")}
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    okButtonProps={{ type: "danger" }}
                    okText="Yes"
                    cancelText="No"
                    placement="top"
                  > */}
                  <Button
                    type="primary"
                    style={{
                      background: "red",
                      borderColor: "#ff4d4f",
                      margin: "15px 15px 15px 0",
                    }}
                    onClick={() => {
                      this.setState({
                        modalReject: true,
                      });
                    }}
                  >
                    Reject
                  </Button>
                  {/* </Popconfirm> */}
                </span>
              )}
            {_.includes(
              ["fulfilled", "cancelled"],
              productOrder.status
            ) ? null : (
              <Button
                onClick={() => this.onSavePrintFiles()}
                type="primary"
                style={{ margin: "15px 15px 15px 0" }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <Modal
          title="View lange file"
          visible={typeof this.state.visible === "object" ? true : false}
          onCancel={() => this.setState({ visible: false })}
          footer={false}
        >
          <ImageUrl
            widthImage="100%"
            imageKey={this.state.visible && this.state.visible.key}
          // id={this.state.visible && this.state.visible.id}
          />
        </Modal>
        <Modal
          title="Reject design "
          visible={this.state.modalReject}
          onCancel={() => {
            this.setState({ modalReject: false, contentReject: "" });
          }}
          onOk={() => {
            apolloClient
              .mutate({
                mutation: updateOrderStatus,
                variables: {
                  orderIds: [this.props.productOrder.id],
                  status: "rejected",
                  comment: this.state.contentReject,
                },
              })
              .then(() => {
                message.success("Print files had been rejected!");
                this.props.onClose();
                this.props.refetch();
              })
              .catch((err) => {
                message.error(err.message);
              });
          }}
        >
          Reason
          <TextArea
            value={this.state.contentReject}
            onChange={(value) => {
              this.setState({ contentReject: value.target.value });
            }}
          ></TextArea>
        </Modal>
      </Container>
    );
  }
}
export default UploadDesignIsCustom;
