import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "antd";
import _ from "lodash";
import { AppContext } from "../context";
import { AreaChartOutlined } from "@ant-design/icons";
import { apolloClient } from "../apollo-client";
import { LOGIN_ICTEES_APP } from "../graphql/mutates/loginIctees";
import { TOKEN_NAME, TRELLO_URL } from "../config";

const { SubMenu } = Menu;

const Container = styled.div`
  width: 256px;
  position: relative;
  height: calc(100vh - 60px);
  .setting-menu {
    position: fixed;
    border-right: 1px solid rgba(48, 100, 107, 0.3);
    padding-top: 15px;
    height: ${(props) =>
    props.changeSize === false ? "100vh" : "calc(100vh - 60px)"};
  }
`;
class SideBar extends Component {
  render() {
    const { location, routes, collapsed, changeSize } = this.props;
    const childMenu = _.filter(routes, (menu) => {
      return menu.child;
    });
    const defaultOpenKeys = _.filter(childMenu, (children) => {
      return _.find(children.child, (childRouter) => {
        return childRouter.path === location.pathname;
      });
    });

    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <Container collapsed={collapsed} changeSize={changeSize}>
            <div style={{ color: "#f5f5f5" }}>
              <Menu
                className="setting-menu"
                theme="dark"
                defaultOpenKeys={
                  defaultOpenKeys.length > 0
                    ? [defaultOpenKeys[0].path]
                    : defaultOpenKeys
                }
                selectedKeys={[location.pathname]}
                mode="inline"
                inlineCollapsed={collapsed}
                style={{
                  width: collapsed ? "80px" : "210px",
                  height:
                    window.innerWidth > 991
                      ? typeof collapsed === Boolean && "calc(100vh - 64px)"
                      : "100vh !important",
                }}
              // className={`${
              //   collapsed ? "ictees-menu collapsed" : "ictees-menu"
              // }`}
              >
                {routes
                  .filter((r) => r.menu)
                  .map((route) => {
                    // if (route.roles) {
                    //   let canAccess = false;
                    //   route.roles &&
                    //     route.roles.forEach((role) => {
                    //       if (
                    //         currentUser &&
                    //         currentUser.roles.map((r) => r).includes(role)
                    //       ) {
                    //         canAccess = true;
                    //       }
                    //       //Hide Hoa Menu
                    //       // if(currentUser.email === 'knocktheme@gmail.com' && menu.icon === 'setting'){
                    //       //   canAccess = false
                    //       // }
                    //     });
                    //   if (!canAccess) return null;
                    // }
                    return route.child ? (
                      <SubMenu
                        icon={route.icon}
                        key={route.path}
                        title={route.menu.title}
                      >
                        {route.child.map((child) => (
                          <Menu.Item icon={child.icon} key={child.path}>
                            <NavLink to={child.path}>
                              {child.menu.title
                                ? child.menu.title
                                : child.title}
                            </NavLink>
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : route.path === "/settings" ? (
                      <Menu.Item
                        key={route.path}
                        icon={route.icon}
                        style={{ position: "absolute", bottom: 0 }}
                      >
                        <NavLink to={route.path}>
                          {route.menu.title ? route.menu.title : route.title}
                        </NavLink>
                      </Menu.Item>
                    ) : (
                      <Menu.Item icon={route.icon} key={route.path}>
                        <NavLink to={route.path}>
                          {route.menu.title ? route.menu.title : route.title}
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
                <Menu.Item
                  icon={<AreaChartOutlined />}
                  onClick={() => {
                    apolloClient
                      .mutate({
                        mutation: LOGIN_ICTEES_APP,
                      })
                      .then((res) => {
                        window.location.href = `${TRELLO_URL}?token=${res.data.loginIcteesApp}`;
                        // `http://localhost:3001/?token=${res.data.loginIcteesApp}`
                      })
                  }}
                >
                  Trello app
                </Menu.Item>
              </Menu>
            </div>
          </Container>
        )
        }
      </AppContext.Consumer>
    );
  }
}

export default withRouter(SideBar);
