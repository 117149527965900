import React, { Component } from "react";
import styled from "styled-components";
import { Tabs, PageHeader } from "antd";
import { apolloClient } from "../../apollo-client";
import productBases from "../../graphql/queries/productBases";
import ShowProductBase from "./ShowProductBase";

const Container = styled.div`
  .p-header {
    display: flex;
    margin-bottom: 20px;
    .p-left-header {
      flex-grow: 1;
    }
  }

  .anticon svg {
    display: inline-block;
    color: #001529;
  }
`;

class ProductStep1 extends Component {
  state = {
    productBases: [],
    loading: false,
    selectedProductBase: [],
  };
  componentDidMount() {
    apolloClient
      .query({
        query: productBases,
        variables:{filter:{limit:-1}},
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.setState({
          productBases: res?.data?.productBases?.nodes,
          loading: false,
        });
      });
  }
  handleChange = (selectedProductBase) => {
    this.setState({ selectedProductBase });
  };
  render() {
    const { productBases, selectedProductBase } = this.state;
    const getProductBaseGroup = productBases.reduce((acc, item) => {
      const found = acc.find(
        (i) => i.group[0].category.id === item.category.id
      );
      if (found) {
        found.group.push(item);
      } else {
        acc.push({
          category: { id: item.category.id, name: item.category.title },
          group: [item],
        });
      }
      return acc;
    }, []);
    return (
      <Container>
        <div className="p-header">
          <div className="p-left-header">
            <PageHeader
              title="Create New Product: Step 1"
              onBack={() => this.props.history.goBack()}
            />
          </div>
        </div>
        <div>
          {getProductBaseGroup && (
            <Tabs onChange={() => this.handleChange([])} tabPosition="left">
              <Tabs.TabPane tab="All Product Base" key="all">
                {getProductBaseGroup.map((gr, index) => (
                  <ShowProductBase
                    key={gr.category.id}
                    selectedProductBase={selectedProductBase}
                    multiple={true}
                    productBaseGroup={gr}
                    disable={
                      selectedProductBase[0]
                        ? selectedProductBase[0].category.id === gr.category.id
                        : true
                    }
                    handleChange={this.handleChange}
                  />
                ))}
              </Tabs.TabPane>
              {getProductBaseGroup.map((gr) => (
                <Tabs.TabPane
                  key={gr.group[0].category.id}
                  tab={gr.category.name}
                >
                  <ShowProductBase
                    selectedProductBase={selectedProductBase}
                    handleChange={this.handleChange}
                    multiple={true}
                    productBaseGroup={gr}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          )}
        </div>
      </Container>
    );
  }
}

export default ProductStep1;
