import React, { Component } from "react";
import {
  Tabs,
  Badge,
  Select,
  Table,
  Skeleton,
  Tag,
  Avatar,
  message,
  Modal,
  Dropdown,
  Menu,
  Popconfirm,
  Button,
  Tooltip,
  notification,
  Drawer,
  Pagination,
} from "antd";
import _ from "lodash";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { apolloClient } from "../../apollo-client";
import moment from "moment";
import {
  ExclamationCircleOutlined,
  MoreOutlined,
  CopyOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import createFile from "../../graphql/mutates/createFile";
import Upload from "../files/Upload";
import ImageUrl from "../ImageUrl";
import CreateTask from "../orders/CreateTask";
import UpdateTask from "../orders/UpdateTask";
import UploadPNG from "../orders/UploadPNG";
import ChangePrintFiles from "../orders/ChangePrintFiles";
import RemovePrintFiles from "../orders/RemovePrintFiles";
import DesignConfirmation from "../orders/DesignConfirmation";
import PushToStore from "../orders/PushToStore";
import CreateTaskForm from "../orders/CreateTaskForm";
import UpdateTaskForm from "../orders/UpdateTaskForm";
import CarouselImage from "../orders/CarouselImage";
import orders from "../../graphql/queries/orders/orders";
import defaultImage from "../../assets/images/default.png";
import MappingOrder from "./MappingOrder";
import Mapshopify from "./Mapshopify";
import FilterOrder, { orderStatus } from "../orders/FilterOrder";
import uploadPrintFile from "../../graphql/mutates/uploadPrintFile";
import CustomerInfo from "./CustomerInfo";

const { confirm } = Modal;

const { TabPane } = Tabs;

const Container = styled.div`
  .order-action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .search-reset {
    display: flex;
    width: -webkit-fill-available;
  }
  .bulk-action {
    margin-left: 20px;
    min-width: 150px;
  }
  @media only screen and (max-width: 768px) {
    .order-action {
      flex-direction: column-reverse;
    }
    .ant-select {
      width: 48%;
    }
  }
  @media only screen and (max-width: 1420px) {
    .bulk-action {
      margin-bottom: 10px;
    }
    .order-action {
      flex-direction: column-reverse;
    }
  }
`;

class ExternalOrders extends Component {
  state = {
    selected: null,
    filter: {
      limit: 25,
      offset: 0,
      siteId: null,
      status: null,
      search: null,
      productId: null,
      from: null,
      to: null,
      userId: null,
      external: true,
      shippingMethod: null,
      pushStatus: null,
      baseId: null,
      type: "name",
    },
    page: 1,
    loading: false,
    orderSelect: [],
    visible: false,
    typeOfFile: null,
    aggs: [],
    action: [],
    showModalBU: false,
    hideText: true,
    showMaping: false,
    showCustomerInfo: false,
    modalCustomize: false,
  };

  showImageModal = (order, typeOfFile) => {
    this.setState({
      visible: {
        ...order,
      },
      typeOfFile,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  renderOrderFileName = (orderSelect) => {
    let newOrders = [...orderSelect];
    newOrders = newOrders.map((el) => {
      return {
        ...el,
        fileBackName: el.fileName.replace(".png", "-Back.png"),
        fileFrontName: el.fileName.replace(".png", "-Front.png"),
      };
    });
    return newOrders;
  };

  getFile = async (values) => {
    const { orderSelect } = this.state;
    let orderAdd = await Promise.all(
      this.renderOrderFileName(orderSelect).map(async (order) => {
        const responseFile = await new Promise(async (resolve) => {
          await Promise.all(
            values.map((file) => {
              if (
                [
                  order.fileBackName.toUpperCase(),
                  order.fileFrontName.toUpperCase(),
                ].includes(file.name.toUpperCase())
              ) {
                return new Promise((resol) => {
                  apolloClient
                    .mutate({
                      mutation: createFile,
                      variables: {
                        input: {
                          source: "aws",
                          key: file.key,
                          fileName: file.name,
                          fileMime: file.type,
                          fileSize: file.size,
                          genThumb: true,
                        },
                      },
                    })
                    .then((res) => {
                      if (
                        file.name.toUpperCase() ===
                        order.fileFrontName.toUpperCase()
                      ) {
                        resol({
                          fileId: res.data.createFile.id,
                          side: "front",
                        });
                      } else if (
                        file.name.toUpperCase() ===
                        order.fileBackName.toUpperCase()
                      ) {
                        resol({
                          fileId: res.data.createFile.id,
                          side: "back",
                        });
                      }
                    })
                    .catch((err) => {
                      message.error(err.message);
                    });
                });
              }
              return false;
            })
          ).then((fileResponses) => {
            fileResponses.forEach((fileResponse) => {
              if (fileResponse) {
                order.printFiles.push(fileResponse);
              }
            });
            resolve(order);
          });
        });
        return responseFile;
      })
    );
    return orderAdd;
  };

  uploadPNG = (values) => {
    this.getFile(values).then((orderAdd) => {
      const checkOrderHasPrintFiles = orderAdd.filter(
        (el) => el.printFiles.length > 0
      );
      if (checkOrderHasPrintFiles.length) {
        orderAdd.forEach((order) => {
          if (order.printFiles.length) {
            apolloClient
              .mutate({
                mutation: uploadPrintFile,
                variables: {
                  printFiles: order.printFiles,
                  orderIds: [order.id],
                },
              })
              .then(() => {
                message.success(
                  "Upload " + order.product.title + " print file successfully"
                );
                this.setState({ orderSelect: [], action: [] });
                this.refetch().then((newData) => {
                  this.setState({ aggs: newData.data.orders.aggs });
                });
              })
              .catch((err) => {
                message.error(
                  "Upload " + order.product.title + " print file failed"
                );
              });
          }
        });
      } else {
        message.error("Invalid file name for print files!");
      }
    });
  };

  onRemovePrintFiles = () => {
    const { orderSelect } = this.state;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [String!]) {
            removePrintFile(orderIds: $orderIds)
          }
        `,
        variables: {
          orderIds: orderIdSelect,
        },
      })
      .then(() => {
        message.success("Remove print files successfully");
        this.props.refetch();
        this.setState({
          loading: false,
          visible: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onChangeStatus = (value) => {
    const { orderSelect } = this.state;
    const orderSelectId = orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!) {
            updateOrderStatus(orderIds: $orderIds, status: $status)
          }
        `,
        variables: {
          orderIds: orderSelectId,
          status: value,
        },
      })
      .then(() => {
        message.success("Order has been " + value);
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onCancelOrder = (id) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!) {
            updateOrderStatus(orderIds: $orderIds, status: $status)
          }
        `,
        variables: {
          orderIds: [id],
          status: "cancelled",
        },
      })
      .then(() => {
        message.success("Order has been cancel");
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  pushToStore = () => {
    const { orderSelect } = this.state;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    // const cookieExpired = orderSelect
    //   .reduce(
    //     (init, item) =>
    //       init.map((el) => el.site.id).includes(item.site.id)
    //         ? init
    //         : [...init, item],
    //     []
    //   )
    //   .filter(
    //     (order) =>
    //       moment(order.site.cookieUpdatedAt)
    //         .add(1, "days")
    //         .diff(moment(), "minutes") < 10
    //   );
    // if (cookieExpired.length > 0) {
    //   cookieExpired.forEach((order) => {
    //     if (
    //       moment(cookieExpired[0].site.cookieUpdatedAt)
    //         .add(1, "days")
    //         .diff(moment(), "minutes") < 0
    //     ) {
    //       notification.error({
    //         message: cookieExpired[0].site.title + "'s cookie has expired!",
    //       });
    //     } else if (
    //       0 <
    //       moment(cookieExpired[0].site.cookieUpdatedAt)
    //         .add(1, "days")
    //         .diff(moment(), "minutes") <
    //       10
    //     ) {
    //       notification.warning({
    //         message:
    //           cookieExpired[0].site.title +
    //           "'s cookie will expire in" +
    //           moment(cookieExpired[0].site.cookieUpdatedAt)
    //             .add(1, "days")
    //             .diff(moment(), "minutes") +
    //           "minutes",
    //       });
    //     }
    //   });
    // } else {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($order_ids: [ID!]) {
            pushProduct(order_ids: $order_ids)
          }
        `,
        variables: {
          order_ids: orderIdSelect,
        },
      })
      .then(() => {
        message.success(
          "Pushing product in the background, the order status will be updated in a moment.",
          5
        );
        this.setState({ action: [] });
        this.refetch().then((newData) => {
          this.setState({ aggs: newData.data.orders.aggs });
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
    //}
  };

  showConfirm = (value) => {
    confirm({
      title:
        "Do you want to " +
        (value === "designed"
          ? "approve"
          : value === "push-product"
            ? "push to store"
            : value) +
        " these order?",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        if (value === "remove print files") {
          this.onRemovePrintFiles();
        } else if (value === "push-product") {
          this.pushToStore();
        } else {
          this.onChangeStatus(value);
        }
      },
      onCancel: () => {
        this.setState({ action: [] });
      },
    });
  };

  showModal = (value) => {
    this.setState({
      showModalBU: value,
    });
  };

  handleOk = () => {
    this.setState({
      showModalBU: false,
    });
  };

  handleCancelShowBU = () => {
    this.setState({
      showModalBU: false,
      action: [],
    });
  };

  onChangeDate = (range) => {
    if (range.from !== null && range.to !== null) {
      this.setState({
        filter: {
          ...this.state.filter,
          from: moment(range.from).format("MMM DD, YYYY"),
          to: moment(range.to).format("MMM DD, YYYY"),
          offset: 0,
        },
        page: 1,
        action: [],
      });
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          from: null,
          to: null,
        },
      });
    }
  };

  actionBulkUpload(value) {
    this.setState({ action: value });
    switch (value) {
      case "change-designer":
      case "create-task":
        return this.showModal(value);
      case "push-product":
      case "cancelled":
      case "designed":
      case "rejected":
      case "remove print files":
        return this.showConfirm(value);
      default:
        break;
    }
  }
  actionColums(record, isAdmin, refetch) {
    const fileFrontName = record.fileName.replace(".png", "-Front.png");
    const fileBackName = record.fileName.replace(".png", "-Back.png");
    switch (record.status) {
      case "unassigned":
        return (
          <>
            <Dropdown.Button
              icon={<MoreOutlined />}
              type="link"
              overlay={
                <Menu
                  style={{
                    background: "white",
                    padding: 10,
                    lineHeight: "25px",
                  }}
                >
                  <div
                    onClick={() => {
                      this.setState({ showMaping: record });
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()} href="/#">
                      Mapping
                    </a>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ showCustomerInfo: record });
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()} href="/#">
                      Customer info
                    </a>
                  </div>
                  <div>
                    <Popconfirm
                      title="Are you sure cancel this order？"
                      onConfirm={() => this.onCancelOrder(record.id)}
                      okText="Yes"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      cancelText="No"
                      placement="left"
                    >
                      <a onClick={(e) => e.preventDefault()} href="/#">
                        Cancel
                      </a>
                    </Popconfirm>
                  </div>
                </Menu>
              }
            >
              {isAdmin && (
                <CreateTask
                  orderId={record.id}
                  refetch={() => {
                    refetch().then((newData) => {
                      this.setState({
                        aggs: newData.data.orders.aggs,
                      });
                    });
                  }}
                />
              )}
            </Dropdown.Button>
          </>
        );
      case "processing":
        return (
          <Dropdown.Button
            icon={<MoreOutlined />}
            type="link"
            overlay={
              <Menu
                style={{
                  background: "white",
                  padding: 10,
                  lineHeight: "25px",
                }}
              >
                {isAdmin ? (
                  <div>
                    <UpdateTask
                      designerId={record.task && record.task.user?.id}
                      orderId={record.id}
                      refetch={() => {
                        refetch().then((newData) => {
                          this.setState({
                            aggs: newData.data.orders.aggs,
                          });
                        });
                      }}
                    />
                    <Popconfirm
                      title="Are you sure cancel this order？"
                      onConfirm={() => this.onCancelOrder(record.id)}
                      okText="Yes"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      cancelText="No"
                      placement="topLeft"
                    >
                      <a onClick={(e) => e.preventDefault()} href="/#">
                        Cancel
                      </a>
                    </Popconfirm>
                    <div
                      onClick={() => {
                        this.setState({ showCustomerInfo: record });
                      }}
                    >
                      <a onClick={(e) => e.preventDefault()} href="/#">
                        Customer info
                      </a>
                    </div>
                  </div>
                ) : null}
                <div
                  onClick={() => {
                    this.setState({ showMaping: record });
                  }}
                >
                  <a onClick={(e) => e.preventDefault()} href="/#">
                    Mapping
                  </a>
                </div>
              </Menu>
            }
          >
            <UploadPNG
              setHeight="200px"
              fileBackName={fileBackName}
              fileFrontName={fileFrontName}
              orderId={record.id}
              refetch={() => {
                refetch().then((newData) => {
                  this.setState({
                    aggs: newData.data.orders.aggs,
                  });
                });
              }}
            />
          </Dropdown.Button>
        );
      case "pending_approve":
        return (
          <Dropdown.Button
            icon={<MoreOutlined />}
            type="link"
            overlay={
              <Menu
                style={{
                  background: "white",
                  padding: 10,
                  lineHeight: "25px",
                }}
              >
                {isAdmin && (
                  <UpdateTask
                    designerId={record.task && record.task.user?.id}
                    orderId={record.id}
                    refetch={() => {
                      refetch().then((newData) => {
                        this.setState({
                          aggs: newData.data.orders.aggs,
                        });
                      });
                    }}
                  />
                )}
                <ChangePrintFiles
                  fileBackName={fileBackName}
                  fileFrontName={fileFrontName}
                  printFiles={record.printFiles}
                  setWidth="200px"
                  setHeight="200px"
                  orderId={record.id}
                  refetch={() => {
                    refetch().then((newData) => {
                      this.setState({
                        aggs: newData.data.orders.aggs,
                      });
                    });
                  }}
                />
                <RemovePrintFiles
                  orderId={record.id}
                  refetch={() => {
                    refetch().then((newData) => {
                      this.setState({
                        aggs: newData.data.orders.aggs,
                      });
                    });
                  }}
                />
                {isAdmin && (
                  <>
                    <Popconfirm
                      title="Are you sure cancel this order？"
                      onConfirm={() => this.onCancelOrder(record.id)}
                      okText="Yes"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      cancelText="No"
                      placement="topLeft"
                    >
                      <a href="/#">Cancel</a>
                    </Popconfirm>
                  </>
                )}
                <div
                  onClick={() => {
                    this.setState({ showCustomerInfo: record });
                  }}
                >
                  <a onClick={(e) => e.preventDefault()} href="/#">
                    Customer info
                  </a>
                </div>
              </Menu>
            }
          >
            {isAdmin && (
              <DesignConfirmation
                orderId={record.id}
                fileId={record.printFiles}
                refetch={() => {
                  refetch().then((newData) => {
                    this.setState({
                      aggs: newData.data.orders.aggs,
                    });
                  });
                }}
              />
            )}
          </Dropdown.Button>
        );
      case "designed":
        return (
          <Dropdown.Button
            disabled={["Pending", "Pushing"].includes(record.push_status)}
            icon={<MoreOutlined />}
            type="link"
            overlay={
              <Menu
                style={{
                  background: "white",
                  padding: 10,
                  lineHeight: "25px",
                }}
              >
                {isAdmin && record.push_status !== "Pushed" && (
                  <UpdateTask
                    designerId={record.task && record.task.user?.id}
                    orderId={record.id}
                    refetch={() => {
                      refetch().then((newData) => {
                        this.setState({
                          aggs: newData.data.orders.aggs,
                        });
                      });
                    }}
                  />
                )}
                {record.push_status !== "Pushed" && (
                  <div>
                    <ChangePrintFiles
                      fileBackName={fileBackName}
                      fileFrontName={fileFrontName}
                      printFiles={record.printFiles}
                      fileName={record.fileName}
                      setWidth="200px"
                      setHeight="200px"
                      orderId={record.id}
                      refetch={() => {
                        refetch().then((newData) => {
                          this.setState({
                            aggs: newData.data.orders.aggs,
                          });
                        });
                      }}
                    />
                    <RemovePrintFiles
                      orderId={record.id}
                      refetch={() => {
                        refetch().then((newData) => {
                          this.setState({
                            aggs: newData.data.orders.aggs,
                          });
                        });
                      }}
                    />
                  </div>
                )}
                {isAdmin && (
                  <>
                    <Popconfirm
                      title="Are you sure cancel this order？"
                      onConfirm={() => this.onCancelOrder(record.id)}
                      okText="Yes"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      cancelText="No"
                      placement="topLeft"
                    >
                      <a href="/#">Cancel</a>
                    </Popconfirm>
                  </>
                )}
                <div
                  onClick={() => {
                    this.setState({ showCustomerInfo: record });
                  }}
                >
                  <a onClick={(e) => e.preventDefault()} href="/#">
                    Customer info
                  </a>
                </div>
              </Menu>
            }
          >
            {isAdmin && (
              <PushToStore
                disabled={["Pending", "Pushing", "Pushed"].includes(
                  record.push_status
                )}
                order={record}
                refetch={() => {
                  refetch().then((newData) => {
                    this.setState({
                      aggs: newData.data.orders.aggs,
                    });
                  });
                }}
              />
            )}
          </Dropdown.Button>
        );
      case "rejected":
        return (
          <Dropdown.Button
            disabled={["Pending", "Pushing"].includes(record.push_status)}
            icon={<MoreOutlined />}
            type="link"
            overlay={
              <Menu
                style={{
                  background: "white",
                  padding: 10,
                  lineHeight: "25px",
                }}
              >
                {isAdmin ? (
                  <div>
                    <UpdateTask
                      designerId={record.task && record.task.user?.id}
                      orderId={record.id}
                      refetch={() => {
                        refetch().then((newData) => {
                          this.setState({
                            aggs: newData.data.orders.aggs,
                          });
                        });
                      }}
                    />
                    <Popconfirm
                      title="Are you sure cancel this order？"
                      onConfirm={() => this.onCancelOrder(record.id)}
                      okText="Yes"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      cancelText="No"
                      placement="topLeft"
                    >
                      <a onClick={(e) => e.preventDefault()} href="/#">
                        Cancel
                      </a>
                    </Popconfirm>
                  </div>
                ) : null}
                <div
                  onClick={() => {
                    this.setState({ showCustomerInfo: record });
                  }}
                >
                  <a onClick={(e) => e.preventDefault()} href="/#">
                    Customer info
                  </a>
                </div>
              </Menu>
            }
          >
            <ChangePrintFiles
              fileBackName={fileBackName}
              fileFrontName={fileFrontName}
              printFiles={record.printFiles}
              fileName={record.fileName}
              setWidth="200px"
              setHeight="200px"
              orderId={record.id}
              refetch={() => {
                refetch().then((newData) => {
                  this.setState({
                    aggs: newData.data.orders.aggs,
                  });
                });
              }}
            />
          </Dropdown.Button>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      filter,
      page,
      visible,
      typeOfFile,
      aggs,
      orderSelect,
      action,
      showModalBU,
      showMaping,
      hideText,
      showCustomerInfo,
    } = this.state;
    const { currentUser } = this.props;
    const isAdmin =
      currentUser && currentUser.roles.find((role) => role === "Administrator");
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ orderSelect: selectedRows });
      },
    };
    let value;
    let isSame = true;
    let isStatus;
    orderSelect.forEach((_, index) => {
      if (index === 0) {
        value = orderSelect[index];
        isStatus = orderSelect[index].status;
      } else {
        if (orderSelect[index].status !== value.status) {
          isSame = false;
          isStatus = null;
        }
      }
      return { isSame, isStatus };
    });
    const renderFileName = (fileName, name) => {
      return (
        <div>
          <em style={{ fontSize: 13 }}>
            <b>{name}: </b>
            {fileName}
          </em>
          <Tooltip title="Copy file name">
            <Button
              style={{ padding: "2px 10px" }}
              onClick={() => {
                var textField = document.createElement("textarea");
                textField.innerText = fileName.toString();
                document.body.appendChild(textField);
                textField.select();
                document.execCommand("copy");
                textField.remove();
                message.success(fileName + " copied");
              }}
              type="link"
            >
              <CopyOutlined />
            </Button>
          </Tooltip>
        </div>
      );
    };
    return (
      <Container>
        <div className="order-action">
          <FilterOrder
            onChangeFilter={(dataFilter) =>
              this.setState({
                ...this.state,
                filter: dataFilter.filter,
                action: dataFilter.action ?? action,
                page: dataFilter.page ?? page,
                orderSelect: dataFilter.orderSelect ?? orderSelect,
              })
            }
            isAdmin={isAdmin}
            filter={filter}
            isExternal={true}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Select
              className="bulk-action"
              disabled={
                !(orderSelect.length > 0 && isSame === true) ||
                  orderSelect.find((order) =>
                    ["Pending", "Pushing", "Pushed"].includes(order.push_status)
                  )
                  ? true
                  : false
              }
              placeholder="Bulk action"
              onSelect={(value) => this.actionBulkUpload(value)}
              value={action}
            >
              {
                {
                  unassigned: (
                    <>
                      <Select.Option value="create-task">
                        Create Task
                      </Select.Option>
                      <Select.Option value="cancelled">Cancel</Select.Option>
                    </>
                  ),
                  rejected: (
                    <>
                      <Select.Option value="upload-png">
                        <Upload
                          accept=".png"
                          multiple={true}
                          onChange={this.uploadPNG}
                          printFiles="upload-design"
                        >
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.65)",
                            }}
                            onClick={() =>
                              this.setState({
                                action: [],
                              })
                            }
                          >
                            Upload PNG
                          </p>
                        </Upload>
                      </Select.Option>
                      <Select.Option value="change-designer">
                        Change Designer
                      </Select.Option>
                    </>
                  ),
                  processing: (
                    <>
                      <Select.Option value="upload-png">
                        <Upload
                          accept=".png"
                          multiple={true}
                          onChange={this.uploadPNG}
                          printFiles="upload-design"
                        >
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.65)",
                            }}
                            onClick={() =>
                              this.setState({
                                action: [],
                              })
                            }
                          >
                            Upload PNG
                          </p>
                        </Upload>
                      </Select.Option>
                      <Select.Option value="change-designer">
                        Change Designer
                      </Select.Option>
                    </>
                  ),
                  pending_approve: (
                    <>
                      <Select.Option value="change-designer">
                        Change Designer
                      </Select.Option>
                      <Select.Option value="change-print-files">
                        <Upload
                          accept=".png"
                          multiple={true}
                          onChange={this.uploadPNG}
                          printFiles="upload-design"
                        >
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.65)",
                            }}
                            onClick={() =>
                              this.setState({
                                action: [],
                              })
                            }
                          >
                            Change Print File
                          </p>
                        </Upload>
                      </Select.Option>
                      <Select.Option value="remove print files">
                        Remove Print Files
                      </Select.Option>
                      <Select.Option value="designed">Approve</Select.Option>
                      <Select.Option value="rejected">Reject</Select.Option>
                    </>
                  ),
                  designed: (
                    <>
                      <Select.Option
                        disabled={
                          orderSelect.find((order) =>
                            ["Pending", "Pushing", "Pushed"].includes(
                              order.push_status
                            )
                          )
                            ? true
                            : false
                        }
                        value="push-product"
                      >
                        Push to store
                      </Select.Option>
                      <Select.Option value="change-designer">
                        Change Designer
                      </Select.Option>
                      <Select.Option value="change-print-files">
                        <Upload
                          accept=".png"
                          multiple={true}
                          onChange={this.uploadPNG}
                          printFiles="upload-design"
                          custom={true}
                        >
                          <p
                            style={{
                              color: "rgba(0, 0, 0, 0.65)",
                              display: "block",
                            }}
                            onClick={() =>
                              this.setState({
                                action: [],
                              })
                            }
                          >
                            Change Print File
                          </p>
                        </Upload>
                      </Select.Option>
                      <Select.Option value="remove print files">
                        Remove Print Files
                      </Select.Option>
                    </>
                  ),
                }[isStatus]
              }
            </Select>
          </div>
        </div>
        <Tabs
          activeKey={filter.status ? filter.status : "all"}
          onChange={(key) =>
            this.setState({
              filter: {
                ...filter,
                status: key === "all" ? null : key,
                offset: 0,
              },
              action: [],
              orderSelect: [],
              page: 1,
            })
          }
        >
          <TabPane
            tab={
              <Badge
                overflowCount={9999999}
                count={_.sum(aggs && aggs.map((agg) => agg.count))}
                style={{
                  background: "#fff",
                  color: "#000",
                  border: "1px solid #f5f5f5",
                  right: -20,
                }}
              >
                All
              </Badge>
            }
            key="all"
          ></TabPane>
          {orderStatus.map((s) => (
            <Tabs.TabPane
              key={s.key}
              tab={
                <Badge
                  overflowCount={9999999}
                  count={_.sum(
                    aggs &&
                    aggs
                      .filter((agg) => agg.status === s.key)
                      .map((a) => a.count)
                  )}
                  style={{ backgroundColor: s.color }}
                >
                  {s.title}
                </Badge>
              }
            />
          ))}
        </Tabs>
        <Query
          query={orders}
          fetchPolicy="network-only"
          variables={{ filter: filter }}
          onCompleted={(res) => this.setState({ aggs: res.orders.aggs })}
        >
          {({ error, loading, data, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            this.refetch = refetch;
            const orders = data.orders.hits;
            const total = data.orders.count;
            const columns = [
              {
                title: "Product",
                dataIndex: "product",
                key: "product",
                width: 430,
                render: (product, order) => (
                  <div
                    style={{
                      display: "grid",
                      alignItems: "center",
                      gridTemplateColumns: "70px auto",
                    }}
                  >
                    <Avatar
                      shape="square"
                      style={{
                        width: 60,
                        height: 60,
                        lineHeight: 60,
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                      src={
                        product.mockups[0]
                          ? product.mockups[0].file.url
                          : defaultImage
                      }
                      onClick={() => {
                        if (product.mockups[0]) {
                          this.showImageModal(order, "product");
                        }
                      }}
                    />
                    <div>
                      <a
                        href={`/product-order/${order.siteId}/${order.product.id}`}
                      >{`${product.title}`}</a>
                      <a
                        style={{ paddingLeft: 15 }}
                        className="icon-button"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={product.sites[0].permalink}
                      >
                        <EyeOutlined />
                      </a>
                      {order.variant_title && (
                        <p style={{ marginBottom: 0 }}>
                          <b>Variant</b>: {order.variant_title}
                        </p>
                      )}
                      <p style={{ marginBottom: 0 }}>
                        {renderFileName(
                          order.fileName.replace(".png", "-Front.png"),
                          "Front"
                        )}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {renderFileName(
                          order.fileName.replace(".png", "-Back.png"),
                          "Back"
                        )}
                      </p>
                    </div>
                  </div>
                ),
              },
              {
                title: "ID/SKU",
                key: "id/sku",
                width: 300,
                render: (order) => (
                  <div style={{ display: "grid" }}>
                    <span>App ID: {order.id}</span>
                    <span>
                      {order.site.title}: {order.name} ({order.originId})
                    </span>
                  </div>
                ),
              },
              {
                title: "Product Type",
                dataIndex: "product",
                key: "product_type",
                width: 130,
                render: (product) => <div>{`${product.productType}`}</div>,
              },
              {
                title: "Variant",
                dataIndex: "variant_title",
                key: "variant_title",
                width: 100,
              },
              {
                title: "Customize",
                dataIndex: "customize",
                key: "customize",
                width: 350,
                render: (customize, order) => {
                  const printFileShow = order.printFiles.find(
                    (el) => el.fileID
                  );
                  return (
                    <div
                      style={{
                        display: "grid",
                        alignItems: "center",
                        gridTemplateColumns:
                          order.printFiles.length > 0 ? "70px 248px" : "318px",
                      }}
                    >
                      {order.printFiles.length > 0 ? (
                        <div
                          style={{
                            width: 60,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.showImageModal(order, "printFile")
                          }
                        >
                          {printFileShow?.file?.thumbnail ||
                            printFileShow?.file?.key ? (
                            <div>
                              {order.originPrintFileThumbUrl ? (
                                <img
                                  src={order.originPrintFileThumbUrl}
                                  style={{
                                    width: 60,
                                    height: 60,
                                    objectFit: "contain",
                                  }}
                                  alt={order.originPrintFileThumbUrl}
                                />
                              ) : (
                                <ImageUrl
                                  widthImage="60px"
                                  heightImage="60px"
                                  imageKey={
                                    printFileShow?.file?.thumbnail ??
                                    printFileShow?.file?.key
                                  }
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      <div style={{ cursor: "pointer" }}>
                        {order.customize && order.customize[0] ? (
                          <div>
                            <b>{order.customize[0].name} :</b>{" "}
                            {order.customize[0].value}
                          </div>
                        ) : null}
                        {order.customize && order.customize[1] ? (
                          <div>
                            <b>{order.customize[1].name} :</b>{" "}
                            {order.customize[1].value}
                          </div>
                        ) : null}
                        {order.customize && order.customize[2] ? (
                          <div>
                            <b>{order.customize[2].name} :</b>{" "}
                            {order.customize[2].value}
                          </div>
                        ) : null}
                        {order.customize && order.customize.length > 3 ? (
                          <Button type="link">Show more...</Button>
                        ) : null}
                      </div>
                    </div>
                  );
                },
              },
              {
                title: "Qty - Cost",
                key: "qty-cost",
                width: 120,
                render: (order) => (
                  <div>
                    {order.quantity} x ${order.price}
                    <br />
                    <em style={{ fontSize: 12 }}>
                      {moment(order.createdAt).format("MMM DD, YYYY")}
                    </em>
                  </div>
                ),
              },
              {
                title: "Designer",
                dataIndex: "task",
                key: "designer",
                width: 150,
                render: (task) => (
                  <div>
                    {task && task.user !== null ? (
                      <Tag key={task.user?.id} color="blue">
                        {task.user?.firstName + " " + task.user?.lastName}
                      </Tag>
                    ) : null}
                  </div>
                ),
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                width: 150,
                render: (status) => (
                  <span
                    style={{
                      color: orderStatus.find((item) => item.key === status)
                        ?.color,
                      // ["processing"].includes(status)
                      //   ? "#f94"
                      //   : status === "fulfilled"
                      //   ? "green"
                      //   : status === "unassigned"
                      //   ? "unset"
                      //   : ["cancelled", "rejected"].includes(status)
                      //   ? "#ff5500"
                      //   : status === "error"
                      //   ? "red"
                      //   : "blue",
                    }}
                  >
                    {orderStatus.find((item) => item.key === status)?.title}
                  </span>
                ),
              },
              {
                title: "Actions",
                dataIndex: "id",
                key: "actions",
                align: "right",
                fixed: "right",
                width: 220,
                render: (id, record) => {
                  return (
                    <div>{this.actionColums(record, isAdmin, refetch)}</div>
                  );
                },
              },
            ];
            filter.status === "designed" &&
              columns.splice(6, 0, {
                title: "Push Status",
                dataIndex: "push_status",
                key: "push_status",
                width: 130,
                render: (push_status) => (
                  <span
                    style={{
                      color: ["pushed", "pushing"].includes(
                        push_status && push_status.toLowerCase()
                      )
                        ? "green"
                        : push_status && push_status.toLowerCase() === "pending"
                          ? "#f94"
                          : push_status && push_status.toLowerCase() === "error"
                            ? "red"
                            : "unset",
                    }}
                  >
                    {push_status &&
                      push_status.charAt(0).toUpperCase() +
                      push_status.slice(1)}
                  </span>
                ),
              });
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <div>
                <Pagination
                  style={{
                    padding: "12px 16px 12px 16px",
                    borderBottom: "1px solid rgb(223, 227, 232)",
                    width: "100%",
                  }}
                  pageSize={filter.limit}
                  total={total}
                  current={page}
                  showSizeChanger={false}
                  onChange={(page, pageSize) => {
                    this.setState({
                      page: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * pageSize,
                      },
                    });
                  }}
                />
                <Table
                  scroll={{ x: tableWidth }}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={orders}
                  pagination={{
                    pageSize: filter.limit,
                    total: total,
                    current: page,
                    showSizeChanger: false,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...filter,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                  }}
                  rowKey={(record) => record.id}
                  loading={loading}
                />
                <Modal
                  title={
                    showModalBU === "create-task"
                      ? "Create Task"
                      : "Change Designer"
                  }
                  visible={typeof showModalBU === "string" ? true : false}
                  onOk={this.handleOk}
                  onCancel={this.handleCancelShowBU}
                  footer={null}
                >
                  <>
                    {showModalBU === "create-task" ? (
                      <CreateTaskForm
                        hide={this.handleCancelShowBU}
                        refetch={() => {
                          refetch().then((newData) => {
                            this.setState({ aggs: newData.data.orders.aggs });
                          });
                        }}
                        orderSelect={orderSelect}
                      />
                    ) : (
                      <UpdateTaskForm
                        orderSelect={orderSelect}
                        hide={this.handleCancelShowBU}
                        refetch={() => {
                          refetch().then((newData) => {
                            this.setState({ aggs: newData.data.orders.aggs });
                          });
                        }}
                      />
                    )}
                  </>
                </Modal>
                <Modal
                  title={`Mapping Order ${showMaping.name}`}
                  visible={typeof showMaping === "object" ? true : false}
                  onCancel={() => {
                    this.setState({ showMaping: false });
                  }}
                  key={showMaping.id}
                  footer={null}
                  width={850}
                >
                  <div>
                    {typeof showMaping === "object" &&
                      showMaping.site.platform === "woocommerce" ? (
                      <MappingOrder
                        order={showMaping}
                        refetch={() => {
                          refetch().then((newData) => {
                            this.setState({ aggs: newData.data.orders.aggs });
                          });
                          this.setState({ showMaping: false });
                        }}
                      />
                    ) : typeof showMaping === "object" &&
                      showMaping.site.platform === "shopify" ? (
                      <Mapshopify
                        order={showMaping}
                        refetch={() => {
                          refetch().then((newData) => {
                            this.setState({
                              aggs: newData.data.orders.aggs,
                            });
                          });
                          this.setState({ showMaping: false });
                        }}
                      />
                    ) : null}
                  </div>
                </Modal>
              </div>
            );
          }}
        </Query>
        <Modal
          title="Customize info"
          visible={this.state.modalCustomize}
          onCancel={() => {
            this.setState({ modalCustomize: false });
          }}
          width={600}
          footer={[
            <Button
              onClick={() => {
                this.setState({ modalCustomize: false });
              }}
            >
              Close
            </Button>,
          ]}
        >
          <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
            {this.state.modalCustomize.customize
              ? this.state.modalCustomize.customize.map((option, index) => (
                <div key={index}>
                  <em>{option.name}: </em>
                  <div>
                    {/* {option.value.length > 70 ? (
                        <div>
                          {option.value.substring(0, 70)}
                          <span
                            style={{
                              display:
                                hideText === this.state.modalCustomize.id
                                  ? "none"
                                  : "initial",
                            }}
                          >
                            ...
                          </span>
                          <span
                            style={{
                              display:
                                hideText === this.state.modalCustomize.id
                                  ? "initial"
                                  : "none",
                            }}
                          >
                            {option.value.substring(70)}
                          </span>
                          <div>
                            <Button
                              size="small"
                              type="link"
                              onClick={() => {
                                this.setState({
                                  hideText: this.state.modalCustomize.id,
                                });
                              }}
                              style={{
                                display:
                                  hideText === this.state.modalCustomize.id
                                    ? "none"
                                    : "initial",
                                padding: 0,
                              }}
                            >
                              Show more...
                            </Button>
                            <Button
                              onClick={() => {
                                this.setState({
                                  hideText: false,
                                });
                              }}
                              size="small"
                              type="link"
                              style={{
                                display:
                                  hideText === this.state.modalCustomize.id
                                    ? "initial"
                                    : "none",
                                padding: 0,
                              }}
                            >
                              Show less
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <span
                          style={{ whiteSpace: "pre-line" }}
                          dangerouslySetInnerHTML={{
                            __html: option.value,
                          }}
                        />
                      )} */}
                    <div
                      onClick={(e) => e.stopPropagation()}
                      dangerouslySetInnerHTML={{
                        __html: option.value?.replace(
                          /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gim,
                          '<a target="blank" href="$1">$1</a>'
                        ),
                      }}></div>
                  </div>
                </div>
              ))
              : null}
          </div>
        </Modal>
        <Modal
          title={typeOfFile === "product" ? "Product Images" : "PrintFile"}
          visible={typeof visible === "object" ? true : false}
          onCancel={this.handleCancel}
          key={visible.id && visible.id}
          footer={null}
          width={850}
        >
          {typeOfFile === "product" ? (
            <CarouselImage
              images={visible.product && visible.product.mockups}
            />
          ) : (
            <CarouselImage
              widthImage="450px"
              images={visible && visible.printFiles}
            />
          )}
        </Modal>
        <Drawer
          title="Customer Info"
          placement="right"
          width={500}
          onClose={() => {
            this.setState({ showCustomerInfo: false });
          }}
          visible={typeof showCustomerInfo === "object" ? true : false}
        >
          <CustomerInfo
            order={showCustomerInfo}
            onClose={() => {
              this.setState({ showCustomerInfo: false });
            }}
          />
        </Drawer>
      </Container>
    );
  }
}

export default ExternalOrders;
