import { gql } from "apollo-boost";

export default gql`
  query categories($filter: CategoryFilter) {
    categories(filter: $filter) {
      total
      nodes {
        id
        title
        description
        status
        totalProductType
      }
    }
  }
`;
