import React, { Component } from "react";
import styled from "styled-components";
import { Button, Popover, Form, message, Tabs } from "antd";
import Upload from "../Upload";
import createFile from "../../graphql/mutates/createFile";
import { apolloClient } from "../../apollo-client";
import uploadPrintFile from "../../graphql/mutates/uploadPrintFile";

const Container = styled.div``;

const { TabPane } = Tabs;

export default class ChangePrintFiles extends Component {
  state = {
    visible: false,
    name: null,
    loading: false,
    fileBack: null,
    fileFront: null,
  };

  formRef = React.createRef();
  componentDidMount() {
    this.setState({
      fileBack: this.props.printFiles?.find(
        (printFile) => printFile.side === "back"
      ),
      fileFront: this.props.printFiles?.find(
        (printFile) => printFile.side === "front"
      ),
    });
  }

  hide = () => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      fileList: [],
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  validatorName = (value, fileName) => {
    const { fileBack, fileFront } = this.state;
    if (
      value
        ? value.file.name.toUpperCase() === fileName.toUpperCase()
        : fileBack
          ? fileBack.file.fileName.toUpperCase() === fileName.toUpperCase()
          : fileFront &&
          fileFront.file.fileName.toUpperCase() === fileName.toUpperCase()
    ) {
      return true;
    }
    return false;
  };

  renderFile = (fileUpload) => {
    const { fileBack, fileFront } = this.state;
    const { fileBackName, fileFrontName } = this.props;
    if (
      [fileFrontName.toUpperCase(), fileBackName.toUpperCase()].includes(
        fileUpload.file.name.toUpperCase()
      )
    ) {
      apolloClient
        .mutate({
          mutation: createFile,
          variables: {
            input: {
              source: "aws",
              key: fileUpload.key,
              fileName: fileUpload.file.name,
              fileMime: fileUpload.file.type,
              fileSize: fileUpload.file.size,
              genThumb: true,
            },
          },
        })
        .then((res) => {
          if (
            res.data.createFile.fileName.toUpperCase() ===
            fileFrontName.toUpperCase()
          ) {
            this.setState({
              fileFront: {
                ...fileFront,
                id: res.data.createFile.id,
                file: res.data.createFile,
              },
              loading: false,
            });
          }
          if (
            res.data.createFile.fileName.toUpperCase() ===
            fileBackName.toUpperCase()
          ) {
            this.setState({
              fileBack: {
                ...fileBack,
                id: res.data.createFile.id,
                file: res.data.createFile,
              },
              loading: false,
            });
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  onFinish = () => {
    const { fileFront, fileBack } = this.state;
    apolloClient
      .mutate({
        mutation: uploadPrintFile,
        variables: {
          printFiles: fileBack
            ? [
              {
                fileId: fileFront ? fileFront.file.id : null,
                side: "front",
              },
              {
                fileId: fileBack ? fileBack.file.id : null,
                side: "back",
              },
            ]
            : [
              {
                fileId: fileFront ? fileFront.file.id : null,
                side: "front",
              },
            ],
          orderIds: [this.props.orderId],
        },
      })
      .then(() => {
        message.success("Upload printfile successfully");
        this.formRef.current.resetFields();
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { visible, fileFront, fileBack } = this.state;
    const { fileBackName, fileFrontName, setWidth, setHeight } = this.props;
    const content = (
      <Form ref={this.formRef} style={{ width: 200 }} onFinish={this.onFinish}>
        <Tabs defaultActiveKey="front">
          <TabPane tab="Front" key="front">
            <Form.Item
              initialValue={fileFront}
              name="front"
              rules={[
                {
                  required: true,
                  message: "Please select at least one image!",
                },
                {
                  validator: (_, value) =>
                    fileFront
                      ? Promise.resolve()
                      : this.validatorName(value, fileFrontName)
                        ? Promise.resolve()
                        : Promise.reject(
                          "Invalid name. Name must have type: " + fileFrontName
                        ),
                },
              ]}
            >
              <Upload
                onChange={(file) => {
                  this.renderFile(file);
                }}
                setWidth={setWidth}
                setHeight={setHeight}
                multiple={true}
                accept=".png"
                imageKey={
                  fileFront
                    //? fileFront.file.thumbnail ?? fileFront.file.key
                    ? fileFront.file.key
                    : null
                }
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Back" key="back">
            <Form.Item
              initialValue={fileBack}
              name="back"
              rules={[
                {
                  validator: (_, value) =>
                    fileBack
                      ? Promise.resolve()
                      : this.validatorName(value, fileBackName)
                        ? Promise.resolve()
                        : Promise.reject(
                          "Invalid name. Name must have type: " + fileBackName
                        ),
                },
              ]}
            >
              <Upload
                onChange={(file) => {
                  this.renderFile(file);
                }}
                setWidth={setWidth}
                setHeight={setHeight}
                multiple={true}
                imageKey={
                  fileBack ? fileBack.file.thumbnail ?? fileBack.file.key : null
                }
                accept=".png"
              />
            </Form.Item>
          </TabPane>
        </Tabs>
        <Form.Item>
          <Button type="primary" style={{ marginRight: 10 }} htmlType="submit">
            Upload PNG
          </Button>
          <Button onClick={this.hide}>Cancel</Button>
        </Form.Item>
      </Form>
    );
    return (
      <Container>
        <div style={{ position: "relative" }}>
          <Popover
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
            content={content}
            trigger="click"
            visible={visible}
            placement="bottom"
            onVisibleChange={this.handleVisibleChange}
          >
            <a href="/#">Change Print File</a>
          </Popover>
        </div>
      </Container>
    );
  }
}
