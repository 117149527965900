import React, { Component } from "react";
import styled from "styled-components";
import { Popover } from "antd";
import UpdateTaskForm from "./UpdateTaskForm";

const Container = styled.div``;

export default class UpdateTask extends Component {
  state = {
    visible: false,
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { orderId, orderSelect, designerId } = this.props;
    return (
      <Container>
        <div>
          <Popover
            content={
              <UpdateTaskForm
                designerId={designerId}
                orderSelect={orderSelect}
                orderId={orderId}
                refetch={this.props.refetch}
                hide={() => this.hide()}
              />
            }
            trigger="click"
            visible={visible}
            placement="bottom"
            onVisibleChange={this.handleVisibleChange}
          >
            <a href="/#">Change Designer</a>
          </Popover>
        </div>
      </Container>
    );
  }
}
