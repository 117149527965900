import { gql } from "apollo-boost";

export default gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      user {
        id
        email
        firstName
        lastName
        roles
      }
    }
  }
`;
