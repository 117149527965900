import axios from "axios";
import _ from "lodash";
import { API_URL, TOKEN_NAME } from "../config";

class Rest {
  constructor() {
    this.endpoint = `${API_URL}/api/rest`;
    this.token = localStorage.getItem(TOKEN_NAME);
  }

  getToken = () => {
    return localStorage.getItem(TOKEN_NAME);
  };

  /**
   *
   */
  request = (method, site_id, endpoint, data) => {
    return new Promise((rs, rj) => {
      let requestOptions = {
        url: `${this.endpoint}/${site_id}`,
        method: method,
        headers: {
          Authorization: `Bearer ${this.getToken()}`,
        },
        withCredentials: true,
        params: { endpoint: endpoint },
        data: data,
      };
      axios(requestOptions)
        .then((res) => {
          if (_.get(res, "data.errors")) return rj(res.data.errors);
          return rs(res.data);
        })
        .catch((err) => rj(err));
    });
  };
}

export default new Rest();
