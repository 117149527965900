import React, { Component } from "react";
import styled from "styled-components";
import {
  Card,
  Input,
  Col,
  Row,
  Form,
  Button,
  notification,
  Select,
  Skeleton,
  Modal,
  Checkbox,
  message,
} from "antd";
import Animate from "rc-animate";
import Wysiwyg from "../Wysiwyg";
import { apolloClient } from "../../apollo-client";
import ProductCategoriesSelect from "../../components/products/ProductCategoriesSelect";
import history from "../../history";
import ProductPrintFiles from "./ProductPrintFiles";
import TagSelect from "./TagSelect";
import createProduct from "../../graphql/mutates/createProduct";
import ProductVariants from "./ProductVariants";
import _ from "lodash";
import updateProduct from "../../graphql/mutates/updateProduct";
import sites from "../../graphql/queries/sites";
import { Query } from "react-apollo";
import ProductUpload from "./ProductUpload";
import createFile from "../../graphql/mutates/createFile";
import { AWS_CLOUDFRONT_URL } from "../../config";
import Upload from "../Upload";
const Container = styled.div`
  .ant-popover-inner-content {
    width: 250px;
  }
`;

const buttonItemLayout = { labelCol: { span: 2 }, wrapperCol: { span: 22 } };

class ProductForm extends Component {
  formRef = React.createRef();
  state = {
    productBase: [],
    variants: [],
    loading: false,
    mockups: [],
    sampleDesign: null,
    designs: this.props.designs,
    imageVariant: [],
    pushStore: false,
    library: [],
    errors: [],
    baseIds: this.props.product
      ? this.props.product.productBases.map((base) => base.id)
      : this.props.productBase.map((base) => base.id),
  };

  disabledRequiredMockups = () => {
    const { productBases } = this.props;
    let required = false;
    productBases.forEach((base) => {
      if (base.autoRender) {
        required = true;
      }
    });
    return required;
  };

  onSubmit = (values) => {
    const { mockups, imageVariant, baseIds, sampleDesign } = this.state;
    const { productBases } = this.props;
    const errors = [];
    const mockupsHasSKU =
      mockups &&
      [...mockups].reduce((init, mockup) => {
        const mockupCustomName = mockup.fileName.split("_");
        if (mockupCustomName.length > 1) {
          init.push(mockupCustomName[1].toLowerCase());
        }
        return init;
      }, []);
    const productBaseSKU = productBases.reduce((baseRequiredMockup, base) => {
      if (base.autoRender) {
        baseRequiredMockup.push(base.sku.toLowerCase());
      }
      return baseRequiredMockup;
    }, []);
    const productBasesInput = baseIds.map((el, index) => {
      return {
        productBaseId: el,
        ordering: index,
      };
    });
    if (
      _.difference(productBaseSKU, mockupsHasSKU).length === 0 ||
      this.disabledRequiredMockups() === false
    ) {
      this.setState({ loading: true });
      let newVariant = [];
      _.forEach(values.variants, (value) => {
        value = value.map((variant) => {
          variant.sku = variant.sku.replace(/\s/g, "-");
          delete variant.file;
          delete variant.key;
          delete variant.__typename;
          return variant;
        });
        newVariant = [...newVariant, ...value];
      });
      const { id } = this.props;
      if (!id) {
        values.mockups = mockups.map((e) => ({
          fileId: e.imageId,
          fileName: e.fileName,
          type: "any",
        }));
        const newDesignValue = values.isCustomize
          ? [...this.state.designs]
          : [...values.designs];
        const setValueDesign = newDesignValue.map((v) => {
          return {
            baseDesignId: v.id,
            fileId: v.fileId,
          };
        });
        apolloClient
          .mutate({
            mutation: createProduct,
            variables: {
              input: {
                ...values,
                fileID: sampleDesign ? sampleDesign.id : null,
                baseIds: baseIds,
                productBases: productBasesInput,
                variants: newVariant.map((item) => ({
                  ...item,
                  salePrice: item.salePrice ? item.salePrice : 0,
                  baseVariantId: item.id,
                })),
                designs: setValueDesign,
              },
            },
          })
          .then(() => {
            notification.success({ message: "Add product success!" });
            history.push("/products");
          })
          .catch((err) => {
            this.setState({ loading: false });
            notification.error(err.message);
          });
      }
      if (id) {
        values.mockups = mockups.map((e) => ({
          fileId: e.imageId,
          fileName: e.fileName,
          type: "any",
          id: e.id,
        }));
        const editDesignValue = values.isCustomize
          ? [...this.state.designs]
          : [...values.designs];
        const setEditValueDesign = editDesignValue.map((v) => {
          return {
            id: v.id,
            fileId: v.fileId,
          };
        });
        apolloClient
          .mutate({
            mutation: updateProduct,
            variables: {
              input: {
                ...values,
                fileID: sampleDesign ? sampleDesign.id : null,
                productBases: productBasesInput,
                id: id,
                variants: newVariant.map((item) => ({
                  ...item,
                  salePrice: item.salePrice ? item.salePrice : 0,
                })),
                baseIds: baseIds,
                designs: setEditValueDesign,
              },
            },
          })
          .then(() => {
            notification.success({ message: "Edit product success!" });
            history.push("/products");
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
            notification.error(err.message);
          });
      }
    } else {
      const missingMockup = _.difference(productBaseSKU, mockupsHasSKU);
      productBases.forEach((base) => {
        if (missingMockup.includes(base.sku.toLowerCase())) {
          errors.push({
            name: [`mockup for ${base.sku}: ${base.renderAttrName} attribute`],
          });
        }
      });
      this.setState({ errors });
      this.error();
    }
  };

  deleteImage = (id) => {
    this.setState({
      mockups: this.state.mockups.filter((el) => el.imageId !== id),
    });
  };

  onChangeMockups = (files) => {
    const { mockups } = this.state;
    this.setState({
      mockups: [...mockups, files],
    });
  };

  componentDidMount() {
    const { product } = this.props;
    if (product) {
      const newMockups = product.mockups.map((el) => {
        return {
          ...el.file,
          imageId: el.file.id,
          id: el.id,
        };
      });
      this.setState({
        mockups: newMockups,
      });
    }
  }

  requiredMockupName = (productBases) => {
    let mockupsRequired = [];
    productBases.forEach((base) => {
      if (base.autoRender === true) {
        mockupsRequired.push({
          title: base.title,
          mockupName: base.renderAttrName,
        });
      }
    });
    return mockupsRequired;
  };

  error() {
    const { errors } = this.state;
    Modal.error({
      title: "Error message",
      content: `Please input ${errors.map((err) => err.name[0])}`,
    });
  }

  genNameMockups = () => {
    const sampleName = [];
    const { productBases } = this.props;
    productBases.forEach((base) => {
      if (base.autoRender) {
        const changeSKU =
          base.sku.charAt(0).toUpperCase() + base.sku.toLowerCase().slice(1);
        sampleName.push(`Name_${changeSKU}_Red`);
      }
    });
    return sampleName;
  };

  uploadDesign = (values) => {
    this.setState({ visible: true });
    apolloClient
      .mutate({
        mutation: createFile,
        variables: {
          input: {
            source: "aws",
            key: values.key,
            fileName: values.file.name,
            fileMime: values.file.type,
            fileSize: values.file.size,
          },
        },
      })
      .then((res) => {
        this.setState({ visible: false, sampleDesign: res.data.createFile });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    const { productBases, product, productBase } = this.props;
    const {
      pushStore,
      designs,
      library,
      mockups,
      imageVariant,
      loading,
      sampleDesign,
    } = this.state;
    if (!designs) return null;

    return (
      <Container>
        <Form
          onFinishFailed={({ errorFields, values }) => {
            this.setState({ errors: errorFields });
            this.error();
          }}
          ref={this.formRef}
          {...layout}
          onFinish={this.onSubmit}
          initialValues={{
            mockups: mockups ?? [],
            designs: designs ?? [],
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24} md={16}>
              <Card title="Detail" style={{ marginBottom: 20 }}>
                <Form.Item
                  label="Title"
                  name="title"
                  initialValue={
                    productBase
                      ? productBase[0]?.title
                      : product
                      ? product.title
                      : ""
                  }
                  rules={[{ required: true, message: "Please input title!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label="Description"
                  name="description"
                  initialValue={
                    productBase
                      ? productBase[0]?.description
                      : product
                      ? product.description
                      : ""
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input description!",
                    },
                  ]}
                >
                  <Wysiwyg />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24} md={8}>
              <Card title="Categories" style={{ marginBottom: 20 }}>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="categories"
                  initialValue={
                    product
                      ? product.categories.map((category) => category.id)
                      : []
                  }
                >
                  <ProductCategoriesSelect />
                </Form.Item>
              </Card>
              <Card title="Tags">
                <Form.Item
                  style={{ marginBottom: 0 }}
                  name="tags"
                  initialValue={
                    product ? product.tags.map((tag) => tag.id) : []
                  }
                >
                  <TagSelect />
                </Form.Item>
              </Card>
            </Col>
            <Col span={24} md={16}>
              <Form.Item name="variants">
                <ProductVariants
                  product={product}
                  library={library}
                  onChangeMockups={(files) => this.onChangeMockups(files)}
                  productBases={productBases}
                  mockups={mockups ?? []}
                  uploadImageVariant={(file) =>
                    this.setState({
                      imageVariant: [...imageVariant, file],
                      mockups: [...mockups, file],
                    })
                  }
                  imageVariant={imageVariant}
                  setVariant={(data) => this.setState({ variants: data })}
                  handleChangeBaseOrder={(value) =>
                    this.setState({
                      baseIds: value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col style={{ marginTop: 60 }} span={24} md={8}>
              <Card title="Personalized" style={{ marginBottom: 20 }}>
                <Form.Item
                  name="isCustomize"
                  initialValue={product ? product.isCustomize : false}
                  valuePropName="checked"
                >
                  <Checkbox checked={product ? product.isCustomize : false}>
                    This is a personalized product
                  </Checkbox>
                </Form.Item>
              </Card>
              <Card
                title={`Mockups ${this.genNameMockups().length ? "for" : ""} ${
                  productBases &&
                  productBases.map((base) =>
                    base.autoRender
                      ? base.title + ": " + base.renderAttrName
                      : ""
                  )
                }`}
              >
                <Form.Item
                  name="mockups"
                  rules={[
                    {
                      required: this.disabledRequiredMockups(),
                      message: `Please upload mockups for ${this.requiredMockupName(
                        productBases
                      ).map((el) =>
                        el.title.concat(`: ${el.mockupName} attribute`)
                      )} `,
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <ProductUpload
                    sampleName={this.genNameMockups()}
                    onChangeMockups={(files) => this.onChangeMockups(files)}
                    mockups={mockups}
                    deleteImage={(key) => this.deleteImage(key)}
                    imageVariant={imageVariant}
                    onChangeSort={(values) => {
                      console.log("valuesssss", values);
                      this.setState({ mockups: values });
                    }}
                    accept="image/*"
                  />
                </Form.Item>
              </Card>
              <Card title="Sample Design" style={{ marginTop: 20 }}>
                <Form.Item>
                  {product?.file?.id || sampleDesign != null ? (
                    <div>
                      <Upload
                        custom="upload-design"
                        multiple={false}
                        onChange={this.uploadDesign}
                      >
                        <Button>Edit Sample Design</Button>
                      </Upload>
                      {sampleDesign != null ? (
                        <a
                          style={{ marginTop: "10px", display: "block" }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${AWS_CLOUDFRONT_URL}/${sampleDesign.key}`}
                        >
                          Download Sample Design
                        </a>
                      ) : (
                        <a
                          style={{ marginTop: "10px", display: "block" }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${AWS_CLOUDFRONT_URL}/${product.file.key}`}
                        >
                          Download Sample Design
                        </a>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Upload
                        custom="upload-design"
                        multiple={false}
                        onChange={this.uploadDesign}
                      >
                        <Button type="primary">Upload Sample Design</Button>
                      </Upload>
                    </div>
                  )}
                </Form.Item>
              </Card>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.isCustomize !== currentValues.isCustomize
                }
              >
                {({ getFieldValue }) => {
                  return (
                    <Animate transitionName="fade" transitionAppear>
                      {!getFieldValue("isCustomize") && (
                        <div>
                          <Card title="Print Files" style={{ marginTop: 20 }}>
                            <Form.Item
                              style={{ marginBottom: 0 }}
                              name="designs"
                            >
                              <ProductPrintFiles />
                            </Form.Item>
                          </Card>
                        </div>
                      )}
                    </Animate>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Modal
            title="Push to store"
            visible={pushStore}
            onCancel={() => {
              if (!this.state.loading) {
                this.setState({ pushStore: false });
              }
            }}
            footer={null}
          >
            <Query
              query={sites}
              variables={{
                filter: { verified: true, status: true, limit: 200 },
              }}
            >
              {({ data, loading, error }) => {
                if (loading) return <Skeleton />;
                if (error) return <div>{error.toString()}</div>;
                return (
                  <Form.Item {...buttonItemLayout} label="Site" name="siteId">
                    <Select placeholder="Please select a store to push">
                      {data?.sites?.hits.map((c) => (
                        <Select.Option value={c.id} key={c.id}>
                          {c.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Query>
            <Form.Item style={{ textAlign: "right", marginBottom: 0 }}>
              <Button
                disabled={this.state.loading}
                style={{ marginRight: 10 }}
                onClick={() => this.setState({ pushStore: false })}
              >
                Cancel
              </Button>
              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                onClick={() => this.formRef.current.submit()}
              >
                Push
              </Button>
            </Form.Item>
          </Modal>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              onClick={() => this.setState({ pushStore: true })}
            >
              Push to store
            </Button>
            <Button
              loading={loading}
              style={{ marginLeft: 15 }}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
            <Button
              disabled={loading}
              style={{ marginLeft: 15 }}
              onClick={() => history.push("/products")}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default ProductForm;
