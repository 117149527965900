import { gql } from "apollo-boost";

export const UPLOAD_PRINT_FILE = gql`
  mutation uploadPrintFiles($productId: ID!, $designs: [ProductDesignInput!]) {
    uploadPrintFiles(productId: $productId, designs: $designs)
  }
`;

export const UPLOAD_CUSTOMIZE_DESIGN = gql`
  mutation uploadCustomizeDesigns(
    $orderId: ID!
    $printFiles: [OrderCustomDesignInput!]
  ) {
    uploadCustomizeDesigns(orderId: $orderId, printFiles: $printFiles)
  }
`;
