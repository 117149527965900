import React, { Component } from "react";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import { Select } from "antd";

class MappingSelect extends Component {
  state = {
    mapping: [],
  };
  componentDidMount() {
    apolloClient
      .query({
        query: gql`
          query mappings($filter: MappingFilter) {
            mappings(filter: $filter) {
              count
              hits {
                id
                group
                parent_id
                parent {
                  id
                  origin
                  target
                }
                origin
                target
                category
              }
            }
          }
        `,
        variables: {
          filter: {
            group: "Product Type",
          },
        },
      })
      .then((res) => {
        this.setState({
          mapping: res?.data?.mappings?.hits,
        });
      });
  }
  render() {
    const { mapping } = this.state;
    return (
      <div>
        <Select
          placeholder="Select..."
          value={this.props.value ? this.props.value : []}
          onChange={(value) => {
            this.setState({ value }, () => this.props.onChange(value));
          }}
        >
          {mapping.map((el) => (
            <Select.Option key={el.id} value={el.id}>
              {" "}
              {el.origin}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default MappingSelect;
