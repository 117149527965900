import { gql } from "apollo-boost";

export default gql`
  mutation createProductCategory($input: NewProductCategory!) {
    createProductCategory(input: $input) {
      id
      title
      status
      description
      createdAt
    }
  }
`;
