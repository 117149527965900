import React, { Component } from "react";
import products from "../../graphql/queries/products";
import {
  Button,
  Table,
  Skeleton,
  Input,
  Popover,
  Divider,
  Popconfirm,
  notification,
  Avatar,
  Carousel,
  Tag,
  Tooltip,
  Select,
  Form,
  message,
  Menu,
  Dropdown,
  Drawer,
} from "antd";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import _ from "lodash";
import {
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  DownOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import ProductsFilter from "./ProductsFilter";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import { AWS_CLOUDFRONT_URL } from "../../config";
import Modal from "antd/lib/modal/Modal";
import sites from "../../graphql/queries/sites";
import PrintFile from "./PrintFile";
const Container = styled.div`
  .p-header {
    display: flex;
    margin: 20px 0;
    .p-filter {
      flex-grow: 1;
      margin-right: 10px;
    }
  }
  .ant-tag-orange:hover,
  .ant-tag-orange:focus {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591;
  }
  .ant-tag-red:hover,
  .ant-tag-red:focus {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e;
  }
`;
class Products extends Component {
  state = {
    filter: {
      baseIds: null,
      categoryIds: null,
      siteIds: null,
      tagIds: null,
      designStatus: null,
      status: true,
      search: null,
      offset: 0,
      limit: 20,
    },
    categories: null,
    base: null,
    site: null,
    page: 1,
    pushToStore: null,
    loading: false,
    visible: false,
  };

  showDrawer = (record) => {
    this.setState({ visible: record });
  };
  onCloseDrawer = () => {
    this.setState({ visible: false });
  };
  onClose = () => {
    this.setState({
      pushToStore: false,
    });
  };
  onDelete = (id) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteProduct($id: ID!) {
            deleteProduct(id: $id)
          }
        `,
        variables: { id },
      })
      .then(() => {
        notification.success({ message: "Delete success!" });
        this.refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  render() {
    const { filter, page, categories, base, site, visible } = this.state;
    return (
      <Container>
        <div className="p-header">
          <div className="p-filter">
            <Input.Search
              placeholder="Search"
              onSearch={(s) =>
                this.setState({ filter: { ...filter, search: s } })
              }
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...filter,
                      search: null,
                    },
                  });
                }
              }}
              addonBefore={
                <Popover
                  trigger="click"
                  placement="bottomLeft"
                  content={
                    <ProductsFilter
                      value={{ categories: categories, base: base, site: site }}
                      filter={filter}
                      onChange={(v) => {
                        this.setState({
                          filter: {
                            ...filter,
                            categoryIds: v.categories ? v.categories : null,
                            baseIds: v.base ? v.base.map((t) => t) : null,
                            siteIds: v.site ? v.site.map((t) => t) : null,
                          },
                          categories: v.categories,
                          base: v.base,
                          site: v.site,
                        });
                      }}
                    />
                  }
                >
                  <div style={{ cursor: "pointer" }}>
                    Filter <FilterOutlined />
                  </div>
                </Popover>
              }
            />
          </div>
          <Link to="/products/add">
            <Button type="primary">Add product</Button>
          </Link>
        </div>
        <Query
          query={products}
          // fetchPolicy="network-only"
          fetchPolicy="no-cache"
          variables={{
            filter: {
              ...filter,
            },
          }}
        >
          {({ error, data, loading, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const dataSource = data.products.nodes;
            this.refetch = refetch;
            const columns = [
              {
                title: "Title",
                key: "title",
                render: (record) => (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "70px auto",
                      alignItems: "center",
                    }}
                  >
                    <Popover
                      placement="right"
                      content={
                        <div
                          style={{
                            width: 400,
                            //minHeight: 400,
                            position: "relative",
                          }}
                        >
                          <Carousel autoplay style={{ width: 400 }}>
                            {record &&
                              record.mockups &&
                              record.mockups.map((mockup, index) => (
                                <img
                                  style={{ maxWidth: 400, maxHeight: 400 }}
                                  key={index}
                                  alt=""
                                  src={
                                    mockup.file.source === "store"
                                      ? mockup.file.url
                                      : `${AWS_CLOUDFRONT_URL}/${mockup.file.key}`
                                  }
                                />
                              ))}
                          </Carousel>
                        </div>
                      }
                    >
                      {record &&
                        record.mockups[0] &&
                        record.mockups[0].file && (
                          <Avatar
                            size={70}
                            shape="square"
                            src={
                              record.mockups[0].file.source === "store"
                                ? record.mockups[0].file.url
                                : `${AWS_CLOUDFRONT_URL}/${record.mockups[0].file.key}`
                            }
                          />
                        )}
                    </Popover>
                    <p style={{ marginLeft: 10 }}>{record.title}</p>
                  </div>
                ),
                width: 220,
              },
              {
                title: "Sites",
                key: "sites",
                dataIndex: "sites",
                render: (sites) => {
                  if (!sites) return null;
                  if (sites.length === 1) {
                    return (
                      <div>
                        {sites &&
                          sites.map((s) => (
                            <a
                              key={s.id}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={
                                sites ? sites.map((s) => s.permalink) : null
                              }
                            >
                              {s.site?.title}
                            </a>
                          ))}
                      </div>
                    );
                  }
                  if (sites.length > 1) {
                    return (
                      <Dropdown
                        overlay={
                          <Menu>
                            {sites &&
                              sites.map((s) => (
                                <Menu.Item key={s.id}>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={s.permalink}
                                  >
                                    {s.site?.title}
                                  </a>
                                </Menu.Item>
                              ))}
                          </Menu>
                        }
                      >
                        <a
                          href="/#"
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          Stores <DownOutlined />
                        </a>
                      </Dropdown>
                    );
                  }
                },
                width: 150,
              },
              {
                title: "Print file",
                render: (record) => (
                  <div>
                    {record.isCustomize ? (
                      <Tooltip
                        placement="top"
                        title="View/upload designs from order"
                      >
                        <Button
                          style={{
                            border: "1px dashed",
                            width: 100,
                            borderRadius: 3,
                          }}
                          disabled={true}
                        >
                          Custom
                        </Button>
                      </Tooltip>
                    ) : (
                      <span>
                        {record.designStatus === "done" ||
                          record.designStatus === "approved" ? (
                          <Button
                            onClick={() => this.showDrawer(record)}
                            icon={
                              record.isCustomize ? (
                                <CheckOutlined />
                              ) : record.designStatus === "done" ? (
                                ""
                              ) : (
                                <CheckOutlined />
                              )
                            }
                            className={
                              record.isCustomize
                                ? "ant-tag-orange"
                                : record.designStatus === "done"
                                  ? "ant-tag-blue"
                                  : "ant-tag-orange"
                            }
                            style={{
                              width: 100,
                            }}
                          >
                            {record.isCustomize
                              ? "View"
                              : record.designStatus === "done"
                                ? "Approve"
                                : "View"}
                          </Button>
                        ) : (
                          <Button
                            onClick={() => this.showDrawer(record)}
                            icon={<CloudUploadOutlined />}
                            className="ant-tag-red"
                            style={{
                              width: 100,
                            }}
                          >
                            Upload
                          </Button>
                        )}
                      </span>
                    )}
                  </div>
                ),
                width: 200,
              },
              {
                title: "Product Base",
                key: "base",
                dataIndex: "productBases",
                render: (record) => (
                  <div>{record?.map((c) => `${c.title}`).join(",")}</div>
                ),
                width: 200,
              },
              {
                title: "Categories",
                key: "categories",
                dataIndex: "categories",
                render: (categories) => (
                  <div>
                    {categories?.map((c, index) => (
                      <Tag key={index}>{c.title}</Tag>
                    ))}
                  </div>
                ),
                width: 200,
              },
              {
                title: "Tags",
                key: "tags",
                dataIndex: "tags",
                render: (tags) => (
                  <div>
                    {tags?.map((c, index) => (
                      <Tag key={index}>{c.title}</Tag>
                    ))}
                  </div>
                ),
                width: 200,
              },

              {
                title: "Action",
                key: "action",
                align: "right",
                render: (record) => (
                  <div>
                    <Tooltip title="Push to store">
                      <Button
                        size="small"
                        onClick={() => this.setState({ pushToStore: record })}
                      >
                        <CloudUploadOutlined style={{ color: "#30646b" }} />
                      </Button>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Link to={`/products/edit/${record.id}`}>
                      <Button size="small">
                        <EditOutlined />
                      </Button>
                    </Link>
                    <Divider type="vertical" />
                    <Popconfirm
                      title={
                        <span>
                          Are you sure to delete product? <br />
                          This action will delete all related product on store
                          and not able to be revoked.
                        </span>
                      }
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => this.onDelete(record.id)}
                    >
                      <Button style={{ color: "red" }} size="small">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </div>
                ),
                width: 200,
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <Table
                columns={columns}
                scroll={{ x: tableWidth }}
                rowKey={(index, key) => key}
                dataSource={dataSource}
                pagination={{
                  current: page,
                  total: data.products.total,
                  pageSize: filter.limit,
                  onChange: (page, pageSize) => {
                    this.setState({
                      page: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * pageSize,
                      },
                    });
                  },
                }}
              />
            );
          }}
        </Query>
        <Modal
          visible={!!this.state.pushToStore}
          title="Push to store"
          onCancel={() => {
            if (!this.state.loading) {
              this.onClose();
            }
          }}
          footer={null}
        >
          <Query
            query={sites}
            variables={{ filter: { verified: true, status: true, limit: 200 } }}
          >
            {({ data, loading, error }) => {
              if (loading) return <Skeleton />;
              if (error) return <div>{error.toString()}</div>;
              return (
                <div>
                  <Form
                    onFinish={(values) => {
                      this.setState({ loading: 5 });
                      apolloClient
                        .mutate({
                          mutation: gql`
                            mutation pushProductToSites(
                              $productIds: [ID!]!
                              $siteId: ID!
                            ) {
                              pushProductToSites(
                                productIds: $productIds
                                siteId: $siteId
                              )
                            }
                          `,
                          variables: {
                            productIds: [this.state.pushToStore?.id],
                            siteId: values.siteId,
                          },
                        })
                        .then(() => {
                          message.success("Push to store success!");
                          this.onClose();
                          this.setState({ loading: false });
                          this.refetch();
                        })
                        .catch((err) => {
                          notification.error({ message: err.message });
                          this.setState({ loading: false });
                        });
                    }}
                  >
                    <Form.Item
                      label="Site"
                      name="siteId"
                      rules={[{ required: true, message: "Site is required!" }]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Please select a store to push"
                      >
                        {data?.sites?.hits.map((c) => (
                          <Select.Option value={c.id} key={c.id}>
                            {c.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right", marginBottom: 0 }}>
                      <Button
                        disabled={this.state.loading}
                        onClick={this.onClose}
                        style={{ marginRight: 10 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={this.state.loading}
                        type="primary"
                        htmlType="submit"
                      >
                        Push
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              );
            }}
          </Query>
        </Modal>
        <Drawer
          width="600"
          title="Upload Designs"
          placement="right"
          closable={true}
          onClose={this.onCloseDrawer}
          visible={typeof visible === "object" ? true : false}
          key={visible.id}
        >
          <PrintFile
            id={visible.id}
            onClose={this.onCloseDrawer}
            refetch={this.refetch}
          />
        </Drawer>
      </Container>
    );
  }
}

export default Products;
