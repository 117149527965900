import React, { Component } from "react";
import CategoryList from "../../src/components/products/ProductCategories";
import { PageHeader } from "antd";
class ProductCategories extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Product Categories"
          onBack={() => this.props.history.goBack()}
        />
        <CategoryList />
      </div>
    );
  }
}

export default ProductCategories;
