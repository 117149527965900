import React, { Component } from "react";
import { Avatar, Dropdown, Menu, Row, Col, message } from "antd";
import styled from "styled-components";
import { AppContext } from "../context";
import Logo from "../assets/images/logo.png";
import _ from "lodash";
import { apolloClient } from "../apollo-client";
import history from "../history";
import { TOKEN_NAME } from "../config";
import Modal from "antd/lib/modal/Modal";
import ChangePassword from "./ChangePassword";
import { LOGOUT_ICTEES_APP } from "../graphql/mutates/loginIctees";

const Container = styled.div`
  display: flex;
  height: 64px;
  //border-bottom: 1px solid #001529;
  padding: 0 20px;
  .user-profile {
    float: right;
    color: white;
  }
  .drop-down {
    width: 100%;
  }
`;
class NavBar extends Component {
  state = { visible: false };
  logout = () => {
    apolloClient
      .mutate({
        mutation: LOGOUT_ICTEES_APP,
      })
      .then((res) => {
        apolloClient.resetStore();
        localStorage.setItem(TOKEN_NAME, "");
        history.push("/login");
      }).catch((err) => {
        apolloClient.resetStore();
        localStorage.setItem(TOKEN_NAME, "");
        history.push("/login");
      });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const menu = (
      <Menu style={{ maxWidth: 180 }}>
        <Menu.Item>
          <a
            target="_blank"
            href="/#"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ visible: true });
            }}
          >
            Change Password
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.logout}>
          Logout
        </Menu.Item>
      </Menu>
    );
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <Container>
            <Row type="flex" style={{ width: "100%", alignItems: "center" }}>
              <Col
                span={14}
                lg={21}
                style={{ display: "flex", alignItems: "center" }}
              >
                <div onClick={this.toggle}>
                  <img
                    style={{ maxHeight: 50 }}
                    alt="logo"
                    src={Logo}
                    onClick={() => this.props.onClick()}
                  />
                </div>
              </Col>
              <Col span={10} lg={3}>
                <div className="drop-down">
                  <Dropdown
                    overlay={menu}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <div
                      onClick={(e) => e.defaultPrevented}
                      className="user-profile"
                    >
                      <Avatar>
                        {currentUser &&
                          _.first(currentUser.firstName) +
                          //currentUser.firstName.charAt(0) +
                          //currentUser.lastName.charAt(0)
                          _.first(currentUser.lastName)}
                      </Avatar>
                      &nbsp; &nbsp;
                      {currentUser &&
                        currentUser.firstName + " " + currentUser.lastName}
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <Modal
              title="Change Password"
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={null}
            >
              <ChangePassword
                onCancel={this.handleCancel}
                id={currentUser?.id}
              />
            </Modal>
          </Container>
        )}
      </AppContext.Consumer>
    );
  }
}

export default NavBar;
