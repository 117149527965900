import { gql } from "apollo-boost";

export default gql`
  mutation createExportTemplate($input: ExportTemplateInput!) {
    createExportTemplate(input: $input) {
      id
      name
      data
      createdAt
    }
  }
`;
