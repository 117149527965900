import React, { Component } from "react";
import { Upload, message, notification } from "antd";
import { Mutation } from "react-apollo";
import signedUrl from "../../graphql/mutates/signedUrl";
import axios from "axios";
import { AWS_CLOUDFRONT_URL } from "../../config";
import { PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import styled from "styled-components";
import { apolloClient } from "../../apollo-client";
import createFile from "../../graphql/mutates/createFile";

const Container = styled.div`
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    background: #f0f0f0;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 5px 5px;
  }
`;
class UploadImage extends Component {
  uploadAction = (signedUrl, file) => {
    signedUrl({
      variables: {
        filename: file.name,
        filemine: file.type,
      },
    })
      .then((res) => {
        var key = res.data.createSignedUrl.key;
        axios
          .put(res.data.createSignedUrl.url, file, {
            onUploadProgress: (e) => {
              if (this.props.changePercent) {
                this.props.changePercent(
                  Math.round((e.loaded / e.total) * 100)
                );
              }
            },
            headers: { "Content-Type": file.type },
          })
          .then((res) => {
            apolloClient
              .mutate({
                mutation: createFile,
                variables: {
                  input: {
                    source: "aws",
                    key,
                    fileName: file.name,
                    fileMime: file.type,
                    fileSize: file.size,
                  },
                },
              })
              .then((res) => {
                if (this.props.onChangeMockups) {
                  this.props.onChangeMockups({
                    fileMime: file.type,
                    fileName: file.name,
                    fileSize: file.size,
                    imageId: res.data.createFile.id,
                    key,
                    sku: "",
                    source: "aws",
                  });
                }
              })
              .catch((err) => notification.error({ message: err.message }));
          })
          .catch((err) => {
            message.error(err.message);
          });
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  render() {
    const { custom, multiple, disabled, imageKey, imageWidth } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Container style={{ pointerEvents: custom ? "none" : "inherit" }}>
        <Mutation mutation={signedUrl}>
          {(signedUrl, { data, loading }) => (
            <Upload.Dragger
              disabled={disabled ? disabled : false}
              {..._.omit(this.props, ["onChange"])}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={(file) => {
                this.uploadAction(signedUrl, file);
              }}
              accept={this.props.accept ? this.props.accept : "all"}
              multiple={multiple || false}
              beforeUpload={() => false}
            >
              {imageKey ? (
                <img
                  src={`${AWS_CLOUDFRONT_URL}/${imageKey}`}
                  style={{
                    width: imageWidth ?? 100,
                    height: imageWidth ?? 100,
                    objectFit: "contain",
                  }}
                  alt={imageKey}
                />
              ) : (
                uploadButton
              )}
            </Upload.Dragger>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default UploadImage;
