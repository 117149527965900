import React, { Component } from "react";
import ProductBaseList from "../components/product_base/ProductBases";
import { PageHeader } from "antd";

class ProductBases extends Component {
  render() {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PageHeader
            title="Product Bases"
            onBack={() => this.props.history.goBack()}
          />
        </div>

        <ProductBaseList />
      </div>
    );
  }
}

export default ProductBases;
