import React, { Component } from "react";
import {
  Table,
  Button,
  Input,
  Skeleton,
  Divider,
  Popconfirm,
  notification,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { Link } from "react-router-dom";
import exportTemplates from "../../graphql/queries/exportTemplates";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import { apolloClient } from "../../apollo-client";
import Modal from "antd/lib/modal/Modal";
import ViewTemplates from "./ViewTemplates";
import _ from "lodash";
const Container = styled.div`
  .p-filter {
    display: flex;
    margin: 20px 0;
    .p-search {
      flex-grow: 1;
      margin-right: 10px;
      marging-bottom: 20px;
    }
  }
`;
class ExportTemplates extends Component {
  state = {
    filter: {
      name: "",
      limit: 20,
      offset: 0,
    },
    page: 1,
    visible: false,
  };
  showModal = (record) => {
    this.setState({ visible: record });
  };
  onDelete = (id, refetch) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteExportTemplate($id: ID!) {
            deleteExportTemplate(id: $id)
          }
        `,
        variables: {
          id,
        },
      })
      .then(() => {
        notification.success({ message: "Delete template success!" });
        this.refetch();
      })
      .catch((err) => notification.error({ message: err.message }));
  };
  render() {
    const { filter, page } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <div className="p-search">
            <Input.Search
              placeholder="Search..."
              onSearch={(s) =>
                this.setState({ filter: { ...filter, name: s } })
              }
              onChange={(e) => {
                if (!e.target.value) {
                  this.setState({
                    filter: {
                      ...filter,
                      name: null,
                    },
                  });
                }
              }}
            />
          </div>
          <Link to="/export-templates/add">
            <Button type="primary">
              <PlusOutlined />
              Add export template
            </Button>
          </Link>
        </div>
        <Query
          query={exportTemplates}
          variables={{ filter: { ...filter } }}
          fetchPolicy="network-only"
        >
          {({ loading, data, error, refetch }) => {
            window.refetchTemplates = refetch;
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const dataSource = data.exportTemplates.nodes;
            this.refetch = refetch;
            const columns = [
              {
                title: "Template Name",
                key: "name",
                dataIndex: "name",
                width: 500,
              },
              {
                title: "Action",
                key: "action",
                width: 220,
                align: "right",
                fixed: "right",
                render: (record) => (
                  <div>
                    <Tooltip title="View template">
                      <Button
                        style={{ color: "orange" }}
                        onClick={() => this.showModal(record)}
                        size="small"
                      >
                        <EyeOutlined />
                      </Button>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title="Clone template">
                      <Link to={`/export-templates/clone/${record.id}`}>
                        <Button size="small">
                          <CopyOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title="Edit template">
                      <Link to={`/export-templates/edit/${record.id}`}>
                        <Button size="small">
                          <EditOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Are you sure delete template?"
                      okText="Yes"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                      okButtonProps={{type:"danger"}}
                      cancelText="No"
                      onConfirm={() => {
                        this.onDelete(record.id);
                        refetch();
                      }}
                    >
                      <Tooltip title="Delete template">
                        <Button style={{ color: "red" }} size="small">
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </div>
                ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <Table
                rowKey={(index, key) => key}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: tableWidth }}
                pagination={{
                  current: page,
                  total: data.exportTemplates.total,
                  pageSize: filter.limit,
                  onChange: (page, pageSize) => {
                    this.setState({
                      page: page,
                      filter: {
                        ...filter,
                        offset: (page - 1) * pageSize,
                      },
                    });
                  },
                }}
              />
            );
          }}
        </Query>
        <Modal
          width={800}
          footer={null}
          visible={this.state.visible}
          title="View export template"
          onCancel={() => this.setState({ visible: false })}
        >
          <ViewTemplates data={this.state.visible.data} />
        </Modal>
      </Container>
    );
  }
}

export default ExportTemplates;
