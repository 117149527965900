import React from "react";
import { useMutation } from "react-apollo";
import { Upload, Row, Col, message } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import signedUrl from "../graphql/mutates/signedUrl";
import { AWS_CLOUDFRONT_URL } from "../config";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Container = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: ${(props) => props.setWidth ?? "100%"};
    height: ${(props) => props.setHeight ?? "auto"};
    min-height: ${(props) => props.setHeight ?? "300px"};
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
`;

const OrderUploadPrintFile = (props) => {
  const {
    onChange,
    imageKey,
    setHeight,
    setWidth,
    accept,
    loadingFiles,
    beforeFile,
  } = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFilesList] = useState([]);
  const [createSignedMutate] = useMutation(signedUrl);

  useEffect(() => {
    uploadPrintFiles(fileList).then((newFileList) => {
      if (fileList.length) {
        onChange(newFileList);
        setFilesList([]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const uploadPrintFiles = async (fileList) => {
    let newFileList = [...fileList];
    newFileList = await Promise.all(
      newFileList.map(async (file) => {
        setLoading(true);
        const responseFile = await new Promise((resolve) => {
          createSignedMutate({
            variables: {
              filename: file.name,
              filemine: file.type,
            },
          }).then((res) => {
            var key = res.data.createSignedUrl.key;
            axios
              .put(res.data.createSignedUrl.url, file.originFileObj, {
                headers: { "Content-Type": file.type },
              })
              .then((res2) => {
                // setLoading(false);
                resolve({ ...file, key });
              })
              .catch((err) => {
                // setLoading(false);
                message.error(err.message);
              });
          });
        });
        return responseFile;
      })
    ).then((result) => result);
    setLoading(false);
    return newFileList;
  };
  const uploadButton = (
    <div>
      {loading || loadingFiles ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        beforeFile.push({
          ...file,
          width: width,
          height: height,
          name: file.name,
        });
      });
    });
  };
  return (
    <Container setWidth={setWidth} setHeight={setHeight}>
      <Row type="flex" gutter={10}>
        <Col style={{ width: "100%" }} span={24}>
          <Upload
            listType="picture-card"
            showUploadList={false}
            action={() => {}}
            multiple={true}
            onChange={async ({ file, fileList }) => {
              beforeUpload(file);
              await setFilesList(fileList);
            }}
            accept={accept ?? "all"}
            fileList={fileList}
            beforeUpload={() => false}
          >
            {imageKey ? (
              <img
                src={`${AWS_CLOUDFRONT_URL}/${imageKey}`}
                style={{
                  width: setWidth ?? "100%",
                  height: setHeight ?? "auto",
                  objectFit: "contain",
                  background: "darkgray",
                }}
                alt={imageKey}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Col>
      </Row>
    </Container>
  );
};
export default OrderUploadPrintFile;
