import { gql } from "apollo-boost";

export default gql`
  mutation updateExportTemplate($id: ID!, $input: ExportTemplateInput!) {
    updateExportTemplate(id: $id, input: $input) {
      id
      name
      data
      createdAt
    }
  }
`;
