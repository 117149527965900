import React, { Component } from "react";
import { PageHeader, Skeleton } from "antd";
import ProductForm from "./ProductForm";
import { Query } from "react-apollo";
import productById from "../../graphql/queries/productById";

class EditProduct extends Component {
  formatProductBase = (productBases, variants, productBaseOrders) => {
    productBases.forEach((productBase) => {
      productBaseOrders.forEach((baseOrder) => {
        variants.forEach((variant) => {
          if (
            productBase.id === variant.baseId &&
            productBase.id === baseOrder.productBaseId
          ) {
            productBase.variants.push(variant);
            productBase.ordering = baseOrder.ordering;
          }
        });
      });
    });
    return productBases;
  };

  render() {
    return (
      <div>
        <PageHeader
          title="Edit product"
          onBack={() => this.props.history.goBack()}
        />
        <Query
          query={productById}
          variables={{ id: this.props.match.params.id }}
          fetchPolicy="no-cache"
        >
          {({ data, loading, error }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const product = data.product;
            const { productBaseOrders } = product;
            return (
              <ProductForm
                id={this.props.match.params.id}
                productBases={this.formatProductBase(
                  data?.product?.productBases,
                  data?.product?.variants,
                  productBaseOrders
                )}
                product={product}
                designs={
                  data?.product?.designs
                    ? data?.product.designs.map((ds) => {
                        return {
                          ...ds.baseDesign,
                          file: ds.file,
                          fileId: ds.fileId,
                          baseDesignId: ds.baseDesign.id,
                          id: ds.id,
                          baseId: ds.baseDesign.baseId,
                          title: data.product.productBases.find(
                            (item) => item.id === ds.baseDesign.baseId
                          )?.title,
                        };
                      })
                    : []
                }
              />
            );
          }}
        </Query>
      </div>
    );
  }
}

export default EditProduct;
