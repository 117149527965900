import React, { Component } from "react";
import _ from "lodash";
import {
  Avatar,
  Form,
  Select,
  Skeleton,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Popconfirm,
} from "antd";
import productBases from "../../graphql/queries/productBases";
import { Query, Mutation } from "react-apollo";
import pullShopifyProduct from "../../graphql/queries/orders/pullShopifyProduct";
import mapOrderMutate from "../../graphql/mutates/mapOrder";
import { omit } from "lodash";
import defaultImage from "../../assets/images/default.png";

class VariantSelect extends Component {
  render() {
    const { productType } = this.props;
    if (!productType) return null;
    const { variants } = productType || [];

    return (
      <Select
        allowClear={true}
        {...omit(this.props, ["productType"])}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {variants.map((variant) => {
          let optionName = variant.attributes
            .map((attr) => `${attr.name}: ${attr.option}`)
            .join(", ");

          return (
            <Select.Option value={variant.id} key={variant.id}>
              {optionName}
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}

class Mapshopify extends Component {
  state = {
    selectedProductTypeId: null,
    showAllVariants: false,
    loading: false,
    isPersonalized: false,
    variantTitle: null
  };
  formRef = React.createRef();

  onFinish = (mapOrder, values) => {
    this.setState({ loading: true });
    const { order } = this.props;
    var variants_map = Object.keys(values)
      .filter((key) => key.indexOf("variant_id_") === 0 && values[key])
      .map((key) => {
        let arraykey = key.split("_");
        return {
          variant_id: values[key],
          origin_id: arraykey[2],
          sku: arraykey[5],
          absolute: key.indexOf("_true") !== -1,
        };
      });

    mapOrder({
      variables: {
        order_id: order.id,
        variants_map: variants_map,
        is_personalized: this.state.isPersonalized,
      },
    })
      .then((res) => {
        notification.success({
          message: "Order has been mapped",
        });
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        notification.error({
          message: _.get(err, "[0].message"),
        });
        this.setState({ loading: false });
      });
    console.log("Received values of form: ", values);
  };
  componentDidMount() {
    const { order } = this.props;
    this.setState({ isPersonalized: order.product.isCustomize, variantTitle: order.variant_title });
  }
  render() {
    const {
      selectedProductTypeId,
      showAllVariants,
      isPersonalized,
    } = this.state;
    const { order } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Checkbox
            onChange={() => this.setState({ isPersonalized: !isPersonalized })}
            checked={isPersonalized}
            disabled={order.product.isCustomize}
          >
            <span style={{ color: "red" }}>Personalized?</span>
          </Checkbox>
        </div>
        <div style={{ marginBottom: 15 }}>
          <Avatar
            src={
              order.product.mockups[0]
                ? order.product.mockups[0].file.url
                : defaultImage
            }
            shape="square"
            size={64}
            style={{ marginRight: 5 }}
          />
          {/* {_.get(order, "product.title")} */}
          <div style={{ margin: "auto 0" }}>
            <p style={{ marginBottom: 0 }}>{_.get(order, "product.title")}</p>
            {this.state.variantTitle && <p style={{ marginBottom: 0, color: "red", fontSize: "14px" }}>{this.state.variantTitle}</p>}
          </div>
        </div>
        <Query
          query={productBases}
          fetchPolicy="network-only"
          variables={{
            filter: {
              autoRender: false,
              limit: 500,
            },
          }}
        >
          {({ error, loading, data }) => {
            if (loading) return <Skeleton loading={true} />;
            if (error) return <div>{error.toString()}</div>;
            if (data) {
              var productTypes = data.productBases.nodes;
              return (
                <Mutation
                  mutation={mapOrderMutate}
                  onCompleted={() => {
                    this.props.refetch();
                  }}
                >
                  {(mapOrder, res) => (
                    <div>
                      <Form
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={(values) => {
                          this.onFinish(mapOrder, values);
                        }}
                      >
                        <Form.Item label="Product Type" name="product_type_id">
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            onChange={(selectedProductTypeId) => {
                              this.setState({ selectedProductTypeId });
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {productTypes.map((type) => (
                              <Select.Option key={type.id}>
                                {type.title}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {selectedProductTypeId && (
                          <Form.Item label="Variants">
                            <div>
                              <Query
                                query={pullShopifyProduct}
                                fetchPolicy="network-only"
                                variables={{
                                  orderId: order.id,
                                }}
                              >
                                {({ error, loading, data }) => {
                                  console.log("data", data?.pullShopifyProduct?.variants);
                                  if (loading)
                                    return <Skeleton loading={true} />;
                                  if (error)
                                    return <div>{error.toString()}</div>;
                                  if (data) {
                                    let variants =
                                      data.pullShopifyProduct.variants;
                                    return (
                                      <div>
                                        {variants?.map((variant) => {
                                          var absolute = "false";
                                          let a = productTypes.find(
                                            (type) =>
                                              type.id === selectedProductTypeId
                                          );
                                          let attrs = variant.title?.split(" / ")
                                          if (attrs.length > 2) {
                                            attrs.shift()
                                            //console.log("attrs", attrs)
                                          }

                                          let title = attrs?.sort().toString();

                                          let variantIdOption = a.variants.map(
                                            (item) => {
                                              //let title = "";
                                              let option = []
                                              for (
                                                var i = 0;
                                                i < item.attributes.length;
                                                i++
                                              ) {
                                                // let a = i > 0 ? " / " : "";
                                                // title =
                                                //   title +
                                                //   a +
                                                //   item.attributes[i].option;
                                                option.push(item.attributes[i].option)
                                              }

                                              return {
                                                id: item.id,
                                                title: option?.sort().toString(),
                                              };
                                            }
                                          );

                                          let idVariant = variantIdOption.find(
                                            (item) => item.title?.toLowerCase() === title?.toLowerCase()
                                          );
                                          let key = `variant_id_${variant.originId}_${absolute}_sku_${variant.sku}`;
                                          let obj = {};
                                          obj[key] = idVariant?.id;
                                          this.formRef.current.setFieldsValue(
                                            obj
                                          );
                                          if (
                                            !showAllVariants &&
                                            !variant.isCurrent
                                          )
                                            return null;
                                          return (
                                            <Row
                                              type="flex"
                                              gutter={20}
                                              key={variant.originId}
                                              style={{
                                                // alignItems: "center",
                                                borderBottom:
                                                  "1px solid #f5f5f5",
                                                paddingTop: 10,
                                                color: variant.isCurrent
                                                  ? "red"
                                                  : "unset",
                                              }}
                                            >
                                              <Col span={10}>
                                                <div
                                                  style={{
                                                    lineHeight: 1.6,
                                                  }}
                                                >
                                                  {variant.title}
                                                </div>
                                              </Col>
                                              <Col span={14}>
                                                <Form.Item
                                                  name={`variant_id_${variant.originId}_${absolute}_sku_${variant.sku}`}
                                                  rules={[
                                                    {
                                                      required:
                                                        variant.isCurrent,
                                                      message:
                                                        "This field is required",
                                                    },
                                                  ]}
                                                  className="form-select"
                                                >
                                                  <VariantSelect
                                                    title={variant.title}
                                                    productType={productTypes.find(
                                                      (type) =>
                                                        type.id ===
                                                        selectedProductTypeId
                                                    )}
                                                    variant={variant}
                                                    absolute={absolute}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                          );
                                        })}
                                        <div>
                                          <Checkbox
                                            style={{
                                              marginTop: 10,
                                            }}
                                            onChange={() =>
                                              this.setState({
                                                showAllVariants: !showAllVariants,
                                              })
                                            }
                                          >
                                            Show all variants
                                          </Checkbox>
                                        </div>
                                      </div>
                                    );
                                  }
                                }}
                              </Query>
                            </div>
                          </Form.Item>
                        )}
                        {isPersonalized ? (
                          <Button
                            loading={this.state.loading}
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        ) : (
                          <Popconfirm
                            title="Are you sure it isn't a personalized product?"
                            onConfirm={() => this.formRef.current.submit()}
                            onCancel={() => console.log("cancel")}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              loading={this.state.loading}
                              type="primary"
                              htmlType="submit"
                            >
                              Submit
                            </Button>
                          </Popconfirm>
                        )}
                      </Form>
                    </div>
                  )}
                </Mutation>
              );
            }
          }}
        </Query>
      </div>
    );
  }
}
export default Mapshopify;
