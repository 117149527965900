import React, { Component } from "react";
import styled from "styled-components";
import { Button, Popover, Form, message, Tabs } from "antd";
import createFile from "../../graphql/mutates/createFile";
import { apolloClient } from "../../apollo-client";
import uploadPrintFile from "../../graphql/mutates/uploadPrintFile";
import OrderUploadPrintFile from "../OrderUploadPrintFile";
import _ from "lodash";

const Container = styled.div`
  width: 100%;
`;

const { TabPane } = Tabs;

export default class UploadPNG extends Component {
  state = {
    visible: false,
    name: null,
    fileBack: null,
    fileFront: null,
    loadingFiles: false,
    beforeFile: [],
  };

  formRef = React.createRef();

  hide = () => {
    this.formRef.current.resetFields();
    this.setState({
      visible: false,
      fileBack: null,
      fileFront: null,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  getFile = async (values) => {
    const { fileBackName, fileFrontName } = this.props;

    let orderAdd = await Promise.all(
      values.map((file) => {
        if (
          [
            fileBackName.toUpperCase().substring(0, fileBackName.length - 4),
            fileFrontName.toUpperCase().substring(0, fileFrontName.length - 4),
          ].includes(file.name.toUpperCase().substring(0, file.name.length - 4))
        ) {
          return new Promise((resol) => {
            apolloClient
              .mutate({
                mutation: createFile,
                variables: {
                  input: {
                    source: "aws",
                    key: file.key,
                    fileName: file.name,
                    fileMime: file.type,
                    fileSize: file.size,
                    genThumb: true,
                    width: this.state.beforeFile.find(
                      (item) => item.name === file.name
                    )?.width,
                    height: this.state.beforeFile.find(
                      (item) => item.name === file.name
                    )?.height,
                  },
                },
              })
              .then((res) => {
                if (
                  file.name
                    .toUpperCase()
                    .substring(0, fileBackName.length - 4) ===
                  fileFrontName
                    .toUpperCase()
                    .substring(0, fileBackName.length - 4)
                ) {
                  this.setState({
                    fileFront: { ...res.data.createFile, side: "front" },
                  });
                } else if (
                  file.name
                    .toUpperCase()
                    .substring(0, fileBackName.length - 4) ===
                  fileBackName
                    .toUpperCase()
                    .substring(0, fileBackName.length - 4)
                ) {
                  this.setState({
                    fileBack: { ...res.data.createFile, side: "back" },
                  });
                }
                this.setState({ loadingFiles: false });
              })
              .catch((err) => {
                message.error(err.message);
              });
          });
        } else {
          message.error(file.name);
        }
        return false;
      })
    ).then((fileResponses) => {
      this.setState({ loadingFiles: false, beforeFile: [] });
      return fileResponses;
    });
    this.setState({ loadingFiles: false, beforeFile: [] });
    return orderAdd;
  };

  onFinish = () => {
    const { fileFront, fileBack } = this.state;
    apolloClient
      .mutate({
        mutation: uploadPrintFile,
        variables: {
          printFiles: _.compact([fileBack, fileFront]).map((item) => {
            return {
              fileId: item.id,
              side: item.side,
            };
          }),
          orderIds: [this.props.orderId],
        },
      })
      .then(() => {
        message.success("Upload printfile successfully");
        this.formRef.current.resetFields();
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  validatorName = async (value, fileName) => {
    if (value && value.length) {
      let newValue = [...value];
      newValue = await Promise.all(
        newValue.map(async (file) => {
          const responseFile = await new Promise((resolve) => {
            if (file.name.toUpperCase() === fileName.toUpperCase()) {
              return resolve(true);
            }
            return resolve(false);
          });
          return responseFile;
        })
      );
      return newValue;
    }
  };

  render() {
    const {
      visible,
      fileFront,
      fileBack,
      loadingFiles,
      beforeFile,
    } = this.state;
    const {
      color,
      // fileBackName,
      // fileFrontName,
      tabs,
      setWidth,
      setHeight,
      size,
    } = this.props;
    const content = (
      <Form
        ref={this.formRef}
        className="form-create-task"
        style={{ width: setWidth ?? 200 }}
        onFinish={this.onFinish}
      >
        <Tabs defaultActiveKey="front" tabBarExtraContent={<div>{size}</div>}>
          <TabPane
            tab={
              fileFront?.width
                ? `Front (${fileFront.width} X ${fileFront.height})`
                : "Front"
            }
            key="front"
          >
            <Form.Item
              name="front"
              initialValue={fileFront}
            // rules={[
            //   {
            //     validator: (_, value) =>
            //       this.validatorName(value, fileFrontName).then((result) => {
            //         if ((result && result.includes(true)) || fileFront) {
            //           return Promise.resolve();
            //         } else {
            //           return Promise.reject(
            //             "Invalid name. Name must have type: " + fileFrontName
            //           );
            //         }
            //       }),
            //   },
            // ]}
            >
              <OrderUploadPrintFile
                beforeFile={beforeFile}
                onChange={(fileUpload) => {
                  this.setState({ loadingFiles: true });
                  this.formRef.current.setFieldsValue({
                    back: fileBack,
                  });
                  this.getFile(fileUpload);
                }}
                setHeight={setHeight}
                setWidth={setWidth}
                multiple={true}
                accept=".png,.jpg"
                loadingFiles={loadingFiles}
                imageKey={
                  //fileFront ? fileFront.thumbnail ?? fileFront.key : null
                  fileFront ? fileFront.key : null
                }
              />
            </Form.Item>
          </TabPane>
          <TabPane
            tab={
              fileFront?.width
                ? `Back (${fileFront.width} X ${fileFront.height})`
                : "Back"
            }
            key="back"
          >
            <Form.Item
              name="back"
              initialValue={fileBack}
            // rules={[
            //   {
            //     validator: (_, value) =>
            //       this.validatorName(value, fileBackName).then((result) => {
            //         if ((result && result.includes(true)) || fileBack) {
            //           return Promise.resolve();
            //         } else {
            //           return Promise.reject(
            //             "Invalid name. Name must have type: " + fileBackName
            //           );
            //         }
            //       }),
            //   },
            // ]}
            >
              <OrderUploadPrintFile
                beforeFile={beforeFile}
                onChange={(fileUpload) => {
                  this.setState({ loadingFiles: true });
                  this.formRef.current.setFieldsValue({
                    front: fileFront ?? null,
                  });
                  this.getFile(fileUpload);
                }}
                setHeight={setHeight}
                setWidth={setWidth}
                multiple={true}
                loadingFiles={loadingFiles}
                accept=".png,.jpg"
                //imageKey={fileBack ? fileBack.thumbnail ?? fileBack.key : null}
                imageKey={fileBack ? fileBack.key : null}
              />
            </Form.Item>
          </TabPane>
        </Tabs>
        <Form.Item>
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            htmlType="submit"
            disabled={!(fileBack || fileFront)}
          >
            Upload PNG
          </Button>
          <Button onClick={this.hide}>Cancel</Button>
        </Form.Item>
      </Form>
    );
    return (
      <Container className="upload-png">
        <div>
          {tabs ? (
            <div style={{ background: color, padding: 10 }}>{content}</div>
          ) : (
            <Popover
              content={content}
              trigger="click"
              visible={visible}
              placement="bottom"
              onVisibleChange={this.handleVisibleChange}
            >
              <a href="/#" style={{ color: color ? color : "#1890ff" }}>
                Upload PNG
              </a>
            </Popover>
          )}
        </div>
      </Container>
    );
  }
}
