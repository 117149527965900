import React, { Component } from "react";
import styled from "styled-components";
import { Button, Popover, Form, message, Tabs } from "antd";
import createFile from "../../graphql/mutates/createFile";
import { apolloClient } from "../../apollo-client";
import OrderUploadPrintFile from "../OrderUploadPrintFile";
import { UPLOAD_CUSTOMIZE_DESIGN } from "../../graphql/mutates/uploadPrintFiles";
import bulkUploadOrderPersonalizedPrintFiles from "../../graphql/mutates/bulkUploadOrderPersonalizedPrintFiles";
import _ from "lodash";

const Container = styled.div`
  width: 100%;
`;

const { TabPane } = Tabs;

export default class UploadPNGPersonalized extends Component {
  state = {
    visible: false,
    renderFileNameFromPrintFiles: this.props.renderFileNameFromPrintFiles ?? [],
    loadingFiles: false,
    beforeFile: [],
  };

  formRef = React.createRef();

  hide = () => {
    this.formRef.current.resetFields();
    const { renderFileNameFromPrintFiles } = this.state;
    this.setState({
      visible: false,
      renderFileNameFromPrintFiles: renderFileNameFromPrintFiles.map((item) => {
        return {
          fileName: item.fileName,
          key: item.key,
          id: item.id,
          file: {},
        };
      }),
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  getFile = async (values) => {
    let newRenderFileNameFromPrintFiles = [
      ...this.state.renderFileNameFromPrintFiles,
    ];
    let orderAdd = await Promise.all(
      values.map((file) => {
        let fileArray = file.name.split(".");
        let fileName = fileArray.slice(0, fileArray.length - 1).toString();
        if (
          newRenderFileNameFromPrintFiles
            .map((item) => item.fileName.toUpperCase())
            .includes(fileName.toUpperCase())
        ) {
          return new Promise((resol) => {
            apolloClient
              .mutate({
                mutation: createFile,
                variables: {
                  input: {
                    source: "aws",
                    key: file.key,
                    fileName: file.name,
                    fileMime: file.type,
                    fileSize: file.size,
                    genThumb: true,
                    width: this.state.beforeFile.find(
                      (item) => item.name === file.name
                    )?.width,
                    height: this.state.beforeFile.find(
                      (item) => item.name === file.name
                    )?.height,
                  },
                },
              })
              .then((res) => {
                newRenderFileNameFromPrintFiles = newRenderFileNameFromPrintFiles.map(
                  (item) => {
                    const newItem = { ...item };
                    if (
                      newItem.fileName.toUpperCase() === fileName.toUpperCase()
                      // newItem.fileName.toUpperCase() === file.name.toUpperCase()
                    ) {
                      return {
                        ...newItem,
                        file: res.data.createFile,
                      };
                    }
                    return newItem;
                  }
                );
                this.setState({
                  renderFileNameFromPrintFiles: newRenderFileNameFromPrintFiles,
                  loadingFiles: false,
                });
              })
              .catch((err) => {
                message.error(err.message);
                this.setState({ loadingFiles: false });
              });
          });
        } else {
          message.error(file.name);
        }
        message.error(file.name);
        return false;
      })
    ).then((fileResponses) => {
      this.setState({ loadingFiles: false });
      return fileResponses;
    });
    this.setState({ loadingFiles: false, beforeFile: [] });
    return orderAdd;
  };

  onFinish = () => {
    const { renderFileNameFromPrintFiles } = this.state;
    const { status } = this.props;
    const newPrintFiles = renderFileNameFromPrintFiles.filter(
      (item) => item.file
    );
    const printFileUpload = newPrintFiles.filter(
      (printFile) => printFile.file.id
    );
    if (status !== "rejected") {
      apolloClient
        .mutate({
          mutation: bulkUploadOrderPersonalizedPrintFiles,
          variables: {
            printFiles: printFileUpload.map((printFile) => {
              return {
                fileId: printFile.file.id,
                fileName: printFile.file.fileName,
              };
            }),
            orderId: this.props.orderId,
          },
        })
        .then(() => {
          message.success("Upload printfile successfully");
          this.formRef.current.resetFields();
          this.props.refetch();
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else {
      apolloClient
        .mutate({
          mutation: UPLOAD_CUSTOMIZE_DESIGN,
          variables: {
            printFiles: newPrintFiles.map((printFile) => {
              return {
                fileId: printFile.file.id,
                id: printFile.id,
              };
            }),
            orderId: this.props.orderId,
          },
        })
        .then(() => {
          message.success("Upload printfile successfully");
          this.formRef.current.resetFields();
          this.props.refetch();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  render() {
    const {
      visible,
      renderFileNameFromPrintFiles,
      loadingFiles,
      beforeFile,
    } = this.state;
    const checkDisable = renderFileNameFromPrintFiles.find(
      (item) => !_.isEmpty(item.file)
    );
    const { color, tabs, setWidth, setHeight, orderId, size } = this.props;
    const content = (
      <Form
        ref={this.formRef}
        className="form-create-task"
        style={{ width: setWidth ?? 200 }}
        onFinish={this.onFinish}
      >
        <Tabs tabBarExtraContent={<div>{size}</div>}>
          {renderFileNameFromPrintFiles &&
            _.sortBy(
              renderFileNameFromPrintFiles,
              (printFile) => printFile.ordering
            ).map((item) => {
              let widthheight = item.file.width
                ? ` (${item.file.width}X ${item.file.height})`
                : "";
              return (
                <TabPane tab={`${item.key} ${widthheight}`} key={item.key}>
                  <Form.Item name={item.key} initialValue={item.file}>
                    <OrderUploadPrintFile
                      beforeFile={beforeFile}
                      onChange={(fileUpload) => {
                        this.setState({ loadingFiles: true });
                        this.getFile(fileUpload);
                      }}
                      setHeight={setHeight}
                      setWidth={setWidth}
                      multiple={true}
                      accept=".png,.jpg"
                      imageKey={
                        //item.file ? item.file.thumbnail ?? item.file.key : null
                        item.file ? item.file.key : null
                      }
                      loadingFiles={loadingFiles}
                      orderId={orderId}
                      file={item.file}
                      refetch={() => this.props.refetch()}
                    />
                  </Form.Item>
                </TabPane>
              );
            })}
        </Tabs>
        <Form.Item>
          <Button
            disabled={!checkDisable}
            type="primary"
            style={{
              marginRight: 10,
              color: !checkDisable ? "rgba(0, 0, 0, 0.65)" : "#fff",
            }}
            htmlType="submit"
          >
            Upload PNG
          </Button>
          <Button onClick={this.hide}>Cancel</Button>
        </Form.Item>
      </Form>
    );
    return (
      <Container className="upload-png">
        <div>
          {tabs ? (
            <div style={{ background: color, padding: 10 }}>{content}</div>
          ) : (
            <Popover
              content={content}
              trigger="click"
              visible={visible}
              placement="bottom"
              onVisibleChange={this.handleVisibleChange}
            >
              <a href="/#" style={{ color: color ? color : "#1890ff" }}>
                Upload PNG
              </a>
            </Popover>
          )}
        </div>
      </Container>
    );
  }
}
