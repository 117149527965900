import { gql } from "apollo-boost";

export default gql`
  query productByStore($filter: ProductFilter) {
    productByStore(filter: $filter) {
      total
      nodes {
        id
        title
      }
    }
  }
`;
