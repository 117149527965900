import React, { Component } from "react";
import { Form, Input, Button, notification } from "antd";
import customerInfo from "../../graphql/mutates/customerInfo";
import { Mutation } from "react-apollo";
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
class CustomerInfo extends Component {
  formRef = React.createRef();

  onFinish = (customerInfo, values) => {
    customerInfo({
      variables: {
        orderId: this.props.order.id,
        customerInfo: {
          ...values,
          email: values.email ? values.email : "",
        },
      },
    })
      .then((res) => {
        notification.success({
          message: "update customer info success",
        });
        this.props.onClose();
      })
      .catch((err) => {
        notification.error({
          message: err.message,
        });
      });
  };
  render() {
    const { order } = this.props;

    return (
      <div>
        <Mutation mutation={customerInfo}>
          {(customerInfo, res) => (
            <Form
              ref={this.formRef}
              {...layout}
              onFinish={(values) => {
                this.onFinish(customerInfo, values);
              }}
            >
              <h3>Customer</h3>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  { required: true, message: "Please input first name!" },
                ]}
                initialValue={
                  order?.shipping?.firstName ? order.shipping.firstName : null
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Code"
                name="lastName"
                rules={[{ required: true, message: "Please input last name!" }]}
                initialValue={
                  order?.shipping?.lastName ? order.shipping.lastName : null
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  // {
                  //   required: true,
                  //   message: "Please input your E-mail!",
                  // },
                ]}
                initialValue={
                  order?.customer?.email ? order.customer.email : null
                }
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                initialValue={
                  order?.shipping?.phone ? order.shipping.phone : null
                }
              >
                {/* <InputNumber style={{ width: "100%" }} /> */}
                <Input />
              </Form.Item>
              <h3>shipping</h3>
              <Form.Item
                label="Address 1"
                name="address1"
                rules={[
                  { required: true, message: "Please input last address!" },
                ]}
                initialValue={
                  order?.shipping?.address1 ? order.shipping.address1 : null
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address 2"
                name="address2"
                initialValue={order ? order.shipping.address2 : null}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                initialValue={
                  order?.shipping?.city ? order.shipping.city : null
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="State"
                name="state"
                initialValue={order ? order.shipping.state : null}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="State code"
                name="stateCode"
                initialValue={order ? order.shipping.stateCode : null}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                initialValue={order ? order.shipping.country : null}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country Code"
                name="countryCode"
                initialValue={order ? order.shipping.countryCode : null}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Zip"
                name="zip"
                initialValue={order ? order.shipping.zip : null}
              >
                <Input />
              </Form.Item>
              <div style={{ float: "right" }}>
                <Button
                  className="btn"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    this.props.onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button className="btn" htmlType="submit" type="primary">
                  save
                </Button>
              </div>
            </Form>
          )}
        </Mutation>
      </div>
    );
  }
}
export default CustomerInfo;
