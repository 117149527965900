import React, { Component } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { apolloClient } from "./apollo-client";
import me from "./graphql/queries/me";
import { Skeleton } from "antd";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { AppContext } from "./context";
import { TOKEN_NAME } from "./config";
import history from "./history";
import routes from "./routes";
import RenderRouter from "./components/RenderRouter";
import { LOGOUT_ICTEES_APP } from "./graphql/mutates/loginIctees";

class App extends Component {
  state = {
    currentUser: null,
    loading: true,
  };

  login = (data) => {
    localStorage.setItem(TOKEN_NAME, data.id);
    this.setState(
      {
        currentUser: data.user,
      },
      () => {
        history.push("/orders");
      }
    );
  };
  logout = () => {
    apolloClient.resetStore();
    this.setCurrentUser(null);
    history.push("/login");
  };
  setCurrentUser = (currentUser) => {
    this.setState({ currentUser });
  };

  componentDidMount() {
    const params = new URLSearchParams(document.location.search);
    if (params.get("token")) {
      localStorage.setItem(TOKEN_NAME, params.get("token"));
      history.push("/");
    }
    var token = localStorage.getItem(TOKEN_NAME);
    if (token) {
      this.setState(
        {
          loading: true,
        },
        () => {
          apolloClient
            .query({
              query: me,
            })
            .then((res) => {
              this.setState({
                currentUser: res.data.me,
                loading: false,
              });
            })
            .catch(() => {
              this.setState({ loading: false });
              localStorage.setItem(TOKEN_NAME, null);
              history.push("/login");
            });
        }
      );
    } else {
      this.setState({ loading: false }, () => {
        history.push("/login");
      });
    }
  }

  render() {
    const { currentUser, loading } = this.state;
    const contextValue = {
      currentUser,
      login: this.login,
      logout: this.logout,
      setCurrentUser: this.setCurrentUser,
    };
    let arr = [];
    let getRoutes = (items) => {
      let middleArr = [];
      for (let i = 0; i < items.length; i++) {
        middleArr.push(items[i]);
        if (items[i].child) {
          middleArr = [...middleArr, ...getRoutes(items[i].child)];
        }
      }
      return middleArr;
    };
    for (let i = 0; i < routes.length; i++) {
      arr.push(routes[i]);
      if (routes[i].child) {
        arr = [...arr, ...getRoutes(routes[i].child)];
      }
    }
    return loading ? (
      <Skeleton />
    ) : (
      <AppContext.Provider value={contextValue}>
        <ApolloProvider client={apolloClient}>
          <Router history={history}>
            <Switch>
              {arr.map((route, index) => {
                return (
                  <Route
                    key={index}
                    exact={route.exact ? route.exact : false}
                    path={route.path}
                    component={RenderRouter(route, currentUser)}
                  />
                );
              })}
              <Redirect to="/orders" />
            </Switch>
          </Router>
        </ApolloProvider>
      </AppContext.Provider>
    );
  }
}

export default App;
