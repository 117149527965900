import React from "react";
import { useMutation } from "react-apollo";
import signedUrlMutate from "../../graphql/mutates/signedUrl";
import { Upload, Row, Col, Progress, Button, message } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { AWS_CLOUDFRONT_URL } from "../../config";
import createFile from "../../graphql/mutates/createFile";
import { DeleteOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";

export default (props) => {
  const {
    productUpload,
    custom,
    multiple,
    productBaseImage,
    onCompleted = () => { },
    onChange = () => { },
    deleteImage = () => { },
    onChangeMockups = () => { },
    changePercent = () => { },
    printFiles,
    accept,
    beforeFile = [],
  } = props;
  const [files, setFiles] = useState([]);
  const [fileList, setFilesList] = useState([]);
  const [createSignedMutate] = useMutation(signedUrlMutate);
  useEffect(() => {
    if (files.length && files.every((file) => file.file)) {
      onCompleted(files.map((file) => file.file));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    uploadPrintFiles(fileList).then((newFileList) => {
      if (fileList.length) {
        onChange(newFileList);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);
  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        beforeFile.push({
          ...file,
          width: width,
          height: height,
          name: file.name,
        });
      });
    });
  };

  const uploadPrintFiles = async (fileList) => {
    if (printFiles) {
      let newFileList = [...fileList];
      newFileList = await Promise.all(
        newFileList.map(async (file) => {
          const responseFile = await new Promise((resolve) => {
            createSignedMutate({
              variables: {
                filename: file.name,
                filemine: file.type,
              },
            }).then((res) => {
              var key = res.data.createSignedUrl.key;
              axios
                .put(res.data.createSignedUrl.url, file.originFileObj, {
                  headers: { "Content-Type": file.type },
                })
                .then((res2) => {
                  resolve({ ...file, key });
                })
                .catch((err) => {
                  message.error(err.message);
                });
            });
          });
          return responseFile;
        })
      ).then((result) => result);
      return newFileList;
    }
  };
  return (
    <div>
      <Row type="flex" gutter={10}>
        {productBaseImage
          ? productBaseImage.map((el) => (
            <div key={el.id} style={{ width: 102, marginRight: 15 }}>
              <img
                style={{
                  background: "lightgray",
                  width: 102,
                  height: 102,
                  objectFit: "contain",
                }}
                src={`${AWS_CLOUDFRONT_URL}/${el.key}`}
                alt=""
              />
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => {
                    deleteImage(el.key, "productBaseImage");
                  }}
                  style={{ color: "red" }}
                  type="link"
                  size="small"
                >
                  <DeleteOutlined />
                </Button>
              </div>
            </div>
          ))
          : null}
        {printFiles
          ? null
          : files.map((file, index) => (
            <ProcessUpload
              key={file.uid}
              productBaseImage={productBaseImage}
              deleteImage={(key) => {
                deleteImage(key, "upload");
                setFiles(files.filter((el) => el.file.key !== key));
              }}
              file={file.originFileObj}
              onCompleted={(f) => {
                files[index].file = f;
                setFiles([...files]);
              }}
              onChangeMockups={(files) => onChangeMockups(files)}
              changePercent={(percent) => changePercent(percent)}
              productUpload={productUpload}
            />
          ))}
        <Col
          style={{ width: "100%" }}
          span={custom ? 24 : 12}
          md={custom ? 24 : 8}
          lg={custom ? 24 : 6}
        >
          <Upload
            className={
              printFiles ? "uploadPrintFiles" : custom && "custom-file"
            }
            listType="picture-card"
            showUploadList={false}
            action={() => { }}
            multiple={multiple || false}
            onChange={async ({ file, fileList }) => {
              beforeUpload(file);
              setFiles(fileList);
              await setFilesList(fileList);
            }}
            fileList={printFiles ? [] : files}
            beforeUpload={() => false}
            accept={accept ? accept : "all"}
          >
            {printFiles ? (
              props.children
            ) : custom ? (
              <div
                style={{
                  height: 100,
                  alignItems: "center",
                  display: "grid",
                }}
              >
                <p style={{ fontSize: 40 }} className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </div>
            ) : (
              <div>
                <PlusOutlined />
                <p>Upload</p>
              </div>
            )}
          </Upload>
        </Col>
      </Row>
    </div>
  );
};

const ProcessUpload = ({
  file,
  productUpload,
  productBaseImage,
  onCompleted = () => { },
  deleteImage = (key) => {
    deleteImage(key);
  },
  onChangeMockups = () => { },
  changePercent = () => { },
}) => {
  const [createSignedMutate, createSignedMutateData] = useMutation(
    signedUrlMutate
  );
  const [createFileMutate, createFileData] = useMutation(createFile);
  const [fileUrl, setFileUrl] = useState(null);
  const [key, setKey] = useState(null);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    createSignedMutate({
      variables: {
        filename: file.name,
        filemine: file.type,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createSignedMutateData.data) {
      const { data } = createSignedMutateData;
      setKey(data.createSignedUrl.key);
      axios
        .put(data.createSignedUrl.url, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (e) => {
            setPercent(Math.round((e.loaded / e.total) * 100));
            changePercent(Math.round((e.loaded / e.total) * 100));
          },
        })
        .then((res) => {
          setFileUrl(`${AWS_CLOUDFRONT_URL}/${data.createSignedUrl.key}`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSignedMutateData.data]);

  useEffect(() => {
    if (fileUrl) {
      createFileMutate({
        variables: {
          input: {
            source: "aws",
            key: key,
            fileName: file.name,
            fileMime: file.type,
            fileSize: file.size,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl]);

  const formatFile = (data) => {
    let newValue = { ...data, imageId: data.id };
    delete newValue.id;
    delete newValue.__typename;
    return newValue;
  };

  useEffect(() => {
    if (createFileData.data) {
      onCompleted(createFileData.data.createFile);
      onChangeMockups(formatFile(createFileData.data.createFile));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFileData]);
  return (
    <div>
      {productUpload ? null : (
        <div style={{ marginRight: productBaseImage ? "15px" : "auto" }}>
          {fileUrl ? (
            <img
              src={fileUrl}
              alt=""
              style={{
                maxWidth: "100%",
                width: productBaseImage ? "102px" : "100%",
                height: productBaseImage ? "102px" : "auto",
                background: productBaseImage ? "lightgray" : "auto",
              }}
            />
          ) : (
            <Progress percent={percent} type="circle" width={64} />
          )}
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() => deleteImage(key)}
              style={{
                color: "red",
                width: productBaseImage ? "102px" : "100%",
              }}
              type="link"
              size="small"
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
