import { message, Modal, Select, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { UPDATE_ORDER_SHIPPING_METHOD } from '../../graphql/mutates/order';

const ChangeShippingMethod = ({ order, visible, setVisible, onUpdated = () => { } }) => {
  const [shippingMethods, setShippingMethods] = useState(["Economy", "Ground", "2-Day", "Overnight", "International Economy"]);
  const [selectedMethod, setSelectedMethod] = useState(order?.shippingMethod);
  const [BulkUpdateOrderShippingMethod, { loading: updating }] = useMutation(UPDATE_ORDER_SHIPPING_METHOD)

  const handleUpdateOrderShippingMethod = () => {
    BulkUpdateOrderShippingMethod({
      variables: {
        input: [{
          id: order?.id,
          shippingMethod: selectedMethod
        }]
      }
    }).then(({ data }) => {
      console.log(data);
      setVisible(false);
      onUpdated();
      message.success('Update order shipping method successfully');
    }).catch(err => {
      console.log(err);
      message.error('Update order shipping method failed');
    })
  }
  return (
    <Modal
      title="Change order shipping method"
      visible={visible} onCancel={() => setVisible(false)}
      onOk={handleUpdateOrderShippingMethod}
      okButtonProps={{ loading: updating }}
    >
      {/* <Select
        showSearch
        style={{ width: "100%" }}
        defaultValue={selectedMethod}
        optionFilterProp="children"
        onChange={value => setSelectedMethod(value)}
      >
        {shippingMethods?.map(v => (
          <Select.Option value={v}>{v}</Select.Option>
        ))}
      </Select> */}
      <Select showSearch
        style={{ width: "100%" }}
        defaultValue={selectedMethod}
        optionFilterProp="children"
        onChange={value => setSelectedMethod(value)}>
        {order?.fulfillService?.type === "customcat"
          ?
          <>
            <Select.Option value="Default">Default</Select.Option>
            <Select.Option value="Economy">Economy</Select.Option>
            <Select.Option value="Ground">Ground</Select.Option>
            <Select.Option value="2 Day">2 Day</Select.Option>
            <Select.Option value="Standard Overnight">Standard Overnight</Select.Option>
          </>
          : order?.fulfillService?.type === "dreamship"
            ?
            <>
              <Select.Option value="Default">Default</Select.Option>
              <Select.Option value="economy">Economy</Select.Option>
              <Select.Option value="express">Express</Select.Option>
              <Select.Option value="ground">Ground</Select.Option>
              <Select.Option value="two_day">2 Day</Select.Option>
              <Select.Option value="economy_untracked">Economy Untracked</Select.Option>
            </>
            : null
        }
      </Select>
    </Modal>
  );
};

export default ChangeShippingMethod;