import React, { Component } from "react";
import styled from "styled-components";
import { gql } from "apollo-boost";
import { apolloClient } from "../apollo-client";
import { AWS_CLOUDFRONT_THUMB_URL, AWS_CLOUDFRONT_URL } from "../config";

const Container = styled.div`
  height: 100%;
`;
export default class ImageUrl extends Component {
  state = {
    fileUrl: [],
  };

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      apolloClient
        .query({
          query: gql`
            query($fileId: ID!) {
              getFileThumbnailUrl(fileId: $fileId)
            }
          `,
          variables: {
            fileId: id,
          },
        })
        .then((res) => {
          this.setState({
            fileUrl: res.data.getFileThumbnailUrl,
          });
        });
    }
  }

  render() {
    const { fileUrl } = this.state;
    const { widthImage, heightImage, id, imageKey, useThumbnail = false } = this.props;
    return (
      <Container>
        <div
          style={{
            height: "100%",
            background: "darkgray",
            display: "grid",
            alignItems: "center",
          }}
        >
          {imageKey ? (
            <img
              src={useThumbnail ? `${AWS_CLOUDFRONT_THUMB_URL}/300/${imageKey}` : `${AWS_CLOUDFRONT_URL}/${imageKey}`}
              style={{
                width: widthImage ? widthImage : "100%",
                height: heightImage ? heightImage : "auto",
                objectFit: "contain",
              }}
              alt={imageKey}
            />
          ) : id ? (
            <img
              style={{
                width: widthImage ? widthImage : "auto",
                objectFit: "cover",
                height: heightImage ? heightImage : "auto",
                maxWidth: widthImage ? widthImage : 250,
              }}
              alt="default-img"
              src={fileUrl}
            />
          ) : null}
        </div>
      </Container>
    );
  }
}
