import React, { Component } from "react";
import { Form, Input, Button, message, Select } from "antd";
import styled from "styled-components";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import { SHOPIFY_API, API_URL, APP_URL } from "../../config";

const Container = styled.div``;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default class SiteForm extends Component {
  formRef = React.createRef();

  onFinish = (values) => {
    const id = this.props.value && this.props.value.id;
    if (!id) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation($title: String!, $domain: String!, $platform: String!) {
              createSite(title: $title, domain: $domain, platform: $platform) {
                id
                title
                domain
                platform
                token
              }
            }
          `,
          variables: {
            title: values.title,
            domain: values.domain,
            platform: values.platform,
          },
        })
        .then((res) => {
          //message.success("Connect site successfully");
          this.setState({
            loading: false,
          });
          const { id, platform, domain } = res.data.createSite;
          switch (platform) {
            case 'woocommerce':
              var app_name = 'ICTees'
              var return_url = `${APP_URL}/sites`
              var callback_url = `${API_URL}/auth_callback/${id}`
              var redirectUrl = `${domain}/wc-auth/v1/authorize?app_name=${app_name}&scope=read_write&user_id=1&return_url=${return_url}&callback_url=${callback_url}`
              window.location = redirectUrl
              break
            case "shopify":
              var url = domain;
              var redirect_uri = `${API_URL}/auth_callback/shopify`;
              var scopes = "write_products,write_orders,write_fulfillments,write_draft_orders";
              var nonce = id;
              window.location = `${url}/admin/oauth/authorize?client_id=${SHOPIFY_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
              break;
            default:
              break;
          }
          this.props.refetch();
          this.props.onClose();
          this.formRef.current.resetFields();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
    if (id) {
      apolloClient
        .mutate({
          mutation: gql`
            mutation(
              $site_id: String!
              $title: String!
              $token: String
              $email: String
            ) {
              updateSite(
                site_id: $site_id
                title: $title
                token: $token
                email: $email
              ) {
                id
                title
                token
              }
            }
          `,
          variables: {
            site_id: id,
            title: values.title,
            token: values.token,
            email: values.email,
          },
        })
        .then(() => {
          message.success("Update user successfully");
          this.setState({
            loading: false,
          });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  validatorHttps = (value) => {
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);
    if (value.match(regex)) {
      return true;
    }
    return false;
  };

  render() {
    const { value } = this.props;
    return (
      <Container>
        <Form ref={this.formRef} {...layout} onFinish={this.onFinish}>
          <Form.Item
            name="title"
            label="Site Name"
            initialValue={value ? value.title : ""}
            rules={[
              {
                required: true,
                message: "Please input Site Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {value && value.id ? (
            <div>
              <Form.Item
                name="email"
                initialValue={value ? value.email : ""}
                label="Order Email"
              >
                <Input />
              </Form.Item>
            </div>
          ) : (
            <div>
              <Form.Item
                name="platform"
                label="Platform"
                initialValue={value ? value.platform : "shopify"}
                rules={[
                  {
                    required: true,
                    message: "Please input platform!",
                  },
                ]}
              >
                <Select>
                  <Select.Option value="shopify">Shopify</Select.Option>
                  <Select.Option value="woocommerce">Woocommerce</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="domain"
                label="Site Url"
                rules={[
                  {
                    required: true,
                    message: "Please input Site URL!",
                  },
                  {
                    validator: (_, value) =>
                      this.validatorHttps(value)
                        ? Promise.resolve()
                        : Promise.reject(
                          "Invalid Url. Url must start with https!"
                        ),
                  },
                ]}
              >
                <Input placeholder="https://" />
              </Form.Item>
            </div>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {value ? "Save" : "Connect"}
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}
