import { gql } from "apollo-boost";

export default gql`
  query exportTemplates($filter: TemplateFilter) {
    exportTemplates(filter: $filter) {
      total
      nodes {
        id
        name
        data
        createdAt
      }
    }
  }
`;
