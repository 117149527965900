import React, { Component } from "react";
import styled from "styled-components";
import { Carousel } from "antd";
import { AWS_CLOUDFRONT_THUMB_URL, AWS_CLOUDFRONT_URL } from "../../config";

const Container = styled.div`
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
  }
  .bg-dr {
    background: darkgray;
  }
  h2 {
    width: 100%;
    color: black;
    margin: 0;
    text-transform: capitalize;
  }
  .ant-carousel .slick-slide h3 {
    color: #fff;
  }
`;

export default class CarouselImage extends Component {
  render() {
    const { images } = this.props;
    return (
      <Container>
        <Carousel autoplay>
          {images &&
            images.map((image, index) =>
              image.file ? (
                <div key={index}>
                  <h2>
                    {image?.baseDesign
                      ? image.baseDesign.name
                      : image?.side
                        ? image.side
                        : null}
                    {image.file.width
                      ? ` (${image.file.width}X ${image.file.height})`
                      : ""}
                  </h2>

                  <div className="bg-dr">
                    <img
                      src={
                        image.file.source === "aws"
                          ? `${AWS_CLOUDFRONT_URL}/${image.file.key}`
                          : `${image.file.url}`
                      }
                      alt="images-product"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              ) : null
            )}
        </Carousel>
      </Container>
    );
  }
}
