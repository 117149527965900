import React, { Component } from "react";
import styled from "styled-components";
import { message, Popconfirm } from "antd";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import {
  QuestionCircleOutlined,
} from "@ant-design/icons";
const Container = styled.div``;

export default class RemovePrintFiles extends Component {
  confirm = () => {
    const { orderId, orderSelect } = this.props;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [String!]) {
            removePrintFile(orderIds: $orderIds)
          }
        `,
        variables: {
          orderIds: orderIdSelect ? orderIdSelect : [orderId],
        },
      })
      .then(() => {
        message.success("Remove print file successfully");
        this.props.refetch();
        this.setState({
          loading: false,
          visible: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    return (
      <Container>
        <div>
          <Popconfirm
            title="Are you sure remove this print file？"
            icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
            okButtonProps={{type:"danger"}}
            onConfirm={() => this.confirm()}
            okText="Yes"
            cancelText="No"
            placement="top"
          >
            <a href="/#" style={{ padding: 0 }}>
              Remove Print file
            </a>
          </Popconfirm>
        </div>
      </Container>
    );
  }
}
