import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Skeleton,
  notification,
} from "antd";
import styled from "styled-components";
import { Query } from "react-apollo";
import exportTemplates from "../../graphql/queries/exportTemplates";
import createFulfillService from "../../graphql/mutates/createFulfillService";
import { apolloClient } from "../../apollo-client";
import updateFulfillService from "../../graphql/mutates/updateFulfillService";

const Container = styled.div`
  .btn {
    margin-right: 10px;
  }
`;
class FulfillmentForm extends Component {
  state = {
    loading: false,
  };
  formRef = React.createRef();

  onFinish = (values) => {
    const id = this.props.showDrawer.id;
    if (!id) {
      apolloClient
        .mutate({
          mutation: createFulfillService,
          variables: {
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Add fulfillment success!" });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        });
    }
    if (id) {
      apolloClient
        .mutate({
          mutation: updateFulfillService,
          variables: {
            id,
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Edit fulfillment success!" });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        });
    }
  };
  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const { showDrawer } = this.props;
    const { loading } = this.state;
    return (
      <Container>
        <Form ref={this.formRef} {...layout} onFinish={this.onFinish}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
            initialValue={showDrawer ? showDrawer.name : ""}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Row gutter={16}>
            {/* <Col span={24} md={12}> */}
            <Form.Item
              hidden={true}
              label="Type"
              name="type"
              rules={[{ required: true, message: "Please input type!" }]}
              initialValue={"csv"}
            >
              <Select placeholder="Type">
                <Select.Option value="csv">CSV</Select.Option>
              </Select>
            </Form.Item>
            {/* </Col> */}
            <Col span={24} md={24}>
              <Query query={exportTemplates} fetchPolicy="no-cache">
                {({ data, loading, error }) => {
                  if (loading) return <Skeleton />;
                  if (error) return <div>{error.toString()}</div>;
                  const exportTemplates = data.exportTemplates.nodes;
                  const template =
                    showDrawer &&
                    showDrawer.templates &&
                    showDrawer.templates.map((t) => t.id);
                  return (
                    <div>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.type !== currentValues.type
                        }
                      >
                        {({ getFieldValue }) => {
                          return (
                            getFieldValue("type") === "csv" && (
                              <Form.Item
                                label="Template"
                                name="templateIDs"
                                initialValue={template ? template : []}
                              >
                                <Select
                                  placeholder="Templates"
                                  mode="multiple"
                                  showSearch
                                  optionFilterProp="children"
                                >
                                  {exportTemplates.map((ex) => (
                                    <Select.Option value={ex.id} key={ex.id}>
                                      {ex.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )
                          );
                        }}
                      </Form.Item>
                    </div>
                  );
                }}
              </Query>
            </Col>
          </Row>
          <Form.Item>
            <Button
              className="btn"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Save
            </Button>
            <Button loading={loading} onClick={() => this.props.onClose()}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default FulfillmentForm;
