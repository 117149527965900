import React, { Component } from "react";
import styled from "styled-components";
import { Select, Input, Button, Popover } from "antd";
import DateRange from "../DateRange";
import moment from "moment";
import { apolloClient } from "../../apollo-client";
import { LOAD_SITE } from "../sites/Sites";
import { gql } from "apollo-boost";
import fulfillServices from "../../graphql/queries/fulfillServices";
import { FilterOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import countries from "../../countries-en.json";
const Container = styled.div`
  width: 100%;
  .filter {
    display: flex;
    width: -webkit-fill-available;
  }
  @media only screen and (max-width: 768px) {
    .filter {
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .filter {
      display: grid;
      grid-template-columns: 37.5% 64.5%;
    }
    .search-reset {
      margin-top: 10px;
    }
    .fulfillment {
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 480px) {
    .filter {
      display: grid !important;
      grid-template-columns: auto;
      grid-row-gap: 10px;
    }
  }
`;
const shippingmethod = [
  "Economy",
  "Ground",
  "2-Day",
  "Overnight",
  "International Economy",
];
const pushStatus = ["Pending", "Pushing", "Pushed", "Error", "NULL"];
export const orderInternalStatus = [
  {
    key: "processing",
    title: "Processing",
    color: "#f94",
  },
  {
    key: "await",
    title: "Await",
    color: "#f94",
  },
  {
    key: "unassigned",
    title: "Unassigned",
    color: "#f94",
  },
  {
    key: "designed",
    title: "Designed",
    color: "blue",
  },
  {
    key: "in_production",
    title: "In Production",
    color: "#ff5500",
  },
  {
    key: "fulfilled",
    title: "Fulfilled",
    color: "green",
  },
  {
    key: "cancelled",
    title: "Cancelled",
    color: "#ff5500",
  },
  {
    key: "rejected",
    title: "Rejected",
    color: "#ff5500",
  },
  {
    key: "error",
    title: "Error",
    color: "red",
  },
];

export const orderStatus = [
  {
    key: "unassigned",
    title: "Unassigned",
    color: "#f94",
  },
  {
    key: "processing",
    title: "Processing",
    color: "#f94",
  },
  {
    key: "pending_approve",
    title: "Pending Approve",
    color: "#f94",
  },
  {
    key: "designed",
    title: "Designed",
    color: "blue",
  },
  {
    key: "in_production",
    title: "In Production",
    color: "#ff5500",
  },
  {
    key: "fulfilled",
    title: "Fulfilled",
    color: "green",
  },
  {
    key: "cancelled",
    title: "Cancelled",
    color: "#ff5500",
  },
  {
    key: "rejected",
    title: "Rejected",
    color: "#ff5500",
  },
  {
    key: "error",
    title: "Error",
    color: "red",
  },
];

class FilterOrder extends Component {
  state = {
    sites: [],
    users: [],
    fulfillServices: [],
    searchBase: null,
    type: this.props.filter.type,
    search: this.props.filter.search,
    countryCode: this.props.filter.countryCode,
  };

  componentDidMount() {
    const { isAdmin } = this.props;
    if (isAdmin) {
      this.loadSites();
      this.loadDesigner();
      this.loadFulfillServices();
      this.loadBase();
    }
  }

  loadSites() {
    apolloClient
      .query({
        query: LOAD_SITE,
        variables: {
          filter: {
            verified: true,
            status: true,
          },
        },
      })
      .then((res) => {
        this.setState({
          sites: res.data.sites.hits || [],
        });
      });
  }
  loadBase(value) {
    apolloClient
      .query({
        query: gql`
          query($filter: ProductBaseFilter) {
            productBases(filter: $filter) {
              total
              nodes {
                id
                title
              }
            }
          }
        `,
        variables: { filter: { limit: 20, search: value } },
      })
      .then((res) => {
        this.setState({
          base: res.data.productBases.nodes || [],
          loading: false,
        });
      });
  }
  loadDesigner() {
    apolloClient
      .query({
        query: gql`
          query {
            users {
              total
              nodes {
                id
                firstName
                lastName
                roles
              }
            }
          }
        `,
      })
      .then((res) => {
        this.setState({
          users: res.data.users.nodes || [],
          loading: false,
        });
      });
  }

  loadFulfillServices() {
    apolloClient
      .query({
        query: fulfillServices,
      })
      .then((res) => {
        this.setState({
          fulfillServices: res.data.FulfillServices.nodes || [],
          loading: false,
        });
      });
  }

  loadFul() {
    apolloClient
      .query({
        query: gql`
          query {
            users {
              total
              nodes {
                id
                firstName
                lastName
                roles
              }
            }
          }
        `,
      })
      .then((res) => {
        this.setState({
          users: res.data.users.nodes || [],
          loading: false,
        });
      });
  }

  onChangeDate = (range) => {
    if (range.from !== null && range.to !== null) {
      this.props.onChangeFilter({
        filter: {
          ...this.props.filter,
          from: moment(range.from).format("YYYY-MM-DD"),
          to: moment(range.to).format("YYYY-MM-DD"),
          offset: 0,
        },
        page: 1,
        action: [],
      });
    } else {
      this.props.onChangeFilter({
        filter: { ...this.props.filter, from: null, to: null },
      });
    }
  };

  render() {
    const {
      filter,
      isAdmin,
      onChangeFilter,
      setTemplateByFulfillment,
      isExternal,
    } = this.props;
    const { sites, users, fulfillServices, base, search, countryCode } = this.state;
    const designers = users.filter((user) =>
      user.roles.find((role) => role === "Designer")
    );
    return (
      <Container>
        <div className="filter">
          <Popover
            trigger="click"
            placement="bottomLeft"
            content={
              <div style={{ width: 220 }}>
                <Select
                  value={filter.siteId}
                  style={{ minWidth: 140, display: "block", marginBottom: 10 }}
                  placeholder="Filter by store"
                  onChange={(siteId) => {
                    onChangeFilter({
                      filter: { ...this.props.filter, siteId },
                    });
                  }}
                >
                  <Select.Option key="all" value={null}>
                    All Sites
                  </Select.Option>
                  {sites.map((site) => (
                    <Select.Option key={site.id} value={site.id}>
                      {site.title}
                    </Select.Option>
                  ))}
                </Select>
                {isAdmin ? (
                  <Select
                    showSearch
                    value={filter.userId}
                    placeholder="Filter designer"
                    style={{
                      display: "block",
                      marginBottom: 10,
                    }}
                    onChange={(userId) =>
                      onChangeFilter({
                        filter: { ...this.props.filter, userId },
                      })
                    }
                  >
                    <Select.Option key="all" value={null}>
                      All Designers
                    </Select.Option>
                    {designers.map((designer) => (
                      <Select.Option key={designer.id} value={designer.id}>
                        {designer.firstName + " " + designer.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}
                <Select
                  className="fulfillment"
                  placeholder="Filter by shipping method"
                  style={{ marginBottom: 10, display: "block" }}
                  onChange={(shippingMethod) => {
                    onChangeFilter({
                      filter: { ...this.props.filter, shippingMethod },
                    });
                  }}
                  value={filter.shippingMethod}
                >
                  <Select.Option key="all" value={null}>
                    All Shipping Method
                  </Select.Option>
                  {shippingmethod.map((item, key) => (
                    <Select.Option value={item} key={key}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
                <Select
                  className="fulfillment"
                  placeholder="Push status"
                  style={{ marginBottom: 10, display: "block" }}
                  onChange={(pushStatus) => {
                    onChangeFilter({
                      filter: { ...this.props.filter, pushStatus },
                    });
                  }}
                  value={filter.pushStatus}
                >
                  <Select.Option key="all" value={null}>
                    All Push Status
                  </Select.Option>
                  {pushStatus.map((item) => (
                    <Select.Option key={item}>
                      {item === "NULL" ? "None" : item}
                    </Select.Option>
                  ))}
                </Select>
                {isAdmin ? (
                  <Select
                    showSearch
                    style={{ display: "block", marginBottom: 10 }}
                    placeholder="Filter product base"
                    onChange={(baseId) => {
                      onChangeFilter({
                        filter: { ...this.props.filter, baseId },
                      });
                    }}
                    value={filter.baseId}
                    onSearch={debounce((value) => {
                      this.loadBase(value);
                    }, 300)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Select.Option key="all" value={null}>
                      All Product Base
                    </Select.Option>
                    {base?.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}
                <Button
                  onClick={() => {
                    let filterResert = { ...filter };
                    delete filterResert.pushStatus;
                    delete filterResert.shippingMethod;
                    delete filterResert.siteId;
                    delete filterResert.userId;
                    delete filterResert.baseId;

                    onChangeFilter({
                      filter: filterResert,
                      action: [],
                      page: 1,
                      orderSelect: [],
                    });
                  }}
                  type="primary"
                >
                  Reset Filter
                </Button>
              </div>
            }
          >
            <Button style={{ marginRight: 10 }}>
              Filter <FilterOutlined />
            </Button>
          </Popover>
          <div style={{ marginRight: 10 }}>
            <DateRange
              defaultDate={
                filter.from === null && filter.to === null
                  ? [filter.from, filter.to]
                  : [moment(filter.from), moment(filter.to)]
              }
              onChange={(value) => this.onChangeDate(value)}
            />
          </div>

          {isExternal ? null : (
            <>
              <Select
                className="fulfillment"
                value={filter.fulfilmentId ?? null}
                style={{ marginRight: 10, minWidth: 180 }}
                placeholder="Filter by fulfillment"
                onChange={(fulfilmentId) => {
                  onChangeFilter({
                    filter: { ...this.props.filter, fulfilmentId },
                  });
                  const itemFulfillServices = fulfillServices.find(
                    (fulfill) => fulfill.id === fulfilmentId
                  );
                  setTemplateByFulfillment(itemFulfillServices);
                }}
              >
                <Select.Option key="all" value={null}>
                  All fulfillment
                </Select.Option>
                {fulfillServices.map((fulfillService) => (
                  <Select.Option
                    key={fulfillService.id}
                    value={fulfillService.id}
                  >
                    {fulfillService.name}
                  </Select.Option>
                ))}
              </Select>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ minWidth: 150, marginRight: 10 }}
                value={filter.countryCode ?? null}
                onChange={(countryCode) => {
                  onChangeFilter({
                    filter: { ...this.props.filter, countryCode },
                  });
                }}
                allowClear
              >
                <Select.Option value={null}>All Country</Select.Option>
                {countries?.data.countries.map((country) => (
                  <Select.Option value={country.code} key={country.code}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}
          <div className="search-reset">
            <Input.Search
              addonBefore={
                <Select
                  style={{ width: 150 }}
                  value={this.state.type}
                  onChange={(type) => {
                    this.setState({ type });
                    if (search) {
                      onChangeFilter({
                        filter: {
                          ...this.props.filter,
                          type,
                          search: search,
                        },
                      });
                    }
                  }}
                >
                  <Select.Option value={"name"}>Origin Number</Select.Option>
                  <Select.Option value={"origin_id"}>Origin ID</Select.Option>
                  <Select.Option value={"all"}>ALL</Select.Option>
                </Select>
              }
              enterButton
              onSearch={(search) => {
                onChangeFilter({
                  filter: {
                    ...filter,
                    search,
                    offset: 0,
                    type: this.state.type,
                  },
                  page: 1,
                  action: [],
                });
              }}
              value={this.state.search}
              onChange={(e) => {
                this.setState({ search: e.target.value });
                if (e.target.value.length <= 0) {
                  onChangeFilter({
                    filter: {
                      ...filter,
                      search: e.target.value,
                      offset: 0,
                      type: this.state.type,
                    },
                    page: 1,
                    action: [],
                  });
                }
              }}
              defaultValue={filter.search}
              className="search"
              placeholder="Search by order id, product title, customer"
            />
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                onChangeFilter({
                  filter: {
                    ...filter,
                    limit: 25,
                    offset: 0,
                    siteId: null,
                    status: null,
                    search: null,
                    productId: null,
                    from: null,
                    to: null,
                    userId: null,
                    external: isExternal ?? false,
                    shippingMethod: null,
                    pushStatus: null,
                    baseId: null,
                    fulfilmentId: null,
                    type: "name",
                  },
                  action: [],
                  page: 1,
                  orderSelect: [],
                });
                this.setState({ search: null, type: "name" });
              }}
              type="primary"
            >
              Reset Filter
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default FilterOrder;
