import React, { Component } from "react";
import { Tabs } from "antd";
import DefaultFuelStore from "./defaultFuelStore";
export default class Setting extends Component {
  render() {
    return (
      <div>
        <Tabs>
          <Tabs.TabPane tab="Default fuel store" key="default-fuel-store">
            <DefaultFuelStore />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}
