import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "react-apollo";
import { PRODUCT_ID_DESIGN } from "../../graphql/queries/productByIdDesigns";
import { Col, Row, Button, notification, Skeleton } from "antd";
import UploadSingle from "../UploadSingle";
import { UPLOAD_PRINT_FILE } from "../../graphql/mutates/uploadPrintFiles";
import { AWS_CLOUDFRONT_URL } from "../../config";
import _ from "lodash";
import approveProductDesign from "../../graphql/mutates/approveProductDesign";
import { DownloadOutlined } from "@ant-design/icons";

const PrintFile = (props) => {
  const { id, onClose, refetch } = props;
  const [uploadFile] = useMutation(UPLOAD_PRINT_FILE);
  const [approveProduct] = useMutation(approveProductDesign);
  const [getData, { loading, data }] = useLazyQuery(PRODUCT_ID_DESIGN, {
    fetchPolicy: "network-only",
    variables: { id: id },
    onCompleted(data) {
      setDesigns(
        data?.product?.designs.map((design) => {
          return {
            ...design.baseDesign,
            file: design.file,
            fileId: design.fileId,
            baseDesignId: design.baseDesign.id,
            id: design.id,
            baseId: design.baseDesign.baseId,
            title: productBases.find(
              (item) => item.id === design.baseDesign.baseId
            )?.title,
          };
        })
      );
    },
  });

  const mockups = data ? data.product.mockups : [];
  const productBases = data ? data.product?.productBases : [];

  const [designsSave, setDesigns] = useState([]);

  const onApprovePrintFiles = () => {
    approveProduct({
      variables: {
        productId: id,
      },
    }).then(() => {
      notification.success({
        message: "approve product success!",
      });
      refetch();
    }).catch((err) => {
      notification.error({
        message: err.message,
      });
    });
  };

  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const mergeDesignByBaseId = (objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };
  const saveUpload = () => {
    uploadFile({
      variables: {
        productId: id,
        designs: designsSave.map((design) => {
          return {
            id: design.id,
            baseDesignId: design.baseDesignId,
            fileId: design.fileId,
          };
        }),
      },
    }).then(() => {
      notification.success({
        message: "upload success!",
      });
      onClose();
      refetch();
    });
  };
  if (loading) return <Skeleton />;
  return (
    <div className="product-upload">
      <Row>
        {_.map(
          mergeDesignByBaseId(designsSave, "baseId"),
          (designs, dsIndex) => {
            return (
              <Col key={dsIndex} md={24} span={24}>
                <h3
                  style={{
                    borderBottom: "1px solid #d9d9d9",
                  }}
                >
                  {designs[0].title}
                </h3>
                <Row gutter={[16, 16]} className="gutter-row">
                  {_.orderBy(
                    designs,
                    [(design) => design.ordering],
                    ["asc"]
                  ).map((design) => (
                    <Col key={design.id} span={8}>

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>
                          {design.baseDesign
                            ? design.baseDesign.name
                            : design.name}
                        </h3>
                        {design.file && <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${AWS_CLOUDFRONT_URL}/${design.file?.key}`}
                        >
                          <DownloadOutlined
                            style={{ color: "dodgerblue" }}
                          />
                        </a>}
                      </div>
                      <div
                        style={{
                          border: "1px solid #f0f0f0",
                          borderRadius: 5,
                        }}
                      >
                        <UploadSingle
                          multiple={false}
                          fileList={design.fileId}
                          // setWidth="200px"
                          setHeight="200px"
                          value={design.fileId}
                          imageKey={design.file ? design.file.key : null}
                          printFiles={true}
                          onChangePrintFiles={(file) => {
                            setDesigns((prevState) => {
                              return prevState.map((item) => {
                                if (item.id === design.id) {
                                  return {
                                    ...item,
                                    fileId: file.fileId,
                                    file: {
                                      ...item.file,
                                      key: file.file.key,
                                    },
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                        >
                          <div style={{ textAlign: "center", padding: 10 }}>{`${design.baseDesign
                            ? design.baseDesign.height
                            : design.height
                            } x ${design.baseDesign
                              ? design.baseDesign.width
                              : design.width
                            }`}</div>
                        </UploadSingle>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            );
          }
        )}
      </Row>
      {mockups.length > 0 && (
        <div
          style={{
            marginBottom: 50,
            borderTop: "1px solid #f0f0f0",
            paddingTop: 10,
          }}
        >
          <h3>Product mockups</h3>
          <div style={{ textAlign: "left" }}>
            <img
              style={{ maxWidth: 400, height: "400" }}
              src={
                mockups[0].file.source === "store"
                  ? mockups[0].file.url
                  : `${AWS_CLOUDFRONT_URL}/${mockups[0].file.key}`
              }
              alt=""
            ></img>
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          borderTop: "1px solid #ccc",
          background: "white",
          height: 60,
          right: 0,
          textAlign: "right",
        }}
      >
        {data?.product?.designStatus === "done" && (
          <Button
            onClick={onApprovePrintFiles}
            type="primary"
            style={{ margin: 15 }}
          >
            Approve
          </Button>
        )}
        <Button onClick={saveUpload} type="primary" style={{ margin: 10 }}>
          Save
        </Button>
      </div>
    </div>
  );
};
export default PrintFile;
