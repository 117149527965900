import { Radio, Select, Table } from "antd";
import React, { forwardRef, useEffect, useState } from "react";

const SelectFullfill = forwardRef((props, ref) => {
  console.log("FF", props)
  const { fulfillment, onChange, choseFulfill, onChangeDefault } = props;
  const [fulfill, SetFullfill] = useState();
  useEffect(() => {
    if (props.value)
      SetFullfill(fulfillment.filter((item) => props.value.includes(item.id)));
  }, [props.value, fulfillment]);
  const columns = [
    {
      render: (record) => (
        <span>
          <Radio
            checked={record.id === choseFulfill}
            onChange={(e) => {
              onChangeDefault(record.id);
            }}
          ></Radio>
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];
  return (
    <div>
      <Select
        mode="multiple"
        value={props.value}
        onChange={(e) => {
          onChange(e);
          if (!e.includes(choseFulfill) && e.length > 0) {
            onChangeDefault(e[0]);
          }
        }}
      >
        {fulfillment?.map((ful) => (
          <Select.Option key={ful.id} value={ful.id}>
            {ful.title}
          </Select.Option>
        ))}
      </Select>
      {fulfill && fulfill.length > 0 ? (
        <Table
          dataSource={fulfill}
          columns={columns}
          showHeader={false}
          pagination={false}
          rowKey={(index) => index.id}
        />
      ) : null}
    </div>
  );
});

export default SelectFullfill;
