import gql from "graphql-tag";

export default gql`
  mutation($orderId: ID!, $printFiles: [OrderPersonalizedPrintFileInput!]) {
    bulkUploadOrderPersonalizedPrintFiles(
      orderId: $orderId
      printFiles: $printFiles
    )
  }
`;
