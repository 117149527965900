import React, { Component } from "react";
import { Select, Button, notification } from "antd";
import { LOAD_SITE } from "../sites/Sites";
import { apolloClient } from "../../apollo-client";
import SetDefaultFuelStore from "../../graphql/mutates/setting.js/SetDefaultFuelStore";

export default class DefaultFuelStore extends Component {
  state = {
    sites: [],
    site: null,
    loading: false,
  };

  componentDidMount() {
    this.loadSites();
  }

  loadSites() {
    apolloClient
      .query({
        query: LOAD_SITE,
        variables: {
          filter: {
            verified: true,
            status: true,
            platform: "shopify",
          },
        },
      })
      .then((res) => {
        this.setState({
          sites: res.data.sites.hits || [],
          site: res.data.sites.hits.find((site) => site.fuelFulfill === true)
            ?.id,
        });
      });
  }
  saveSote = () => {
    this.setState({ loading: true });
    apolloClient
      .mutate({
        mutation: SetDefaultFuelStore,
        variables: {
          site_id: this.state.site,
        },
      })
      .then((res) => {
        notification.success({
          message: "Setting default fuel store success!",
        });
        this.setState({ loading: false });
      })
      .catch((err) => {
        notification.error(err.toString());
        this.setState({ loading: false });
      });
  };
  render() {
    const { sites, site } = this.state;

    return (
      <div>
        <div style={{ display: "flex" }}>
          <span style={{ width: 100 }}>Site :</span>
          <Select
            value={site ? site : null}
            style={{ width: 300 }}
            onChange={(value) => {
              this.setState({ site: value });
            }}
          >
            {sites.map((site) => (
              <Select.Option key={site.id} value={site.id}>
                {site.title}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <Button
            type="primary"
            onClick={this.saveSote}
            style={{ float: "right" }}
            loading={this.state.loading}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
