import React, { Component } from "react";
import {
  Form,
  Input,
  PageHeader,
  Card,
  Button,
  notification,
  Skeleton,
} from "antd";
import styled from "styled-components";
import TemplateConfig from "./TemplateConfig";
import { apolloClient } from "../../apollo-client";
import createExportTemplate from "../../graphql/mutates/createExportTemplate";
import updateExportTemplate from "../../graphql/mutates/updateExportTemplate";
import history from "../../history";
import { Link } from "react-router-dom";
import _ from "lodash";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { LoadingOutlined } from "@ant-design/icons";

const Container = styled.div`
  .btn-save {
    margin-right: 10px;
  }
`;
const DEFAULT_EXPORT_COLUMNS = gql`
  query defaultExportColumns {
    defaultExportColumns {
      name
      type
      value
    }
  }
`;
class ExportTemplateForm extends Component {
  state = {
    defaultExportColumns: [],
    exportTemplate: null,
    loading: false,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    id && this.exportTemplate(id);
    // this.defaultExportColumns();
  }

  onFinish = (values) => {
    const { id } = this.props.match.params;
    const isClone = this.props.match.path === "/export-templates/clone/:id";
    if (!id || isClone) {
      apolloClient
        .mutate({
          mutation: createExportTemplate,
          variables: {
            input: values,
          },
        })
        .then(() => {
          notification.success({
            message: `${
              isClone ? "Clone template success!" : "Add template success!"
            }`,
          });
          history.push("/export-templates");
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.toString().includes("uix_export_templates_name")) {
            notification.error({
              message: "Columns name already in use, please try another name.",
            });
          } else {
            notification["error"]({
              message: _.get(err, "[0].message"),
            });
          }
        });
    } else {
      apolloClient
        .mutate({
          mutation: updateExportTemplate,
          variables: {
            id,
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Edit template success!" });
          history.push("/export-templates");
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.toString().includes("uix_export_templates_name")) {
            notification.error({
              message: "Columns name already in use, please try another name.",
            });
          } else {
            notification["error"]({
              message: _.get(err, "[0].message"),
            });
          }
        });
    }
  };
  exportTemplate(id) {
    this.setState({ loading: true });
    apolloClient
      .query({
        query: gql`
          query exportTemplate($id: ID!) {
            exportTemplate(id: $id) {
              id
              name
              data
            }
          }
        `,
        variables: {
          id: id,
        },
      })
      .then((res) => {
        this.setState({
          exportTemplate: res.data.exportTemplate,
          loading: false,
        });
      });
  }
  defaultExportColumns() {
    this.setState({ loading: true });
    apolloClient
      .query({
        query: gql`
          query defaultExportColumns {
            defaultExportColumns {
              name
              type
              value
            }
          }
        `,
      })
      .then((res) => {
        this.setState({
          defaultExportColumns: res.data.defaultExportColumns,
          loading: false,
        });
      });
  }

  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const { exportTemplate, loading } = this.state;
    const isClone = this.props.match.path === "/export-templates/clone/:id";
    if (loading) return null;
    return (
      <Query query={DEFAULT_EXPORT_COLUMNS}>
        {({ data, loading, error }) => {
          if (loading) return <Skeleton />;
          if (error) return <div>{error.toString()}</div>;
          const defaultExportColumns = data.defaultExportColumns;
          return (
            <Container>
              <PageHeader
                title={`${
                  !exportTemplate
                    ? "Add export template"
                    : isClone
                    ? "Clone export template"
                    : " Edit export template"
                }`}
                onBack={() => this.props.history.goBack()}
              />
              <Card>
                <Form {...layout} onFinish={this.onFinish}>
                  <Form.Item
                    label="Template Name"
                    name="name"
                    rules={[{ required: true, message: "Please input name!" }]}
                    initialValue={exportTemplate?.name}
                  >
                    <Input placeholder="Teamplate name" />
                  </Form.Item>
                  <Form.Item
                    label="Template Configs"
                    name="data"
                    rules={[
                      {
                        required: true,
                        message: "Please input template configs!",
                      },
                    ]}
                    initialValue={
                      exportTemplate
                        ? exportTemplate.data || []
                        : defaultExportColumns.reduce(
                            (init, t) => [
                              ...init,
                              {
                                name: t.name,
                                value: t.value,
                                type: "defined_value",
                              },
                            ],
                            []
                          )
                    }
                  >
                    <TemplateConfig
                      defaultExportColumns={defaultExportColumns}
                    />
                  </Form.Item>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      className="btn-save"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {exportTemplate ? "Save change" : "Save"}
                      {loading ? <LoadingOutlined /> : null}
                    </Button>
                    <Link to="/export-templates">
                      <Button>Cancel</Button>
                    </Link>
                  </div>
                </Form>
              </Card>
            </Container>
          );
        }}
      </Query>
    );
  }
}

export default ExportTemplateForm;
