import gql from "graphql-tag";
export default gql`
  mutation createCarrier($input: NewCarrier!) {
    createCarrier(input: $input) {
      id
      name
      code
      description
      trackingUrl
    }
  }
`;
