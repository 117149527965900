import React, { Component } from "react";
import { message, Select, Button, Form } from "antd";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";

export default class UpdateTaskForm extends Component {
  state = {
    users: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.loadDesigner();
  }

  hide = () => {
    this.formRef.current.resetFields();
    this.props.hide();
  };

  loadDesigner() {
    apolloClient
      .query({
        query: gql`
          query {
            users {
              total
              nodes {
                id
                firstName
                lastName
                roles
              }
            }
          }
        `,
      })
      .then((res) => {
        this.setState({
          users: res.data.users.nodes || [],
          loading: false,
        });
      });
  }

  onFinish = (values) => {
    const { orderId, orderSelect } = this.props;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [String!], $userID: ID!) {
            reAssignTask(orderIds: $orderIds, userID: $userID)
          }
        `,
        variables: {
          orderIds: orderIdSelect ? orderIdSelect : [orderId],
          userID: values.userID,
        },
      })
      .then(() => {
        message.success("Change designer successfully");
        this.formRef.current.resetFields();
        this.props.hide();
        this.props.refetch();
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  render() {
    const { users } = this.state;
    const { designerId } = this.props;
    const designers = users.filter((user) =>
      user.roles.find((role) => role === "Designer")
    );
    return (
      <Form
        ref={this.formRef}
        className="form-create-task"
        style={{ width: 300 }}
        onFinish={this.onFinish}
      >
        <Form.Item
          name="userID"
          initialValue={designerId ? designerId : []}
          rules={[
            {
              required: true,
              message: "Please select at least one Designer!",
            },
          ]}
        >
          <Select placeholder="Select designer" style={{ minWidth: 180 }}>
            {designers.map((designer) => (
              <Select.Option key={designer.id} value={designer.id}>
                {designer.firstName + " " + designer.lastName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" style={{ marginRight: 10 }} htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
