import { gql } from "apollo-boost";

export default gql`
  query productBase($ids: [ID!]!, $baseId: String) {
    productBaseByIds(ids: $ids) {
      id
      title
      mockup
      sku
      designs {
        id
        name
        description
        fileId
        width
        height
      }
      description
      attributes
      autoRender
      renderAttrName
      images {
        id
        key
        fileName
        fileMime
        fileSize
      }
      carriers {
        id
        name
      }
      category {
        id
        title
      }
      type {
        id
        category
      }
      fulfillService {
        id
        name
      }
      fulfillServices {
        id
        name
      }
      variants {
        id
        sku
        baseId
        attributes
        regularPrice
        basePrice
        salePrice
        ordering
      }
    }
    productBaseVariants(baseId: $baseId) {
      id
      sku
      baseId
      attributes
      regularPrice
      basePrice
      salePrice
      ordering
      status
      createdAt
      ccId
      dsId
      size
    }
  }
`;
