import React, { Component } from "react";
import {
  Card,
  InputNumber,
  Table,
  Tabs,
  Dropdown,
  Menu,
  Button,
  Form,
  Switch,
} from "antd";
import _ from "lodash";
import { CaretDownOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import UploadImage from "./UploadImage";
import ProductUpload from "./ProductUpload";
import { formatter } from "../../helper";
import { DndProvider, DragSource, DropTarget } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 19,
  },
};

const ButtonLayout = {
  wrapperCol: {
    span: 24,
  },
};

const JOIN_KEY = "/";

const { TabPane } = Tabs;

class TabNode extends React.Component {
  render() {
    const { connectDragSource, connectDropTarget, children } = this.props;

    return connectDragSource(connectDropTarget(children));
  }
}

const cardTarget = {
  drop(props, monitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  },
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const WrapTabNode = DropTarget("DND_NODE", cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource("DND_NODE", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode)
);

class DraggableTabs extends React.Component {
  state = {
    order: [],
  };

  moveTabNode = (dragKey, hoverKey) => {
    const newOrder = this.state.order.slice();
    const { children } = this.props;

    React.Children.forEach(children, (c) => {
      if (newOrder.indexOf(c.key) === -1) {
        newOrder.push(c.key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    this.props.handleChangeBaseOrder(newOrder);
    this.setState({
      order: newOrder,
    });
  };

  renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar {...props}>
      {(node) => (
        <WrapTabNode
          key={node.key}
          index={node.key}
          moveTabNode={this.moveTabNode}
        >
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  render() {
    const { order } = this.state;
    const { children, defaultActiveKey, defaultValue, onChange } = this.props;
    const tabs = [];
    React.Children.forEach(children, (c) => {
      tabs.push(c);
    });

    const orderTabs = tabs.slice().sort((a, b) => {
      const orderA = order.indexOf(a.key);
      const orderB = order.indexOf(b.key);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }
      if (orderA !== -1) {
        return -1;
      }
      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);

      return ia - ib;
    });

    return (
      <DndProvider backend={HTML5Backend}>
        <Tabs
          renderTabBar={this.renderTabBar}
          {...this.props}
          defaultActiveKey={defaultActiveKey}
          defaultValue={defaultValue}
          onChange={(value) => onChange(value)}
        >
          {orderTabs}
        </Tabs>
      </DndProvider>
    );
  }
}
class ProductVariants extends Component {
  state = {
    data: [],
    selectedVariants: [],
    variants: {},
    editModal: false,
    selectedImage: null,
    variant: [],
    filesMockup: [],
    productBaseSelected: this.props.productBases[0]?.id,
  };

  formRef = React.createRef();

  difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.mockups, prevProps.mockups)) {
      console.log("re render variants because mockup changes");
      this.handleSetBaseVariants(this.props.productBases);
    }

    if (!_.isEqual(this.state.variants, prevState.variants)) {
      this.props.onChange(this.state.variants);
    }
  }
  componentDidMount() {
    this.handleSetBaseVariants(this.props.productBases);
  }

  setImageToVariant = () => {
    const { productBases, mockups } = this.props;
    const updatedVariants = { ...this.state.variants };
    productBases &&
      productBases.forEach((base) => {
        let variants = this.state.variants[base.id];
        // get all object {file, name, sku, option} from mockups
        const newFileMockup = mockups.map((mockup) =>
          this.getOptionFromMockup(mockup)
        );
        variants = variants?.map((variant) => {
          const selectedMockup = newFileMockup.find((mockup) => {
            // Find mockup matching with attributeName
            const selectedOption = variant.attributes.find(
              (attribute) => attribute.name === base.renderAttrName
            );

            if (selectedOption) {
              // check matching option & sku
              return (
                selectedOption.option === mockup.option &&
                variant.sku.split("-")[0].toLowerCase() ===
                  mockup.sku.toLowerCase()
              );
            }
            return false;
          });
          if (selectedMockup) {
            return {
              ...variant,
              file: selectedMockup.mockup,
              fileId: selectedMockup.mockup.imageId,
            };
          }
          return { ...variant };
        });
        updatedVariants[base.id] = variants;
      });
    this.setState({
      ...this.state,
      variants: updatedVariants,
      selectedVariants: [],
    });
  };

  getOptionFromMockup = (mockup) => {
    const [filename] = mockup.fileName.split(".");
    const [tempName, sku, option] = filename.split("_");
    return {
      mockup,
      tempName,
      sku,
      option,
    };
  };

  handleSetBaseVariants = (productBases) => {
    let variants = {};
    for (let i = 0; i < productBases?.length; i++) {
      let newVariants = this.getVariants(productBases[i]);
      if (_.has(this.state.variants, productBases[i].id)) {
        newVariants = newVariants.map((variant) => {
          const stateVariants = this.state.variants[productBases[i].id].find(
            (stateVariant) => stateVariant.key === variant.key
          );
          if (stateVariants) {
            variant.salePrice = stateVariants.salePrice;
            variant.basePrice = stateVariants.basePrice;
            variant.regularPrice = stateVariants.regularPrice;
            variant.status = stateVariants.status;
          }
          return variant;
        });
      }
      variants[productBases[i].id] = newVariants;
    }
    this.setState(
      {
        variants,
      },
      () => {
        // this.setImageToVariant();
      }
    );
  };

  getAttributeKey = (attributes) => {
    if (attributes) {
      return attributes
        .sort((a, b) => a.option < b.option)
        .map((att) => att.option)
        .join(JOIN_KEY);
    }
  };

  getUniqueKey = (key) => {
    var hash = 0;
    if (key.length === 0) {
      return hash;
    }
    for (var i = 0; i < key.length; i++) {
      var char = key.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    const number = 0.0000000001;
    return Math.abs(hash * number)
      .toString(36)
      .substr(2, 10);
  };

  genSKUVariantKey = (attributes, fileId) => {
    if (attributes) {
      const key = attributes
        .sort((a, b) => a.option < b.option)
        .map((att) => att.option)
        .join("-");
      let uniqueSuffix;
      if (fileId) {
        uniqueSuffix = this.getUniqueKey(`${fileId}-${key}`);
      } else {
        uniqueSuffix = Math.random().toString(36).substr(2, 10);
      }
      return `${key}-${uniqueSuffix}`.toLocaleUpperCase();
    }
  };

  getMockupSkuOptionFromFileName = (filename) => {
    const splitMockup = filename.split("_");
    if (splitMockup.length < 2) {
      return null;
    }
    const len = splitMockup.length;
    const sku = splitMockup[len - 2];
    const optionWithFileName = splitMockup[len - 1];
    //fileName": "shirt_Gray.jpg",
    const extSplit = optionWithFileName.split(".");
    return [sku, extSplit[0]];
  };

  generateVariantsByAttributes = (arrays) => {
    if (arrays.length === 0) {
      return [];
    }
    let results = [[]];
    arrays.forEach((attribute) => {
      let tmp = [];
      results.forEach((resultItem) => {
        attribute.options.forEach((option) => {
          tmp.push(
            resultItem.concat([
              {
                name: attribute.name,
                option,
              },
            ])
          );
        });
      });
      results = tmp;
    });
    return results;
  };

  getUpdateProductVariants(productBase) {
    const { product, mockups } = this.props;
    const variants =
      product.variants && product.variants.length ? product.variants : [];
    variants.map((varri) => {
      varri.key = this.genSKUVariantKey(
        varri.attributes,
        varri.file ? varri.file.id : null
      );
      return varri;
    });
    if (!productBase.autoRender) {
      return variants.filter((v) => v.baseId === productBase.id);
    } else {
      if (mockups.length === 0) {
        return variants.filter((v) => v.baseId === productBase.id);
      }
      // xu ly ca mockups
      let nameMapping = {};
      let attributes = [];
      const variant = variants.filter((v) => v.baseId === productBase.id);
      variant.forEach((v) => {
        const key = this.getAttributeKey(v.attributes);
        variant[key] = v;
        v.attributes.forEach((att) => {
          if (nameMapping[att.name]) {
            // da ton tai name
            attributes = attributes.map((a) => {
              if (a.name === att.name && !a.options.includes(att.option)) {
                return {
                  ...a,
                  options: [...a.options, att.option],
                };
              }

              return a;
            });
          } else {
            nameMapping[att.name] = true;
            attributes.push({
              name: att.name,
              options: [att.option],
            });
          }
        });
      });

      // xu ly mockup

      mockups.forEach((mockup) => {
        // "fileName": "shirt_Gray.jpg",
        const skuOption = this.getMockupSkuOptionFromFileName(mockup.fileName);
        const option = skuOption ? skuOption[1] : null;
        if (skuOption !== null) {
          // [sku, option] ex: [shirt, red]
          if (skuOption[0].toLowerCase() === productBase.sku.toLowerCase()) {
            attributes = attributes.map((att) => {
              if (att.name === productBase.renderAttrName) {
                if (!att.options.includes(option)) {
                  return {
                    ...att,
                    options: [...att.options, option],
                  };
                }
              }
              return att;
            });
          }
        }
      });

      // let variantMapping = {};
      // variant.forEach((v) => {
      //   const key = this.getAttributeKey(v.attributes);
      //   variantMapping[key] = v;
      // });
      // let variantMapping = {};

      const genVariants = this.generateVariantsByAttributes(
        attributes.filter((att) => att.options.length > 0)
      );
      return genVariants.map((v, index) => {
        //////////////////////////// RENDER IMAGE ////////////////////////////////////
        const newFileMockup = mockups.map((mockup) =>
          this.getOptionFromMockup(mockup)
        );
        const selectedMockup = newFileMockup.find((mockup) => {
          // Find mockup matching with attributeName
          const selectedOption = v.find(
            (attribute) => attribute.name === productBase.renderAttrName
          );
          if (selectedOption) {
            // check matching option & sku
            return (
              selectedOption.option === mockup.option &&
              productBase.sku.split("-")[0].toLowerCase() ===
                mockup.sku.toLowerCase()
            );
          }
          return false;
        });
        //////////////////////////// RENDER IMAGE ////////////////////////////////////
        let variantKey;
        if (selectedMockup) {
          variantKey = this.genSKUVariantKey(v, selectedMockup.mockup.imageId);
        } else {
          variantKey = this.genSKUVariantKey(v);
        }
        const variantSku = `${productBase.sku}-${variantKey}`;
        const originVariant = variant.find((item) =>
          _.isEqual(item.attributes, v)
        );
        const variantBase = productBase.variants.find((el) =>
          v.map((v1) => _.isEqual(el.attributes, v1))
        );
        return {
          id: originVariant ? originVariant.id : null,
          baseId: productBase.id,
          sku: originVariant ? originVariant.sku : variantSku,
          basePrice: originVariant
            ? originVariant.basePrice
            : variantBase
            ? variantBase.basePrice
            : 0,
          regularPrice: originVariant
            ? originVariant.regularPrice
            : variantBase
            ? variantBase.regularPrice
            : 0,
          salePrice: originVariant
            ? originVariant.salePrice
            : variantBase
            ? variantBase.salePrice
            : 0,
          fileId: originVariant
            ? originVariant.fileId
            : selectedMockup
            ? selectedMockup.mockup.imageId
            : null,
          ordering: originVariant
            ? originVariant.ordering
            : variantBase
            ? variantBase.ordering
            : index,
          attributes: v,
          status: originVariant
            ? originVariant.status
            : variantBase
            ? variantBase.status
            : true,
          key: variantKey,
          file: originVariant
            ? originVariant.file
            : selectedMockup
            ? selectedMockup.mockup
            : null,
        };
      });
    }
  }

  getVariants = (productBase) => {
    const { mockups, product } = this.props;
    if (product) {
      return this.getUpdateProductVariants(productBase);
    }
    if (!productBase.autoRender) {
      const productBaseVariants = productBase.variants
        ? productBase.variants
        : [];
      return productBaseVariants.map((v, index) => {
        return {
          id: v.id,
          baseId: productBase.id,
          sku: productBase.sku.concat(
            `-${this.genSKUVariantKey(v.attributes, v.file ? v.file.id : null)}`
          ),
          basePrice: v.basePrice,
          regularPrice: v.regularPrice,
          salePrice: v.salePrice,
          fileId: null,
          ordering: index,
          attributes: v.attributes,
          status: true,
          file: v ? v.file : null,
          key: this.genSKUVariantKey(v.attributes),
        };
      });
    } else {
      // ket hop voi mockups
      let nameMapping = {};
      let attributes = [];
      let baseVariantMapping = {};
      // let variantFile = [];
      // tim tat ca cac attributes trong base attributes
      const variantAttributes = productBase.attributes
        ? productBase.attributes
        : [];
      const key = this.getAttributeKey(productBase.attributes);
      baseVariantMapping[key] = productBase.attributes;
      variantAttributes.forEach((v) => {
        if (nameMapping[v.name]) {
          // da ton tai roi
          attributes = attributes.map((a) => {
            if (a.name === v.name && !a.options.includes(v.option)) {
              return {
                ...a,
                options: [...a.options, v.option],
              };
            }
            return a;
          });
        } else {
          nameMapping[v.name] = true;
          // {name: "Color": option: "Red"}
          attributes.push({
            name: v.name,
            options: v.options,
          });
        }
      });
      // them attributes cua render by attribute name
      attributes.push({
        name: productBase.renderAttrName,
        options: [],
      });

      // tim attributes ben trong mockups
      if (mockups.length) {
        mockups.forEach((mockup) => {
          const skuOption = this.getMockupSkuOptionFromFileName(
            mockup.fileName
          ); // ["sku","option"]
          const option = skuOption && skuOption[1]; // "Option"
          if (skuOption !== null) {
            // [sku, option] ex: [shirt, red]
            if (
              skuOption[0].toLowerCase() ===
              productBase.sku.split("-")[0].toLowerCase()
            ) {
              attributes = attributes.map((att) => {
                if (att.name === productBase.renderAttrName) {
                  if (!att.options.includes(option)) {
                    return {
                      ...att,
                      options: [...att.options, option],
                    };
                  }
                }
                return att;
              });
              // return (variantFile = mockup);
              this.setState({
                filesMockup: [...this.state.filesMockup, mockup],
              });
            }
          }
        });
      }
      //gen variants theo attributes
      const genVariants = this.generateVariantsByAttributes(
        attributes.filter((att) => att.options.length > 0)
      );
      return genVariants.map((v, index) => {
        //////////////////////////// RENDER IMAGE ////////////////////////////////////
        const newFileMockup = mockups.map((mockup) =>
          this.getOptionFromMockup(mockup)
        );
        const selectedMockup = newFileMockup.find((mockup) => {
          // Find mockup matching with attributeName
          const selectedOption = v.find(
            (attribute) => attribute.name === productBase.renderAttrName
          );

          if (selectedOption) {
            // check matching option & sku
            return (
              selectedOption.option === mockup.option &&
              productBase.sku.split("-")[0].toLowerCase() ===
                mockup.sku.toLowerCase()
            );
          }
          return false;
        });
        //////////////////////////// RENDER IMAGE ////////////////////////////////////
        const productBaseVariants = productBase?.variants ?? [];
        const variant = productBaseVariants.find((el) =>
          //v.map((v1) => _.isEqual(el.attributes, v1))
          _.isEqual(
            el.attributes[0],
            v.find((v2) => _.isEqual(v2, el.attributes[0]))
          )
        );
        let variantKey;
        if (selectedMockup) {
          variantKey = this.genSKUVariantKey(v, selectedMockup.mockup.imageId);
        } else {
          variantKey = this.genSKUVariantKey(v);
        }

        return {
          baseId: productBase.id,
          sku: productBase.sku.concat(`-${variantKey}`),
          basePrice: variant?.basePrice ?? 0,
          regularPrice: variant?.regularPrice ?? 0,
          salePrice: variant?.salePrice ?? 0,
          fileId: selectedMockup ? selectedMockup.mockup.imageId : null,
          ordering: variant?.ordering ?? index,
          attributes: v,
          status: variant?.status ?? true,
          key: variantKey,
          file: selectedMockup ? selectedMockup.mockup : null,
        };
      });
    }
  };

  handleCancel = () => {
    this.setState({ editModal: false, selectedVariants: [] });
    this.formRef.current.resetFields();
  };

  // change price
  changePrice = (index, value, record, field) => {
    const newVariants = { ...this.state.variants };
    let variants = this.state.variants[record.baseId];
    variants[index][field] = value;
    newVariants[record.baseId] = variants;
    this.setState({ variants: newVariants });
    this.props.onChange(newVariants);
  };

  handleChangeProductBase = (key) => {
    this.setState({ selectedVariants: [], productBaseSelected: key });
  };

  // Bulk action for selectedRowKeys
  onFinish = (values) => {
    const { selectedVariants, productBaseSelected, selectedImage } = this.state;
    const newVariants = { ...this.state.variants };
    newVariants[productBaseSelected] &&
      newVariants[productBaseSelected].forEach((variant) => {
        //
        if (selectedVariants.includes(variant.key)) {
          // change regularPrice
          if (values.regularPrice) {
            variant.regularPrice = values.regularPrice;
          }
          // change salePrice
          if (values.salePrice) {
            variant.salePrice = values.salePrice;
          }
          // change basePrice
          if (values.basePrice) {
            variant.basePrice = values.basePrice;
          }
          // change Image
          if (selectedImage) {
            variant.file = selectedImage;
            variant.fileId = selectedImage.imageId;
          }
        }
      });
    this.setState({
      variants: newVariants,
      selectedImage: null,
      selectedVariants: [],
    });
    this.props.onChange(newVariants);
    this.setVariant(this.state.variants);
    this.handleCancel();
  };

  handleChangeVariantStatus = (value) => {
    const { selectedVariants, productBaseSelected } = this.state;
    const newVariants = { ...this.state.variants };
    newVariants[productBaseSelected] &&
      newVariants[productBaseSelected].forEach((variant) => {
        if (selectedVariants.includes(variant.key)) {
          variant.status = value;
        }
      });
    this.setState({
      variants: newVariants,
      selectedImage: null,
      selectedVariants: [],
    });
  };

  setVariant = (data) => {
    if (this.props.setVariant) {
      this.props.setVariant(data);
    }
  };
  // Math.random().toString(36).substr(2,10)
  render() {
    const {
      selectedVariants,
      variants,
      editModal,
      selectedImage,
      productBaseSelected,
    } = this.state;
    const { productBases } = this.props;
    const columns = [
      {
        title: "Image",
        key: "image",
        render: (record, id) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  editModal: "Change image",
                  selectedVariants: [record.key],
                })
              }
            >
              <UploadImage
                imageWidth="50px"
                custom="modal"
                imageKey={record && record.file ? record.file.key : null}
                accept="image/*"
              />
            </div>
          );
        },
        width: 150,
      },
      {
        title: "Variant",
        key: "variant",
        render: (record) => (
          <div>
            {record.attributes
              ? record.attributes
                  .map((attr) => `${attr.name}: ${attr.option}`)
                  .join("/")
              : null}
            <br></br>
            <span style={{ color: "#999" }}>
              SKU:{record.sku.replace(/\s/g, "-").toUpperCase()}
            </span>
          </div>
        ),
        width: 200,
      },
      {
        title: "Base cost",
        key: "base",
        render: (record, _, index) => {
          return (
            <InputNumber
              onChange={(value) =>
                this.changePrice(index, value, record, "basePrice")
              }
              value={record.basePrice ?? 0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          );
        },
        width: 120,
      },
      {
        title: "Regular price",
        key: "regular",
        render: (record, _, index) => {
          return (
            <InputNumber
              onChange={(value) =>
                this.changePrice(index, value, record, "regularPrice")
              }
              value={record.regularPrice ?? 0}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          );
        },
        width: 140,
      },
      {
        title: "Sale price",
        key: "sale",
        render: (record, _, index) => (
          <InputNumber
            onChange={(value) =>
              this.changePrice(index, value, record, "salePrice")
            }
            value={record.salePrice ?? 0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          />
        ),
        width: 120,
      },
      {
        title: "Profit",
        key: "profit",
        render: (variants) => (
          <span>
            {variants.salePrice
              ? formatter.format(variants.salePrice - variants.basePrice)
              : formatter.format(variants.regularPrice - variants.basePrice)}
          </span>
        ),
      },
      {
        title: "Status",
        key: "status",
        width: 50,
        render: (record, _, index) => (
          <Switch
            onChange={(value) =>
              this.changePrice(index, value, record, "status")
            }
            checked={record.status}
          />
        ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <div>
        <DraggableTabs
          defaultActiveKey={productBaseSelected}
          defaultValue={productBaseSelected}
          onChange={(value) => this.handleChangeProductBase(value)}
          handleChangeBaseOrder={(value) =>
            this.props.handleChangeBaseOrder(value)
          }
        >
          {productBases &&
            _.sortBy(productBases, [
              function (o) {
                return o.ordering;
              },
            ]).map((base) => {
              return (
                <TabPane key={base.id} tab={base.title}>
                  <Card
                    title="Variants"
                    extra={
                      <Dropdown
                        disabled={!selectedVariants.length}
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="Change price"
                              onClick={() =>
                                this.setState({ editModal: "Change price" })
                              }
                            >
                              Price
                            </Menu.Item>
                            <Menu.Item
                              key="Change image"
                              onClick={() =>
                                this.setState({ editModal: "Change image" })
                              }
                            >
                              Image
                            </Menu.Item>
                            <Menu.Item
                              key="Enable"
                              onClick={() =>
                                this.handleChangeVariantStatus(true)
                              }
                            >
                              Enable
                            </Menu.Item>
                            <Menu.Item
                              key="Disable"
                              onClick={() =>
                                this.handleChangeVariantStatus(false)
                              }
                            >
                              Disable
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button type="link" icon={<CaretDownOutlined />}>
                          Edit
                        </Button>
                      </Dropdown>
                    }
                    className="variant-product"
                  >
                    <Table
                      rowSelection={{
                        selectedRowKeys: selectedVariants,
                        onChange: (selectedVariants) =>
                          this.setState({ selectedVariants }),
                        selections:
                          variants && variants[base.id]
                            ? _.concat(
                                ...variants[base.id].map(
                                  (item) => item.attributes
                                )
                              ).reduce((b, c) => {
                                if (
                                  b.filter(
                                    (item) =>
                                      item.option === c.option &&
                                      item.name === c.name
                                  ).length === 0
                                ) {
                                  b.push({
                                    key: `${c.name} : ${c.option}`,
                                    option: c.option,
                                    name: c.name,
                                    text: `${c.name} : ${c.option}`,
                                    onSelect: (changableRowKeys) => {
                                      let newSelectedRowKeys = [];
                                      newSelectedRowKeys = variants[
                                        base.id
                                      ].filter(
                                        (key) =>
                                          key.attributes.filter(
                                            (item) =>
                                              item.option === c.option &&
                                              item.name === c.name
                                          ).length > 0
                                      );
                                      this.setState({
                                        selectedVariants: newSelectedRowKeys.map(
                                          (item) => item.key
                                        ),
                                      });
                                    },
                                  });
                                }
                                return _.orderBy(
                                  b,
                                  ["name", "option"],
                                  ["asc", "asc"]
                                );
                              }, [])
                            : null,
                      }}
                      columns={columns}
                      rowKey="key"
                      scroll={{ x: tableWidth }}
                      dataSource={variants[base.id]}
                      pagination={false}
                    />
                  </Card>
                </TabPane>
              );
            })}
        </DraggableTabs>

        <Modal
          title={editModal}
          visible={!!editModal}
          onCancel={() => this.handleCancel()}
          footer={null}
          style={{ minWidth: 558 }}
          width="fit-content"
        >
          <Form
            {...layout}
            ref={this.formRef}
            onFinish={(values) => this.onFinish(values)}
          >
            {editModal === "Change price" ? (
              <div>
                <Form.Item
                  className="custom-label"
                  label="Base Cost"
                  name="basePrice"
                >
                  <InputNumber
                    style={{ width: 150 }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
                <Form.Item
                  className="custom-label"
                  label="Regular Price"
                  name="regularPrice"
                >
                  <InputNumber
                    style={{ width: 150 }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
                <Form.Item
                  className="custom-label"
                  label="Sale Price"
                  name="salePrice"
                >
                  <InputNumber
                    style={{ width: 150 }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            ) : (
              <Form.Item name="file">
                <ProductUpload
                  accept="image/*"
                  type="horizontal"
                  selectedVariants={selectedVariants}
                  mockups={this.props.mockups}
                  uploadImageVariant={(files) =>
                    this.props.uploadImageVariant(files)
                  }
                  imageVariant={this.props.imageVariant}
                  onSelect={(file) => {
                    this.setState({ selectedImage: file });
                  }}
                />
              </Form.Item>
            )}
            <Form.Item
              {...ButtonLayout}
              style={{ marginBottom: 0, textAlign: "end" }}
            >
              <Button onClick={() => this.handleCancel()}>Cancel</Button>
              <Button
                style={{ marginLeft: 15 }}
                disabled={!selectedImage && editModal === "Change image"}
                type="primary"
                htmlType="submit"
              >
                Ok
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default ProductVariants;
