const origin = window.location.origin;
const MODE = "dev1";
export const TOKEN_NAME = "ictees_token2";
export const TRELLO_TOKEN = "__tk";
export const TRELLO_URL = "https://ictees.niftyjs.com/";
export const API_URI =
  MODE === "dev" && origin === "http://localhost:3000"
    ? "localhost:7890"
    : "ictees-staging.niftyjs.com";
export const API_URL =
  MODE === "dev" && origin === "http://localhost:3000"
    ? `http://${API_URI}`
    : `https://${API_URI}`;
export const APP_URL =
  MODE === "dev" && origin === "http://localhost:3000"
    ? "http://localhost:3000"
    : "https://ictees1.niftyjs.com";

export const SHOPIFY_API = "8a7be891377ee5a50940961b7ecda3d2";
export const AWS_CLOUDFRONT_URL = "https://d2mpqjdvrrtjpj.cloudfront.net";
export const AWS_CLOUDFRONT_THUMB_URL = "https://d18n15iff3ogsh.cloudfront.net";
