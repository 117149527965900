import { gql } from "apollo-boost";

export const UPDATE_ORDER_VARIANT = gql`
  mutation UpdateOrderVariant($id: ID!, $variantId: ID!) {
    updateOrderVariant(id: $id, variantId: $variantId) 
  }
`;
export const UPDATE_ORDER_SHIPPING_METHOD = gql`
  mutation BulkUpdateOrderShippingMethod($input: [OrderShippingMethodInput!]) {
    bulkUpdateOrderShippingMethod(input: $input)
  }
`;