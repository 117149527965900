import { gql } from "apollo-boost";

export default gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      variants {
        id
        attributes
        status
      }
    }
  }
`;
