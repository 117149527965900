import React, { Component } from "react";
import ExportTemplateList from "../components/export-template/ExportTemplates";
import { PageHeader } from "antd";
class ExportTemplates extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Export Templates"
          onBack={() => this.props.history.goBack()}
        />
        <ExportTemplateList />
      </div>
    );
  }
}

export default ExportTemplates;
