import React, { Component } from "react";
import { PageHeader } from "antd";
import UserList from "../components/users/Users";
import { AppContext } from "../context";

class Users extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <div>
            <PageHeader
              title="User"
              onBack={() => this.props.history.goBack()}
            />
            <UserList currentUser={currentUser} />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default Users;
