import React, { Component } from "react";
import styled from "styled-components";
import { message } from "antd";
import createFile from "../graphql/mutates/createFile";
import Upload from "./Upload";
import { apolloClient } from "../apollo-client";
import { AWS_CLOUDFRONT_URL } from "../config";

const Container = styled.div``;

export default class UploadImage extends Component {
  state = {
    fileList: [],
  };
  onFinish = (values) => {
    apolloClient
      .mutate({
        mutation: createFile,
        variables: {
          input: {
            source: "aws",
            key: values.key,
            fileName: values.file.name,
            fileMime: values.file.type,
            fileSize: values.file.size,
          },
        },
      })
      .then((res) => {
        if (this.props.onChange) {
          let newFile = {
            ...res.data.createFile,
            url:
              res.data.createFile.url ??
              `${AWS_CLOUDFRONT_URL}/${res.data.createFile.key}`,
          };
          this.props.onChange([newFile]);
          // src={`${AWS_CLOUDFRONT_URL}/${el.key}`}
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    return (
      <Container>
        <Upload
          fileList={this.state.fileList}
          multiple={true}
          onChange={(values) => this.onFinish(values)}
        />
      </Container>
    );
  }
}
