import React, { Component } from "react";
import { PageHeader } from "antd";
import ListSetting from "../../src/components/setting/setting";

class Setting extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Settings"
          onBack={() => this.props.history.goBack()}
        />
        <ListSetting />
      </div>
    );
  }
}

export default Setting;
