import React, { Component } from "react";
import SideBar from "../components/SideBar";
import routes from "../routes";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import { Drawer } from "antd";

const MOBILEWIDTH = 991;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-rows: 64px auto;
  .header {
    background-color: #30646B;
    box-shadow: 0px 1px 5px 1px rgba(48, 100, 107, 0.3);
  }
  .content {
    display: grid;
    grid-template-columns: ${(props) =>
      window.innerWidth > MOBILEWIDTH
        ? props.collapsed === true
          ? "80px auto"
          : "200px auto"
        : "auto"};
    box-sizing: border-box;
    max-width: 100vw;
    overflow: hidden;
  }
  .main-content {
    grid-template-rows: auto 55px;
    height: calc(100vh - 55px);
    display: grid;
    .footer {
      display: grid;
      z-index: 100;
      p {
        margin: auto;
        text-align: center;
      }
    }
  }
  .toolbar {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(232, 236, 238);
    box-shadow: rgba(21, 27, 38, 0.15) 0px 1px 6px 0px;
    z-index: 20;
    .extra {
      margin: auto 20px;
      .collapse-button {
        display: inline-block;
        height: 44px;
        width: 44px;
        line-height: 44px;
        margin-right: 0px;
        font-size: 20px;
      }
    }
  }
`;
class DefaultLayout extends Component {
  state = {
    collapsed: false,
    draw: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { collapsed } = this.state;
    return (
      <Container collapsed={collapsed}>
        <div className="header">
          <NavBar onClick={this.toggleCollapsed} />
        </div>
        {/* <div className="toolbar">
          <div className="extra">
            <span className="collapse-button" onClick={this.toggleCollapsed}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
              )}
              <span>{title && title}</span>
            </span>
          </div>
        </div> */}
        <div className="content">
          {window.innerWidth > MOBILEWIDTH ? (
            <SideBar
              className="side-bar"
              collapsed={collapsed}
              routes={routes}
            />
          ) : (
            <Drawer
              bodyStyle={{
                padding: 0,
              }}
              visible={collapsed}
              placement="left"
              closable={false}
              maskClosable={true}
              onClose={(e) => {
                this.setState({
                  collapsed: false,
                });
              }}
            >
              <SideBar collapsed={null} className="side-bar" routes={routes} />
            </Drawer>
          )}
          <div className="main-content">
            <div style={{ padding: 20, overflowX: "auto" }}>
              {this.props.children}
            </div>
            <div className="footer">
              <p>ICTees ©2020. Created by <a href="https://niftyjs.com" title="NiftyJS">NiftyJS</a></p>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default DefaultLayout;
