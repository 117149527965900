import { Button, Modal, Select, Skeleton } from "antd";
import React, { useState } from "react";
import { Query } from "react-apollo";
import fulfillmentProducts from "../../graphql/queries/orders/fulfillmentProducts";

const SelectMapingFulfill = ({
  type,
  selectedRowKeys,
  setSelectRowKey,
  setVisibleAllFulfull,
  onChange,
  dataSource,
}) => {
  const [product, setProduct] = useState();
  const [variants, setVariants] = useState([]);
  const [dataMap, setDataMap] = useState(selectedRowKeys);
  const checkVariant = (attributes, variants) => {
    let result = variants.find((item) => {
      let abc = attributes?.map((att) => {
        if (att.name.toLowerCase() === "color") {
          let objectColor = item.attributes.find(
            (item) => item.name.toLowerCase() === "color"
          );
          if (objectColor) {
            return att.option
              .toLowerCase()
              .indexOf(objectColor.option.toLowerCase()) === 0
              ? true
              : false;
          } else {
            return false;
          }
        } else {
          let objectAtr = item.attributes.find(
            (item) => item.name.toLowerCase() === att.name.toLowerCase()
          );
          if (objectAtr) {
            return objectAtr.option.toLowerCase() === att.option.toLowerCase();
          } else {
            return false;
          }
        }
      });
      if (!abc.includes(false)) {
        return item;
      }
    });

    return result?.fulfillment_product_id
      ? result.fulfillment_product_id
      : null;
  };
  const nameId = type === "customcat" ? "ccId" : "dsId";

  return (
    <div>
      <Modal
        title="Select record select fulfillment"
        visible={type === false ? false : true}
        width={500}
        onCancel={() => {
          setVisibleAllFulfull(false);
        }}
        footer={[
          <Button onClick={() => setVisibleAllFulfull(false)}>Cancel</Button>,
          <Button
            type="primary"
            disabled={
              !dataMap.find((item) => item[nameId] || item[nameId] !== null) ||
              !product
            }
            onClick={() => {
              let newData = dataSource.map((item) => {
                let NewItem = dataMap.find((data) => data.sku === item.sku);
                if (NewItem) {
                  return NewItem;
                } else {
                  return item;
                }
              });
              onChange(newData);
              setSelectRowKey([]);
              setVisibleAllFulfull(false);
            }}
          >
            Save
          </Button>,
        ]}
      >
        <Query
          query={fulfillmentProducts}
          fetchPolicy="no-cache"
          variables={{ filter: { fulfillmentName: type, limit: -1 } }}
        >
          {({ error, loading, data, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            return (
              <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                <p>Product:</p>
                <Select
                  style={{ width: "100%", marginBottom: 20 }}
                  onChange={(value, data) => {
                    setProduct(value);
                    setVariants(data.variants);

                    let newData = dataMap.map((item) => {
                      return {
                        ...item,
                        [nameId]: checkVariant(item.attributes, data.variants),
                      };
                    });
                    setDataMap(newData);
                  }}
                  showSearch
                  filterOption={(input, option) =>
                    option.search.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {data.fulfillmentProducts.hits.map((item) => (
                    <Select.Option
                      value={item.id}
                      variants={item.variants}
                      key={item.id}
                      search={item.title}
                    >
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
                {product &&
                  dataMap.map((item, index) => (
                    <div
                      style={{
                        display: "grid",
                        gap: 10,
                        gridTemplateColumns: "100px 300px",
                        marginBottom: 10,
                      }}
                      key={index}
                    >
                      <span>
                        {item?.attributes?.map((attr) => attr.option).join("/")}
                      </span>
                      <Select
                        value={item[nameId]}
                        style={{ width: "100%", display: "block" }}
                        onChange={(value) => {
                          let NewDataMap = dataMap.map((data, key) => {
                            if (key === index) {
                              console.log("vao cao");
                              return { ...data, [nameId]: value };
                            } else {
                              return data;
                            }
                          });
                          console.log("NewDataMap", NewDataMap);
                          setDataMap(NewDataMap);
                          // onChange(value);
                        }}
                        filterOption={(input, option) =>
                          option.search
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                      >
                        {variants.map((variant) => (
                          <Select.Option
                            value={variant.fulfillment_product_id}
                            key={variant.fulfillment_product_id}
                            search={`${variant.attributes.map(
                              (item) => `${item.name}: ${item.option} `
                            )} ${variant.fulfillment_product_id}`}
                          >
                            {" "}
                            {variant.attributes.map(
                              (att) => `${att.name}: ${att.option},`
                            )}
                            ({variant.fulfillment_product_id})
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  ))}
              </div>
            );
          }}
        </Query>
      </Modal>
    </div>
  );
};

export default SelectMapingFulfill;
