import React, { Component } from "react";
import FulfillmentList from "../components/fulfillments/Fulfillments";
import { PageHeader } from "antd";
class Fulfillments extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Fulfillment Services"
          onBack={() => this.props.history.goBack()}
        />
        <FulfillmentList />
      </div>
    );
  }
}

export default Fulfillments;
