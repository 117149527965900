import React, { Component } from "react";
import productCategories from "../../graphql/queries/productCategories";
import { Select, Skeleton } from "antd";
import { Query } from "react-apollo";
class ProductCategoriesSelect extends Component {
  render() {
    return (
      <div>
        <Query query={productCategories}>
          {({ loading, data, error }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            return (
              <Select
                placeholder="Select..."
                optionFilterProp="children"
                mode={"multiple"}
                value={this.props.value ? this.props.value : []}
                onChange={(value) => {
                  this.setState({ value }, () => this.props.onChange(value));
                }}
              >
                {data?.productCategories?.nodes.map((el) => (
                  <Select.Option key={el.id} value={el.id}>
                    {el.title}
                  </Select.Option>
                ))}
              </Select>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ProductCategoriesSelect;
