import { gql } from "apollo-boost";

export default gql`
  query productCategories($filter: ProductCategoryFilter) {
    productCategories(filter: $filter) {
      total
      nodes {
        id
        title
        status
        description
        createdAt
      }
    }
  }
`;
