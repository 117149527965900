import { gql } from "apollo-boost";

export default gql`
  query productBases($filter: ProductBaseFilter) {
    productBases(filter: $filter) {
      total
      nodes {
        id
        title
        mockup
        sku
        storeType
        designs {
          id
          name
          description
          fileId
          width
          height
        }
        description
        attributes
        autoRender
        renderAttrName
        images {
          id
          key
          fileName
          fileMime
          fileSize
          thumbnail
        }
        carriers {
          id
          name
        }
        category {
          id
          title
        }
        type {
          id
          category
          origin
        }
        fulfillService {
          id
          name
        }
        variants {
          id
          baseId
          attributes
          regularPrice
          basePrice
          salePrice
          ordering
        }
      }
    }
  }
`;
