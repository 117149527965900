import React, { Component } from "react";
import {
  Table,
  Button,
  Input,
  Popconfirm,
  Select,
  InputNumber,
  Form,
  Modal,
} from "antd";
import styled from "styled-components";
import {
  PlusOutlined,
  DeleteOutlined,
  DragOutlined,
  QuestionCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { DropTarget, DragSource, DndProvider } from "react-dnd";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import _ from "lodash";
import SelectDSName from "./SelectDSName";

const Container = styled.div`
  table tr.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }
  table tr.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`;
let dragingIndex = -1;
class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      ...restProps
    } = this.props;
    const style = { ...restProps.style, cursor: "move" };

    let { className } = restProps;
    if (isOver) {
      if (restProps.index > dragingIndex) {
        className += " drop-over-downward";
      }
      if (restProps.index < dragingIndex) {
        className += " drop-over-upward";
      }
    }
    return connectDragSource(
      connectDropTarget(
        <tr {...restProps} className={className} style={style} />
      )
    );
  }
}
const rowSource = {
  beginDrag(props) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};
const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
};
const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource("row", rowSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);
class ProductBasePrintFile extends Component {
  state = {
    data: [],
    percent: [],
    uploading: [],
    nameDS: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (
        (this.props.isCustomcat &&
          this.props.value.find(
            (item) => !item.ccName || item.ccName === ""
          )) ||
        (this.props.isDreamship &&
          this.props.value.find(
            (item) => !item.dsName || item.dsName === ""
          )) ||
        this.props.value.find((item) => item.name === "")
      ) {
        this.props.checkValidate(true);
      } else {
        this.props.checkValidate(false);
      }

      this.setState({ data: this.props.value ? this.props.value : [] });
    }
  }
  handleDelete = (i) => {
    const { data } = this.state;
    const newData = data.filter((el, item) => item !== i);
    this.setState({ data: newData }, () => this.props.onChange(newData));
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };
  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
      () => {
        this.props.onChange(this.state.data);
      }
    );
  };
  render() {
    const { data } = this.state;
    const { productBaseVariants } = this.props;
    const columns = [
      {
        title: "",
        key: "drag",
        width: 30,
        render: () => <DragOutlined />,
      },
      // {
      //   title: "Mockup",
      //   key: "fileId",
      //   dataIndex: "fileId",
      //   width: 200,
      //   render: (fileId, _, index) => (
      //     <div>
      //       <UploadPrintFile
      //         value={fileId}
      //         multiple={false}
      //         fileList={false}
      //         onUpload={(file) => {
      //           const { data } = this.state;
      //           data[index].fileId = file.url;
      //           this.setState({ data }, () => this.props.onChange(data));
      //         }}
      //       >
      //         <UploadPreview>
      //           {fileId ? <img alt="" src={fileId} /> : <PlusOutlined />}
      //         </UploadPreview>
      //       </UploadPrintFile>
      //     </div>
      //   ),
      // },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        width: 200,
        render: (name, _, index) => (
          <Form.Item
            style={{ marginBottom: 0 }}
            validateStatus={!name || name === "" ? "error" : ""}
          >
            <Input
              placeholder="Name"
              value={name}
              onChange={(e) => {
                data[index].name = e.target.value;
                data[index].ccName = e.target.value;
                // data[index].dsName = e.target.value;
                this.setState({ data }, () => this.props.onChange(data));
              }}
            />
            <div>
              {!name || name === "" ? (
                <small
                  style={{
                    position: "absolute",
                    bottom: -14,
                    color: "red",
                  }}
                >
                  Please input name
                </small>
              ) : null}
            </div>
          </Form.Item>
        ),
      },
      {
        title: "Target",
        key: "target",
        dataIndex: "target",
        render: (target, _, index) => (
          <Select
            mode="multiple"
            onChange={(values) => {
              data[index].target = values;
              this.setState({ data }, () => this.props.onChange(data));
            }}
            value={target ? target : []}
          >
            {productBaseVariants?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item?.attributes?.map((attr) => attr.option).join("/")}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Size",
        key: "size",
        width: 180,
        dataIndex: "size",
        render: (_, { width, height }, index) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 20px auto",
              alignItems: "center",
            }}
          >
            <InputNumber
              placeholder="Width"
              style={{ width: "100%" }}
              value={width}
              onChange={(value) => {
                data[index].width = value;
                this.setState({ data }, () => this.props.onChange(data));
              }}
            />
            <div style={{ textAlign: "center" }}>x</div>
            <InputNumber
              placeholder="Height"
              style={{ width: "100%" }}
              value={height}
              onChange={(value) => {
                data[index].height = value;
                this.setState(
                  {
                    data,
                  },
                  () => this.props.onChange(data)
                );
              }}
            />
          </div>
        ),
      },
      {
        title: "Note",
        key: "note",
        dataIndex: "description",
        width: 200,
        render: (description, _, index) => (
          <Input
            placeholder="Description"
            value={description}
            onChange={(e) => {
              data[index].description = e.target.value;
              this.setState({ data }, () => this.props.onChange(data));
            }}
          />
        ),
      },
      {
        title: "side",
        key: "side",
        dataIndex: "side",
        width: 120,
        render: (side, _, index) => (
          <Select
            value={side}
            onChange={(value) => {
              data[index].side = value;
              this.setState({ data }, () => this.props.onChange(data));
            }}
          >
            <Select.Option value="front">Front</Select.Option>
            <Select.Option value="back">Back</Select.Option>
            <Select.Option value="left">Left</Select.Option>
            <Select.Option value="right">Right</Select.Option>
          </Select>
        ),
      },
    ];
    if (this.props.isCustomcat) {
      columns.push({
        title: "ccName",
        key: "ccName",
        dataIndex: "ccName",
        width: 150,
        render: (ccName, _, index) => (
          <Form.Item
            style={{ marginBottom: 0 }}
            validateStatus={!ccName || ccName === "" ? "error" : ""}
          >
            <Input
              placeholder="ccName"
              value={ccName}
              onChange={(e) => {
                data[index].ccName = e.target.value;
                this.setState({ data }, () => this.props.onChange(data));
              }}
            />
            <div>
              {!ccName || ccName === "" ? (
                <small
                  style={{
                    position: "absolute",
                    bottom: -14,
                    color: "red",
                  }}
                >
                  Please input ccName
                </small>
              ) : null}
            </div>
          </Form.Item>
        ),
      });
    }
    if (this.props.isDreamship) {
      columns.push({
        title: "dsName",
        key: "dsName",
        dataIndex: "dsName",
        width: 200,
        render: (dsName, _, index) => (
          <Form.Item
            style={{ marginBottom: 0 }}
            validateStatus={!dsName || dsName === "" ? "error" : ""}
          >
            <div style={{ display: "flex" }}>
              <Input
                placeholder="dsName"
                value={dsName}
                onChange={(e) => {
                  data[index].dsName = e.target.value;
                  this.setState({ data }, () => this.props.onChange(data));
                }}
              />
              <Button
                type="link"
                onClick={() => {
                  this.setState({ dsname: { name: dsName, index } });
                }}
                icon={<FormOutlined />}
              />
            </div>

            <div>
              {!dsName || dsName === "" ? (
                <small
                  style={{
                    position: "absolute",
                    bottom: -14,
                    color: "red",
                  }}
                >
                  Please input dsName
                </small>
              ) : null}
            </div>
          </Form.Item>
        ),
      });
    }
    columns.push({
      title: "",
      key: "remove",
      width: 50,
      align: "center",
      render: (_, row, i) => (
        <Popconfirm
          title="Are you sure to delete?"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          okButtonProps={{ type: "danger" }}
          onConfirm={() => this.handleDelete(i)}
        >
          <DeleteOutlined style={{ color: "red" }} />
        </Popconfirm>
      ),
    });

    const tableWidth = _.sum(columns.map((c) => c.width));
    console.log("data", data);
    return (
      <Container>
        <DndProvider backend={HTML5Backend}>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(index, key) => key}
            components={this.components}
            pagination={false}
            scroll={{ x: tableWidth }}
            onRow={(row, index) => ({
              index,
              moveRow: this.moveRow,
            })}
            footer={() => (
              <Button
                type="link"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      data: [
                        ...data,
                        {
                          name: "",
                          width: null,
                          height: null,
                          description: "",
                          side: "",
                          dsName: "",
                          ccName: "",
                          target: [],
                        },
                      ],
                    },
                    () =>
                      this.props.onChange([
                        ...data,
                        {
                          name: "",
                          width: null,
                          height: null,
                          description: "",
                          side: "",
                          dsName: "",
                          ccName: "",
                          target: [],
                        },
                      ])
                  );
                }}
              >
                <PlusOutlined /> Add new
              </Button>
            )}
          />
        </DndProvider>
        {this.state.dsname && (
          <Modal
            title="Select DS Name"
            onCancel={() => {
              this.setState({
                dsname: false,
              });
            }}
            visible={this.state.dsname}
            footer={
              <div>
                <Button
                  onClick={() => {
                    this.setState({
                      dsname: false,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  disabled={this.state.nameDS === null}
                  onClick={() => {
                    data[this.state.dsname.index].dsName = this.state.nameDS;
                    this.setState({ data }, () => this.props.onChange(data));
                    this.setState({ nameDS: null, dsname: false });
                  }}
                >
                  Save
                </Button>
              </div>
            }
          >
            <SelectDSName
              onChange={(value) => {
                this.setState({ nameDS: value });
              }}
            />
          </Modal>
        )}
      </Container>
    );
  }
}

export default ProductBasePrintFile;
