import React, { Component } from "react";
import { Form, PageHeader, Input, Button, message } from "antd";
import { Mutation } from "react-apollo";
import login from "../graphql/mutates/login";
import styled from "styled-components";
import { AppContext } from "../context";

const Container = styled.div`
  width: 350px;
  max-width: 90%;
  margin: 100px auto;
  border: 1px solid #f5f5f5;
  padding: 20px;
`;
class Login extends Component {
  static contextType = AppContext;
  login = (mutate, values) => {
    const { login } = this.context;
    mutate({ variables: values })
      .then((res) => {
        /*this.props.setToken(res.data.login.access_token);
      this.props.setCurrentUser(res.data.login.user);*/
        login(res.data.login);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  onError = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <Container>
        <PageHeader title="Login" onBack={() => {}} />
        <Mutation mutation={login}>
          {(login, res) => (
            <Form
              name="login"
              layout="vertical"
              initialValues={{}}
              onFinish={(values) => this.login(login, values)}
              onFinishFailed={this.onError}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          )}
        </Mutation>
      </Container>
    );
  }
}
export default Login;
