import React, { Component } from "react";
import { Form, Input, Button, notification } from "antd";
import styled from "styled-components";
import createCarrier from "../../graphql/mutates/createCarrier";
import { apolloClient } from "../../apollo-client";
import updateCarrier from "../../graphql/mutates/editCarrier";

const Container = styled.div`
  .btn {
    margin-right: 5px;
  }
`;
class CarrierForm extends Component {
  formRef = React.createRef();

  validatorHttps = (value) => {
    var expression = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    var regex = new RegExp(expression);
    if (value.match(regex) && value.includes("{{TRACKING_CODE}}")) {
      return true;
    }
    return false;
  };

  onFinish = (values) => {
    const id = this.props.showDrawer?.id;
    if (!id) {
      apolloClient
        .mutate({
          mutation: createCarrier,
          variables: {
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Add success!" });
          this.setState({
            loading: false,
          });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        })
        .catch((err) => {
          notification.error(err.toString());
        });
    }
    if (id) {
      apolloClient
        .mutate({
          mutation: updateCarrier,
          variables: {
            id: id,
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Edit success!" });
          this.setState({
            loading: false,
          });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        });
    }
  };
  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const { showDrawer } = this.props;
    return (
      <Container>
        <Form ref={this.formRef} {...layout} onFinish={this.onFinish}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input name!" }]}
            initialValue={showDrawer ? showDrawer.name : null}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: "Please input code!" }]}
            initialValue={showDrawer ? showDrawer.code : null}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Url"
            name="trackingUrl"
            // rules={[{ required: true, message: "Please input url!" }]}
            initialValue={showDrawer ? showDrawer.trackingUrl : null}
            required
            rules={[
              {
                validator: (_, value) =>
                  this.validatorHttps(value.trim())
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter a valid tracking URL (e.g. https://t.17track.net/en#nums={{TRACKING_CODE}})!"
                      ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button className="btn" htmlType="submit" type="primary">
              {showDrawer.id ? "Save change" : "Save"}
            </Button>
            <Button onClick={() => this.props.onClose()}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default CarrierForm;
