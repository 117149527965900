import React, { Component } from "react";
import { Table, Skeleton, Input, Button, notification } from "antd";
import styled from "styled-components";
import orderExports from "../../graphql/queries/orders/orderExports";
import downloadFile from "../../graphql/mutates/downloadFile";
import { Query } from "react-apollo";
import _ from "lodash";
import { DownloadOutlined } from "@ant-design/icons";
import DateRange from "../DateRange";
import moment from "moment";
import { apolloClient } from "../../apollo-client";

const Container = styled.div`
  .p-filter {
    display: flex;
    margin-bottom: 20px;
  }
  .ant-table .ant-table-tbody > tr > td {
    height: 50px;
  }
`;
class DownloadCSV extends Component {
  state = {
    filter: {
      supplier_id: null,
      from: null,
      to: null,
      search: null,
      limit: 10,
      offset: 0,
    },
    page: 1,
  };

  onChangeDate = (range) => {
    const { filter } = this.state;
    if (range.from !== null && range.to !== null) {
      this.setState({
        filter: {
          ...filter,
          from: moment(range.from).format("MMM DD, YYYY"),
          to: moment(range.to).format("MMM DD, YYYY"),
          offset: 0,
        },
        page: 1,
      });
    } else {
      this.setState({
        filter: { ...filter, from: null, to: null },
      });
    }
  };

  render() {
    const { filter, page } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <DateRange
            defaultDate={
              filter.from === null && filter.to === null
                ? [filter.from, filter.to]
                : [moment(filter.from), moment(filter.to)]
            }
            onChange={(value) => this.onChangeDate(value)}
          />
          <Input.Search
            style={{ width: 500, marginLeft: 20 }}
            enterButton
            onSearch={(search) => {
              this.setState({
                filter: { ...filter, search, offset: 0 },
                page: 1,
              });
            }}
            onChange={(e) => {
              if (e.target.value.length < 0) {
                this.setState({
                  filter: { ...filter, search: e.target.value, offset: 0 },
                  page: 1,
                });
              }
            }}
            defaultValue={filter.search}
            className="search"
            placeholder="Search by order id"
          />
          <Button
            style={{ marginLeft: 20 }}
            type="primary"
            onClick={() => {
              this.setState({
                filter: {
                  supplier_id: null,
                  from: null,
                  to: null,
                  search: null,
                  limit: 10,
                  offset: 0,
                },
                page: 1,
              });
            }}
          >
            Reset Filter
          </Button>
        </div>
        <Query query={orderExports} variables={{ ...filter }}>
          {({ loading, data, error, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const columns = [
              {
                title: "FileName",
                key: "fileName",
                dataIndex: "fileName",
                width: 200,
              },
              {
                title: "Created at",
                key: "create_at",
                dataIndex: "createdAt",
                width: 150,
              },
              {
                title: "Action",
                key: "action",
                width: 120,
                align: "right",
                render: (record) => (
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      apolloClient
                        .mutate({
                          mutation: downloadFile,
                          variables: {
                            id: record.id,
                          },
                        })
                        .then((res) => {
                          window.location.href = res.data.downloadFile;
                        })
                        .catch((err) => {
                          notification.error(err.message);
                        });
                    }}
                  >
                    <DownloadOutlined />
                    <span style={{ paddingLeft: 10 }}>Download</span>
                  </a>
                ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            const dataSource = data.orderExports.hits;
            const pagination = {
              current: page,
              total: data.orderExports.count,
              pageSize: filter.limit,
              onChange: (page, pageSize) => {
                this.setState({
                  page: page,
                  filter: {
                    ...filter,
                    offset: (page - 1) * pageSize,
                  },
                });
              },
            };
            if (dataSource)
              return (
                <div>
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: tableWidth }}
                    pagination={pagination}
                  />
                </div>
              );
          }}
        </Query>
      </Container>
    );
  }
}

export default DownloadCSV;
