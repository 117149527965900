import React, { Component } from "react";
import { Upload as AntUpload, message, Progress } from "antd";
import { Mutation } from "react-apollo";
import signedUrl from "../graphql/mutates/signedUrl";
import axios from "axios";
import { AWS_CLOUDFRONT_THUMB_URL, AWS_CLOUDFRONT_URL } from "../config";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import styled from "styled-components";

const Container = styled.div`
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    background: #f0f0f0;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: ${(props) => props.setWidth ?? "100%"};
    height: ${(props) => props.setHeight ?? "auto"};
    min-height: ${(props) => props.setHeight ?? "300px"};
  }
  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
`;

export default class Upload extends Component {
  state = {
    imageUrl: null,
    percent: 0,
    width: 0,
    height: 0,
  };
  beforeUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        this.setState({ width: width, height: height });
      });
    });
  };

  uploadAction = (signedUrl, file) => {
    const { printFiles } = this.props;
    signedUrl({
      variables: {
        filename: file.name,
        filemine: file.type,
      },
    }).then((res) => {
      var key = res.data.createSignedUrl.key;
      axios
        .put(res.data.createSignedUrl.url, file, {
          onUploadProgress: (e) => {
            // setPercent(Math.round((e.loaded / e.total) * 100));
            this.setState({ percent: Math.round((e.loaded / e.total) * 100) });
          },
          headers: { "Content-Type": file.type },
        })
        .then((res) => {
          if (printFiles) {
            this.setState({
              imageUrl: null,
            });
          } else {
            this.setState({
              imageUrl: `${AWS_CLOUDFRONT_URL}/${key}`,
            });
          }

          this.props.onChange({
            key,
            file,
            width: this.state.width,
            height: this.state.height,
          });
        })
        .catch((err) => {
          message.error(err.message);
        });
    });
  };

  render() {
    const { imageUrl, percent } = this.state;
    const {
      custom,
      multiple,
      disabled,
      imageKey,
      setWidth,
      setHeight,
    } = this.props;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Container setWidth={setWidth} setHeight={setHeight}>
        <Mutation mutation={signedUrl}>
          {(signedUrl, { data, loading }) => (
            <AntUpload
              disabled={disabled ? disabled : false}
              multiple={multiple ? multiple : false}
              {..._.omit(this.props, ["onChange"])}
              name="avatar"
              listType={!custom ? "picture-card" : null}
              className="avatar-uploader"
              showUploadList={false}
              action={(file) => this.uploadAction(signedUrl, file)}
              accept={this.props.accept ? this.props.accept : "all"}
              beforeUpload={this.beforeUpload}
            >
              {imageKey ? (
                <img
                  src={`${AWS_CLOUDFRONT_THUMB_URL}/300/${imageKey}`}
                  style={{
                    width: "100%",
                    // width: 100,
                    height: setHeight ?? "auto",
                    objectFit: "contain",
                  }}
                  alt={imageKey}
                />
              ) : percent > 0 && percent < 100 ? (
                <Progress percent={percent} type="circle" width={32} />
              ) : imageUrl && custom !== "upload-design" ? (
                <img
                  src={imageUrl}
                  alt="imageUpload"
                  style={{
                    width: "100%",
                    height: setHeight ?? "auto",
                    objectFit: "contain",
                  }}
                />
              ) : custom ? (
                <div>{this.props.children}</div>
              ) : (
                uploadButton
              )}
            </AntUpload>
          )}
        </Mutation>
      </Container>
    );
  }
}
