import { gql } from "apollo-boost";

export default gql`
  query fulfillmentProducts($filter: FulfillmentProductFilter) {
    fulfillmentProducts(filter: $filter) {
      count
      hits {
        id
        title
        variants
        printAreas
      }
    }
  }
`;
