import React, { Component } from "react";
import carriers from "../../graphql/queries/carriers";
import { apolloClient } from "../../apollo-client";
import { Select } from "antd";

class CarrierSelect extends Component {
  state = {
    carrier: [],
    value: this.props.value ?? [],
    option: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentDidMount() {
    apolloClient
      .query({
        query: carriers,
      })
      .then((res) => {
        this.setState({
          carrier: res?.data?.carriers || [],
        });
      });
  }

  render() {
    const { carrier, value } = this.state;
    const { multiple } = this.props;
    return (
      <div>
        <Select
          style={{ width: "100%" }}
          mode={multiple ? "multiple" : false}
          optionFilterProp="children"
          placeholder="Select..."
          value={value}
          onChange={(value, option) => {
            this.setState({ value, option });
            if (this.props.onChange) {
              this.props.onChange(value);
            }
          }}
        >
          {carrier.map((c) => (
            <Select.Option key={c.id}>{c.name}</Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}

export default CarrierSelect;
