import React, { Component } from "react";
import styled from "styled-components";
import { Button, Input, Select, DatePicker, Form, message } from "antd";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import moment from "moment";

const Container = styled.div``;

const { Option } = Select;

const { TextArea } = Input;

export default class CreateTaskForm extends Component {
  state = {
    users: [],
  };

  formRef = React.createRef();

  componentDidMount() {
    this.loadDesigner();
  }

  loadDesigner() {
    apolloClient
      .query({
        query: gql`
          query {
            users {
              total
              nodes {
                id
                firstName
                lastName
                roles
              }
            }
          }
        `,
      })
      .then((res) => {
        this.setState({
          users: res.data.users.nodes || [],
          loading: false,
        });
      });
  }

  onFinish = (values) => {
    const { orderId, orderSelect } = this.props;
    const orderIdSelect = orderSelect && orderSelect.map((order) => order.id);
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [String!], $input: OrderTask) {
            assignTask(orderIds: $orderIds, input: $input)
          }
        `,
        variables: {
          orderIds: orderIdSelect ? orderIdSelect : [orderId],
          input: {
            ...values,
            dueAt: moment(values.dueAt),
          },
        },
      })
      .then(() => {
        message.success("Assign task successfully");
        this.formRef.current.resetFields();
        this.props.hide();
        this.props.refetch();
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { users } = this.state;
    const designers = users.filter((user) =>
      user.roles.find((role) => role === "Designer")
    );
    return (
      <Container>
        <Form
          ref={this.formRef}
          className="form-create-task"
          style={{ width: 300 }}
          onFinish={this.onFinish}
        >
          <Form.Item
            name="userId"
            rules={[
              {
                required: true,
                message: "Please select at least one Designer!",
              },
            ]}
          >
            <Select placeholder="Select designer" style={{ minWidth: 180 }}>
              {designers.map((designer) => (
                <Option key={designer.id} value={designer.id}>
                  {designer.firstName + " " + designer.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "48% 48%",
              gridGap: "2%",
            }}
          >
            <Form.Item
              name="dueAt"
              initialValue={moment(new Date())}
              rules={[
                {
                  required: true,
                  message: "Please input your Due day!",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              initialValue="normal"
              name="priority"
              rules={[
                {
                  required: true,
                  message: "Please input priority!",
                },
              ]}
            >
              <Select style={{ width: "100%" }}>
                <Option value="low">Low</Option>
                <Option value="normal">Normal</Option>
                <Option value="high">High</Option>
                <Option value="urgent">Urgent</Option>
              </Select>
            </Form.Item>
          </div>
          <Form.Item name="comment">
            <TextArea rows={3} placeholder="Comment..." />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              htmlType="submit"
            >
              Create Task
            </Button>
            <Button onClick={this.props.hide}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}
