import React, { Component } from "react";
import { Button, Row, Col, Popconfirm, Progress } from "antd";
import { AWS_CLOUDFRONT_URL } from "../../config";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Upload from "../files/Upload";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const Container = styled.div`
  .mockup-hori {
    display: grid;
    grid-template-columns: repeat(5, 102px);
    grid-column-gap: 5px;
  }
  .image-hori {
    max-width: 100%;
    width: 102px;
    object-fit: contain;
    height: 102px;
  }
  .text-name {
    color: darkred;
    display: flex;
    justify-content: center;
    .sample-header {
      margin-right: 10px;
    }
  }
  .image-delete {
    position: relative;
    .image {
      // max-width: 100%;
      border: 1px solid #f0f0f0;
    }
    .ant-btn-sm {
      color: #ab012a;
      position: absolute;
      top: -7px;
      right: -7px;
      background: rgba(0, 0, 0, 0.2);
      padding: 2px;
      line-height: 0;
      border-radius: 100px;
      height: 15px;
      width: 15px;
    }
    .ant-btn .anticon {
      line-height: 0;
    }
    .anticon svg {
      line-height: 0;
      font-size: 10px;
    }
  }
`;
const SortableItem = SortableElement(({ mockup, deleteImage }) => (
  <div style={{ zIndex: 99 }}>
    <Row key={mockup.imageId} type="flex" gutter={[10, 10]}>
      <Col span={24} md={7} lg={6}>
        <div
          className="image-delete"
          style={{ position: "relative", width: "100%" }}
        >
          <img
            className="image"
            src={`${AWS_CLOUDFRONT_URL}/${mockup.key}`}
            alt=""
            style={{ maxWidth: 200, width: "100%" }}
          />
          <Popconfirm
            title="Are you sure delete this image?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{ type: "danger" }}
            onConfirm={() => {
              deleteImage(mockup.imageId);
            }}
            onCancel={() => this.cancel()}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              size="small"
              style={{ position: "absolute", top: "-7px", right: "-7px" }}
            >
              <CloseOutlined />
            </Button>
          </Popconfirm>
        </div>
      </Col>
      <Col span={24} md={17} lg={18}>
        <span style={{ fontSize: 12 }}>{mockup.fileName}</span>
      </Col>
    </Row>
  </div>
));
const SortableList = SortableContainer(({ value, deleteImage }) => {
  return (
    <div>
      {value?.map((image, index) => (
        <SortableItem
          key={index}
          mockup={image}
          index={index}
          deleteImage={deleteImage}
          // setPreviewImage={setPreviewImage}
        />
      ))}
    </div>
  );
});
class ProductUpload extends Component {
  state = {
    mockups: this.props.mockups ?? [],
    selectedImage: null,
    percent: 0,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mockups !== this.props.mockups) {
      this.setState({ mockups: this.props.mockups });
      this.props.onChange(this.props.mockups);
    }
  }

  cancel(e) {
    console.log(e);
  }

  changePercent = (percent) => {
    this.setState({ percent });
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onChangeSort(arrayMove(this.state.mockups, oldIndex, newIndex));
  };

  render() {
    const { mockups, selectedImage, percent } = this.state;
    const { deleteImage, type, imageVariant, sampleName, accept } = this.props;
    return (
      <Container>
        {!type ? (
          <div>
            <SortableList
              value={mockups}
              axis="y"
              distance={1}
              deleteImage={deleteImage}
              onSortEnd={this.onSortEnd}
            />
            <Col span={24} md={24} lg={24}>
              {percent === 0 || percent === 100 ? null : (
                <Progress percent={percent} type="circle" width={64} />
              )}
              <Upload
                productUpload={true}
                sampleName={sampleName}
                custom="drag-file"
                changePercent={(percent) => this.changePercent(percent)}
                multiple={true}
                onChangeMockups={(files) => {
                  this.props.onChange(files);
                  if (type) {
                    this.props.uploadImageVariant(files);
                  } else {
                    this.props.onChangeMockups(files);
                  }
                }}
                accept={accept}
              />
              {sampleName.length ? (
                <div className="text-name">
                  <div className="sample-header">Sample:</div>
                  <div style={{ textAlign: "left" }}>
                    {sampleName.map((sample, index) => (
                      <div key={index}>{sample}</div>
                    ))}
                  </div>
                </div>
              ) : null}
            </Col>
          </div>
        ) : (
          <div className="mockup-hori">
            {/* ////select variant  */}
            {mockups.map((image, index) => (
              <div
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      selectedImage: image,
                    },
                    () => {
                      const { onSelect } = this.props;
                      if (typeof onSelect === "function") {
                        onSelect(image);
                      }
                    }
                  );
                }}
                style={{
                  border:
                    selectedImage?.imageId === image.imageId
                      ? "1px dashed blue"
                      : "1px solid #f5f5f5",
                  cursor: "pointer",
                }}
              >
                <img
                  className="image-hori"
                  src={`${AWS_CLOUDFRONT_URL}/${image.key}`}
                  alt=""
                />
              </div>
            ))}
            <Upload
              accept={accept}
              productUpload={true}
              changePercent={(percent) => this.changePercent(percent)}
              onChangeMockups={(files) => {
                if (type) {
                  this.props.uploadImageVariant(files);
                } else {
                  this.props.onChangeMockups(files);
                }
              }}
            />
          </div>
        )}
      </Container>
    );
  }
}

export default ProductUpload;
