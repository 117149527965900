import React, { Component } from "react";
import { PageHeader } from "antd";
import SiteList from "../components/sites/Sites";
import { AppContext } from "../context";

class Sites extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <div>
            <PageHeader
              title="Site"
              onBack={() => this.props.history.goBack()}
            />
            <SiteList
              showDrawer={(site) => this.showDrawer(site)}
              currentUser={currentUser}
            />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default Sites;
