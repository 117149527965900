import React, { Component } from "react";
import { PageHeader } from "antd";
import TagList from "../../src/components/products/ProductTags";
class ProductTags extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Product Tags"
          onBack={() => this.props.history.goBack()}
        />
        <TagList />
      </div>
    );
  }
}

export default ProductTags;
