import React, { Component } from "react";
import {
  Table,
  Button,
  Popconfirm,
  Divider,
  message,
  Select,
  Skeleton,
  Drawer,
  Modal,
  Input,
  Tooltip,
} from "antd";
import _ from "lodash";
import { apolloClient } from "../../apollo-client";
import { Query } from "react-apollo";
import styled from "styled-components";
import { gql } from "apollo-boost";
import {
  DeleteTwoTone,
  PushpinFilled,
  ApiFilled,
  PlusOutlined,
  EditOutlined,
  KeyOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import shopify from "../../assets/images/shopify-logo.png";
import woo from "../../assets/images/logo-woocommerce.png";
import SiteForm from "./SiteForm";
import { SHOPIFY_API, API_URL, APP_URL } from "../../config";
import moment from "moment";

const Container = styled.div`
  .site-filter {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 480px) {
    .site-filter {
      flex-direction: column-reverse;
    }
    .select-filter {
      margin-top: 10px;
    }
  }
`;

export const LOAD_SITE = gql`
  query($filter: SiteFilter) {
    sites(filter: $filter) {
      count
      hits {
        id
        title
        domain
        verified
        platform
        email
        cookie
        user {
          id
          firstName
          lastName
        }
        status
        token
        cookieUpdatedAt
        fuelFulfill
      }
    }
  }
`;

export default class Sites extends Component {
  state = {
    loading: true,
    users: [],
    visible: false,
    showModal: false,
    filter: {
      userId: null,
      status: true,
      verified: true,
      limit: 20,
      offset: 0,
    },
    page: 1,
    cookie: "",
  };

  componentDidMount() {
    const { currentUser } = this.props;
    const isAdmin =
      currentUser && currentUser.roles.find((role) => role === "Administrator");
    if (isAdmin) {
      this.loadUser();
    }
  }

  loadUser() {
    apolloClient
      .query({
        query: gql`
          query {
            users {
              total
              nodes {
                id
                firstName
                lastName
              }
            }
          }
        `,
      })
      .then((res) => {
        this.setState({
          users: res.data.users.nodes || [],
          loading: false,
        });
      });
  }

  deleteSite = (id) => {
    this.setState({ loading: true });
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteSite($id: String!) {
            deleteSite(id: $id)
          }
        `,
        variables: { id },
      })
      .then(() => {
        message.success("Delete site success!");
        this.setState({
          loading: false,
        });
        this.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  showDrawer = (site) => {
    this.setState({ visible: site && site.id ? site : true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  reConnect = (site) => {
    const { id, platform, domain } = site;
    switch (platform) {
      case "woocommerce":
        var app_name = "ICTees";
        var return_url = `${APP_URL}/sites`;
        var callback_url = `${API_URL}/auth_callback/${id}`;
        var redirectUrl = `${domain}/wc-auth/v1/authorize?app_name=${app_name}&scope=read_write&user_id=1&return_url=${return_url}&callback_url=${callback_url}`;
        window.location = redirectUrl;
        break;
      case "shopify":
        var url = domain;
        var redirect_uri = `${API_URL}/auth_callback/shopify`;
        var scopes = "write_products,write_orders,write_fulfillments,write_draft_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders";
        var nonce = id;
        window.location = `${url}/admin/oauth/authorize?client_id=${SHOPIFY_API}&scope=${scopes}&redirect_uri=${redirect_uri}&state=${nonce}`;
        break;
      default:
        break;
    }
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOk = () => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation setSiteCookie($id: ID!, $cookie: String!) {
            setSiteCookie(id: $id, cookie: $cookie)
          }
        `,
        variables: {
          id: this.state.showModal.id,
          cookie: this.state.cookie,
        },
      })
      .then(() => {
        message.success("Add token success!");
        this.setState({
          showModal: false,
        });
        this.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { users, filter, visible, cookie, showModal } = this.state;
    const { currentUser } = this.props;
    const isAdmin =
      currentUser && currentUser.roles.find((role) => role === "Administrator");
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        width: 20,
      },
      {
        title: "SITE NAME",
        dataIndex: "title",
        key: "title",
        width: 120,
      },
      {
        title: "SITE URL",
        dataIndex: "domain",
        key: "domain",
        width: 250,
        render: (domain) => (
          <a href={domain} target="_blank" rel="noopener noreferrer">
            {domain}
          </a>
        ),
      },
      {
        title: "Platform",
        dataIndex: "platform",
        key: "platform",
        width: 120,
        render: (row) => {
          switch (row) {
            case "shopify":
              return <img alt="" src={shopify} width="80"></img>;
            case "woocommerce":
              return <img alt="" src={woo} width="100"></img>;
            default:
              return <span />;
          }
        },
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        width: 120,
        render: (user) => <span>{user.firstName + " " + user.lastName}</span>,
      },
      {
        title: "Status",
        dataIndex: "verified",
        key: "verified",
        width: 100,
        align: "center",
        render: (verified, site) => (
          <div>
            {verified === true ? (
              <PushpinFilled color="green" />
            ) : (
              <ApiFilled
                style={{ color: "#d92121", fontSize: 20 }}
                onClick={() => this.reConnect(site)}
              />
            )}
          </div>
        ),
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "id",
        width: 350,
        align: "right",
        render: (id, record) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                textAlign: "left",
              }}
            >
              {record.fuelFulfill ? (
                <span
                  style={{
                    color:
                      record.cookieUpdatedAt === null ||
                        moment(record.cookieUpdatedAt)
                          .add(1, "days")
                          .diff(moment(), "hours") <= 0
                        ? "#ff5500"
                        : "green",
                  }}
                >
                  Token
                  {record.cookieUpdatedAt === null ||
                    moment(record.cookieUpdatedAt)
                      .add(1, "days")
                      .diff(moment(), "hours") <= 0
                    ? " has expired"
                    : " will expire in " +
                    moment(record.cookieUpdatedAt)
                      .add(1, "days")
                      .diff(moment(), "hours") +
                    " hours"}
                </span>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                size="small"
                icon={<EditOutlined />}
                onClick={() => this.showDrawer(record)}
              />
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure delete this site?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                okButtonProps={{ type: "danger" }}
                onConfirm={() => this.deleteSite(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button size="small">
                  <DeleteTwoTone twoToneColor="#FF0000" />
                </Button>
              </Popconfirm>
              {record.fuelFulfill ? <Divider type="vertical" /> : null}
              {record.fuelFulfill ? (
                <Tooltip title="Set Fuel Token">
                  <Button
                    size="small"
                    icon={<KeyOutlined />}
                    onClick={() =>
                      this.setState({
                        showModal: record,
                        cookie: record.cookie ? record.cookie : "",
                      })
                    }
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
        ),
      },
    ];
    const tableWidth = _.sum(columns.map((c) => c.width));
    return (
      <Container>
        <div className="site-filter">
          <div className="select-filter">
            {isAdmin && (
              <Select
                value={filter.userId}
                style={{ marginRight: 10, minWidth: 150 }}
                placeholder="Filter by seller"
                onSelect={(value) => {
                  this.setState({
                    filter: { ...filter, userId: value, offset: 0 },
                    page: 1,
                  });
                }}
              >
                <Select.Option key="all" value={null}>
                  All Sellers
                </Select.Option>
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.firstName + " " + user.lastName}
                  </Select.Option>
                ))}
              </Select>
            )}
            <Select
              style={{ maxWidth: 150 }}
              defaultValue={filter.verified.toString()}
              placeholder="Verified"
              onSelect={(value) => {
                this.setState({
                  filter: { ...filter, verified: JSON.parse(value), offset: 0 },
                  page: 1,
                });
              }}
            >
              <Select.Option value="true"> Connect </Select.Option>
              <Select.Option value="false"> Pending </Select.Option>
            </Select>
          </div>
          <div>
            <Button
              style={{ float: "right" }}
              onClick={this.showDrawer}
              type="primary"
            >
              <PlusOutlined />
              &nbsp; Add Site
            </Button>
          </div>
        </div>
        <Query
          query={LOAD_SITE}
          fetchPolicy="network-only"
          variables={{ filter: this.state.filter }}
        >
          {({ error, loading, data, refetch }) => {
            if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const sites = data.sites.hits;
            this.refetch = refetch;
            return (
              <div>
                <Table
                  bordered={false}
                  scroll={{ x: tableWidth }}
                  loading={loading}
                  columns={columns}
                  dataSource={sites}
                  rowKey={(record) => record.id}
                  pagination={{
                    current: this.state.page,
                    total: data.sites.count,
                    pageSize: this.state.filter.limit,
                    showSizeChanger: false,
                    showTotal: (total, range) => `${range} of ${total}`,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...this.state.filter,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                  }}
                />
                <Drawer
                  title={typeof visible === "object" ? "Edit Site" : "Add Site"}
                  width="500"
                  placement="right"
                  closable={true}
                  onClose={this.onClose}
                  visible={visible}
                >
                  <SiteForm
                    refetch={() => refetch()}
                    onClose={this.onClose}
                    key={typeof visible === "object" && visible.id}
                    value={typeof visible === "object" ? visible : null}
                  />
                </Drawer>
                <Modal
                  title="Set Token"
                  visible={typeof showModal === "object" ? true : false}
                  onOk={() => {
                    this.handleOk();
                  }}
                  onCancel={this.handleCancel}
                  key={showModal.id}
                >
                  <Input.TextArea
                    value={cookie}
                    rows={3}
                    onChange={(e) => this.setState({ cookie: e.target.value })}
                  />
                </Modal>
              </div>
            );
          }}
        </Query>
      </Container>
    );
  }
}
