import React, { Component } from "react";
import { PageHeader } from "antd";
import OrderList from "../components/orders/Orders";
import { AppContext } from "../context";

class Orders extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ currentUser }) => (
          <div>
            <PageHeader
              title="Order"
              onBack={() => this.props.history.goBack()}
            />
            <OrderList currentUser={currentUser} />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default Orders;
