import React, { Component } from "react";
import styled from "styled-components";
import { Form, Select, Skeleton, Button } from "antd";
import productCategories from "../../graphql/queries/productCategories";
import productBases from "../../graphql/queries/productBases";
import sites from "../../graphql/queries/sites";
import { Query } from "react-apollo";

const Container = styled.div`
  width: 250px;
`;
class ProductsFilter extends Component {
  formRef = React.createRef();
  // state = {
  //   categories: null,
  //   tag: null,
  //   base: null,
  //   site: null,
  //   // tag: [],
  // };
  // handleChange = () => {
  //   const { categories, tag } = this.state;
  //   if (this.props.onChange) {
  //     this.props.onChange({ categories, tag });
  //   }
  // };
  onFinish = (values) => {
    this.props.onChange({ ...values });
  };
  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    // const { categories, base, site } = this.props.value;
    return (
      <Container>
        <Form onFinish={this.onFinish} {...layout} ref={this.formRef}>
          <Query query={productCategories}>
            {({ data, loading, error }) => {
              if (loading) return <Skeleton />;
              if (error) return <div>{error.toString()}</div>;
              return (
                <Form.Item name="categories" style={{ marginTop: 10 }}>
                  <Select mode="multiple" placeholder="Categories">
                    {data?.productCategories?.nodes.map((c) => (
                      <Select.Option value={c.id} key={c.id}>
                        {c.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Query>
          <Query query={productBases}>
            {({ data, loading, error }) => {
              if (loading) return <Skeleton />;
              if (error) return <div>{error.toString()}</div>;
              return (
                <Form.Item name="base">
                  <Select mode="multiple" placeholder="ProductBase">
                    {data?.productBases?.nodes.map((c) => (
                      <Select.Option value={c.id} key={c.id}>
                        {c.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Query>
          <Query query={sites}>
            {({ data, loading, error }) => {
              if (loading) return <Skeleton />;
              if (error) return <div>{error.toString()}</div>;
              return (
                <Form.Item name={"site"}>
                  <Select mode="multiple" placeholder="Sites">
                    {data?.sites?.hits.map((c) => (
                      <Select.Option value={c.id} key={c.id}>
                        {c.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Query>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Fillter
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              htmlType="button"
              onClick={() => {
                this.formRef.current.resetFields();
              }}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default ProductsFilter;
