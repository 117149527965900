import React, { Component } from "react";
import styled from "styled-components";
import { Button, Popover, Form, message, Select, Tooltip } from "antd";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";

const Container = styled.div`
  .ant-btn:hover,
  .ant-btn:focus {
    border: none;
  }
`;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

export default class ChangeFulfillService extends Component {
  state = {
    visible: false,
    loading: false,
    getListFulfillServiceOrder: [],
  };
  componentDidMount() {
    this.loadFulfillServiceOrder();
  }
  loadFulfillServiceOrder = () => {
    const { order } = this.props;
    apolloClient
      .query({
        query: gql`
          query($id: ID!) {
            getListFulfillServiceOrder(id: $id) {
              id
              name
              type
            }
          }
        `,
        variables: {
          id: order.id,
        },
      })
      .then((res) => {
        this.setState({
          getListFulfillServiceOrder: res.data.getListFulfillServiceOrder.filter(
            (item) =>
              item.type === "customcat" ||
              item.type === "dreamship" ||
              item.type === "fuel"
          ),
        });
      });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    const { order } = this.props;

    apolloClient
      .mutate({
        mutation: gql`
          mutation($id: ID!, $fulfillServiceId: ID!) {
            changeFulfillService(id: $id, fulfillServiceId: $fulfillServiceId)
          }
        `,
        variables: {
          id: order.id,
          fulfillServiceId: values.fulfillServiceId,
        },
      })
      .then(() => {
        message.success("Change Fulfill Service success", 5);
        this.setState({
          visible: false,
          loading: false,
        });
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
        this.setState({ loading: false });
      });
    //}
  };

  render() {
    const { visible, loading, getListFulfillServiceOrder } = this.state;
    const { order, title, titleTootip } = this.props;

    const content = (
      <Form
        {...layout}
        className="form-create-task"
        style={{ width: 300 }}
        onFinish={this.onFinish}
      >
        <Form.Item
          label="FulfillService "
          name="fulfillServiceId"
          initialValue={order.fulfillServiceId}
          rules={[
            {
              required: true,
              message: "Please input fulfillService!",
            },
          ]}
        >
          <Select>
            {getListFulfillServiceOrder.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div>
          <Button loading={loading} type="primary" htmlType="submit">
            Change
          </Button>
        </div>
      </Form>
    );
    return (
      <Container>
        {order.fulfillService.type !== "csv" ? (
          <Tooltip title={titleTootip ? titleTootip : null}>
            <div style={{ cursor: "pointer" }}>
              <Popover
                content={content}
                trigger="click"
                placement="left"
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
              >
                <a href="/#" onClick={(e) => e.preventDefault()}>
                  {title ? title : "Change FulfillService"}
                </a>
              </Popover>
            </div>
          </Tooltip>
        ) : null}
      </Container>
    );
  }
}
