import React, { Component } from "react";
import {
  Table,
  Button,
  Skeleton,
  Drawer,
  Divider,
  Popconfirm,
  notification,
  Input,
  Modal,
} from "antd";
import styled from "styled-components";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import fulfillServices from "../../graphql/queries/fulfillServices";
import { Query } from "react-apollo";
import FulfillmentForm from "./FulfillmentForm";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import _ from "lodash";

const Container = styled.div`
  .p-filter {
    display: flex;
    margin: 20px 0;
    .p-select {
      flex-grow: 1;
    }
  }
`;
class Fulfillments extends Component {
  state = {
    filter: {
      // status: true,
      limit: 20,
      offset: 0,
    },
    page: 1,
    showDrawer: false,
    configkeys: false,
    key: "",
  };
  onClose = () => {
    this.setState({
      showDrawer: false,
    });
  };
  onDelete = (id, refetch) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation deleteFulfillService($id: ID!) {
            deleteFulfillService(id: $id)
          }
        `,
        variables: { id },
      })
      .then(() => {
        notification.success({ message: "Delete success!" });
      });
  };
  updateApiKey = (id, apiKey, refetch) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation updateApiKey($id: ID!, $apiKey: ID!) {
            updateApiKey(id: $id, apiKey: $apiKey)
          }
        `,
        variables: { id: id, apiKey: apiKey },
      })
      .then(() => {
        notification.success({ message: "Update api key success!" });
        this.setState({ configkeys: false, key: "" });
        refetch();
      })
      .catch((err) => {
        notification.error({
          message: err.message,
        });
        this.setState({ configkeys: false, key: "" });
      });
  };
  render() {
    const { filter, showDrawer, page } = this.state;
    return (
      <Container>
        <div className="p-filter">
          <div className="p-select">
            {/* <Select
              defaultValue={filter.status ? "true" : "false"}
              onChange={(e) =>
                this.setState({ filter: { ...filter, status: JSON.parse(e) } })
              }
              style={{ width: 200 }}
            >
              <Select.Option value="true">Action</Select.Option>
              <Select.Option value="false">Inaction</Select.Option>
            </Select> */}
          </div>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => this.setState({ showDrawer: true })}
          >
            <PlusOutlined /> Add fulfillment
          </Button>
        </div>
        <Query
          query={fulfillServices}
          variables={{ filter: { ...filter } }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error, refetch }) => {
            // if (loading) return <Skeleton />;
            if (error) return <div>{error.toString()}</div>;
            const dataSource = data?.FulfillServices.nodes;
            const columns = [
              { title: "ID", key: "id", dataIndex: "id", width: 120 },
              { title: "Name", key: "name", dataIndex: "name", width: 150 },
              {
                title: "CSV Template",
                key: "id",
                render: (record) => (
                  <div>
                    {record &&
                      record.templates &&
                      record.templates.map((t) => t.name).join(", ")}
                  </div>
                ),
                width: 400,
              },
              {
                title: "Type",
                key: "id",
                render: (record) => <div>{record.type}</div>,
                width: 100,
              },
              {
                title: "Action",
                key: "action",
                align: "right",
                width: 120,
                render: (record) => (
                  <div>
                    <Button
                      size="small"
                      onClick={() =>
                        this.setState({
                          configkeys: record,
                          key: record.apiKey,
                        })
                      }
                      disabled={["csv", "fuel"].includes(record.type)}
                    >
                      <KeyOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      size="small"
                      onClick={() => this.setState({ showDrawer: record })}
                      disabled={
                        record.type === "customcat" ||
                        record.type === "dreamship"
                      }
                    >
                      <EditOutlined />
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Are you sure delete fulfillment?"
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okButtonProps={{ type: "danger" }}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => {
                        this.onDelete(record.id, refetch());
                      }}
                    >
                      <Button
                        style={{ color: "red" }}
                        size="small"
                        disabled={
                          record.type === "customcat" ||
                          record.type === "dreamship" ||
                          record.type === "fuel"
                        }
                      >
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </div>
                ),
              },
            ];
            const tableWidth = _.sum(columns.map((c) => c.width));
            return (
              <div>
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={dataSource}
                  rowKey={(index, key) => key}
                  scroll={{ x: tableWidth }}
                  pagination={{
                    current: page,
                    pageSize: filter.limit,
                    total: data?.FulfillServices?.total,
                    onChange: (page, pageSize) => {
                      this.setState({
                        page: page,
                        filter: {
                          ...filter,
                          offset: (page - 1) * pageSize,
                        },
                      });
                    },
                  }}
                />

                <Drawer
                  width={600}
                  title={`${
                    showDrawer.id ? "Edit fulfillment" : "Add fulfillment"
                  }`}
                  visible={showDrawer}
                  onClose={this.onClose}
                >
                  <FulfillmentForm
                    id={showDrawer && showDrawer.id}
                    key={showDrawer && showDrawer.id}
                    showDrawer={showDrawer}
                    onClose={this.onClose}
                    refetch={() => refetch()}
                  />
                </Drawer>
                <Modal
                  title="Config keys"
                  visible={this.state.configkeys}
                  footer={[
                    <Button
                      onClick={() => {
                        this.setState({ configkeys: false });
                      }}
                    >
                      Cancel
                    </Button>,
                    <Button
                      onClick={() => {
                        this.updateApiKey(
                          this.state.configkeys.id,
                          this.state.key,
                          refetch
                        );
                      }}
                      type="primary"
                      disabled={this.state.key === ""}
                    >
                      Save
                    </Button>,
                  ]}
                  onCancel={() => {
                    this.setState({ configkeys: false });
                  }}
                >
                  <Input
                    value={this.state.key}
                    onChange={(value) => {
                      this.setState({ key: value.target.value });
                    }}
                  />
                </Modal>
              </div>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default Fulfillments;
