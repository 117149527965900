import { Select, Skeleton } from "antd";
import React, { useState } from "react";
import { Query } from "react-apollo";
import fulfillmentProducts from "../../graphql/queries/orders/fulfillmentProducts";

const SelectDSName = ({ onChange }) => {
  const [product, setProduct] = useState();
  const [printAreas, setPrintAreas] = useState();
  return (
    <div>
      <Query
        query={fulfillmentProducts}
        fetchPolicy="no-cache"
        variables={{ filter: { fulfillmentName: "dreamship", limit: -1 } }}
      >
        {({ error, loading, data, refetch }) => {
          if (loading) return <Skeleton />;
          if (error) return <div>{error.toString()}</div>;
          return (
            <div>
              <p>Product:</p>
              <Select
                style={{ width: "100%", marginBottom: 20 }}
                onChange={(value, data) => {
                  setProduct(value);
                  setPrintAreas(data.printAreas);
                }}
                value={product}
                showSearch
                filterOption={(input, option) =>
                  option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {data.fulfillmentProducts.hits.map((item) => (
                  <Select.Option
                    value={item.id}
                    printAreas={item.printAreas}
                    key={item.id}
                    search={item.title}
                  >
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
              {product ? (
                <div>
                  <p>DS Name:</p>
                  <Select
                    style={{ width: "100%", display: "block" }}
                    onChange={(value) => {
                      onChange(value);
                    }}
                    showSearch
                  >
                    {printAreas.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.key}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              ) : null}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default SelectDSName;
