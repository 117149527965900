import { gql } from "apollo-boost";

export default gql`
  query orders($filter: OrderFilter) {
    orders(filter: $filter) {
      count
      aggs {
        status
        count
      }
      hits {
        id
        name
        note
        quantity
        baseCost
        price
        carrierId
        customer
        shipping
        shippingMethod
        push_status
        variant_title
        submitOrderId
        push_log
        carrier {
          id
          name
          code
          description
          trackingUrl
        }
        fulfillServiceId
        fulfillService {
          id
          name
          status
          type
          templates {
            id
            name
            data
          }
        }
        siteId
        site {
          id
          title
          cookieUpdatedAt
          platform
        }
        originId
        push_status
        customize
        variant_title
        status
        fileName
        createdAt
        printFiles {
          id
          side
          fileID
          ordering
          file {
            id
            source
            key
            fileName
            fileMime
            fileSize
            url
            thumbnail
            width
            height
          }
          baseDesignId
          baseDesign {
            id
            name
            height
            width
          }
        }
        product {
          id
          sku
          title
          images
          productType
          designStatus
          designs {
            id
            fileId
            file {
              id
              source
              key
              fileName
              fileMime
              fileSize
              url
              thumbnail
            }
          }
          sites {
            id
            permalink
            siteId
          }
          mockups {
            id
            fileId
            file {
              id
              source
              key
              fileName
              fileMime
              fileSize
              url
              thumbnail
            }
          }
          isCustomize
        }
        originPrintFileUrl
        originPrintFileThumbUrl
        task {
          id
          user {
            id
            firstName
            lastName
          }
        }
        item_id
        originLineItemID
        comment
        variant {
          id
          attributes
          sku
          productBase {
            id
            title
            autoRender
            type {
              origin
            }
          }
          productBaseVariant {
            id
            size
            ccId
            dsId
          }
          ccId
          dsId
        }
        trackings {
          id
          carrierCode
          code
          trackingUrl
          createdAt
          updatedAt
          deletedAt
          fuelTrackUrl
        }
      }
    }
  }
`;
