import { gql } from "apollo-boost";

export default gql`
  mutation createCategory($input: NewCategory!) {
    createCategory(input: $input) {
      id
      title
      description
      status
    }
  }
`;
