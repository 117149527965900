import React, { Component } from "react";
import { PageHeader } from "antd";
import CategoryList from "../components/categories/Categories";
class Categories extends Component {
  render() {
    return (
      <div>
        <PageHeader
          title="Categories"
          onBack={() => this.props.history.goBack()}
        />
        <CategoryList />
      </div>
    );
  }
}

export default Categories;
