import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Select, notification, Divider } from "antd";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { LOAD_SITE } from "../sites/Sites";
import { apolloClient } from "../../apollo-client";
import Icon from "@ant-design/icons";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

let index = 0;

const CREATE_MUTATION = gql`
  mutation addMapping($input: NewMapping!) {
    addMapping(input: $input) {
      id
      group
      parent_id
      parent {
        id
        origin
        target
      }
      origin
      target
      category
      printType
    }
  }
`;

const EDIT_MUTATION = gql`
  mutation editMapping($input: EditMapping!) {
    editMapping(input: $input) {
      id
      group
      parent_id
      parent {
        id
        origin
        target
      }
      origin
      target
      category
      printType
    }
  }
`;

class MappingForm extends Component {
  state = {
    loading: false,
    sites: [],
    items: [
      "Shirts",
      "Sweatshirts",
      "Embroidered Hats",
      "Mugs",
      "Posters",
      "Phone Cases",
      "Bags",
    ],
    name: "",
  };

  componentDidMount() {
    if (this.props.type) {
      this.loadSites();
    }
  }

  loadSites() {
    this.setState({ loading: true });
    apolloClient
      .query({
        query: LOAD_SITE,
      })
      .then((res) => {
        this.setState({
          sites: res.data.sites.hits || [],
          loading: false,
        });
      });
  }

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  addItem = () => {
    const { items, name } = this.state;
    this.setState({
      items: [...items, name || `New item ${index++}`],
      name: "",
    });
  };

  render() {
    const { onDone, group, productTypes, store, type } = this.props;
    const { items, name } = this.state;
    return (
      <Mutation
        onError={(e) => {
          notification.error({ message: e.toString() });
        }}
        onCompleted={(res) => {
          if (store && type === null) {
            onDone(res.editMapping);
          } else onDone(res.addMapping);
        }}
        mutation={store && type === null ? EDIT_MUTATION : CREATE_MUTATION}
      >
        {(mutation) => (
          <Form
            {...layout}
            onFinish={(values) => {
              this.setState(
                {
                  loading: true,
                },
                () => {
                  if (store && type === null) {
                    mutation({
                      variables: {
                        input: {
                          ...values,
                          id: store.id,
                        },
                      },
                    });
                  } else {
                    mutation({
                      variables: {
                        input: {
                          ...values,
                          ...{
                            group: group,
                          },
                        },
                      },
                    });
                  }
                }
              );
            }}
          >
            {(group === "Size" || group === "Color") && (
              <Form.Item
                name={"parent_id"}
                label={"Product Type"}
                rules={[
                  { required: true, message: "Product type is required!" },
                ]}
                initialValue={store && store ? store.parent_id : ""}
              >
                <Select>
                  {productTypes.map((p, index) => (
                    <Select.Option key={index} value={p.id}>
                      {p.origin}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name={"origin"}
              label={"Store"}
              rules={[{ required: true, message: "Store value is required!" }]}
              initialValue={store ? store.origin : ""}
            >
              <Input placeholder={"Store"} />
            </Form.Item>
            {group !== "Size" && group !== "Color" && (
              <Form.Item
                name={"category"}
                label={"Category"}
                initialValue={store ? store.category : ""}
              >
                <Select
                  style={{ width: 240 }}
                  placeholder="custom dropdown render"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={name}
                          onChange={this.onNameChange}
                        />
                        <a
                          href="/#"
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            this.addItem();
                          }}
                        >
                          <Icon type="plus" /> Add item
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {items.map((item) => (
                    <Select.Option key={item}>{item}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              name={"target"}
              label={"Fuel Identifier ID"}
              rules={[{ required: true, message: "Fuel value is required!" }]}
              initialValue={store ? store.target : ""}
            >
              <Input placeholder={"Fuel"} />
            </Form.Item>
            {group === "Product Type" && (
              <Form.Item
                name={"printType"}
                label={"Fuel Print Type"}
                rules={[
                  { required: true, message: "Fuel print type required!" },
                ]}
                initialValue={store ? store.printType : ""}
              >
                <Input placeholder={"Fuel Print Type"} />
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Mutation>
    );
  }
}

MappingForm.propTypes = {
  onDone: PropTypes.func,
  group: PropTypes.string,
  productTypes: PropTypes.any,
};

export default MappingForm;
