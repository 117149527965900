import React, { Component } from "react";
import { Form, Input, Button, notification } from "antd";
import styled from "styled-components";
import createProductCategory from "../../graphql/mutates/createProductCategory";
import updateProductCategory from "../../graphql/mutates/updateProductCategory";
import { apolloClient } from "../../apollo-client";

const Container = styled.div`
  .btn {
    margin-right: 5px;
  }
`;
class ProductCategoryForm extends Component {
  formRef = React.createRef();
  onFinish = (values) => {
    const id = this.props.showDrawer?.id;
    if (!id) {
      apolloClient
        .mutate({
          mutation: createProductCategory,
          variables: {
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Add category success!" });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        });
    }
    if (id) {
      apolloClient
        .mutate({
          mutation: updateProductCategory,
          variables: {
            id: id,
            input: values,
          },
        })
        .then(() => {
          notification.success({ message: "Edit category success!" });
          this.formRef.current.resetFields();
          this.props.refetch();
          this.props.onClose();
        });
    }
  };

  render() {
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const { onClose, showDrawer } = this.props;
    return (
      <Container>
        <Form ref={this.formRef} {...layout} onFinish={this.onFinish}>
          <Form.Item
            label="Name"
            name="title"
            rules={[{ required: true, message: "Please input name!" }]}
            initialValue={showDrawer ? showDrawer.title : ""}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            initialValue={showDrawer.description}
          >
            <Input.TextArea placeholder="Description" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn">
              {showDrawer.id ? "Save change" : "Save"}
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Form.Item>
        </Form>
      </Container>
    );
  }
}

export default ProductCategoryForm;
