import React, { Component } from "react";
import styled from "styled-components";
import { Button, Popover, message, Tabs, Popconfirm, notification } from "antd";
import ImageUrl from "../ImageUrl";
import { apolloClient } from "../../apollo-client";
import { gql } from "apollo-boost";
import removePersonalizedPrintFiles from "../../graphql/mutates/removePersonalizedPrintFiles";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

const Container = styled.div`
  .ant-tabs-tabpane {
    position: relative;
  }
`;

const { TabPane } = Tabs;

export default class DesignConfirmationPersonalized extends Component {
  state = {
    visible: false,
    fileUrl: [],
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleDeletePrintFiles = (printFileID) => {
    const { orderId } = this.props;

    apolloClient
      .mutate({
        mutation: removePersonalizedPrintFiles,
        variables: {
          orderID: orderId,
          printFileIDs: [printFileID],
        },
      })
      .then(() => {
        notification.success({ message: "Delete print files successful!" });
        this.props.refetch();
      })
      .catch((err) => {
        notification.error(err.message);
      });
  };

  onFinish = (values) => {
    apolloClient
      .mutate({
        mutation: gql`
          mutation($orderIds: [ID!], $status: String!) {
            updateOrderStatus(orderIds: $orderIds, status: $status)
          }
        `,
        variables: {
          orderIds: [this.props.orderId],
          status: values,
        },
      })
      .then(() => {
        message.success("Status order has been move to " + values);
        this.setState({ visible: false });
        this.props.refetch();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  render() {
    const { visible } = this.state;
    const {
      hidePopover,
      renderFileNameFromPrintFiles,
      orderId,
      size,
    } = this.props;
    const content = (
      <div
        style={{
          width: hidePopover ? "100%" : 200,
          padding: hidePopover ? 10 : "auto",
          background: "white",
        }}
      >
        <Tabs defaultActiveKey="front" tabBarExtraContent={<div>{size}</div>}>
          {
            // _.sortBy(
            //   renderFileNameFromPrintFiles,
            //   (printFile) => printFile.key !== "Front"
            // )
            _.sortBy(
              renderFileNameFromPrintFiles,
              (printFile) => printFile.ordering
            ).map(
              (item) =>
                item.file.id && (
                  <TabPane
                    tab={`${item.key} ${item.file.width
                        ? `(${item.file.width} x ${item.file.height}) `
                        : ""
                      } `}
                    key={item.key}
                  >
                    <div
                      onClick={() => this.props.onClickShowModal()}
                      style={{ cursor: "pointer" }}
                    >
                      <ImageUrl
                        widthImage={hidePopover ? "100%" : "200px"}
                        heightImage={hidePopover ? "auto" : "200px"}
                        imageKey={
                          item.file
                            //? item.file.thumbnail ?? item.file.key
                            ? item.file.key
                            : null
                        }
                      />
                    </div>
                    {item.file?.id && orderId && (
                      <Popconfirm
                        title="Are you sure delete this print file?"
                        onConfirm={() => this.handleDeletePrintFiles(item.id)}
                        onCancel={() => console.log("cancel")}
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        okButtonProps={{ type: "danger" }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <a
                          href="/#"
                          onClick={(e) => e.preventDefault()}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            background: "rgba(0, 0, 0, 0.35)",
                            width: 20,
                            height: 20,
                            zIndex: 200,
                            textAlign: "center",
                          }}
                        >
                          <CloseOutlined />
                        </a>
                      </Popconfirm>
                    )}
                  </TabPane>
                )
            )
          }
        </Tabs>
        {hidePopover ? null : (
          <div style={{ marginTop: 15 }}>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => this.onFinish("designed")}
            >
              Approve
            </Button>
            <Button onClick={() => this.onFinish("rejected")}>Reject</Button>
          </div>
        )}
      </div>
    );
    return (
      <Container>
        {hidePopover ? (
          <div>{content}</div>
        ) : (
          <div>
            <Popover
              content={content}
              trigger="click"
              visible={visible}
              placement="bottom"
              onVisibleChange={this.handleVisibleChange}
            >
              <a href="/#">Design Confirm</a>
            </Popover>
          </div>
        )}
      </Container>
    );
  }
}
